import axiosConfig from 'utils/api';

export const billing = {
  getPlans: {
    action: () => axiosConfig.get('/api/payment/v1/get_user_prices'),
    cancel: () => null,
  },
  getBillingSession: {
    action: ({ userId, priceId }) =>
      axiosConfig.get(`/api/payment/v1/create_session?user_uid=${userId}&price_id=${priceId}`),
    cancel: () => null,
  },
  getBillingPaymentSession: {
    action: sessionId => axiosConfig.get(`/api/payment/v1/get_session?session_id=${sessionId}`),
    cancel: () => null,
  },
};
