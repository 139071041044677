export default [
  {
    id: 'node-1',
    type: 'buttonnode',
    position: { x: 0, y: 0 },
    data: { text: 'Incoming lead', className: 'incoming-node', isHandleBottom: true, type: 'incoming-lead' },
  },
  {
    id: 'create-else-1',
    type: 'buttonnode',
    position: { x: 0, y: 100 },
    data: {
      text: '+',
      isHandleTop: true,
      isHandleBottom: true,
      className: 'create-node-else',
      type: 'create-else',
    },
    className: 'create-else',
  },
  {
    id: 'e-2',
    source: 'create-else-1',
    target: 'create-button',
    type: 'smoothstep',
  },
  {
    id: 'create-button',
    type: 'buttonnode',
    position: { x: 0, y: 10 },
    data: {
      label: 'Finish',
      text: '',
      isHandleTop: true,
      className: 'create-node',
      type: 'create',
    },
  },
  {
    id: 'e-1',
    source: 'node-1',
    target: 'create-else-1',
    type: 'smoothstep',
  },
  {
    id: 'e-3',
    source: 'node-1',
    target: 'default-fallback',
    type: 'smoothstep',
  },
  {
    id: 'default-fallback',
    type: 'buttonnode',
    position: { x: 0, y: 100 },
    data: {
      text: 'Default Fallback',
      isHandleBottom: true,
      isHandleTop: true,
      className: 'default-fallback-node',
      type: 'default-fallback',
      functional: true,
    },
  },
  {
    id: 'e-4',
    source: 'default-fallback',
    target: 'create-else-fallback',
    type: 'smoothstep',
  },
  {
    id: 'create-else-fallback',
    type: 'buttonnode',
    position: { x: 200, y: 100 },
    data: {
      text: '+',
      isHandleTop: true,
      isHandleBottom: true,
      className: 'create-node-else',
      type: 'create-else',
    },
    className: 'create-else',
  },
];
