import React, { useState, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

export const ChatForm = ({ onKeyDownTextarea, onKeyUpTextarea, textareaRef }) => {
  return (
    <div className="nk-chat-editor-form">
      <div className="form-control-wrap">
        <TextareaAutosize
          className="form-control form-control-simple no-resize"
          rows="1"
          id="default-textarea"
          placeholder="Type your message..."
          onKeyDown={onKeyDownTextarea}
          onKeyUp={onKeyUpTextarea}
          ref={textareaRef}
        />
      </div>
    </div>
  );
};
