import React, { useState, useEffect, useRef } from 'react';

const PlaceholderInput = ({ element }) => {
  const inputRef = useRef(null);

  const [value, setValue] = useState(element?.getAttribute('placeholder') || '');

  const onChange = ({ target }) => setValue(target.value);

  useEffect(() => {
    if (element) {
      element.setAttribute('placeholder', value);
    }
  }, [value]);

  return (
    <div className="form-group mt-4">
      <label className="form-label">Placeholder</label>
      <input type="text" required="" className="form-control" onChange={onChange} ref={inputRef} value={value} />
    </div>
  );
};

export default PlaceholderInput;
