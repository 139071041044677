import axiosConfig from 'utils/api';

export const extraFields = {
  getFields: {
    action: () => axiosConfig.get('/api/v1/get_user_extra_fields'),
    cancel: () => null,
  },
  addFields: {
    action: (params = []) => axiosConfig.post('/api/v1/add_client_extra_fields', params),
    cancel: () => null,
  },
  removeField: {
    action: id => axiosConfig.delete(`/api/v1/delete_extra_field/${id}`),
    cancel: () => null,
  },
  patchField: {
    action: (params = {}) => axiosConfig.patch(`/api/v1/edit_extra_field/${params.id}`, params),
    cancel: () => null,
  },
};
