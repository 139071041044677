import axiosConfig, { axiosUtilsConfig } from 'utils/api';

export const flow = {
  create: {
    action: (params = {}) => axiosConfig.post('/api/flows/v1/create_flow', params),
    cancel: () => null,
  },
  update: {
    action: (flowId, params = {}) => axiosConfig.patch(`/api/flows/v1/update_flow?flow_id=${flowId}`, params),
    cancel: () => null,
  },
  // update: {
  //   action: (params = {}) =>
  //     axiosConfig.post(`update_popup?popup_id=${params.id}&active=${params.active}`, params.body),
  //   cancel: () => null,
  // },
  list: {
    action: () => axiosConfig.get(`/api/flows/v1/get_flows`),
    cancel: () => null,
  },
  delete: {
    action: id => axiosConfig.delete(`/api/flows/v1/delete_flow`, { params: { flow_id: id } }),
    cancel: () => null,
  },
  publichFlow: {
    action: (id, type) => axiosConfig.patch(`/api/flows/v1/push?flow_id=${id}&to_prod=${type}`),
    cancel: () => null,
  },
  getFlow: {
    action: flow_id => axiosConfig.get(`/api/flows/v1/get_flow_by_id?flow_id=${flow_id}`),
    cancel: () => null,
  },
  getPublicFlow: {
    action: flow_id => axiosConfig.get(`/api/flows/share/v1/get_public_flow_by_id?flow_id=${flow_id}`),
    cancel: () => null,
  },
  getFunctions: {
    action: () => axiosConfig.get('/api/flows/v1/get_functions'),
    cancel: () => null,
  },
  uploadImage: {
    action: imageUpload => axiosUtilsConfig.post('/api/flows/v1/upload_image', imageUpload),
    cancel: () => null,
  },
  uploadVideo: {
    action: videoUpload => axiosUtilsConfig.post('/api/flows/v1/upload_video', videoUpload),
    cancel: () => null,
  },
  updateSharing: {
    action: (params = {}) =>
      axiosConfig.patch(`/api/flows/v1/share_flow?flow_id=${params.flow_id}&is_public=${params.is_public}`),
    cancel: () => null,
  },
  getAnalytics: {
    action: (flow_id, params = {}) =>
      axiosConfig.get(`/api/flow_analytics/v1/get_flow_start_stats?flow_id=${flow_id}`, { params }),
    cancel: () => null,
  },
  getNodesStats: {
    action: flow_id => axiosConfig.get(`/api/flow_analytics/v1/get_nodes_stats?flow_id=${flow_id}`),
    cancel: () => null,
  },
  getVersions: {
    action: flow_id => axiosConfig.get(`/api/flows/v1/get_flow_versions?flow_id=${flow_id}`),
    cancel: () => null,
  },
  getFlowByVersion: {
    action: (flow_id, version) =>
      axiosConfig.get(`/api/flows/v1/get_flow_version_by_id?flow_id=${flow_id}&version=${version}`),
    cancel: () => null,
  },
};
