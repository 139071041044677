import React from 'react';
import { Link } from 'react-router-dom';

export const BillingError = () => {
  return (
    <div style={{ width: '100%' }}>
      <div className="mb-4 text-center">
        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger" />
        <h4 className="nk-modal-title">Error</h4>
        <div className="nk-modal-action">
          <Link to={{ pathname: `/billing` }} className="btn btn-lg btn-mw btn-primary">
            Back to Billing
          </Link>
        </div>
      </div>
    </div>
  );
};
