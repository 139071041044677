import React, { useEffect } from 'react';
import { useRequest } from 'estafette';
import { billing } from 'libs/http/api/billing';

import { Link } from 'react-router-dom';
import { DataPreloader } from 'components';

export const BillingSuccess = () => {
  const { request: requestPayment, loading: loadingPayment, data: dataPayment } = useRequest({ loading: true });

  const getUserInfo = () => {
    const sessionId = localStorage.getItem('payment_session');
    requestPayment(billing.getBillingPaymentSession.action(sessionId));
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <div className="mb-4 text-center">
        <DataPreloader loading={loadingPayment}>
          {dataPayment?.subscription?.status === 'active' ? (
            <>
              <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success" />
              <h4 className="nk-modal-title">Payment Sucess!</h4>
            </>
          ) : (
            <>
              <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger" />
              <h4 className="nk-modal-title">Status: innactive</h4>
            </>
          )}

          <div className="nk-modal-action">
            <Link to={{ pathname: `/billing` }} className="btn btn-lg btn-mw btn-primary">
              Back to Billing
            </Link>
          </div>
        </DataPreloader>
      </div>
    </div>
  );
};
