import React from 'react';

import './HeaderModalPreview.scss';

export const HeaderModalPreview = ({ onChangeView, activeView }) => (
  <div className="header-modal-preview">
    <div className="inner flex">
      <div className={`item-button ${activeView === 'edit' && 'active'}`} onClick={() => onChangeView('edit')}>
        <i className="fas fa-cogs" />
        Edit
      </div>
      <div className={`item-button ${activeView === 'desktop' && 'active'}`} onClick={() => onChangeView('desktop')}>
        <i className="fas fa-desktop" />
        Desktop
      </div>
      <div className={`item-button ${activeView === 'mobile' && 'active'}`} onClick={() => onChangeView('mobile')}>
        <i className="fas fa-mobile-alt" />
        Mobile
      </div>
    </div>
  </div>
);
