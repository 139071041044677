import React from 'react';

const Line = ({ columns, onDragstartHandler, onClick }) => {
    return (
        <div className="tuls-line" draggable="true" onDragStart={onDragstartHandler} onClick={onClick}>
            {columns.map(col => (
                <div className="tuls-column tuls-column-empty" data-width={col} />
            ))}
        </div>
    );
};

export default Line;