import React from 'react';

import './Table.scss';

const Table = ({ className, children }) => {
  return <table className={`${className ? className : ''}`}>{children}</table>;
};

Table.Tr = ({ className, title, children }) => (
  <tr className={`${className ? className : ''}`} title={title}>
    {children}
  </tr>
);

Table.Th = ({ className, children }) => <th className={className ? className : ''}>{children}</th>;

Table.Td = ({ className, children }) => <td className={`${className ? className : ''}`}>{children}</td>;

export default Table;
