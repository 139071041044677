import React, { useRef, useState, useEffect } from 'react';

import imageIcon from 'assets/images/icons/image.svg';

// async function readFile(file) {
//   return new Promise((resolve, reject) => {
//     var fr = new FileReader();
//     fr.onload = () => {
//       resolve(fr.result);
//     };
//     fr.onerror = reject;
//     fr.readAsDataURL(file);
//   });
// }

const BackgroundImage = ({ element, styleName, item }) => {
  const [value, setValue] = useState(null);
  const [randName] = useState(Math.round());
  const backgroundImageRef = useRef(null);

  const onChangeBackgroundImage = async e => {
    const file = e.target.files[0];
    const objectUrl = URL.createObjectURL(file);
    // const objectUrl = await readFile(file);
    console.log(element, styleName, item);
    backgroundImageRef.current.style[styleName] = `url("${objectUrl}")`;
    element.style[styleName] = `url("${objectUrl}")`;
    element.classList.remove('tuls-column-empty');
  };

  useEffect(() => {
    if (element && element.style) {
      if (element.style[styleName]) {
        setValue([element.style[styleName]]);
      }
    }
  }, [element]);

  return (
    <div className="form-group mt-4">
      <div className="background-image mt-25">
        <label className="form-label">{item.label}</label>

        <div className="flex">
          <div className="image">
            <div
              style={{
                backgroundImage: value || `url("${imageIcon}")`,
                height: '110px',
                backgroundSize: 'contain',
              }}
              ref={backgroundImageRef}
            />
          </div>

          <div>
            <div className="form-group">
              <div className="select-file mt-28">
                <input
                  type="file"
                  accept="image/*"
                  className="custom-file-input"
                  id={`backgroundFile-${randName}`}
                  onChange={onChangeBackgroundImage}
                  size="409600"
                />

                <label className="custom-file-label" htmlFor={`backgroundFile-${randName}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackgroundImage;
