import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useRequest } from 'estafette';
import { Helmet } from 'react-helmet';
import { form } from 'libs/http/api/form';
import { Header, EmptyData } from 'components';
import Table from 'components/TemplateBuilder/components/Table/Table';
import { DataPreloader } from 'components/DataPreloader/DataPreloader';
import { Modal } from 'components/Modal/Modal';
import { useGTM } from '../../hooks/useGTM';

import './../../assets/styles/FormList.scss';

const FormList = () => {
  const textareaCodeRef = useRef(null);
  const { sendEvent } = useGTM();

  const { request: requestForms, data: dataForms, loading: loadingForms } = useRequest({ loading: true });
  const { request: requestDelete, loading: loadingDelete } = useRequest({});
  const { request: requestPatchActive, loading: loadingPatchActive } = useRequest({});
  const { request: requestStatistik, data: dataStatistik } = useRequest({});

  const [activeForm, setActiveForm] = useState(null);
  const [isCodeModal, setIsCodeModal] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const onToggleDropdown = uid => {
    if (uid) {
      setActiveDropdown(uid);
    } else {
      setActiveDropdown(null);
    }
  };

  const onSetActiveForm = uid => {
    if (uid) {
      setActiveForm(uid);
    } else {
      setActiveForm(null);
    }
  };

  const onToggleCodeModal = stat => setIsCodeModal(stat);

  const onDeleteForm = async uid => {
    await requestDelete(form.delete.action(uid));
    requestForms(form.list.action({}));
  };

  const onActiveForm = async (e, uid) => {
    await requestPatchActive(form.statusUpdate.action(uid, e.target.value === 'true' ? 0 : 1));
    requestForms(form.list.action({}));
  };

  useEffect(() => {
    if (isCodeModal) {
      textareaCodeRef.current.value = `<div id="tf-wrapper"><script type="text/javascript" src="https://api.ezflow.cc/utils/get_form_script?form_id=${activeForm}"></script></div>`;
      textareaCodeRef.current.select();
    }
  }, [isCodeModal, activeForm]);

  useEffect(() => {
    requestForms(form.list.action({}));
    requestStatistik(form.getStatistik.action({}));
  }, []);

  return (
    <>
      <Helmet>
        <title>My forms</title>
      </Helmet>

      <div className="nk-main">
        <div className="nk-wrap">
          <Header />

          <DataPreloader loading={loadingForms || loadingDelete || loadingPatchActive}>
            <div className="nk-content " style={{ marginTop: '70px' }}>
              <div className="container-fluid">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    {/* <PageTitle title={'Forms'}>
              <p>My forms</p>
            </PageTitle> */}
                    {dataForms?.forms?.length === 0 ? (
                      <EmptyData
                        title="No growth tools yet"
                        description="You don't have any growth tools yet. You can create first one – right now"
                        backgroundImage="https://res.cloudinary.com/fosh/image/upload/v1641740563/empty_01_qd6wh9.png"
                      >
                        <Link
                          onClick={() => sendEvent('create_form')}
                          to={{ pathname: '/form-builder' }}
                          className="btn btn-primary mb-3"
                        >
                          Create form
                        </Link>
                      </EmptyData>
                    ) : (
                      <div>
                        <div className="text-right">
                          <Link to={{ pathname: '/form/analytics' }} className="btn btn-primary mr-25">
                            <em className="icon ni ni-growth mr-1" style={{ marginBottom: '2px' }} />
                            View all analytics
                          </Link>

                          <Link
                            onClick={() => sendEvent('create_form')}
                            to={{ pathname: '/form-builder' }}
                            className="btn btn-success"
                          >
                            <em className="icon ni ni-plus mr-1" />
                            Create form
                          </Link>
                        </div>

                        <div className="mt-25" />

                        <div className="card mt-4">
                          <div className="card-inner">
                            <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                              <div className="datatable-wrap my-3">
                                <Table className="datatable-init nk-tb-list nk-tb-ulist dataTable no-footer table table-tranx form-stat-table form-list-table">
                                  <Table.Tr className="tb-tnx-item nk-tb-item">
                                    <Table.Th className="tb-tnx-id">Title</Table.Th>

                                    <Table.Th className="tb-tnx-info text-center td-stat stat-center">Views</Table.Th>

                                    <Table.Th className="tb-tnx-info text-center td-stat stat-center">
                                      Conversions
                                    </Table.Th>

                                    <Table.Th className="tb-tnx-info text-center stat-center">Conversion Rate</Table.Th>

                                    <Table.Th className="tb-tnx-info text-center td-stat stat-center">Closes</Table.Th>

                                    <Table.Th className="tb-tnx-info text-center td-stat stat-center-right" />

                                    <Table.Th className="tb-tnx-item stat-right">Status</Table.Th>

                                    <Table.Th className="tb-tnx-action stat-right">Action</Table.Th>
                                  </Table.Tr>

                                  {dataForms?.forms &&
                                    dataForms.forms.map(form => (
                                      <Table.Tr>
                                        <Table.Td className="tb-tnx-id">
                                          <Link
                                            to={{
                                              pathname: form.config?.info
                                                ? `/form-builder/${form.uid}`
                                                : `/form-builder/${form.uid}`,
                                            }}
                                          >
                                            <span>{form.title}</span>
                                          </Link>
                                        </Table.Td>

                                        <Table.Td className="text-center td-stat stat-center">
                                          {dataStatistik.forms?.find(item => item.uid === form.uid)?.data
                                            ?.form_impression
                                            ? dataStatistik.forms?.find(item => item.uid === form.uid).data
                                                .form_impression
                                            : '-'}
                                        </Table.Td>

                                        <Table.Td className="text-center td-stat stat-center">
                                          {dataStatistik.forms?.find(item => item.uid === form.uid)?.data
                                            ?.form_conversion
                                            ? dataStatistik.forms?.find(item => item.uid === form.uid).data
                                                .form_conversion
                                            : '-'}
                                        </Table.Td>

                                        <Table.Td className="text-center td-stat stat-center">
                                          {dataStatistik.forms?.find(item => item.uid === form.uid)?.data
                                            ?.form_conversion
                                            ? `${(
                                                (dataStatistik.forms?.find(item => item.uid === form.uid).data
                                                  .form_conversion /
                                                  dataStatistik.forms.find(item => item.uid === form.uid).data
                                                    .form_impression) *
                                                100
                                              ).toFixed(1)}%`
                                            : '-'}
                                        </Table.Td>

                                        <Table.Td className="text-center td-stat stat-center">
                                          {dataStatistik.forms?.find(item => item.uid === form.uid)?.data?.form_close
                                            ? dataStatistik.forms?.find(item => item.uid === form.uid).data.form_close
                                            : '-'}
                                        </Table.Td>

                                        <Table.Td className="text-center td-stat stat-center stat-center-right" />

                                        <Table.Td className="tb-tnx-item stat-right">
                                          <div className="custom-control custom-switch custom-switch-wlabel mt-1">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              checked={form.active}
                                              value={form.active}
                                              onChange={e => onActiveForm(e, form.uid)}
                                              id={form.uid}
                                            />

                                            <label className="custom-control-label" for={form.uid} />
                                          </div>
                                        </Table.Td>

                                        <Table.Td className="tb-tnx-action stat-right">
                                          <div className="dropdown">
                                            <span
                                              className="button text-soft dropdown-toggle btn btn-icon btn-trigger"
                                              onClick={() => onToggleDropdown(form.uid)}
                                            >
                                              <em className="icon ni ni-more-h"></em>
                                            </span>

                                            {activeDropdown === form.uid && (
                                              <>
                                                <div
                                                  className="dropdown-menu-close"
                                                  onClick={() => {
                                                    onToggleDropdown(null);
                                                  }}
                                                />
                                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs show">
                                                  <ul className="link-list-plain">
                                                    <li>
                                                      <span
                                                        className="button"
                                                        onClick={() => {
                                                          onToggleCodeModal(true);
                                                          onToggleDropdown(null);
                                                          onSetActiveForm(form.uid);
                                                        }}
                                                      >
                                                        View code
                                                      </span>
                                                    </li>
                                                    <li>
                                                      <Link to={{ pathname: `/form/analytics/${form.uid}` }}>
                                                        View analytics
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      <Link
                                                        className="button"
                                                        to={{
                                                          pathname: form.config?.info
                                                            ? `/form-builder/${form.uid}`
                                                            : `/form/builder/${form.uid}`,
                                                        }}
                                                      >
                                                        <span>Edit</span>
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      <span className="button" onClick={() => onDeleteForm(form.uid)}>
                                                        Delete
                                                      </span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </Table.Td>
                                      </Table.Tr>
                                    ))}
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </DataPreloader>

          {isCodeModal && (
            <Modal title="Form import code" onClose={() => onToggleCodeModal(false)}>
              <>
                <p>Paste this code before the closing tag of the body</p>
                <p>
                  <textarea className="form-control form-control-sm" ref={textareaCodeRef} />
                </p>
              </>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default FormList;
