import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

export const StatusGraph = ({ localChartSeries }) => {
  const [optionsHighcharts, setOptionsHighcharts] = useState(null);

  useEffect(() => {
    if (localChartSeries) {
      let scheduled = 0;
      let created = 0;
      let error = 0;
      let sent = 0;
      localChartSeries.map(item => {
        scheduled += item.data.scheduled_sms_count;
        created += item.data.created_sms_count;
        error += item.data.error_sms_count;
        sent += item.data.sent_sms_count;
      });

      const series = [
        {
          name: 'scheduled',
          y: scheduled || 0,
          color: '#526482',
        },
        {
          name: 'created',
          y: created || 0,
          color: '#6970c9',
        },
        {
          name: 'error',
          y: error || 0,
          color: '#ff794d',
        },
        {
          name: 'sent',
          y: sent || 0,
          color: '#35cd95',
        },
      ];

      setOptionsHighcharts(() => {
        return {
          chart: {
            type: 'column',
          },
          title: {
            text: null,
          },
          accessibility: {
            announceNewData: {
              enabled: true,
            },
          },
          xAxis: {
            type: 'category',
          },
          yAxis: {
            title: {
              text: 'Statuses',
            },
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            series: {
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                format: '{point.y}',
              },
            },
          },

          tooltip: {
            valueDecimals: 0,
          },

          series: [
            {
              name: 'Statuses',
              colorByPoint: true,
              data: series,
            },
          ],
        };
      });
    } else {
      setOptionsHighcharts(() => {
        return {
          chart: {
            type: 'column',
          },
          title: {
            text: null,
          },
          accessibility: {
            announceNewData: {
              enabled: true,
            },
          },
          xAxis: {
            type: 'category',
          },
          yAxis: {
            title: {
              text: 'Statuses',
            },
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            series: {
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                format: '{point.y}',
              },
            },
          },

          tooltip: {
            valueDecimals: 0,
          },

          series: [
            {
              name: 'Statuses',
              colorByPoint: true,
              data: [],
            },
          ],
        };
      });
    }
  }, [localChartSeries]);

  return <HighchartsReact highcharts={Highcharts} options={optionsHighcharts} />;
};
