import React, { useState } from 'react';
import { DataPreloader, ModalConfirm } from 'components';

import './ChannelInfo.scss';

export const ChannelInfo = ({ data, onRemoveChannel, channel, loading }) => {
  const [isRemoveModal, setIsRemoveModal] = useState(false);

  const onDeleteModal = () => setIsRemoveModal(true);

  const onCancelDelete = () => setIsRemoveModal(false);

  const onDelete = () => onRemoveChannel(channel);

  return (
    <div className="channel-info">
      <div className="text-right">
        <button className="btn btn-danger channle-remove" onClick={onDeleteModal}>
          <i className="icon fas fa-trash-alt mr-2" />
          Delete token
        </button>
      </div>

      <div className="dataTables_wrapper dt-bootstrap4 no-footer">
        <div className="datatable-wrap my-3">
          <table className="table info">
            <tbody>
              <tr>
                <td>Token:</td>
                <td>{data?.token}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {isRemoveModal && (
        <ModalConfirm>
          <div className="nk-modal-action mt-5">
            <DataPreloader loading={loading}>
              <button class="btn btn-lg btn-mw btn-light mr-1" onClick={onCancelDelete}>
                Cancel
              </button>
              <button class="btn btn-lg btn-mw btn-danger ml-1" onClick={onDelete}>
                Delete
              </button>
            </DataPreloader>
          </div>
        </ModalConfirm>
      )}
    </div>
  );
};
