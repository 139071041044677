import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';

const chartName = 'sms_count';

export const SmsGraph = ({ localChartSeries }) => {
  const [dataHighcharts, setDataHighcharts] = useState(null);
  const [optionsHighcharts, setOptionsHighcharts] = useState(null);

  useEffect(() => {
    if (localChartSeries) {
      setDataHighcharts(() =>
        localChartSeries.map(item => {
          return [moment(item.date).format('YYYY/M/D'), item.data[chartName]];
        }),
      );
    } else {
      setDataHighcharts([]);
    }
  }, [localChartSeries]);

  useEffect(() => {
    const handleOptions = async () => {
      if (dataHighcharts) {
        const axisCategories = dataHighcharts.map(item => moment(item[0]).format('YYYY/M/D'));
        setOptionsHighcharts({
          title: {
            text: '',
          },
          chart: {
            type: 'column',
          },
          xAxis: {
            categories: axisCategories,
          },
          legend: {
            enabled: false,
          },
          yAxis: {
            min: 0,
            title: {
              text: 'SMS',
            },
          },
          plotOptions: {
            series: {
              turboThreshold: 1000,
            },
          },
          series: [
            {
              name: 'SMS',
              type: 'areaspline',
              data: dataHighcharts,
              color: '#6970c9',
              gapSize: 0,
              tooltip: {
                valueDecimals: 0,
              },
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1,
                },
                stops: [
                  [0, '#6970c926'],
                  [1, '#6970c926'],
                ],
              },
              threshold: null,
            },
          ],
        });
      }
    };
    handleOptions();
  }, [dataHighcharts, chartName]);

  return <HighchartsReact highcharts={Highcharts} options={optionsHighcharts} />;
};
