import React from 'react';

import './Tooltip.scss';

export const Tooltip = ({ text, children }) => (
  <span className="tooltip-ui">
    {children}
    <span className="tooltip-ui-text">{text}</span>
  </span>
);
