import React from 'react';
import moment from 'moment';
import { Avatar } from '..';

export const Message = ({ who, message, image, buttons, name, onChooseButton, datetime }) => {
  return (
    <div className={`chat is-${who}`}>
      {who === 'you' && <Avatar name={name} />}

      <div className="chat-content">
        <div className="chat-bubbles">
          <div className="chat-bubble">
            <div>
              <div>{image && <img src={image} alt="" style={{ borderRadius: '10px' }} className="mb-2" />}</div>
              <div className="chat-msg">{message}</div>
              <div>
                {buttons && (
                  <div className="mt-2">
                    {Object.keys(buttons).map(buttonKey => (
                      <>
                        <input
                          type="button"
                          className="btn btn-sm btn-primary mb-1 mr-1"
                          onClick={() => onChooseButton(buttonKey, buttons[buttonKey])}
                          value={buttons[buttonKey]}
                        />
                      </>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <ul className="chat-meta">
          <li>{name}</li>
          <li>{moment(datetime).format('h:mm a')}</li>
        </ul>
      </div>
    </div>
  );
};
