import React, { useRef, useState } from 'react';
import { ChatForm } from '..';

export const ChatEditor = ({ onMessage }) => {
  const textareaRef = useRef();

  const [isShift, setIsShift] = useState(false);

  const onKeyDownTextarea = e => {
    if (e.key === 'Shift') {
      setIsShift(true);
    }
    if (e.key === 'Enter' && !isShift) {
      e.preventDefault();
      const message = e.target.value;
      onMessage(message);
      textareaRef.current.value = '';
    }
  };

  const onKeyUpTextarea = e => {
    if (e.key === 'Shift') {
      setIsShift(false);
    }
  };

  const onSubmitMessage = () => {
    const message = textareaRef.current.value;
    onMessage(message);
    textareaRef.current.value = '';
  };

  return (
    <div className="nk-chat-editor">
      {/* <div className="nk-chat-editor-upload  ml-n1">
        <a href="#s" className="btn btn-sm btn-icon btn-trigger text-primary toggle-opt" data-target="chat-upload">
          <em className="icon ni ni-plus-circle-fill"></em>
        </a>
        <div className="chat-upload-option" data-content="chat-upload">
          <ul className="">
            <li>
              <a href="#s">
                <em className="icon ni ni-img-fill"></em>
              </a>
            </li>
            <li>
              <a href="#s">
                <em className="icon ni ni-camera-fill"></em>
              </a>
            </li>
            <li>
              <a href="#s">
                <em className="icon ni ni-mic"></em>
              </a>
            </li>
            <li>
              <a href="#s">
                <em className="icon ni ni-grid-sq"></em>
              </a>
            </li>
          </ul>
        </div>
      </div> */}

      <ChatForm textareaRef={textareaRef} onKeyDownTextarea={onKeyDownTextarea} onKeyUpTextarea={onKeyUpTextarea} />

      <ul className="nk-chat-editor-tools g-2">
        {/* <li>
          <a href="#s" className="btn btn-sm btn-icon btn-trigger text-primary">
            <em className="icon ni ni-happyf-fill"></em>
          </a>
        </li> */}
        <li>
          <button className="btn btn-round btn-primary btn-icon" onClick={onSubmitMessage}>
            <em className="icon ni ni-send-alt" />
          </button>
        </li>
      </ul>
    </div>
  );
};
