import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';
import { PeriodSelect } from '../PeriodSelect/PeriodSelect';

import './FlowStatGraph.scss';

const periodList = [
  {
    period: [moment().subtract(7, 'd'), moment.now()],
    prevPeriod: [moment().subtract(14, 'd'), moment().subtract(7, 'd')],
    label: 'Last 7 days',
  },
  {
    period: [moment().subtract(30, 'd'), moment.now()],
    prevPeriod: [moment().subtract(60, 'd'), moment().subtract(30, 'd')],
    label: 'Last 30 days',
  },
  {
    period: [moment().startOf('month'), moment.now()],
    prevPeriod: [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months')],
    label: 'This month',
  },
  {
    period: [moment().subtract(1, 'months').startOf('month'), moment().startOf('month')],
    prevPeriod: [moment().subtract(2, 'months').startOf('month'), moment().subtract(1, 'months').startOf('month')],
    label: 'Previous month',
  },
  {
    period: null,
    label: 'Custom period',
  },
];

const FlowStatGraph = ({ className, getChartSeries, loadingFilterStatistik, typeStat }) => {
  const [localChartSeries, setLocalChartSeries] = useState();
  // const [prevPeriodChartSeries, setPrevPeriodChartSeries] = useState(null);
  const [isRangePeriod, setIsRangePeriod] = useState(false);
  const [periodSelected, setPeriodSelected] = useState({
    period: [moment().subtract(7, 'd'), moment.now()],
    prevPeriod: [moment().subtract(14, 'd'), moment().subtract(7, 'd')],
    label: 'Last 7 days',
  });

  const [chartNames] = useState(['dialog_count']);

  const [dataHighcharts, setDataHighcharts] = useState(null);

  const [optionsHighcharts, setOptionsHighcharts] = useState(null);

  const onHandlePeriod = async item => {
    if (!item.period) {
      setIsRangePeriod(true);
    } else {
      setPeriodSelected(item);
      // const prevPeriodChart = await getChartSeries(
      //   moment(item.prevPeriod[0]).format('DD-MM-YYYY'),
      //   moment(item.prevPeriod[1]).format('DD-MM-YYYY'),
      // );
      const newChart = await getChartSeries(
        moment(item.period[0]).format('DD-MM-YYYY'),
        moment(item.period[1]).format('DD-MM-YYYY'),
      );
      // setPrevPeriodChartSeries(prevPeriodChart);
      setLocalChartSeries(newChart);
    }
  };

  useEffect(() => {
    onHandlePeriod(periodSelected);
  }, []);

  useEffect(() => {
    if (localChartSeries) {
      setDataHighcharts(() =>
        chartNames.map(el => {
          return localChartSeries.map(item => {
            return [moment(item.date).format('YYYY/M/D'), item.data[`${el}`]];
          });
        }),
      );
    }
  }, [localChartSeries, chartNames]);

  useEffect(() => {
    const handleOptions = async () => {
      if (dataHighcharts) {
        const axisCategories = [...new Set(dataHighcharts[0].map(item => moment(item[0]).format('YYYY/M/D')))];
        let yAxis = [];
        const newData = [];
        dataHighcharts[0].map(el => {
          const index = newData.findIndex(ar => ar[0] === el[0]);
          if (index > -1) {
            newData[index] = [el[0], newData[index][1] + el[1]];
          } else {
            newData.push(el);
          }
        });

        const series = dataHighcharts.map((arr, key) => {
          const opposite = ['dialog_count'].includes(chartNames[key]);
          // const type = 'line';

          let color;
          if (chartNames[key] === 'date_ts') {
            color = '#ffa9ce';
          } else if (chartNames[key] === 'dialog_count') {
            color = '#8adfbc';
          } else {
            color = '#6970c9';
          }

          yAxis.push({
            min: 0,
            title: {
              text: '',
            },
            labels: {
              format: `{value}`,
              style: {
                color: Highcharts.getOptions().colors[key],
              },
            },
            opposite,
          });

          return {
            name: `${chartNames[key].replaceAll('_', ' ')}s`,
            data: newData,
            gapSize: 0,
            threshold: null,
            yAxis: opposite ? dataHighcharts.length - 1 : undefined,
            // type: 'areaspline',
            type: chartNames[key] === 'popup_impression' ? 'spline' : 'column',
            stack: 1,
            // type: 'column',
            color,
            tooltip: {
              valueDecimals: 0,
            },
            // fillColor: {
            //   linearGradient: {
            //     x1: 0,
            //     y1: 0,
            //     x2: 0,
            //     y2: 1,
            //   },
            //   stops: [
            //     [0, '#6970c926'],
            //     [1, '#6970c926'],
            //   ],
            // },
          };
        });

        setOptionsHighcharts({
          title: '',
          xAxis: {
            categories: axisCategories,
            reversed: true,
          },
          tooltip: {
            shared: true,
          },
          legend: {
            enabled: true,
          },
          yAxis,
          plotOptions: {
            series: {
              turboThreshold: 1000,
              borderRadius: 5,
            },
            column: {
              stacking: 'normal',
            },
          },
          series,
        });
      }
    };
    handleOptions();
  }, [dataHighcharts, chartNames]);

  return (
    <div className={`conversion-stat-graph card mt-3 ${className ? className : ''}`}>
      <div className="card-inner">
        <div className="period-wrap mb-4">
          <PeriodSelect
            periodSelected={periodSelected}
            periodList={periodList}
            onHandlePeriod={onHandlePeriod}
            isRangePeriod={isRangePeriod}
            loading={loadingFilterStatistik}
          />
        </div>

        {localChartSeries && localChartSeries.length > 0 ? (
          <HighchartsReact highcharts={Highcharts} options={optionsHighcharts} />
        ) : (
          <>
            {!loadingFilterStatistik && (
              <div className="card mt-4">
                <div className="card-inner text-center">
                  <h5 className="no-data-info">
                    There is no data for the selected period, please choose another date range
                  </h5>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FlowStatGraph;
