import axiosConfig from 'utils/api';

export const sms = {
  list: {
    action: ({ limit, offset, sort, start, end, statuses_list }) =>
      axiosConfig.get(
        `/api/contacts/v1/get_sms?limit=${limit}&offset=${offset}&sort=${sort}&start=${start}&end=${end}&statuses_list=${statuses_list}`,
      ),
    cancel: () => null,
  },
};
