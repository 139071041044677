import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ToolsDropdown } from './FlowArea/components/ToolsDropdown/ToolsDropdown';

export const FlowItem = ({
  flow,
  closeDropdown,
  onDeleteFlow,
  onExportJson,
  onUnPublishFlow,
  onPublishFlow,
  onShareFlow,
}) => (
  <div className="col-lg-4">
    <div className="card card-bordered">
      <div className="card-inner">
        <h5 className="card-title">
          <div className="row">
            <div className="col-lg-9">{flow.title}</div>
            <div className="col-lg-3 text-right">
              <ToolsDropdown
                altButton={<em className="icon ni ni-more-h" data-toggle="dropdown" />}
                closeDropdown={closeDropdown}
              >
                <>
                  <li>
                    <Link to={{ pathname: `/flow-builder/${flow.uid}` }}>
                      <span>Edit</span>
                    </Link>
                  </li>
                  <li>
                    <a href="#s" onClick={() => onDeleteFlow(flow.uid)}>
                      <span>Delete Flow</span>
                    </a>
                  </li>
                  <li>
                    <a href="#s" onClick={e => onExportJson(e, flow.uid)}>
                      <span>Export JSON</span>
                    </a>
                  </li>
                  <li>
                    {flow.production ? (
                      <a href="#s" onClick={() => onUnPublishFlow(flow.uid)}>
                        <span>Unpublish Flow</span>
                      </a>
                    ) : (
                      <a href="#s" onClick={() => onPublishFlow(flow.uid)}>
                        <span>Publish Flow</span>
                      </a>
                    )}
                  </li>
                  <li>
                    <a href="#s" onClick={e => onShareFlow(e, flow.uid)}>
                      <span>Share Flow</span>
                    </a>
                  </li>
                  <li>
                    <Link to={{ pathname: `/flow/analytics/${flow.uid}` }}>
                      <span>Show analytics</span>
                    </Link>
                  </li>
                </>
              </ToolsDropdown>
            </div>
          </div>
        </h5>

        <p className="card-text">
          {flow.production ? (
            <span className="badge badge badge-success">Published</span>
          ) : (
            <span className="badge badge badge-gray">Unpublished</span>
          )}

          {flow.flow[0]?.data?.form?.bots &&
            flow.flow[0]?.data?.form?.bots.map(bot => {
              if (bot?.bot_name === 'telegram') {
                return <i className="fab fa-telegram ml-2" style={{ position: 'relative', top: '2px' }} />;
              } else if (bot?.bot_name === 'sms') {
                return <i className="fas fa-sms ml-2" style={{ position: 'relative', top: '2px' }} />;
              }
            })}
        </p>
        <p className="card-text">Created: {moment(flow.creation_date).format('MMMM Do YYYY, h:mm a')}</p>
      </div>
    </div>
  </div>
);
