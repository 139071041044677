export const createVariableNode = ({ activeElement, setElements, setActiveElement, getLayoutedElements }) => {
  setElements(els => {
    let newNode;
    let newNodesList = [];
    const newCreateButtonElseId = `n-${String(Math.random()).replace('.', '')}`;
    const newNodeId = `n-${String(Math.random()).replace('.', '')}`;
    const newEdgeId = `e-${String(Math.random()).replace('.', '')}`;
    const createButton = els.find(el => el.id === 'create-button');

    let prevTarget = els.find(el => el.source === activeElement.id)?.target;
    let newEls = els.filter(el => el.id !== 'create-button' && el.target !== 'create-button');

    newEls = newEls.map(el => {
      if (el.source === activeElement.id) {
        const newEl = el;
        newEl.source = newCreateButtonElseId;
        return newEl;
      }
      return el;
    });

    newNode = {
      id: newNodeId,
      type: 'buttonnode',
      data: {
        text: 'VARIABLE',
        isHandleBottom: true,
        isHandleTop: true,
        className: 'variable-node',
        type: 'variable',
        functional: true,
      },
    };

    newNodesList = [
      newNode,
      {
        id: `e-p-${newEdgeId}`,
        source: activeElement.id,
        target: newNodeId,
        type: 'smoothstep',
      },
      {
        id: newEdgeId,
        source: newNodeId,
        target: newCreateButtonElseId,
        type: 'smoothstep',
      },
      {
        id: `s-${newEdgeId}`,
        source: newCreateButtonElseId,
        target: prevTarget,
        type: 'smoothstep',
      },
    ];

    const createButtonElse = [
      {
        id: newCreateButtonElseId,
        type: 'buttonnode',
        data: {
          text: '+',
          isHandleTop: true,
          isHandleBottom: true,
          className: 'create-node-else',
          type: 'create-else',
        },
        className: 'create-else',
      },
    ];

    let objectElements = [...newEls, ...newNodesList, ...createButtonElse, createButton];
    objectElements.map(el => {
      if (el?.data?.type === 'create-else' && !objectElements.find(elf => elf?.source === el.id)) {
        const newCreateButtonElseId = `n-${String(Math.random()).replace('.', '')}`;
        objectElements = [
          ...objectElements,
          {
            id: `e-${newCreateButtonElseId}`,
            source: el.id,
            target: 'create-button',
            type: 'smoothstep',
            data: { typesmooth: 'to-create' },
          },
        ];
      }
    });

    objectElements = getLayoutedElements(objectElements, 'TB');

    setActiveElement(newNode);

    return objectElements;
  });
};
