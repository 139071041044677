import React, { useState, useEffect } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

const SelectFont = ({ element, defaultValue, styleName }) => {
  const [value, setValue] = useState(defaultValue);
  const [fonts, setFonts] = useState([]);

  const loadOptions = search => {
    const newFonts = fonts;

    if (search) {
      const searchFonts = newFonts.options.filter(item => {
        if (item.value.includes(search, 0)) {
          return true;
        }
        return false;
      });
      const newSearchFonts = { options: searchFonts };

      return newSearchFonts;
    }

    return newFonts;
  };

  const onChangeFont = (obj) => {
    if (element) {
      element.style[styleName] = obj.value;
    }
    setValue(obj);
  };

  useEffect(() => {
    const onSearchFonts = async () => {
      const response = await fetch(
        `https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyA3NGm3hYEsFfXE4TnWwR5t-9mxbcidUQk`,
      );
      const responseJSON = await response.json();

      let newFonts = responseJSON?.items?.slice(0, 500)?.map(item => {
        return {
          value: item.family,
          label: item.family,
        };
      });
      newFonts = { options: newFonts };

      setFonts(newFonts);
    };
    onSearchFonts();
  }, []);

  useEffect(() => {
    if (element && element.style) {
      setValue({
        value: element.style[styleName],
        label: element.style[styleName],
      });
    }
  }, []);

  return (
    <div className="form-group mt-4">
      <label className="form-label">
        Select font
      </label>

      <div className="form-control-wrap">
        <div className="form-control-select">
          <AsyncPaginate
            value={value}
            placeholder={value}
            loadOptions={loadOptions}
            onChange={onChangeFont}
            className="search-select"
          />
        </div>
      </div>
    </div>
  );
};

export default SelectFont;
