export const createJumpFlowNode = ({ activeElement, setElements, setActiveElement, getLayoutedElements }) => {
  setElements(els => {
    let newNode;
    let newNodesList = [];
    const newCreateButtonElseId = `n-${String(Math.random()).replace('.', '')}`;
    const newNodeId = `n-${String(Math.random()).replace('.', '')}`;
    const newEdgeId = `e-${String(Math.random()).replace('.', '')}`;
    const createButton = els.find(el => el.id === 'create-button');

    let newEls = els.filter(el => el.id !== 'create-button' && el.target !== 'create-button');

    newEls = newEls.map(el => {
      if (el.source === activeElement.id) {
        const newEl = el;
        newEl.source = newCreateButtonElseId;
        return newEl;
      }
      return el;
    });

    newNode = {
      id: newNodeId,
      type: 'buttonnode',
      data: {
        text: 'JUMP FLOW',
        isHandleBottom: true,
        isHandleTop: true,
        className: 'jump-flow-node',
        type: 'jump-flow-node',
      },
    };

    newNodesList = [
      newNode,
      {
        id: `e-p-${newEdgeId}`,
        source: activeElement.id,
        target: newNodeId,
        type: 'smoothstep',
      },
    ];

    let objectElements = [...newEls, ...newNodesList, createButton];

    objectElements = getLayoutedElements(objectElements, 'TB');

    setActiveElement(newNode);

    return objectElements;
  });
};
