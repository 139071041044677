import React from 'react';
import moment from 'moment';
import { Message, DateSap, TypingMessage } from '..';

import './ChatArea.scss';

export const ChatArea = ({ userDialog, onChooseButton, messagesAreaRef, isTypingMessage }) => {
  const Dialog = () => {
    if (userDialog?.length > 0) {
      let prevDate = null;

      return userDialog.map(message => {
        const newDate = moment(message.message_datetime).format('MMMM Do YYYY');
        let date = null;
        if (prevDate !== newDate) {
          date = newDate;
          prevDate = newDate;
        }

        return (
          <>
            {date && <DateSap datetime={message.message_datetime} />}

            <Message
              who={message.message_object.from.is_bot ? 'you' : 'me'}
              message={message.message_object.text}
              image={message.message_object?.image}
              buttons={message.message_object.buttons}
              name={message.message_object.from.first_name}
              datetime={message.message_datetime}
              onChooseButton={onChooseButton}
            />
          </>
        );
      });
    } else {
      return null;
    }
  };

  return (
    <div className="nk-chat-panel chat-demo">
      <div className="simplebar-wrapper">
        <div className="simplebar-content-wrapper" aria-label="scrollable content">
          <div className="simplebar-content" ref={messagesAreaRef}>
            <Dialog />

            {isTypingMessage && <Message who={'you'} message={<TypingMessage />} name={'Bot'} datetime={new Date()} />}
          </div>
        </div>
      </div>
    </div>
  );
};
