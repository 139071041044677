import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactFlow, {
  ReactFlowProvider,
  removeElements,
  addEdge,
  MiniMap,
  isNode,
  Background,
  Controls,
  ControlButton,
} from 'react-flow-renderer';
import dagre from 'dagre';
import { useForm } from 'react-hook-form';
import { useRequest } from 'estafette';
import { setUserInfo } from 'features/userSlice';
import { flow } from 'libs/http/api/flow';
import { user } from 'libs/http/api/user';
import { Modal, Toast, Header, FlowPreviewHeader, DataPreloader } from 'components';
import ButtonEdge from './components/ButtonEdge';
import ButtonNode from './components/ButtonNode';
import { FlowSidebar } from './components/FlowSidebar/FlowSidebar';
import { FlowCreateList } from './components/FlowCreateList/FlowCreateList';
import {
  createSmsNode,
  createVariableNode,
  createCallFunctionNode,
  createFunctionNode,
  createJumpNode,
  createJumpFlowNode,
  createResponseNode,
  createBranchNode,
  createHttpRequestNode,
  createConversationEndNode,
} from './createFunctions';

import IconFork from './../../assets/icons/code-fork-solid.svg';

import './FlowArea.scss';

import initialElements from './initial-elements';
import { HistorySidebar } from './components/HistorySidebar/HistorySidebar';

const nodeWidth = 100;
const nodeHeight = 50;
const nodeElseHeight = 80;

const parentNode = (node, elements, logicalBranch) => {
  if (node.id === 'node-1') {
    return null;
  } else {
    if (node?.data?.type === 'function') {
      if (logicalBranch) {
        return `${node.data.functionUid}_${logicalBranch}`;
      }
    }

    const parentSource = elements.find(el => el.target === node.id)?.source;
    if (parentSource) {
      // const sourceElements = elements?.filter(
      //   el => el?.target === elements.find(el => el?.target === node?.id)?.source,
      // );
      // if (sourceElements.length > 1 || sourceElements?.source === sourceElements?.target) {
      //   return null;
      // }

      if (elements?.filter(el => el.target === elements.find(el => el.target === node.id)?.source).length > 1) {
        return null;
      }

      const logic = node?.data?.label === 'true' || node?.data?.label === 'false' ? node?.data?.label : null;
      return parentNode(
        elements.find(el => el.id === parentSource),
        elements,
        logic,
      );
    }
  }
  return null;
};

const paramsElements = elements => {
  const newElements = elements.map(el => {
    const func = parentNode(el, elements);
    if (func) {
      const newEl = JSON.parse(JSON.stringify(el));
      if (!newEl?.data) {
        newEl.data = [];
      }

      newEl.data.functionBranch = func;
      return newEl;
    }
    return el;
  });
  return newElements;
};

const clearElements = elements => {
  let newElements = elements.filter(el => {
    if (!el || !el?.id) {
      return false;
    }

    if (el?.source) {
      if (!elements.find(elf => elf?.id === el.source)) {
        return false;
      }
      if (!elements.find(elf => elf?.id === el.target)) {
        return false;
      }
    } else {
      if (!elements.find(elf => elf?.source === el?.id) && !elements.find(elf => elf?.target === el?.id)) {
        return false;
      }
    }
    return true;
  });

  return [...new Set(newElements)];
};

const getLayoutedElements = (elements, direction = 'TB') => {
  let newElements = clearElements(elements);
  newElements = paramsElements(newElements);
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({
    rankdir: direction,
    // acyclicer: 'greedy',
    ranksep: 50,
  });

  newElements.forEach(el => {
    delete el.position;
    if (el?.data?.type === 'create-else') {
      el.className = 'create-else';
    }
    if (el?.data?.type === 'conversation-end') {
      el.className = 'conversation-end';
    }
    if (isNode(el)) {
      dagreGraph.setNode(el.id, {
        width: nodeWidth,
        height: el.data.type === 'create-else' ? 0 : 90,
      });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  let movex = 0;
  newElements = newElements.map((el, key) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';

      movex = 0;
      if (el.id === 'create-else-fallback') {
        console.log(111, el);
        el.sourcePosition = 'right';
        // movex = 200;
      }

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).

      // const movexNode = movex * 10;
      if (el.data.type === 'create-else') {
        el.position = {
          x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000 + movex,
          y: nodeWithPosition.y - (el.data.type === 'branch' ? nodeElseHeight : nodeHeight) / 2 - 5,
        };
      } else {
        el.position = {
          x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000 + movex,
          y: nodeWithPosition.y - (el.data.type === 'branch' ? nodeElseHeight : nodeHeight) / 2,
        };
      }

      if (el.id === 'default-fallback') {
        el.position = {
          x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000 + 50,
          y: 210,
        };
      }
      if (el.id === 'create-else-fallback') {
        el.position = {
          x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000 + 50,
          y: 300,
        };
      }
    }

    return el;
  });

  newElements.map(el => {
    if (el?.data?.type === 'create-else') {
      const parentEdge = newElements.filter(el2 => el2.target === el.id);
      if (parentEdge.length === 1) {
        const parentCreateElse = newElements.find(el2 => el2.id === parentEdge[0].source);
        if (parentCreateElse?.data?.type === 'create-else') {
          newElements = newElements.filter(el2 => el2.id !== parentEdge.id && el2.id !== el.id);
        }
      }
    }

    return el;
  });

  return newElements;
};

const onLoad = reactFlowInstance => {
  console.log('flow loaded:', reactFlowInstance);
  reactFlowInstance.fitView();
};

const edgeTypes = {
  buttonedge: ButtonEdge,
};

const nodeTypes = {
  buttonnode: ButtonNode,
};

export const FlowArea = ({ flowPreview }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => state);

  const { id: flowId } = useParams();
  const history = useHistory();

  const { request: requestFlowSave } = useRequest({});
  const { request: requestFlowUpdate } = useRequest({});
  const { request: requestNodesAnalytics, loading: loadingNodesAnalytics } = useRequest({ loading: false });
  const { request: requestVersions, loading: loadingVersions, data: dataVersions } = useRequest({ loading: false });
  const { request: requestFlow, data: dataFlow, loading: loadingFlow } = useRequest({ loading: false });
  const { request: requestFunctions, data: dataFunction } = useRequest({});

  const { register, handleSubmit } = useForm();

  const [elements, setElements] = useState(initialElements);
  const [isFlowCreateSidebar, setIsFlowCreateSidebar] = useState(false);
  const [flowCreateSidebarPos, setFlowCreateSidebarPos] = useState({ x: 0, y: 0 });
  const [activeElement, setActiveElement] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [isSavedForm, setIsSavedForm] = useState(true);
  const [isErrorSavedForm, setIsErrorSavedForm] = useState(false);
  const [isErrorRemoving, setIsErrorRemoving] = useState(false);
  const [jsonElements, setJsonElements] = useState(null);
  const [isSaveModal, setIsSaveModal] = useState(false);
  const [newFunctions, setNewFunction] = useState([]);
  const [bots, setBots] = useState([]);
  const [isVersions, setIsVersions] = useState(false);
  const [activeFlowVersion, setActiveFlowVersion] = useState(0);

  const getNodesAnalytics = async (startDate, endDate) => {
    const nodesAnalytics = await requestNodesAnalytics(flow.getNodesStats.action(flowId));
    const newElements = elements.map(el => {
      const statElement = nodesAnalytics.node_stats?.find(stEl => stEl?._id === el?.id);
      if (statElement) {
        el.data.sendedMessagesCount = statElement.count;
        el.data.totalSendedMessagesCount = nodesAnalytics.total_count;
      }
      if (el.id === 'node-1') {
        el.data.totalSendedMessagesCount = nodesAnalytics.total_count;
      }
      return el;
    });
    setElements(getLayoutedElements(newElements));
  };

  const onElementsRemove = elementsToRemove => setElements(els => removeElements(elementsToRemove, els));

  const onConnect = params => setElements(els => addEdge(params, els));

  const onIsSavedForm = val => setIsSavedForm(val);

  const onElementClick = async (e, element) => {
    if (e.persist) {
      e.persist();
    }

    if (element.id === 'default-fallback') {
      return;
    }

    if (element.data?.type === 'jump-node') {
      const edge = {
        id: `s-${element.id}`,
        source: element.id,
        target: element.data.form.jump_node,
        animated: true,
      };
      // let newElements = JSON.parse(JSON.stringify(elements));
      let newElements = removeAnimdateSmooth();
      newElements.push(edge);
      newElements = getLayoutedElements(newElements, 'TB');
      setElements(newElements);
    }

    if (!isSavedForm && activeElement) {
      if (element.data?.type === 'create-else') {
        setIsErrorSavedForm(true);
      }
    } else {
      setActiveElement(null);

      setTimeout(() => {
        let type = element.data?.type;

        if (element.id === 'node-1') {
          type = 'incoming-lead';
        }

        if (type) {
          setActiveElement(element);
          setActionType(type);
          if (type !== 'jump-node') {
            setElements(() => removeAnimdateSmooth());
          }
          if (type === 'create-else') {
            setFlowCreateSidebarPos({ x: e.pageX + 50, y: e.pageY - 100 });
            setIsFlowCreateSidebar(true);
          }
        }
      });
    }
  };

  const onMove = () => {
    setIsFlowCreateSidebar(false);
    if (activeElement?.data?.type === 'create-else') {
      setIsSavedForm(true);
      setActiveElement(null);
    }
  };

  const onCreateSmsNode = () => {
    createSmsNode({ activeElement, setElements, setActiveElement, getLayoutedElements });
  };

  const onCreateVariableNode = () => {
    createVariableNode({ activeElement, setElements, setActiveElement, getLayoutedElements });
  };

  const onCreateCallFunctionNode = () => {
    createCallFunctionNode({ activeElement, setElements, setActiveElement, getLayoutedElements });
  };

  const onCreateFunctionNode = label => {
    createFunctionNode({ activeElement, setElements, setActiveElement, getLayoutedElements, label });
  };

  const onCreateJumpNode = label => {
    createJumpNode({ activeElement, setElements, setActiveElement, getLayoutedElements, label });
  };

  const onCreateJumpFlowNode = label => {
    createJumpFlowNode({ activeElement, setElements, setActiveElement, getLayoutedElements, label });
  };

  const onCreateResponseNode = (type, branchName) => {
    createResponseNode({ activeElement, setElements, setActiveElement, getLayoutedElements, branchName });
  };

  const onCreateBranchNode = (type, branchName) => {
    createBranchNode({ activeElement, setElements, setActiveElement, getLayoutedElements, branchName });
  };

  const onCreateHttpRequestNode = () => {
    createHttpRequestNode({ activeElement, setElements, setActiveElement, getLayoutedElements });
  };

  const onCreateConversationEndNode = () => {
    createConversationEndNode({ activeElement, setElements, setActiveElement, getLayoutedElements });
  };

  const onAction = (e, type, name) => {
    if (e) {
      e.stopPropagation();
    }

    switch (type) {
      case 'sms':
        onCreateSmsNode(type);
        break;
      case 'function':
        onCreateFunctionNode(name);
        break;
      case 'callFunction':
        onCreateCallFunctionNode(name);
        break;
      case 'variable':
        onCreateVariableNode(name);
        break;
      case 'response':
        onCreateResponseNode(type, name);
        break;
      case 'branch':
        onCreateBranchNode(type, name);
        break;
      case 'http-request':
        onCreateHttpRequestNode(type, name);
        break;
      case 'conversation-end':
        onCreateConversationEndNode(type, name);
        break;
      case 'jump-node':
        onCreateJumpNode(type, name);
        break;
      case 'jump-flow-node':
        onCreateJumpFlowNode(type, name);
        break;
      default:
        break;
    }

    setIsFlowCreateSidebar(false);
    setActionType(type);
  };

  const onNewBranch = e => {
    onAction(e, 'branch', e.target.value);
  };

  const onNewResponseBranch = (e, value) => {
    onAction(e, 'response', value);
  };

  // const onChangeFunction = e => {
  //   setNewFunction([]);
  //   const newFuncs = [];
  //   const layoutedElements = getLayoutedElements(
  //     elements.map(el => {
  //       if (el.id === activeElement.id) {
  //         const func = dataFunction.functions.find(el => el.uid === e.target.value);
  //         if (func) {
  //           // el.data.text = func.display_name;
  //           el.data.text = func?.data?.form?.name || func.display_name;
  //           el.data.functionName = func.name;
  //           el.data.functionUid = e.target.value;
  //           newFuncs.push(e.target.value);
  //         }
  //       }
  //       return el;
  //     }),
  //   );
  //   setNewFunction(newFuncs);
  //   setElements(layoutedElements);
  // };

  const onChangeFunction = e => {
    setNewFunction([]);
    const newFuncs = [];
    const layoutedElements = getLayoutedElements(
      elements.map(el => {
        if (el.id === activeElement.id) {
          const func = dataFunction.functions.find(el => el.uid === e.value);
          if (func) {
            // el.data.text = func.display_name;
            el.data.text = func?.data?.form?.name || func.display_name;
            el.data.functionName = func.name;
            el.data.functionUid = e.value;
            newFuncs.push(e.value);
          }
        }
        return el;
      }),
    );
    setNewFunction(newFuncs);
    setElements(layoutedElements);
  };

  const rangeIds = (firstId, lastId, arr) => {
    const sourceElements = elements.filter(el => el.target === lastId);
    const sources = sourceElements.map(el => {
      return el.source;
    });
    const targets = sourceElements.map(el => {
      arr = [...arr, elements.find(el2 => el2.source === el.target).id];
      return el.target;
    });
    const edges = sourceElements.map(el => {
      return el.id;
    });

    arr = [...arr, ...sources, ...edges, ...targets];
    sources.forEach(el => {
      if (el !== firstId && el?.data?.type !== 'create-else') {
        arr = [...arr, ...rangeIds(firstId, el, arr)];
      }
    });

    return arr;
  };

  const removeResponseButtonElement = () => {
    const edgePrev = elements.find(el => el.target === activeElement.id);
    const edgeNext = elements.find(el => el.source === activeElement.id);
    const edgeAfterPlus = elements.find(el => el.source === edgeNext.target);

    const newElements = elements.filter(
      el =>
        el.id !== edgePrev.id &&
        el.id !== activeElement.id &&
        el.id !== edgeNext.id &&
        el.id !== edgeNext.target &&
        el.id !== edgeAfterPlus.id,
    );

    const layoutedElements = getLayoutedElements(newElements);
    setElements(layoutedElements);
    setActiveElement(null);
  };

  const removeElement = () => {
    const removeClearedElement = (type = 'basic') => {
      const prevEdge = elements.find(el => el.target === activeElement.id);

      let nextEdge;
      let nextCreate;
      let nextCreateEdge;
      if (type === 'function') {
        nextEdge = elements.filter(el => el.source === activeElement.id);
        console.log('nextEdge', nextEdge);
        nextCreate = elements.filter(el => el.id === nextEdge[0]?.target || el.id === nextEdge[1]?.target);
        console.log('nextCreate', nextCreate);
        nextCreateEdge = elements.filter(el => el.source === nextCreate[0]?.id || el.source === nextCreate[1]?.id);
        console.log('nextCreateEdge', nextCreateEdge);
      } else if (type === 'jump-node') {
        console.log('delete: jump-node');
        // none
      } else {
        nextEdge = elements.find(el => el.source === activeElement.id);
        nextCreate = elements.find(el => el.id === nextEdge?.target);
        nextCreateEdge = elements.find(el => el.source === nextCreate?.id);
      }
      console.log('type: ', type);

      let newElements = elements
        .map(el => {
          if (type === 'function') {
            if (el?.id === activeElement?.id) {
              return false;
            } else if (el?.id === nextEdge?.id) {
              return false;
            } else if (el?.id === nextCreate[0]?.id || el?.id === nextCreate[1]?.id) {
              return false;
            } else if (el?.id === nextCreateEdge[0]?.id || el?.id === nextCreateEdge[1]?.id) {
              return false;
            } else if (el?.id === nextCreateEdge[0]?.target || el?.id === nextCreateEdge[1]?.target) {
              return false;
            }
          } else if (type === 'jump-node') {
            if (el?.id === prevEdge?.id) {
              return false;
            } else if (el?.id === activeElement?.id) {
              return false;
            }
          } else if (type === 'jump-flow-node') {
            if (el?.id === prevEdge?.id) {
              return false;
            } else if (el?.id === activeElement?.id) {
              return false;
            }
          } else {
            if (el?.id === prevEdge?.id) {
              el.target = nextCreateEdge?.target;
            } else if (el?.id === activeElement?.id) {
              return false;
            } else if (el?.id === nextEdge?.id) {
              return false;
            } else if (el?.id === nextCreate?.id) {
              return false;
            } else if (el?.id === nextCreateEdge?.id) {
              return false;
            }
          }

          return el;
        })
        .filter(el => el);
      newElements = getLayoutedElements(newElements);
      setElements(newElements);

      setActiveElement(null);
    };

    if (activeElement.data.type === 'function') {
      let deleteElement = true;
      const childrenEdges = elements.filter(el => el.source === activeElement.id);
      childrenEdges.map(el => {
        if (deleteElement) {
          const childrenNode = elements.find(el2 => el2.id === el?.target);
          const childrenEdgeNode = elements.find(el2 => el2.id === childrenNode.id);
          const edgeChildrenEdgeNode = elements.find(el2 => el2.source === childrenEdgeNode.id);
          if (elements.find(el2 => el2.id === edgeChildrenEdgeNode?.target)?.data?.type !== 'create-else') {
            deleteElement = false;
          }
          if (!elements.find(el2 => el2.id === edgeChildrenEdgeNode?.target)) {
            deleteElement = true;
          }
        }
      });

      if (deleteElement) {
        console.log('deleting', activeElement.data.type);
        removeClearedElement('function');
      } else {
        setIsErrorRemoving(true);
      }
    } else if (activeElement.data.type === 'response') {
      let deleteElement = true;
      const childrenEdges = elements.filter(el => el.source === activeElement.id);
      childrenEdges.map(el => {
        if (deleteElement) {
          const childrenNode = elements.find(el2 => el2.id === el.target);
          if (childrenNode.data.type !== 'create-else') {
            deleteElement = false;
          }
        }
      });

      if (deleteElement) {
        console.log('deleting', activeElement.data.type);
        removeClearedElement('response');
      } else {
        setIsErrorRemoving(true);
      }
    } else {
      console.log('deleting', activeElement.data.type);
      removeClearedElement(activeElement.data.type);
    }
  };

  const onRemoveElement = () => {
    setIsSavedForm(true);
    console.log(activeElement);
    if (activeElement.id === 'node-1' || activeElement.id === 'default-fallback') {
      return;
    }

    if (activeElement.data.type === 'responseButton') {
      removeResponseButtonElement();
    } else {
      removeElement();
    }
  };

  const removeAnimdateSmooth = () => {
    let newElements = JSON.parse(JSON.stringify(elements)).filter(el => !el?.animated);
    newElements = getLayoutedElements(newElements);
    setElements(newElements);
    return newElements;
  };

  const onCloseSidebar = () => {
    setActiveElement(null);
    setIsErrorSavedForm(false);
    setIsSavedForm(true);

    removeAnimdateSmooth();

    if (!activeElement?.data?.form) {
      onRemoveElement();
    }
  };

  const onSaveForm = (dataForm, children = null, responseType = null) => {
    setIsErrorSavedForm(false);
    setIsSavedForm(true);

    setNewFunction([]);
    const newFuncs = [];

    let newElements = elements.map(el => {
      if (el.id === activeElement.id) {
        const trueKeys = Object.keys(dataForm).filter(key => !key.includes('--'));
        const newDataForm = {};
        trueKeys.map(key => {
          newDataForm[key] = dataForm[key];
        });
        el.data.form = newDataForm;
      }

      if (responseType === 'buttons' && children && children[el.id]) {
        el.data.form = children[el.id];
      }

      if (el.id === activeElement.id) {
        if (responseType === 'incoming-lead' && children && children[el.id]) {
          el.data.form = children[el.id];
        }

        if (responseType === 'user-response' && children && children[el.id]) {
          el.data.form = children[el.id];
        }

        if (responseType === 'jump-node' && children && children[el.id]) {
          el.data.form = children[el.id];
        }

        if (responseType === 'http-request' && children && children[el.id]) {
          el.data.form = children[el.id];
        }

        if (el?.data?.type === 'callFunction') {
          newFuncs.push(el?.data?.form?.name || el.display_name);
        }

        if (
          el?.data?.type === 'callFunction' ||
          el?.data?.type === 'response' ||
          el?.data?.type === 'responseButton' ||
          el?.data?.type === 'jump-node'
        ) {
          el.data.text = el?.data?.form?.name || el.display_name || el?.data?.form?.button_text;
        }

        if (el.id === 'node-1' && children) {
          if (!el.data?.form) {
            el.data.form = {};
          }
          el.data.form.variables = children;
        }
      }

      return el;
    });

    newElements = newElements.map(el => {
      let newEl = el;
      if (newEl?.source) {
        const jump = newElements.find(el2 => el2.id === newEl.source)?.data?.form?.jump_node || null;
        if (jump) {
          newEl.target = jump;
        }
      }

      return newEl;
    });

    const layoutedElements = getLayoutedElements(newElements);

    setElements(layoutedElements);
    setNewFunction(newFuncs);

    setActiveElement(null);
  };

  const onSaveFlow = () => setIsSaveModal(true);

  const onCloseSaveModal = () => setIsSaveModal(false);

  const onSave = async data => {
    if (!elements.find(el => el.id === 'node-1').data.form.variables) {
      elements.find(el => el.id === 'node-1').data.form.variables = [];
    }
    if (
      !Object.values(elements.find(el => el.id === 'node-1').data.form?.variables).find(el => el.key === 'owner_id')
    ) {
      elements.find(el => el.id === 'node-1').data.form.variables[String(Math.random()).replace('.', '')] = {
        key: 'owner_id',
        value: userInfo.data.user.id,
      };
    }

    if (!elements.find(el => el.id === 'node-1').data.form.conditions) {
      elements.find(el => el.id === 'node-1').data.form.conditions = [];
    }

    const payload = {
      ...data,
      flow: elements,
      flow_conditions: elements.find(el => el.id === 'node-1').data.form.conditions,
    };

    if (flowId) {
      await requestFlowUpdate(flow.update.action(flowId, payload));
    } else {
      await requestFlowSave(flow.create.action(payload));
    }
    history.push('/flows');
  };

  const onCloseErrorNote = () => {
    setIsErrorSavedForm(false);
    setIsErrorRemoving(false);
  };

  const onCopyNode = () => {
    if (activeElement?.data?.type === 'sms') {
      const newNodeId = `n-${String(Math.random()).replace('.', '')}`;
      const newEdgeId = `e-${String(Math.random()).replace('.', '')}`;
      const newCreateButtonElseId = `n-${String(Math.random()).replace('.', '')}`;

      const newNode = JSON.parse(JSON.stringify(activeElement));
      newNode.id = newNodeId;

      let newElements = elements.map(el => {
        if (el.source === activeElement.id) {
          const newEl = JSON.parse(JSON.stringify(el));
          newEl.source = newNodeId;
          return newEl;
        }
        return el;
      });

      const createButtonElse = [
        {
          id: newCreateButtonElseId,
          type: 'buttonnode',
          data: {
            text: '+',
            isHandleTop: true,
            isHandleBottom: true,
            className: 'create-node-else',
            type: 'create-else',
          },
          className: 'create-else',
        },
        {
          id: `s-${newEdgeId}`,
          source: newCreateButtonElseId,
          target: newNodeId,
          type: 'smoothstep',
        },
      ];

      newElements = [
        ...newElements,
        newNode,
        ...createButtonElse,
        {
          id: newEdgeId,
          source: activeElement.id,
          target: newCreateButtonElseId,
          type: 'smoothstep',
        },
      ];

      const layoutedElements = getLayoutedElements(newElements);
      setElements(layoutedElements);
    } else {
      const lastId = activeElement.data.lastCreateId;
      let copyElements = rangeIds(activeElement.id, lastId, []);
      copyElements = [...new Set(copyElements)];

      const newCopyElements = copyElements.map(id => {
        const newEl = JSON.parse(JSON.stringify(elements.find(el => el.id === id)));
        if (newEl?.source === activeElement?.data?.lastCreateId) {
          newEl.source = activeElement.data.lastCreateId;
        }
        if (newEl.id === activeElement.id) {
          newEl.data.lastCreateId = `${activeElement.data.lastCreateId}-copy`;
        }
        newEl.id = `${newEl.id}-copy`;
        if (newEl.type === 'smoothstep') {
          newEl.source = `${newEl.source}-copy`;
          newEl.target = `${newEl.target}-copy`;
        }
        return newEl;
      });

      const newElements = elements.map(el => {
        if (el?.source === activeElement.data.lastCreateId) {
          el.source = `${activeElement.data.lastCreateId}-copy`;
        }
        return el;
      });

      const layoutedElements = getLayoutedElements([
        ...newElements,
        ...newCopyElements,
        {
          id: `e-${String(Math.random()).replace('.', '')}`,
          source: activeElement.data.lastCreateId,
          target: `${activeElement.id}-copy`,
          type: 'smoothstep',
        },
      ]);
      setElements(layoutedElements);
    }
  };

  const onExportJson = () => setJsonElements(JSON.stringify(elements));

  const onRemoveJson = () => setJsonElements(null);

  const onShowVersionHistory = async () => {
    setIsVersions(true);
    requestVersions(flow.getVersions.action(flowId));
  };

  const onChangeVersion = newVersion => {
    setActiveFlowVersion(newVersion);
    requestFlow(flow.getFlowByVersion.action(flowId, newVersion));
  };

  useEffect(() => {
    console.log(elements);
  }, [elements]);

  useEffect(() => {
    if (flowId) {
      requestFlow(flowPreview ? flow.getPublicFlow.action(flowId) : flow.getFlow.action(flowId));
    }
  }, []);

  useEffect(() => {
    if (dataFlow?.flow?.flow) {
      setElements(getLayoutedElements(dataFlow.flow.flow));
    }
    if (flowPreview && dataFlow?.flow === null) {
      window.location.href = 'https://ezflow.cc';
    }
    if (dataFlow?.flow?.version) {
      setActiveFlowVersion(dataFlow.flow.version);
    }
  }, [dataFlow]);

  useEffect(() => {
    requestFunctions(flow.getFunctions.action());
  }, []);

  useEffect(() => {
    const getData = async () => {
      if (userInfo?.data && Object.keys(userInfo.data).length === 0) {
        const data = await user.getInfo.action();
        dispatch(setUserInfo(data.data));
      } else {
        const { bots } = userInfo.data.user;
        bots && setBots(Object.keys(bots).filter(key => bots[key]));
      }
    };
    getData();
  }, [userInfo]);

  useEffect(() => {
    if (!flowId) {
      const newBots = bots.map(key => {
        return {
          bot_name: key,
          bot_token: userInfo.data.user.bots[key].token,
        };
      });
      let newElements = JSON.parse(JSON.stringify(elements));
      newElements = newElements.map(element => {
        if (element.id === 'node-1') {
          if (!element?.data) {
            element.data = {};
          }
          if (!element.data?.form?.bots || element.data?.form?.bots.length === 0) {
            element.data.form = {
              bots: newBots,
            };
          }
          // if (!element.data.form?.variables) {
          //   element.data.form.variables = [];
          // }
          // if (!element.data.form?.variables.find(el => el.key === 'owner_id') && userInfo?.data?.user) {
          //   element.data.form.variables[String(Math.random()).replace('.', '')] = {
          //     key: 'owner_id',
          //     value: userInfo.data.user.id,
          //   };
          // }
          element.data.form.variables = [];
          // element.data.form.variables[String(Math.random()).replace('.', '')] = {
          element.data.form.variables.push({
            key: 'owner_id',
            value: userInfo.data.user?.id,
          });
        }
        return element;
      });

      setElements(getLayoutedElements(newElements));
    }
  }, [bots]);

  return (
    <div className="nk-main flow-area-page">
      <div className="nk-wrap">
        {!flowPreview ? <Header onSaveFlow={onSaveFlow} onExportJson={onExportJson} /> : <FlowPreviewHeader />}

        <div className={!flowPreview ? 'nk-content' : ''}>
          <div className={!flowPreview ? 'container-fluid' : ''}>
            <div className="nk-content-inner">
              <div className="nk-content-body">
                <DataPreloader loading={loadingFlow || loadingNodesAnalytics}>
                  <div
                    style={{
                      height: 'calc(100vh - 140px)',
                      width: '100%',
                      paddingRight:
                        !flowPreview && actionType && activeElement && actionType !== 'create-else' ? 410 : 0,
                    }}
                    className={flowPreview ? 'flow-preview' : ''}
                  >
                    <ReactFlowProvider>
                      <ReactFlow
                        elements={elements}
                        onElementsRemove={onElementsRemove}
                        onConnect={onConnect}
                        snapToGrid={true}
                        edgeTypes={edgeTypes}
                        nodeTypes={nodeTypes}
                        onLoad={onLoad}
                        nodesDraggable={false}
                        onElementClick={onElementClick}
                        onMove={onMove}
                        deleteKeyCode={[]}
                      >
                        <MiniMap />

                        {!flowPreview && isFlowCreateSidebar && (
                          <FlowCreateList
                            style={{ left: flowCreateSidebarPos.x, top: flowCreateSidebarPos.y }}
                            onAction={onAction}
                          />
                        )}
                        <Background color="#aaa" gap={16} />
                        <Controls showInteractive={false}>
                          <ControlButton onClick={getNodesAnalytics}>
                            <i className="fas fa-chart-bar" />
                          </ControlButton>

                          <ControlButton onClick={onShowVersionHistory}>
                            <img src={IconFork} alt="" width="90%" />
                          </ControlButton>
                        </Controls>
                      </ReactFlow>
                    </ReactFlowProvider>

                    {!flowPreview && activeElement && actionType !== 'create-else' && (
                      <FlowSidebar
                        type={actionType}
                        onNewBranch={onNewBranch}
                        onNewResponseBranch={onNewResponseBranch}
                        onChangeFunction={onChangeFunction}
                        onCloseSidebar={onCloseSidebar}
                        onSaveForm={onSaveForm}
                        onIsSavedForm={onIsSavedForm}
                        elementDataForm={activeElement?.data}
                        onRemoveElement={onRemoveElement}
                        onCopyNode={onCopyNode}
                        dataFunction={dataFunction}
                        newFunctions={newFunctions}
                        elements={elements}
                        activeElement={activeElement}
                        dataFlow={dataFlow}
                      />
                    )}

                    {!flowPreview && !activeElement && actionType !== 'create-else' && isVersions && (
                      <HistorySidebar
                        versions={dataVersions}
                        onClose={() => {
                          setIsVersions(false);
                        }}
                        loading={loadingVersions}
                        activeVersion={activeFlowVersion}
                        onChangeVersion={onChangeVersion}
                      />
                    )}

                    {isErrorSavedForm && (
                      <Toast title="Error" text="Save the form in the sidebar first" onClose={onCloseErrorNote} />
                    )}

                    {isErrorRemoving && (
                      <Toast title="Error" text="Remove child node before" onClose={onCloseErrorNote} />
                    )}

                    {jsonElements && (
                      <Modal title="JSON" onClose={onRemoveJson}>
                        <>
                          <div className="form-group mt-4">
                            <div className="form-control-input">
                              <textarea className="form-control" value={jsonElements} />
                            </div>
                          </div>

                          <div className="text-right">
                            <input type="submit" className="btn btn-primary" value="Close" onClick={onRemoveJson} />
                          </div>
                        </>
                      </Modal>
                    )}

                    {isSaveModal && (
                      <Modal title="Save flow" onClose={onCloseSaveModal}>
                        <form onSubmit={handleSubmit(onSave)}>
                          <div className="form-group">
                            <label className="form-label">Name</label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                name="title"
                                defaultValue={dataFlow?.flow?.title}
                                {...register('title')}
                                className="form-control"
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="form-label">Description</label>
                            <div className="form-control-wrap">
                              <textarea
                                name="description"
                                defaultValue={dataFlow?.flow?.description}
                                {...register('description')}
                                className="form-control"
                              />
                            </div>
                          </div>

                          {/* <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="prodCheck"
                            defaultValue={dataFlow?.flow?.production}
                            {...register('production')}
                          />
                          <label class="custom-control-label" for="prodCheck">
                            Production
                          </label>
                        </div> */}

                          <div className="text-right">
                            <input type="submit" className="btn btn-success" value="Save" />
                            <input
                              type="button"
                              className="btn btn-primary ml-1"
                              value="Close"
                              onClick={onCloseSaveModal}
                            />
                          </div>
                        </form>
                      </Modal>
                    )}
                  </div>
                </DataPreloader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
