import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Tooltip } from 'components';
import { UsedLimit } from './UsedLimit';

export const BillingPlan = ({ dataUserInfo }) => (
  <>
    <div className="side-billing">
      <div className="plan">
        <div className="card">
          <div className="card-inner">
            <div className="title">{dataUserInfo.user.subscription.product.name}</div>

            <div className="price">
              <sup>$</sup>
              {dataUserInfo.user.subscription.product.price / 100}
              <sub>/m</sub>
            </div>

            <Link to={{ pathname: `/billing/tariff-plans` }}>
              <button type="button" className="btn btn-primary btn-manage">
                Manage Subscription
              </button>
            </Link>
          </div>
        </div>

        <div className="card">
          <div className="card-inner">
            <div className="info">
              <ul className="pricing-features">
                <li>
                  <span className="w-50 text-left">Next payment</span> -{' '}
                  <span className="ml-auto">
                    {console.log(moment(dataUserInfo.user.subscription.current_period_end))}
                    {moment.unix(dataUserInfo.user.subscription.current_period_end).format('MMM Do YYYY')}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="right-section">
      <div className="card">
        <div className="card-inner">
          <h6 class="overline-title title">Usage</h6>
          <hr />

          {dataUserInfo?.user && (
            <div className="flex flex-limits mb-4">
              <div>
                <UsedLimit
                  title={
                    <>
                      Month contacts{' '}
                      <Tooltip text="Month contacts">
                        <i className="fas fa-question-circle" />
                      </Tooltip>
                    </>
                  }
                  hint="Month contacts"
                  used={dataUserInfo.user.plan.month_contacts}
                  limit={dataUserInfo.user.plan.month_contacts_limit}
                  percentPlan={
                    (dataUserInfo.user.plan.month_contacts * 100) / dataUserInfo.user.plan.month_contacts_limit
                  }
                />
              </div>

              <div>
                <UsedLimit
                  title={
                    <>
                      Growth Tools{' '}
                      <Tooltip text="Growth Tools">
                        <i className="fas fa-question-circle" />
                      </Tooltip>
                    </>
                  }
                  hint="Growth Tools"
                  used={dataUserInfo.user.plan.growth_tools_count}
                  limit={dataUserInfo.user.plan.growth_tools_limit}
                  percentPlan={
                    (dataUserInfo.user.plan.growth_tools_count * 100) / dataUserInfo.user.plan.growth_tools_limit
                  }
                />
              </div>

              <div>
                <UsedLimit
                  title={
                    <>
                      API calls{' '}
                      <Tooltip text="API calls">
                        <i className="fas fa-question-circle" />
                      </Tooltip>
                    </>
                  }
                  hint="API calls"
                  used={dataUserInfo.user.plan.api_calls_count}
                  limit={dataUserInfo.user.plan.api_calls_limit}
                  percentPlan={(dataUserInfo.user.plan.api_calls_count * 100) / dataUserInfo.user.plan.api_calls_limit}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <div className="card mt-4">
        <div className="card-inner">
          <h6 class="overline-title title">Invoices</h6>
          <hr />
        </div>
      </div> */}
    </div>
  </>
);
