import React from 'react';
import ReactDOM from 'react-dom';
import { usePortal } from 'hooks/usePortal';

import './ModalConfirm.scss';

export const ModalConfirm = ({ children }) => {
  const target = usePortal('modal-root');

  return ReactDOM.createPortal(
    <div className="modal show" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-dialog-popup" role="document">
        <div className="modal-content">
          <div className="modal-body modal-body-lg text-center">
            <div className="nk-modal">
              <h4 className="nk-modal-title">Confirm Action</h4>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>,
    target,
  );
};
