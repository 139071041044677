import React from 'react';
import { useForm } from 'react-hook-form';
import { useRequest } from 'estafette';
import { Link } from 'react-router-dom';
import { user } from 'libs/http/api/user';
import { DataPreloader } from 'components';
import { useGTM } from '../../hooks/useGTM';

import logo from './../../assets/images/logo_main.svg';
import './SigninForm.scss';

export const SigninForm = () => {
  const { request: requestSigninUser, loading: loadingSigninUser } = useRequest({});

  const { sendEvent } = useGTM();

  const { register, handleSubmit: handleSubmitMessage } = useForm();

  const onSubmitForm = async data => {
    const res = await requestSigninUser(
      user.signinUser.action({
        email: data.email,
        password: data.password,
      }),
    );

    if (res?.access_token) {
      sendEvent('sign_in');
      localStorage.setItem('access_token', res.access_token);
      localStorage.setItem('user_email', data.email);
      localStorage.setItem('api_token', res.api_token);
      setTimeout(() => {
        window.location.href = '/';
      }, 100);
    }
  };

  return (
    <>
      <div className="brand-logo pb-5">
        <a href="/" className="logo-link">
          <img src={logo} alt="" />
        </a>
      </div>
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title">Sign In</h5>
          <div className="nk-block-des">
            <p>Login to your account</p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmitMessage(onSubmitForm)}>
        <div className="form-group">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <div className="form-control-wrap">
            <input
              type="email"
              className="form-control form-control-lg"
              id="email"
              placeholder="Your email address"
              name="email"
              autoComplete="false"
              {...register('email')}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="password">
            Password
          </label>
          <div className="form-control-wrap">
            <input
              type="password"
              className="form-control form-control-lg"
              id="password"
              placeholder="Your password"
              name="password"
              autoComplete="false"
              {...register('password')}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <DataPreloader loading={loadingSigninUser}>
            <button type="submit" className="btn btn-lg btn-primary btn-block">
              Login
            </button>
          </DataPreloader>
        </div>
      </form>

      <div className="form-note-s2 pt-4">
        {' '}
        Need an account?{' '}
        <Link to={{ pathname: '/signup' }} className="fs-13">
          <strong>Sign Up</strong>
        </Link>
      </div>
    </>
  );
};
