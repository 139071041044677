import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useRequest } from 'estafette';
import { useSelector, useDispatch } from 'react-redux';
import { flow } from 'libs/http/api/flow';
import { user } from 'libs/http/api/user';
import { setUserInfo } from 'features/userSlice';
import axiosConfig from 'utils/api';
import { DataPreloader, FlowItem, Header, Modal, ModalConfirm, Toast, EmptyData, Dropdown } from 'components';
import { useGTM } from '../../hooks/useGTM';

import './../../assets/styles/FlowList.scss';

const FlowList = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => state);

  const { sendEvent } = useGTM();

  const shareLinkRef = useRef(null);

  const { request: requestFlowList, loading: loadingFlowList, data: dataFlowList } = useRequest({ loading: true });
  const { request: requestSharingFlow, loading: loadingSharingFlow } = useRequest({ loading: false });
  const { request: requestFlowDelete } = useRequest({});
  const { request: requestFlowPublish } = useRequest({});

  const [jsonElements, setJsonElements] = useState(null);
  const [errorNoteText, setErrorNoteText] = useState(null);
  const [closeDropdown, setCloseDropdown] = useState(null);
  const [isShareModal, setIsShareModal] = useState(false);
  const [isModalPublishPlatforms, setIsModalPublishPlatforms] = useState(false);
  const [activeFlow, setActiveFlow] = useState(null);
  const [activeFlowId, setActiveFlowId] = useState(null);
  const [copiedLink, setCopiedLink] = useState(null);
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [deletingFlowId, setDeletingFlowId] = useState(null);
  const [isPublicFlow, setIsPublicFlow] = useState(false);
  const [flowList, setFlowList] = useState([]);
  const [printFlowList, setPrintFlowList] = useState([]);
  const [bots, setBots] = useState([]);
  const [checkedBots, setCheckedBots] = useState([]);
  const [checkedStatuses, setCheckedStatuses] = useState([0, 1]);

  const onChangeCheckedStatuses = (_, key) => {
    setCheckedStatuses(prevChecked => {
      if (prevChecked.includes(key)) {
        return prevChecked.filter(el => el !== key);
      }
      return [...prevChecked, key];
    });
  };

  const onExportJson = (e, uid) => {
    e.preventDefault();
    setCloseDropdown(Math.random());
    setJsonElements(JSON.stringify(flowList.flows.find(el => el.uid === uid).flow));
  };

  const onRemoveJson = () => setJsonElements(null);

  const onDeleteFlow = async () => {
    setIsModalConfirm(false);
    setCloseDropdown(Math.random());

    await requestFlowDelete(flow.delete.action(deletingFlowId));
    requestFlowList(flow.list.action());
    setDeletingFlowId(null);
  };

  const onDeleteFlowModal = uid => {
    setDeletingFlowId(uid);
    setIsModalConfirm(true);
  };

  const onCancelDeleteFlow = () => {
    setIsModalConfirm(false);
  };

  const onShareFlow = (e, flow) => {
    e.preventDefault();
    setCloseDropdown(Math.random());
    setActiveFlow(flow);
    setCopiedLink(null);
    setIsShareModal(true);
    setIsPublicFlow(flow?.public);
  };

  const onChangeSharingFlow = async () => {
    setIsPublicFlow(prevPublic => !prevPublic);
    setFlowList(prevFlowList => {
      const newFlowList = JSON.parse(JSON.stringify(prevFlowList));
      const flows = newFlowList.flows.map(flow => {
        const newFlow = JSON.parse(JSON.stringify(flow));
        if (newFlow.uid === activeFlow.uid) {
          newFlow.public = !activeFlow.public;
        }
        return newFlow;
      });
      newFlowList.flows = flows;
      return newFlowList;
    });
    setActiveFlow(prevActiveFlow => {
      const newActiveFlow = JSON.parse(JSON.stringify(prevActiveFlow));
      newActiveFlow.public = !newActiveFlow.public;
      return newActiveFlow;
    });
    requestSharingFlow(flow.updateSharing.action({ flow_id: activeFlow.uid, is_public: !activeFlow.public }));
  };

  const onCopyLink = () => {
    setCopiedLink(null);
    navigator.clipboard.writeText(shareLinkRef.current.value).then(
      () => {
        setCopiedLink(true);
      },
      err => {
        setCopiedLink(false);
        console.log(err);
      },
    );
  };

  const onHideShareModal = () => setIsShareModal(false);

  const onCloseErrorNote = () => setErrorNoteText(null);

  const onUnPublishFlow = async uid => {
    setCloseDropdown(Math.random());

    await requestFlowPublish(flow.publichFlow.action(uid, false));
    requestFlowList(flow.list.action());
  };

  const onPublishFlowModal = async uid => {
    setCloseDropdown(Math.random());
    setActiveFlowId(uid);
    setIsModalPublishPlatforms(true);

    // axiosConfig.patch(`flows/v1/push?flow_id=${uid}&to_prod=true`).then(function (response) {
    //   if (response?.status === 200) {
    //     requestFlowList(flow.list.action());
    //   } else {
    //     setErrorNoteText('Some flow already in production');
    //   }
    // });
  };

  const onPublishFlow = async () => {
    setCloseDropdown(Math.random());

    setIsModalPublishPlatforms(false);

    axiosConfig
      .post(`flows/v2/push?flow_id=${activeFlowId}`, {
        to_prod: true,
        bots: checkedBots.map(el => {
          return { bot_name: el };
        }),
      })
      .then(function (response) {
        if (response?.status === 200) {
          requestFlowList(flow.list.action());
        } else {
          setErrorNoteText('Some flow already in production');
        }
      });
  };

  const onHidePublishPlatformsModal = () => setIsModalPublishPlatforms(false);

  const onCheckBots = bot => {
    setCheckedBots(prevCheckedBots =>
      prevCheckedBots.find(el => el === bot) ? prevCheckedBots.filter(el => el !== bot) : [...prevCheckedBots, bot],
    );
  };

  useEffect(() => {
    requestFlowList(flow.list.action());
  }, []);

  useEffect(() => {
    setFlowList(dataFlowList);
  }, [dataFlowList]);

  useEffect(() => {
    const getData = async () => {
      if (userInfo?.data && Object.keys(userInfo.data).length === 0) {
        const data = await user.getInfo.action();
        dispatch(setUserInfo(data.data));
      } else {
        setBots(userInfo.data.user.bots);
      }
    };
    getData();
  }, [userInfo]);

  useEffect(() => {
    if (flowList?.flows) {
      setPrintFlowList(() => {
        const flowListLoc = JSON.parse(JSON.stringify(flowList));
        if (checkedStatuses.includes(0) && !checkedStatuses.includes(1)) {
          flowListLoc.flows = flowList.flows.filter(el => !el?.production);
        } else if (!checkedStatuses.includes(0) && checkedStatuses.includes(1)) {
          flowListLoc.flows = flowList.flows.filter(el => el?.production);
        }
        return flowListLoc;
      });
    }
  }, [checkedStatuses]);

  useEffect(() => {
    setPrintFlowList(flowList);
  }, [flowList]);

  return (
    <>
      <Helmet>
        <title>Flow List</title>
      </Helmet>

      <div className="nk-main">
        <div className="nk-wrap">
          <Header />

          <DataPreloader loading={loadingFlowList}>
            <div className="nk-content">
              <div className="container-fluid">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div className="card-list">
                      {!loadingFlowList && (!flowList?.flows || flowList?.flows.length === 0) ? (
                        <EmptyData
                          title="No flows yet"
                          description="You don't have any flows yet. You can create first one - right now"
                          backgroundImage="https://res.cloudinary.com/fosh/image/upload/v1641740806/empty_02_sxbfbr.png"
                        >
                          <Link
                            onClick={() => sendEvent('create_flow')}
                            to={{ pathname: '/flow-builder' }}
                            className="btn btn-primary"
                          >
                            Create Flow
                          </Link>
                        </EmptyData>
                      ) : (
                        <>
                          <div class="d-flex mb-2">
                            <div style={{ width: '50%' }}>
                              <Link
                                onClick={() => sendEvent('create_flow')}
                                to={{ pathname: '/flow-builder' }}
                                className="btn btn-primary mt-1 mb-1"
                              >
                                Create Flow
                              </Link>
                            </div>
                            <div className="d-flex flex-row-reverse" style={{ width: '50%' }}>
                              <Dropdown title="Filter by status" className="small-dropdown">
                                <ul class="link-tidy">
                                  {['Unpublished', 'Published'].map((el, key) => (
                                    <li>
                                      <div class="custom-control custom-control-sm custom-checkbox">
                                        <input
                                          type="checkbox"
                                          class="custom-control-input"
                                          id={`cdp${key}-blx`}
                                          checked={checkedStatuses.includes(key)}
                                          onChange={e => onChangeCheckedStatuses(e, key)}
                                        />
                                        <label class="custom-control-label" for={`cdp${key}-blx`}>
                                          {el}
                                        </label>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </Dropdown>
                            </div>
                          </div>

                          <div className="row flows-list">
                            {!loadingFlowList &&
                              printFlowList?.flows &&
                              printFlowList.flows.map(flow => (
                                <FlowItem
                                  flow={flow}
                                  closeDropdown={closeDropdown}
                                  onDeleteFlow={onDeleteFlowModal}
                                  onExportJson={onExportJson}
                                  onUnPublishFlow={onUnPublishFlow}
                                  onPublishFlow={onPublishFlowModal}
                                  onShareFlow={e => onShareFlow(e, flow)}
                                  key={flow.uid}
                                />
                              ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DataPreloader>
        </div>
      </div>

      {jsonElements && (
        <Modal title="JSON" onClose={onRemoveJson}>
          <>
            <div className="form-group mt-4">
              <div className="form-control-input">
                <textarea className="form-control" value={jsonElements} />
              </div>
            </div>

            <div className="text-right">
              <input type="submit" className="btn btn-primary" value="Close" onClick={onRemoveJson} />
            </div>
          </>
        </Modal>
      )}

      {isShareModal && (
        <Modal title="Share Modal" onClose={onHideShareModal}>
          <>
            <DataPreloader loading={loadingSharingFlow} inline>
              <div className="d-flex justify-content-start mb-2" style={{ alignItems: 'center' }}>
                <div>
                  <div className="custom-control custom-switch custom-switch-wlabel mt-1">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="sharing-switch"
                      value={isPublicFlow}
                      checked={isPublicFlow}
                      onChange={onChangeSharingFlow}
                    />
                    <label className="custom-control-label" for="sharing-switch"></label>
                  </div>
                </div>

                <div>
                  <label for="sharing-switch" style={{ margin: 0 }}>
                    <strong>Share to the web</strong>
                  </label>
                </div>
              </div>
            </DataPreloader>

            {activeFlow?.public && (
              <div className="form-group mt-2 mb-3">
                <label className="form-label">Flowlink</label>

                <div className="form-control-wrap">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={`https://app.ezflow.cc/share/${activeFlow.uid}`}
                      ref={shareLinkRef}
                    />
                    <div className="input-group-append">
                      <button className="btn btn-outline-primary btn-dim" onClick={onCopyLink}>
                        Copy
                      </button>
                    </div>
                  </div>
                </div>

                {copiedLink === true && (
                  <div className="alert alert-success alert-icon mt-2">
                    <em className="icon ni ni-check-circle" /> Copying to clipboard was successful!
                  </div>
                )}

                {copiedLink === false && (
                  <div className="alert alert-warning alert-icon">
                    <em className="icon ni ni-alert-circle"></em> Unable to copy
                  </div>
                )}
              </div>
            )}
          </>
        </Modal>
      )}

      {isModalConfirm && (
        <ModalConfirm>
          <div className="nk-modal-action mt-5">
            <button className="btn btn-lg btn-mw btn-light mr-1" onClick={onCancelDeleteFlow}>
              Cancel
            </button>
            <button className="btn btn-lg btn-mw btn-danger ml-1" onClick={onDeleteFlow}>
              Delete
            </button>
          </div>
        </ModalConfirm>
      )}

      {isModalPublishPlatforms && (
        <Modal title="Choose a Platform" onClose={onHidePublishPlatformsModal}>
          <div className="row g-gs platforms-list">
            <div className="col-sm-6 col-lg-6">
              <div className="card card-bordered">
                <div className="card-inner">
                  <div className="team">
                    <div className="text-center">
                      <span className="icon" style={{ fontSize: '55px' }}>
                        <i className="fab fa-telegram" />
                      </span>
                    </div>

                    <div className="team-view">
                      <label
                        className="btn btn-round btn-outline-light w-150px"
                        style={{ display: 'inline-block', margin: 0 }}
                      >
                        <div className="custom-control custom-checkbox checked">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            onChange={() => onCheckBots('telegram')}
                          />
                          <span className="custom-control-label" style={{ lineHeight: '22px' }}>
                            Telegram
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-6">
              <div className="card card-bordered">
                <div className="card-inner">
                  <div className="team">
                    <div className="text-center">
                      <span className="icon" style={{ fontSize: '55px' }}>
                        <i className="fas fa-sms" />
                      </span>
                    </div>

                    <div className="team-view">
                      <label
                        className="btn btn-round btn-outline-light w-150px"
                        style={{ display: 'inline-block', margin: 0 }}
                      >
                        <div className="custom-control custom-checkbox checked">
                          <input type="checkbox" className="custom-control-input" onChange={() => onCheckBots('sms')} />
                          <span className="custom-control-label" style={{ lineHeight: '22px' }}>
                            SMS
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-right mt-4">
            <input type="button" className="btn btn-primary" value="Publish" onClick={onPublishFlow} />
          </div>
        </Modal>
      )}

      {errorNoteText && <Toast title="Error" text={errorNoteText} onClose={onCloseErrorNote} />}
    </>
  );
};

export default FlowList;
