import React, { useState, useEffect } from 'react';

const Input = ({ element, item, attributeName }) => {
  const [value, setValue] = useState(item.value);

  const onChangeInput = (e) => {
    setValue(e.target.value);
    if (element) {
      element.setAttribute(attributeName, e.target.value);
    }
  };

  useEffect(() => {
    if (element && element?.value) {
      setValue(element.value);
    }
  }, [element]);

  return (
    <div className="form-group mt-4">
      <label className="form-label">
        {item.label}
      </label>
      <input
        type="text"
        required
        className="form-control"
        onChange={onChangeInput}
        placeholder={item.placeholder}
        value={value}
      />
    </div>
  );
}

export default Input;
