import React from 'react';

import './EmptyData.scss';

export const EmptyData = ({ title, description, backgroundImage, children }) => {
  return (
    <div className="empty-data">
      <div className="empty-image" style={{ backgroundImage: `url(${backgroundImage})` }} />
      <h5>{title}</h5>
      <p>{description}</p>

      {children}
    </div>
  );
};
