import React, { useEffect, useState } from 'react';

import './PercentStatGraph.scss';

const PercentStatGraph = ({ title, data }) => {
  const [reData, setReData] = useState(null);
  const [isAllItems, setIsAllItems] = useState(false);

  const onHandleMore = () => setIsAllItems(true);

  useEffect(() => {
    const sum = data.reduce((acc, current) => acc + current.count, 0);
    setReData(() =>
      data
        .map(item => {
          return {
            icon: item.icon,
            name: item.name,
            percent: ((item.count * 100) / sum).toFixed(2),
          };
        })
        .sort((a, b) => -a.percent - -b.percent),
    );
  }, [data]);

  return (
    <div className="percent-stat-graph card card-bordered h-100">
      <div className="card-inner">
        <div className="percent-stat-graph__title card-title-group mb-2">
          <div className="card-title">
            <h6 className="title">{title}</h6>
          </div>
        </div>

        {reData &&
          reData.map((item, key) => {
            if (!isAllItems && key > 10) {
              return null;
            }

            return (
              <div key={key}>
                <div className="percent-stat-graph__item">
                  <span className="percent-stat-graph__percent-line" style={{ width: `${item.percent}%` }} />
                  <div className="percent-stat-graph__icon">{item.icon}</div>
                  <div className="percent-stat-graph__name">{item.name}</div>
                  <div className="percent-stat-graph__percent">{item.percent}%</div>
                </div>

                {key === 10 && !isAllItems && (
                  <div className="more-link text-right" onClick={onHandleMore}>
                    <em className="icon ni ni-arrow-long-down" /> more
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PercentStatGraph;
