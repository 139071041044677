import React, { useState } from 'react';
import { DataPreloader } from 'components';
import { useRequest } from 'estafette';
import { billing } from 'libs/http/api/billing';
import { useEffect } from 'react';
import { useGTM } from '../../hooks/useGTM';

export const TariffPlans = ({ dataUserInfo }) => {
  const { request: requestPlans, loading: loadingPlans } = useRequest({ data: null, loading: true });
  const { request: requestBillingSession, loading: loadingBillingSession } = useRequest({ data: null, loading: true });

  const { sendEvent } = useGTM();

  const [dataPlans, setDataPlans] = useState(null);
  const [period, setPeriod] = useState('month');
  const [priceId, setPriceId] = useState(null);

  const getPlans = async () => {
    const { prices } = await requestPlans(billing.getPlans.action());
    setDataPlans(prices.reverse());
  };

  const onChangePeriod = (e, newPeriod) => {
    e.preventDefault();
    setPeriod(newPeriod);
  };

  const onGetBillingSession = async newPriceId => {
    setPriceId(newPriceId);
    sendEvent('select_plan', { selected_plan: newPriceId });
    const data = await requestBillingSession(
      billing.getBillingSession.action({ userId: dataUserInfo.user.id, priceId: newPriceId }),
    );
    localStorage.setItem('payment_session', data.session.session_id);
    window.open(data.session.url, '_blank');
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <div className="mb-4 text-center">
        <h5 className="section-name">Tariff Plans</h5>

        <ul className="nav nav-tabs nav-tabs-s2 mt-1 mb-3">
          <li className="nav-item">
            <a
              className={`nav-link${period === 'month' ? ' active' : ''}`}
              data-toggle="tab"
              href="#t"
              onClick={e => onChangePeriod(e, 'month')}
            >
              Month
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link${period === 'year' ? ' active' : ''}`}
              data-toggle="tab"
              href="#t"
              onClick={e => onChangePeriod(e, 'year')}
            >
              Year
            </a>
          </li>
        </ul>
      </div>

      <div className="text-center">
        <DataPreloader loading={loadingPlans}>
          <div className="row g-gs plans-table">
            {dataPlans &&
              dataPlans.map(plan => (
                <>
                  {plan.price_period === period && (
                    <div className="col-md-6 col-xxl-3">
                      <div className="card card-bordered pricing text-center">
                        {plan.product_name === 'Pro' && (
                          <span className="pricing-badge badge badge-primary">Recommend</span>
                        )}

                        <div className="pricing-body">
                          <div className="pricing-title w-220px mx-auto">
                            <h5 className="title">{plan.product_name}</h5>
                            <span className="sub-text">{plan.product_description}</span>
                          </div>

                          <ul className="pricing-features">
                            <li>
                              <span className="w-50 text-left">Contacts limit</span> -{' '}
                              <span className="ml-auto">{plan.product_metadata.contacts_limit}</span>
                            </li>
                            <li>
                              <span className="w-50 text-left">API calls limit</span> -{' '}
                              <span className="ml-auto">{plan.product_metadata.api_calls_limit}</span>
                            </li>
                            <li>
                              <span className="w-50 text-left">Growth tools limit</span> -{' '}
                              <span className="ml-auto">{plan.product_metadata.growth_tools_limit}</span>
                            </li>
                            <li>
                              <span className="w-50 text-left">SMS balance</span> -{' '}
                              <span className="ml-auto">{plan.product_metadata.sms_balance}</span>
                            </li>
                          </ul>

                          <div className="pricing-amount mt-3">
                            <div className="amount">
                              ${plan.price_amount / 100} <span>/{plan.price_period}</span>
                            </div>
                          </div>
                          <div className="pricing-action">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => onGetBillingSession(plan.price_id)}
                              disabled={loadingBillingSession && priceId === plan.price_id}
                            >
                              {loadingBillingSession && priceId === plan.price_id && (
                                <DataPreloader inline loading size="1rem" styles={{ marginRight: '3px' }} />
                              )}
                              Select Plan
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
        </DataPreloader>
      </div>
    </div>
  );
};
