import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/userSlice';
import templateReducer from '../features/templateSlice';

export default configureStore({
  reducer: {
    userInfo: userReducer,
    template: templateReducer,
  },
});
