import axiosConfig from 'utils/api';

export const user = {
  getInfo: {
    action: () => axiosConfig.get('/api/v1/get_user_data'),
    cancel: () => null,
  },
  signupUser: {
    action: (params = {}) => axiosConfig.post('/api/auth/v1/create_user', { ...params }),
    cancel: () => null,
  },
  signinUser: {
    action: (params = {}) => axiosConfig.post('/api/auth/v1/login', { ...params }),
    cancel: () => null,
  },
  updateUserData: {
    action: (params = {}) => axiosConfig.post('/api/v1/update_user_data', { ...params }),
    cancel: () => null,
  },
};
