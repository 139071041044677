import React, { useState, useEffect } from 'react';
import { Range } from 'react-range';

const RangeSelector = ({ element, item, styleName }) => {
  const [value, setValue] = useState(item.value);

  const onChange = val => {
    setValue(val);
    if (element) {
      if (typeof styleName === 'string') {
        element.style[styleName] = `${val[0]}${item.unit}`;
      } else if (typeof styleName === 'object') {
        styleName.forEach(name => {
          element.style[name] = `${val[0]}${item.unit}`;
        });
      }
    }
  };

  useEffect(() => {
    if (element && element.style) {
      if (typeof styleName === 'string') {
        const style = element.style[styleName];
        if (style) {
          if (item.unit) {
            setValue([style.replace(item.unit, '')]);
          } else {
            setValue([style]);
          }
        }
      } else if (typeof styleName === 'object') {
        styleName.forEach(name => {
          const style = element.style[name];
          if (style) {
            if (item.unit) {
              setValue([style.replace(item.unit, '')]);
            } else {
              setValue([style]);
            }
          }
        });
      }
    }
  }, [element]);

  return (
    <div className="mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <label className="form-label">{item.label}</label>
        <span>{value[0]}</span>
      </div>

      <Range
        step={item.step}
        min={item.min}
        max={item.max}
        values={value}
        value={value}
        onChange={onChange}
        renderTrack={({ props, children }) => (
          <div {...props} className="slider__track">
            {children}
          </div>
        )}
        renderThumb={({ props }) => <div {...props} className="slider__thumb" />}
      />
    </div>
  );
};

export default RangeSelector;
