import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Header, ChatArea, ChatEditor } from 'components/Chat';

import 'assets/styles/Chat.scss';

const botApiHost = 'https://bot.api.ezflow.cc';
const wsApiHost = 'wss://bot.api.ezflow.cc';

const ChatPage = () => {
  const { chatToken } = useParams();

  const messagesAreaRef = useRef();
  const ws = useRef();

  const [dialogId, setDialogId] = useState(localStorage.getItem('chat-dialog-id') || null);
  const [messages, setMessages] = useState(
    localStorage.getItem('chat-messages') ? JSON.parse(localStorage.getItem('chat-messages')) : [],
  );
  const [isTypingMessage, setIsTypingMessage] = useState(true);

  const onCreateDialog = async () => {
    let dialogId;

    try {
      const res = await fetch(`${botApiHost}/web/chat/${chatToken}/`, {
        method: 'POST',
      });

      const resJson = await res.json();
      dialogId = resJson.dialog_id;
      setDialogId(resJson.dialog_id);
    } catch (err) {
      console.log(err);
    }

    ws.current = new WebSocket(`${wsApiHost}/web/websocket/dialog/${dialogId}/`);

    ws.current.onopen = () => {
      ws.current.send(JSON.stringify({ action: 'start' }));
    };

    ws.current.onmessage = ev => {
      setIsTypingMessage(false);
      const { data } = JSON.parse(ev.data);
      const message = {
        message_datetime: new Date(),
        message_object: {
          from: { is_bot: true, first_name: 'Bot' },
          text: data.text,
          image: data?.image,
          buttons: data?.buttons,
        },
      };
      setMessages(_messages => [..._messages, message]);
    };
  };

  const onMessage = (newMessage, type, key) => {
    if (!newMessage) return;

    setIsTypingMessage(true);

    const message = {
      message_datetime: new Date(),
      message_object: { from: { first_name: 'You' }, text: newMessage },
    };
    setMessages(_messages => [..._messages, message]);

    if (newMessage === '/start') {
      ws.current.send(JSON.stringify({ action: 'start' }));
    } else {
      if (type === 'pressed_button') {
        ws.current.send(JSON.stringify({ pressed_button: key }));
      } else {
        ws.current.send(JSON.stringify({ text: newMessage }));
      }
    }
  };

  const onChooseButton = (key, value) => {
    onMessage(value, 'pressed_button', key);
  };

  useEffect(() => {
    if (!localStorage.getItem('chat-token')) {
      localStorage.setItem('chat-token', chatToken);
    }
    if (!localStorage.getItem('chat-dialog-id')) {
      localStorage.setItem('chat-dialog-id', dialogId);
    }

    onCreateDialog();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      localStorage.setItem('chat-messages', JSON.stringify(messages));
    }
    messagesAreaRef.current.scrollTop = messagesAreaRef.current.scrollHeight;
  }, [messages]);

  useEffect(() => {
    if (localStorage.getItem('chat-messages')) {
      setMessages(JSON.parse(localStorage.getItem('chat-messages')));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Chat</title>
      </Helmet>

      <div className="nk-chat chat-wrapper">
        <div className="nk-chat-body">
          <Header />

          <ChatArea
            userDialog={messages}
            onChooseButton={onChooseButton}
            messagesAreaRef={messagesAreaRef}
            isTypingMessage={isTypingMessage}
          />

          <ChatEditor onMessage={onMessage} />

          <div className="copyright text-center">
            Powered by{' '}
            <a href="https://ezflow.cc/" target="_blank" rel="noopener noreferrer">
              ezFlow
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatPage;
