import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRequest } from 'estafette';
import { Helmet } from 'react-helmet';
import { form } from 'libs/http/api/form';
import { Header, DataPreloader } from 'components';
import PercentStatGraph from 'components/PercentStatGraph/PercentStatGraph';
import ConversionStatGraph from 'components/ConversionStatGraph/ConversionStatGraph';
import { useEffect } from 'react';

const FormStatistik = () => {
  const { id: idForm } = useParams();

  const { request: requestFilterStatistik, loading: loadingFilterStatistik } = useRequest({ loading: true });

  const [data, setData] = useState(null);
  const [firstLoading, setFirstLoading] = useState(true);

  const getChartSeries = async (startDate, endDate) => {
    let newFilter = { start_date: startDate, end_date: endDate };
    if (idForm) {
      newFilter = { ...newFilter, form_id: idForm };
    }
    const newData = await requestFilterStatistik(form.getFullStatistik.action(newFilter));
    setData(newData);
    setFirstLoading(false);
    return newData?.chart_series || null;
  };

  useEffect(() => {
    if (!loadingFilterStatistik) {
      setFirstLoading(loadingFilterStatistik);
    }
  }, [loadingFilterStatistik]);

  return (
    <>
      <Helmet>
        <title>Form analitics</title>
      </Helmet>

      <div className="nk-main">
        <div className="nk-wrap">
          <Header />

          <DataPreloader loading={firstLoading} />

          <div className="nk-content " style={{ marginTop: '70px', opacity: firstLoading ? 0 : 1 }}>
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  {/* <PageTitle title="analytics" /> */}

                  <div>
                    <div className="text-right">
                      <Link to={{ pathname: '/form/list' }} className="btn btn-primary">
                        <em className="icon ni ni-arrow-left"></em>
                        Back to form list
                      </Link>
                    </div>

                    <ConversionStatGraph
                      className="mt-50"
                      getChartSeries={getChartSeries}
                      loadingFilterStatistik={loadingFilterStatistik}
                      typeStat="form"
                    />

                    {data?.top_devices && (
                      <div className="percent-graphs">
                        <PercentStatGraph
                          title="Top Devices"
                          data={Object.keys(data.top_devices).map(key => {
                            return {
                              icon: <img src={`/assets/images/devices/${key}.svg`} alt="" />,
                              name: key,
                              count: data.top_devices[key],
                            };
                          })}
                        />

                        <PercentStatGraph
                          title="Top Countries"
                          data={Object.keys(data.top_countries).map(key => {
                            return {
                              icon: <img src={`/assets/images/flags/${key}.svg`} alt="" />,
                              name: key,
                              count: data.top_countries[key],
                            };
                          })}
                        />

                        <PercentStatGraph
                          title="Top Browsers"
                          data={Object.keys(data.top_browsers).map(key => {
                            return {
                              icon: (
                                <img
                                  src={`/assets/images/browsers/${
                                    key?.split(' ')[1] ? key.split(' ')[1]?.toLowerCase() : key?.toLowerCase()
                                  }.svg`}
                                  alt=""
                                />
                              ),
                              name: key,
                              count: data.top_browsers[key],
                            };
                          })}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormStatistik;
