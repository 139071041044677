import React from 'react';
import moment from 'moment';

export const DateSap = ({ datetime }) => {
  return (
    <div className="chat-sap">
      <div className="chat-sap-meta">
        <span>{moment(datetime).format('MMMM Do YYYY')}</span>
      </div>
    </div>
  );
};
