import React, { useState, useEffect } from 'react';
import { Range } from 'react-range';
import BlockColorPicker from '../BlockColorPicker/BlockColorPicker';

const styleName = 'boxShadow';

const BoxShadow = ({ element }) => {
  const [size] = useState({ value: [0], min: 0, max: 400, unit: 'px' });
  const [valueSize, setValueSize] = useState([0]);

  const [opacity] = useState({ value: [0], min: 0, max: 1, step: 0.1 });
  const [valueOpacity, setValueOpacity] = useState([0]);

  const [valueColor, setValueColor] = useState({ r: 0, g: 0, b: 0 });

  const [value, setValue] = useState(null);

  const onChangeSize = val => setValueSize(val);

  const onChangeOpacity = val => setValueOpacity(val);

  const onChangeColor = obj => setValueColor(obj.rgb);

  useEffect(() => {
    if (element && element.style) {
      const style = element.style[styleName];
      if (style) {
        setValue(style);
      }
    }
  }, [element]);

  useEffect(() => {
    if (value) {
      element.style[styleName] = value;
    }
  }, [value]);

  useEffect(() => {
    const style = `0 0 ${valueSize[0]}px rgba(${valueColor.r},${valueColor.g},${valueColor.b},${valueOpacity[0]})`;
    console.log(style);
    setValue(style);
  }, [valueSize, valueOpacity, valueColor]);

  return (
    <>
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <label className="form-label">Box shadow size</label>
          <span>{valueSize[0]}</span>
        </div>

        <Range
          step={size.step}
          min={size.min}
          max={size.max}
          values={valueSize}
          value={valueSize}
          onChange={onChangeSize}
          renderTrack={({ props, children }) => (
            <div {...props} className="slider__track">
              {children}
            </div>
          )}
          renderThumb={({ props }) => <div {...props} className="slider__thumb" />}
        />
      </div>

      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <label className="form-label">Box shadow opacity</label>
          <span>{valueOpacity[0]}</span>
        </div>

        <Range
          step={opacity.step}
          min={opacity.min}
          max={opacity.max}
          values={valueOpacity}
          value={valueOpacity}
          onChange={onChangeOpacity}
          renderTrack={({ props, children }) => (
            <div {...props} className="slider__track">
              {children}
            </div>
          )}
          renderThumb={({ props }) => <div {...props} className="slider__thumb" />}
        />
      </div>

      <div className="form-group mt-4">
        <div className="background-image mt-2">
          <BlockColorPicker element={element} label="Box shadow color" onChangeColor={onChangeColor} />
        </div>
      </div>
    </>
  );
};

export default BoxShadow;
