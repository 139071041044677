import React, { useRef } from 'react';

import imageIcon from 'assets/images/icons/image.svg';

import './ImgUpload.scss';

const ImgUpload = ({ item, onChangeImg, itemName }) => {
  const imageRef = useRef(null);

  const onChangeImage = e => {
    const file = e.target.files[0];
    const objectUrl = URL.createObjectURL(file);
    imageRef.current.src = objectUrl;

    onChangeImg(e, itemName, item);
  };

  return (
    <div className="form-group mt-4">
      <div className="background-image mt-25">
        <label className="form-label" htmlFor={item.name}>
          {item.label}
        </label>

        <div className="flex">
          <div className="image">
            <img src={item.value || imageIcon} alt="" ref={imageRef} />
          </div>

          <div>
            <div className="form-group text-center">
              <div className="select-file mt-28">
                <input
                  type="file"
                  accept="image/*"
                  className="custom-file-input"
                  id={`img-${item.name}`}
                  onChange={onChangeImage}
                />

                <label className="custom-file-label" htmlFor={`img-${item.name}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImgUpload;
