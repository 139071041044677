import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { DataPreloader, Dropdown, Header, PeriodSelect, Table } from 'components';
import { Status } from './components/Status/Status';
import { useRequest } from 'estafette';
import { sms } from 'libs/http/api/sms';
import { SmsGraph } from './SmsGraph/SmsGraph';
import { StatusGraph } from './StatusGraph/StatusGraph';

import './Sms.scss';

const statuses = ['Scheduled', 'Created', 'Error', 'Sent', 'Cancelled'];

const columnsTable = {
  name: 'Name',
  phone: 'Phone',
  sent_date: 'Sent date',
  planned_at: 'Scheduled date',
  text: 'Text',
  status: 'Status',
  cost: 'Cost',
};

const periodList = [
  {
    period: [moment().subtract(7, 'd'), moment.now()],
    prevPeriod: [moment().subtract(14, 'd'), moment().subtract(7, 'd')],
    label: 'Last 7 days',
  },
  {
    period: [moment().subtract(30, 'd'), moment.now()],
    prevPeriod: [moment().subtract(60, 'd'), moment().subtract(30, 'd')],
    label: 'Last 30 days',
  },
  {
    period: [moment().startOf('month'), moment.now()],
    prevPeriod: [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months')],
    label: 'This month',
  },
  {
    period: [moment().subtract(1, 'months').startOf('month'), moment().startOf('month')],
    prevPeriod: [moment().subtract(2, 'months').startOf('month'), moment().subtract(1, 'months').startOf('month')],
    label: 'Previous month',
  },
  {
    period: null,
    label: 'Custom period',
  },
];

export const Sms = () => {
  const { page } = useParams();

  const { request: requestSmsList, loading: loadingSmsList, data: dataSmsList } = useRequest({ loading: true });

  const [startLoadingSmsList, setStartLoadingSmsList] = useState(true);
  const [dataTable, setDataTable] = useState(null);
  const [checkedStatuses, setCheckedStatuses] = useState([0, 1, 2, 3, 4]);
  const [sort] = useState(false);
  const [isRangePeriod, setIsRangePeriod] = useState(false);
  const [periodSelected, setPeriodSelected] = useState({
    period: [moment().subtract(7, 'd'), moment.now()],
    prevPeriod: [moment().subtract(14, 'd'), moment().subtract(7, 'd')],
    label: 'Last 7 days',
  });

  const onHandlePeriod = async item => {
    if (!item.period) {
      setIsRangePeriod(true);
    } else {
      setPeriodSelected(item);
    }
  };

  const limit = useRef(500);

  const onChangeCheckedStatuses = (_, key) => {
    setCheckedStatuses(prevChecked => {
      if (prevChecked.includes(key)) {
        return prevChecked.filter(el => el !== key);
      }
      return [...prevChecked, key];
    });
  };

  const getSmsList = () => {
    const newCheckedStatuses = checkedStatuses;
    if (checkedStatuses.includes(4)) {
      newCheckedStatuses.push(5);
    }
    sms.list.cancel();
    requestSmsList(
      sms.list.action({
        offset: page > 0 ? page * limit.current - limit.current : 0,
        limit: limit.current,
        sort: sort ? 1 : -1,
        start: moment(periodSelected.period[0]).format('YYYY-MM-DD'),
        end: moment(periodSelected.period[1]).format('YYYY-MM-DD'),
        statuses_list: newCheckedStatuses.join(',') || [0, 1, 2, 3, 5].join(','),
      }),
    );

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onApplyStatuses = () => getSmsList();

  useEffect(() => {
    getSmsList();
  }, [page, periodSelected]);

  useEffect(() => {
    if (loadingSmsList === false) {
      setStartLoadingSmsList(false);
    }
  }, [loadingSmsList]);

  useEffect(() => {
    if (dataSmsList?.sms_list) {
      setDataTable(() =>
        dataSmsList.sms_list.map(el => {
          return {
            id: el.sms_id,
            data: {
              name: `${el.first_name} ${el.last_name}`,
              phone: el.phone,
              sent_date: el.sent_date ? moment(el.sent_date).format('MMMM Do YYYY, h:mm a') : '---',
              planned_at: el.plannedAtDt ? moment(el.plannedAtDt).format('MMMM Do YYYY, h:mm a') : '---',
              text: el.text,
              status: <Status type={el.status} message={el?.error_message || null} />,
              cost: el?.cost || '---',
            },
          };
        }),
      );
    }
  }, [dataSmsList]);

  return (
    <div className="nk-main">
      <div className="nk-wrap">
        <Header />

        <DataPreloader loading={loadingSmsList}>
          <div className="nk-content">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="card">
                    <div className="card-inner graphs-wrap">
                      <div class="d-flex flex-row-reverse">
                        <div>
                          <PeriodSelect
                            periodSelected={periodSelected}
                            periodList={periodList}
                            onHandlePeriod={onHandlePeriod}
                            isRangePeriod={isRangePeriod}
                            loading={loadingSmsList}
                          />
                        </div>
                      </div>

                      {dataTable && dataSmsList.sms_list.length > 0 ? (
                        <div className="d-flex">
                          <div className="p-2 sms-graph-wrap">
                            <SmsGraph
                              localChartSeries={dataSmsList.sms_list.length ? dataSmsList.user_analytics : null}
                            />
                          </div>
                          <div className="p-2 status-graph-wrap">
                            <StatusGraph
                              localChartSeries={dataSmsList.sms_list.length ? dataSmsList.user_analytics : null}
                            />
                          </div>
                        </div>
                      ) : (
                        <h5 className="no-data-info">
                          There is no data for the selected period, please choose another date range
                        </h5>
                      )}
                    </div>
                  </div>

                  {dataSmsList?.sms_list?.length > 0 && (
                    <div className="card">
                      <div className="card-inner">
                        <div class="d-flex flex-row-reverse">
                          <div>
                            <Dropdown title="Filter by status">
                              <ul class="link-tidy">
                                {statuses.map((el, key) => (
                                  <li>
                                    <div class="custom-control custom-control-sm custom-checkbox">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id={`cdp${key}-blx`}
                                        checked={checkedStatuses.includes(key)}
                                        onChange={e => onChangeCheckedStatuses(e, key)}
                                      />
                                      <label class="custom-control-label" for={`cdp${key}-blx`}>
                                        {el}
                                      </label>
                                    </div>
                                  </li>
                                ))}

                                <li>
                                  <input
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                    value="Apply"
                                    style={{ display: 'inline-block', width: '100%' }}
                                    onClick={onApplyStatuses}
                                  />
                                </li>
                              </ul>
                            </Dropdown>
                          </div>
                        </div>

                        <DataPreloader loading={loadingSmsList}>
                          {!loadingSmsList && dataTable && (
                            <Table
                              columns={columnsTable}
                              data={dataTable}
                              count={dataSmsList.sms_count}
                              page={page ? page * limit.current : 0}
                              limit={limit.current}
                              route="messages"
                            />
                          )}
                        </DataPreloader>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DataPreloader>
      </div>
    </div>
  );
};
