import React from 'react';

import './TypingMessage.scss';

export const TypingMessage = () => (
  <div className="typing-message">
    <span className="dot" />
    <span className="dot" />
    <span className="dot" />
  </div>
);
