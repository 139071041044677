import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

import './PeriodSelect.scss';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { DataPreloader } from 'components';

export const PeriodSelect = ({ periodSelected, periodList, onHandlePeriod, isRangePeriod, loading }) => {
  const [isList, setIsList] = useState(false);
  const [date, setDate] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [focusedDateInput, setFocusedDateInput] = useState(null);

  const onToggleList = () => setIsList(prev => !prev);

  const handleFocusChange = focusedInput => setFocusedDateInput(focusedInput);

  const onChangeRangeDate = newDate => setDate(newDate);

  const onClosePeriod = newDate => {
    onHandlePeriod({
      period: [newDate.startDate, newDate.endDate],
      prevPeriod: [
        moment(newDate.startDate).subtract(
          moment.duration(newDate.endDate.diff(newDate.startDate)).asDays() + 1,
          'days',
        ),
        newDate.startDate,
      ],
      label: 'Custom period',
    });
    setIsList(false);
  };

  useEffect(() => {
    if (isRangePeriod) {
      setIsList(true);
    }
  }, [isRangePeriod]);

  return (
    <div className={`period-select ${isList ? 'active' : ''}`}>
      {isList && <div className="close-select" onClick={onToggleList} />}

      <div className="select-button" onClick={onToggleList}>
        <DataPreloader loading={loading} inline />
        <span>{periodSelected.label}</span>
        <em className="icon ni ni-chevron-down" />
      </div>

      <ul className="period-list">
        {periodList.map((item, key) => (
          <li
            key={key}
            onClick={() => {
              onToggleList();
              onHandlePeriod(item);
            }}
          >
            {item.label}
          </li>
        ))}

        {isRangePeriod && isList && (
          <div className="period-range">
            <DateRangePicker
              startDate={date.startDate}
              endDate={date.endDate}
              onDatesChange={onChangeRangeDate}
              focusedInput={focusedDateInput}
              onFocusChange={handleFocusChange}
              isOutsideRange={() => false}
              onClose={onClosePeriod}
            />
          </div>
        )}
      </ul>
    </div>
  );
};
