import React from 'react';
import Tooltip from 'rc-tooltip';

import './FlowCreateList.scss';
import 'rc-tooltip/assets/bootstrap_white.css';

export const FlowCreateList = ({ style, onAction }) => {
  return (
    <div className="flowarea-create-list" style={style}>
      <div className="inner">
        <div className="action" onClick={e => onAction(e, 'sms')}>
          <div className="icon">
            <i className="fas fa-sms" />
          </div>

          <Tooltip placement="right" trigger={['hover']} overlay={<span>Send an outbound SMS message</span>}>
            <div className="name">Send SMS</div>
          </Tooltip>
        </div>

        <div className="action" onClick={e => onAction(e, 'function', 'choose a function')}>
          <div className="icon">
            <i className="fas fa-code-branch" />
          </div>

          <Tooltip
            placement="right"
            trigger={['hover']}
            overlay={<span>Combine multipleconditions to change the way the flow executes</span>}
          >
            <div className="name">Branch</div>
          </Tooltip>
        </div>

        <div className="action" onClick={e => onAction(e, 'callFunction', 'choose a function')}>
          <div className="icon">
            <i className="fas fa-random" />
          </div>

          <Tooltip placement="right" trigger={['hover']} overlay={<span>Create Call Function</span>}>
            <div className="name">Call Function</div>
          </Tooltip>
        </div>

        <div className="action" onClick={e => onAction(e, 'variable')}>
          <div className="icon">
            <i className="fas fa-archive" />
          </div>

          <Tooltip placement="right" trigger={['hover']} overlay={<span>Add variable</span>}>
            <div className="name">Variable</div>
          </Tooltip>
        </div>

        <div className="action" onClick={e => onAction(e, 'response')}>
          <div className="icon">
            <i className="fas fa-reply" />
          </div>

          <Tooltip placement="right" trigger={['hover']} overlay={<span>Response</span>}>
            <div className="name">Response</div>
          </Tooltip>
        </div>

        <div className="action" onClick={e => onAction(e, 'conversation-end')}>
          <div className="icon">
            <i className="fas fa-comment-slash" />
          </div>

          <Tooltip placement="right" trigger={['hover']} overlay={<span>Conversation End</span>}>
            <div className="name">Conversation End</div>
          </Tooltip>
        </div>

        <div className="action" onClick={e => onAction(e, 'jump-node')}>
          <div className="icon">
            <em className="icon ni ni-curve-left-up" />
          </div>

          <Tooltip placement="right" trigger={['hover']} overlay={<span>Jump to Node</span>}>
            <div className="name">Jump to Node</div>
          </Tooltip>
        </div>

        <div className="action" onClick={e => onAction(e, 'jump-flow-node')}>
          <div className="icon">
            <i className="fas fa-retweet" />
          </div>

          <Tooltip placement="right" trigger={['hover']} overlay={<span>Jump to Flow</span>}>
            <div className="name">Jump to Flow</div>
          </Tooltip>
        </div>

        <div className="action" onClick={e => onAction(e, 'http-request')}>
          <div className="icon">
            <i className="icon fas fa-project-diagram" />
          </div>

          <Tooltip placement="right" trigger={['hover']} overlay={<span>HTTP Request</span>}>
            <div className="name">HTTP Request</div>
          </Tooltip>
        </div>

        {/* <div className="action" onClick={e => onAction(e, 'branch')}>
          <div className="icon">
            <i class="fas fa-code-branch" />
          </div>

          <Tooltip
            placement="right"
            trigger={['hover']}
            overlay={<span>Combine multipleconditions to change the way the flow executes</span>}
          >
            <div className="name">Branch</div>
          </Tooltip>
        </div> */}
      </div>
    </div>
  );
};
