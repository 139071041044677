import React, { useState, useEffect } from 'react';
import Line from '../Line/Line';
import SettingsElement from '../SettingsElement/SettingsElement';
import { DataPreloader } from 'components/DataPreloader/DataPreloader';

const Sidebar = ({
  onDragstartHandler,
  onFocusElement,
  onDragstartHandlerComponent,
  onSave,
  activeElement,
  activeElementType,
  onCloseSettings,
  onRemoveElement,
  loadTemplate,
  formTemplate,
  loading,
}) => {
  const [step, setStep] = useState(0);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSave, setIsSave] = useState(false);

  const onChangeStep = type => {
    window.scrollTo({ top: 0 });

    if (type === 'minus' && step > 0) {
      setStep(prevStep => prevStep - 1);
    } else if (type === 'plus' && step < 3) {
      setStep(prevStep => prevStep + 1);
    }
  };

  const onChangeName = ({ target }) => setName(target.value);

  const onChangeDescription = ({ target }) => setDescription(target.value);

  const onSaveToggle = () => setIsSave(prevIsSave => !prevIsSave);

  const onSaveMid = type => {
    onSave({ type, name, description });
  };

  useEffect(() => {
    if (formTemplate?.form) {
      setName(formTemplate.form.config.info?.title);
      setDescription(formTemplate.form.config.info?.description);
    }
  }, [formTemplate]);

  return (
    <>
      <div className="nk-sidebar side-builder">
        <div className="nk-sidebar-body">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu">
              <div className="simplebar-wrapper">
                <div id="accordion" className="accordion">
                  <div className="accordion-body">
                    <div className="accordion-inner">
                      <div className="btn-group side-steps">
                        <button type="button" className={`btn${step === 0 ? ' active' : ''}`} disabled>
                          Settings
                        </button>
                        <button type="button" className={`btn${step === 1 ? ' active' : ''}`} disabled>
                          Layout
                        </button>
                      </div>
                    </div>
                  </div>

                  {!activeElementType ? (
                    <>
                      {step === 0 && (
                        <div className="accordion-body">
                          <div className="accordion-inner">
                            <div className="form-group mt-4">
                              <label className="form-label">Name</label>
                              <input
                                type="text"
                                required
                                className="form-control"
                                onChange={onChangeName}
                                value={name}
                              />
                            </div>

                            <div className="form-group mt-4">
                              <label className="form-label">Description</label>
                              <textarea
                                required
                                className="form-control"
                                onChange={onChangeDescription}
                                value={description}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {step === 1 && (
                        <>
                          <div className="accordion-item">
                            <div className="accordion-head">
                              <h6 className="title">Rows</h6>
                            </div>
                            <div className="accordion-body">
                              <div className="accordion-inner">
                                <Line columns={[4]} onDragstartHandler={onDragstartHandler} onClick={onFocusElement} />
                                <Line
                                  columns={[1, 3]}
                                  onDragstartHandler={onDragstartHandler}
                                  onClick={onFocusElement}
                                />
                                <Line
                                  columns={[2, 2]}
                                  onDragstartHandler={onDragstartHandler}
                                  onClick={onFocusElement}
                                />
                                <Line
                                  columns={[3, 1]}
                                  onDragstartHandler={onDragstartHandler}
                                  onClick={onFocusElement}
                                />
                                <Line
                                  columns={[1, 1, 2]}
                                  onDragstartHandler={onDragstartHandler}
                                  onClick={onFocusElement}
                                />
                                <Line
                                  columns={[1, 2, 1]}
                                  onDragstartHandler={onDragstartHandler}
                                  onClick={onFocusElement}
                                />
                                <Line
                                  columns={[2, 1, 1]}
                                  onDragstartHandler={onDragstartHandler}
                                  onClick={onFocusElement}
                                />
                                <Line
                                  columns={[1, 1, 1, 1]}
                                  onDragstartHandler={onDragstartHandler}
                                  onClick={onFocusElement}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <div className="accordion-head">
                              <h6 className="title">Elements</h6>
                            </div>
                            <div className="accordion-body">
                              <div className="accordion-inner">
                                <div
                                  draggable="true"
                                  onDragStart={onDragstartHandlerComponent}
                                  data-tuls-type="title"
                                  className="tuls-component tuls-head"
                                  style={{
                                    'font-size': '26px',
                                    'font-weight': '600',
                                    'font-family': 'Arial',
                                    color: 'rgb(0, 0, 0)',
                                  }}
                                >
                                  Heading text
                                </div>

                                <div data-tuls-type="text" className="tuls-component">
                                  <input
                                    type="text"
                                    className="tuls-form-input tuls-component"
                                    placeholder="Your text"
                                    name="name"
                                    required="true"
                                    draggable="true"
                                    onDragStart={onDragstartHandlerComponent}
                                    autocomplete="off"
                                    data-tuls-type="input"
                                    style={{ width: '100%', fontWeight: '400' }}
                                  />
                                </div>

                                <div data-tuls-type="email" className="tuls-component">
                                  <input
                                    type="email"
                                    className="tuls-form-input tuls-component"
                                    placeholder="email@email.com"
                                    name="email"
                                    required="true"
                                    draggable="true"
                                    onDragStart={onDragstartHandlerComponent}
                                    autocomplete="off"
                                    data-tuls-type="input"
                                    style={{ width: '100%', fontWeight: '400' }}
                                  />
                                </div>
                                <div data-tuls-type="phone" className="tuls-component">
                                  <input
                                    type="text"
                                    className="tuls-form-input tuls-component"
                                    placeholder="+7 495 1234567"
                                    name="phone"
                                    required="true"
                                    draggable="true"
                                    onDragStart={onDragstartHandlerComponent}
                                    autocomplete="off"
                                    data-tuls-type="input"
                                    style={{ width: '100%', fontWeight: '400' }}
                                  />
                                </div>

                                <div data-tuls-type="textarea" className="tuls-component">
                                  <textarea
                                    className="tuls-form-input tuls-component"
                                    placeholder="Your text"
                                    name="comment"
                                    draggable="true"
                                    onDragStart={onDragstartHandlerComponent}
                                    autocomplete="off"
                                    data-tuls-type="textarea"
                                    style={{ width: '100%', fontWeight: '400' }}
                                  />
                                </div>

                                <div data-tuls-type="button" className="tuls-component">
                                  <button
                                    draggable="true"
                                    onDragStart={onDragstartHandlerComponent}
                                    data-tuls-type="button"
                                    className="tuls-component tuls-form-button"
                                    style={{
                                      'font-size': '15px',
                                      'font-family': 'Arial',
                                      'font-weight': '500',
                                      color: 'rgb(255, 255, 255)',
                                      'background-color': 'rgb(133, 79, 255)',
                                      'border-radius': '5px',
                                      width: '100%',
                                    }}
                                  >
                                    <span>Submit</span>
                                  </button>
                                </div>

                                <div data-tuls-type="text" data-title="first_name" className="tuls-component">
                                  <input
                                    type="text"
                                    className="tuls-form-input tuls-component"
                                    placeholder="Your first name"
                                    name="first_name"
                                    required="true"
                                    draggable="true"
                                    onDragStart={onDragstartHandlerComponent}
                                    autocomplete="off"
                                    data-tuls-type="input"
                                    style={{ width: '100%', fontWeight: '400' }}
                                  />
                                </div>

                                <div data-tuls-type="text" data-title="last_name" className="tuls-component">
                                  <input
                                    type="text"
                                    className="tuls-form-input tuls-component"
                                    placeholder="Your last name"
                                    name="last_name"
                                    required="true"
                                    draggable="true"
                                    onDragStart={onDragstartHandlerComponent}
                                    autocomplete="off"
                                    data-tuls-type="input"
                                    style={{ width: '100%', fontWeight: '400' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="flex navigation">
                        <DataPreloader loading={loading}>
                          <div>
                            {step !== 0 && (
                              <button className="btn btn-wider btn-primary" onClick={() => onChangeStep('minus')}>
                                <em className="icon ni ni-arrow-left" />
                                <span>Prev</span>
                              </button>
                            )}
                          </div>

                          <div className="text-right">
                            {step < 1 ? (
                              <button className="btn btn-wider btn-primary" onClick={() => onChangeStep('plus')}>
                                <span>Next</span>
                                <em className="icon ni ni-arrow-right" />
                              </button>
                            ) : (
                              // <div className="dropdown">
                              //   <button
                              //     className="btn btn-save dropdown-toggle"
                              //     type="button"
                              //     dataToggle="dropdown"
                              //     onClick={onSaveToggle}
                              //   >
                              //     Save as <em className="icon ni ni-chevron-down ml-1" />
                              //   </button>
                              //   <div
                              //     className={`dropdown-menu ${isSave ? 'show' : ''}`}
                              //     style={{ 'min-width': '109px' }}
                              //   >
                              //     <ul className="link-list-opt">
                              //       <li>
                              //         <a href="#r" onClick={() => onSaveMid('form')}>
                              //           <span>form</span>
                              //         </a>
                              //       </li>
                              //       <li>
                              //         <a href="#r" onClick={() => onSaveMid('template')}>
                              //           <span>template</span>
                              //         </a>
                              //       </li>
                              //     </ul>
                              //   </div>
                              // </div>
                              <button
                                className="btn btn-wider btn-primary"
                                onClick={() => onSaveMid('form')}
                                // disabled={loadingSave || loadingUploadImage}
                              >
                                <span>Save</span>
                                <em className="icon ni ni-check" />
                              </button>
                            )}
                          </div>
                        </DataPreloader>
                      </div>
                    </>
                  ) : (
                    <div className="accordion-item">
                      <div className="accordion-head row d-flex">
                        <h6 className="title col-md-8">Settings</h6>
                        <div className="text-right col-md-4">
                          <em className="icon ni ni-cross close-settings" onClick={onCloseSettings} />
                        </div>
                      </div>
                      <div className="accordion-body">
                        <div className="accordion-inner">
                          <SettingsElement
                            activeElement={activeElement}
                            activeElementType={activeElementType}
                            onRemoveElement={onRemoveElement}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
