import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { DataPreloader } from 'components';

export const ConnectToken = ({ channel, onAddChannel, loading }) => {
  const { register, handleSubmit: handleSubmitToken } = useForm();

  const onSubmit = async data => onAddChannel(channel, data.token);

  return (
    <form className="connecting-form mt-5 mb-5" onSubmit={handleSubmitToken(onSubmit)}>
      {channel === 'telegram' && (
        <>
          <h5>Connect existing Telegram bot</h5>

          <div className="alert alert-warning alert-icon mt-4">
            <em className="icon ni ni-alert-circle"></em> We highly recommend you not to use the same token for
            different services, otherwise the bot will work incorrectly.
          </div>

          <p className="mt-2">The instruction helps you to connect the Telegram bot.</p>

          <ul className="steps-list">
            <li>
              <span className="step">1</span>
              <span>
                Open <Link to={{ pathname: '/' }}>@BotFather</Link> in Telegram and click <strong>/start</strong>
              </span>
            </li>
            <li>
              <span className="step">2</span>
              <span>
                Send <strong>/mybots</strong> and choose the bot you want to connect from the list
              </span>
            </li>
            <li>
              <span className="step">3</span>
              <span>Copy its API token and paste it here</span>
            </li>
          </ul>

          <div className="form-group mt-4">
            <label className="form-label">Telegram bot token</label>
            <div className="form-control-wrap">
              <input type="text" className="form-control" placeholder="Enter token" {...register('token')} required />
            </div>
          </div>
        </>
      )}

      {channel === 'facebook' && (
        <>
          <h5>Connect existing Facebook</h5>

          <div className="alert alert-warning alert-icon mt-4">
            <em className="icon ni ni-alert-circle"></em> We highly recommend you not to use the same token for
            different services, otherwise the bot will work incorrectly.
          </div>

          <p className="mt-2">The instruction helps you to connect the Facebook.</p>

          <ul className="steps-list">
            <li>
              <span className="step">1</span>
              <span>
                Open <Link to={{ pathname: '/' }}>@BotFather</Link> in Telegram and click <strong>/start</strong>
              </span>
            </li>
            <li>
              <span className="step">2</span>
              <span>
                Send <strong>/mybots</strong> and choose the bot you want to connect from the list
              </span>
            </li>
            <li>
              <span className="step">3</span>
              <span>Copy its API token and paste it here</span>
            </li>
          </ul>

          <div className="form-group mt-4">
            <label className="form-label">Telegram bot token</label>
            <div className="form-control-wrap">
              <input type="text" className="form-control" placeholder="Enter token" {...register('token')} required />
            </div>
          </div>
        </>
      )}

      {channel === 'sms' && (
        <>
          <h5>Connect SMS account</h5>

          <p className="mt-2">The instruction helps you to connect the SMS platform.</p>

          <ul className="steps-list">
            <li>
              <span className="step">1</span>
              <span>
                Go to{' '}
                <a
                  href="https://admin.p1sms.ru/ru-ru/login.html?utm_ref_id=5f0eb579b5d6a"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  p1sms
                </a>
              </span>
            </li>
            <li>
              <span className="step">2</span>
              <span>SignIn or SignUP</span>
            </li>
            <li>
              <span className="step">3</span>
              <span>Create your API token</span>
            </li>
            <li>
              <span className="step">4</span>
              <span>And copy it to ezFlow</span>
            </li>
          </ul>

          <div className="form-group mt-4">
            <label className="form-label">SMS token</label>
            <div className="form-control-wrap">
              <input type="text" className="form-control" placeholder="Enter token" {...register('token')} required />
            </div>
          </div>
        </>
      )}

      <DataPreloader loading={loading}>
        <button type="submit" className="btn btn-primary btn-connect mt-1">
          Connect
        </button>
      </DataPreloader>
    </form>
  );
};
