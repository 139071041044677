import React from 'react';
import { Helmet } from 'react-helmet';
import { Dashboard } from 'layouts/Dashboard';
import { Contact } from 'components';

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact detail</title>
      </Helmet>

      <Dashboard>
        <Contact />
      </Dashboard>
    </>
  );
};

export default ContactPage;
