import React, { useState } from 'react';
import SelectFont from '../SelectFont/SelectFont';
import RangeSelector from '../RangeSelector/RangeSelector';
import BlockColorPicker from '../BlockColorPicker/BlockColorPicker';
import Select from '../Select/Select';
import FrameCode from '../FrameCode/FrameCode';
import FormCode from '../FormCode/FormCode';
import BackgroundImage from '../BackgroundImage/BackgroundImage';
import BoxShadow from '../BoxShadow/BoxShadow';
import Input from '../Input/Input';
import Width100 from '../Width100/Width100';
import NameInput from '../NameInput/NameInput';
import PlaceholderInput from '../PlaceholderInput/PlaceholderInput';
import IsRequired from '../IsRequired/IsRequired';

const SettingsElement = ({ activeElement, activeElementType, onRemoveElement }) => {
  const [isRemoving, setIsRemoving] = useState(false);

  const onRemoveToggle = () => setIsRemoving(prev => !prev);

  const InputSettings = () => (
    <>
      <RemoveElement />
      <hr />
      <IsRequired element={activeElement} />
      <NameInput element={activeElement} />
      <PlaceholderInput element={activeElement} />
      <SelectFont element={activeElement} styleName="fontFamily" />
      <RangeSelector
        element={activeElement}
        styleName="width"
        item={{ label: 'Width', value: [100], min: 0, max: 100, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="fontSize"
        item={{ label: 'Font size', value: [18], min: 12, max: 48, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="fontWeight"
        item={{ label: 'Font weight', value: [300], min: 300, max: 800, step: 100, unit: '' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName={['paddingLeft', 'paddingRight']}
        item={{ label: 'Horizontal padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName={['paddingTop', 'paddingBottom']}
        item={{ label: 'Vertical padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="marginLeft"
        item={{ label: 'Margin left', value: [0], min: 0, max: 200, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="marginTop"
        item={{ label: 'Margin top', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="lineHeight"
        item={{ label: 'Line height', value: [24], min: 0, max: 80, unit: 'px' }}
      />
      <Select
        element={activeElement}
        styleName="textAlign"
        item={{ label: 'Text align', value: 'left', options: ['left', 'center', 'right'] }}
      />
      <BlockColorPicker element={activeElement} styleName="color" label="Color" />
      {/* <Width100 element={activeElement} /> */}
    </>
  );

  const TextareaSettings = () => (
    <>
      <RemoveElement />
      <hr />
      <IsRequired element={activeElement} />
      <NameInput element={activeElement} />
      <PlaceholderInput element={activeElement} />
      <SelectFont element={activeElement} styleName="fontFamily" />
      <RangeSelector
        element={activeElement}
        styleName="width"
        item={{ label: 'Width', value: [100], min: 0, max: 100, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="fontSize"
        item={{ label: 'Font size', value: [18], min: 12, max: 48, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="fontWeight"
        item={{ label: 'Font weight', value: [300], min: 300, max: 800, step: 100, unit: '' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName={['paddingLeft', 'paddingRight']}
        item={{ label: 'Horizontal padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName={['paddingTop', 'paddingBottom']}
        item={{ label: 'Vertical padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="marginLeft"
        item={{ label: 'Margin left', value: [0], min: 0, max: 200, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="marginTop"
        item={{ label: 'Margin top', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="lineHeight"
        item={{ label: 'Line height', value: [24], min: 0, max: 80, unit: 'px' }}
      />
      <Select
        element={activeElement}
        styleName="textAlign"
        item={{ label: 'Text align', value: 'left', options: ['left', 'center', 'right'] }}
      />
      <BlockColorPicker element={activeElement} styleName="color" label="Color" />
      {/* <Width100 element={activeElement} /> */}
    </>
  );

  const TextSettings = () => (
    <>
      <RemoveElement />
      <hr />
      <SelectFont element={activeElement} styleName="fontFamily" />
      <RangeSelector
        element={activeElement}
        styleName="width"
        item={{ label: 'Width', value: [100], min: 0, max: 100, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="fontSize"
        item={{ label: 'Font size', value: [18], min: 12, max: 48, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="fontWeight"
        item={{ label: 'Font weight', value: [300], min: 300, max: 800, step: 100, unit: '' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName={['paddingLeft', 'paddingRight']}
        item={{ label: 'Horizontal padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName={['paddingTop', 'paddingBottom']}
        item={{ label: 'Vertical padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="marginLeft"
        item={{ label: 'Margin left', value: [0], min: 0, max: 200, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="marginTop"
        item={{ label: 'Margin top', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="lineHeight"
        item={{ label: 'Line height', value: [24], min: 0, max: 80, unit: 'px' }}
      />
      <Select
        element={activeElement}
        styleName="textAlign"
        item={{ label: 'Text align', value: 'left', options: ['left', 'center', 'right'] }}
      />
      <BlockColorPicker element={activeElement} styleName="color" label="Color" />
    </>
  );

  const ImageSettings = () => (
    <>
      <RemoveElement />
      <hr />
      <BackgroundImage element={activeElement} styleName="backgroundImage" item={{ label: 'Background image' }} />
      <Select
        element={activeElement}
        styleName="backgroundRepeat"
        item={{
          label: 'Repeat mode',
          value: 'repeat',
          options: ['repeat', 'no-repeat', 'repeat-x', 'repeat-y', 'space', 'round'],
        }}
      />
      <Select
        element={activeElement}
        styleName="backgroundSize"
        item={{ label: 'Background size', value: 'auto', options: ['auto', 'contain', 'cover'] }}
      />
      <Select
        element={activeElement}
        styleName="backgroundPosition"
        item={{ label: 'Background position', value: 'left', options: ['left', 'right', 'top', 'bottom', 'center'] }}
      />
      <BlockColorPicker element={activeElement} styleName="backgroundColor" label="Background color" />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName={['paddingLeft', 'paddingRight']}
        item={{ label: 'Horizontal padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName={['paddingTop', 'paddingBottom']}
        item={{ label: 'Vertical padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="left"
        item={{ label: 'Margin left', value: [0], min: 0, max: 200, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="top"
        item={{ label: 'Margin top', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="height"
        item={{ label: 'Height', value: [0], min: 0, max: 1000, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="width"
        item={{ label: 'Width', value: [0], min: 0, max: 1000, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="width"
        item={{ label: 'Width', value: [100], min: 0, max: 100, unit: '%' }}
      />
    </>
  );

  const ButtonSettings = () => (
    <>
      <RemoveElement />
      <hr />
      {/* <Width100 element={activeElement} /> */}
      <SelectFont element={activeElement} styleName="fontFamily" />
      <RangeSelector
        element={activeElement}
        styleName="width"
        item={{ label: 'Width', value: [100], min: 0, max: 100, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="fontSize"
        item={{ label: 'Font size', value: [18], min: 12, max: 48, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="fontWeight"
        item={{ label: 'Font weight', value: [300], min: 300, max: 800, step: 100, unit: '' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="lineHeight"
        item={{ label: 'Line height', value: [24], min: 0, max: 80, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName={['paddingLeft', 'paddingRight']}
        item={{ label: 'Horizontal padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName={['paddingTop', 'paddingBottom']}
        item={{ label: 'Vertical padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="marginLeft"
        item={{ label: 'Margin left', value: [0], min: 0, max: 200, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="marginTop"
        item={{ label: 'Margin top', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <BlockColorPicker element={activeElement} styleName="backgroundColor" label="Background color" />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="borderRadius"
        item={{ label: 'Border radius', value: [0], min: 0, max: 100, unit: 'px' }}
      />
      <hr />
      <Select
        element={activeElement}
        attributeName="data-tuls-action"
        item={{ label: 'Choose action', value: 'button', options: ['button', 'link', 'phone', 'close'] }}
      />
      <Input
        element={activeElement}
        attributeName="data-tuls-link"
        item={{
          label: 'Button link',
          value: activeElement?.getAttribute('data-tuls-link') || '',
          placeholder: 'https://...',
        }}
      />
    </>
  );

  const LineSettings = () => (
    <>
      <RemoveElement />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="width"
        item={{ label: 'Width', value: [100], min: 0, max: 100, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName={['paddingLeft', 'paddingRight']}
        item={{ label: 'Horizontal padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName={['paddingTop', 'paddingBottom']}
        item={{ label: 'Vertical padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <BackgroundImage element={activeElement} styleName="backgroundImage" item={{ label: 'Background image' }} />
      <Select
        element={activeElement}
        styleName="backgroundRepeat"
        item={{
          label: 'Repeat mode',
          value: 'repeat',
          options: ['repeat', 'no-repeat', 'repeat-x', 'repeat-y', 'space', 'round'],
        }}
      />
      <Select
        element={activeElement}
        styleName="backgroundSize"
        item={{ label: 'Background size', value: 'auto', options: ['auto', 'contain', 'cover'] }}
      />
      <Select
        element={activeElement}
        styleName="backgroundPosition"
        item={{ label: 'Background position', value: 'left', options: ['left', 'right', 'top', 'bottom', 'center'] }}
      />
      <hr />
      <BlockColorPicker element={activeElement} styleName="backgroundColor" label="Background color" />
    </>
  );

  const ModalSettings = () => (
    <>
      <RangeSelector
        element={activeElement}
        styleName={['paddingLeft', 'paddingRight']}
        item={{ label: 'Horizontal padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName={['paddingTop', 'paddingBottom']}
        item={{ label: 'Vertical padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <BackgroundImage element={activeElement} styleName="backgroundImage" item={{ label: 'Background image' }} />
      <Select
        element={activeElement}
        styleName="backgroundRepeat"
        item={{
          label: 'Repeat mode',
          value: 'repeat',
          options: ['repeat', 'no-repeat', 'repeat-x', 'repeat-y', 'space', 'round'],
        }}
      />
      <Select
        element={activeElement}
        styleName="backgroundSize"
        item={{ label: 'Background size', value: 'auto', options: ['auto', 'contain', 'cover'] }}
      />
      <Select
        element={activeElement}
        styleName="backgroundPosition"
        item={{ label: 'Background position', value: 'left', options: ['left', 'right', 'top', 'bottom', 'center'] }}
      />
      <BlockColorPicker element={activeElement} styleName="backgroundColor" label="Background color" />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="borderWidth"
        item={{ label: 'Border width', value: [0], min: 0, max: 5, unit: 'px' }}
      />
      <BlockColorPicker element={activeElement} styleName="borderColor" label="Border color" />
      <hr />
      <BoxShadow element={activeElement} />
    </>
  );

  const CodeFrameSettings = () => (
    <>
      <RemoveElement />
      <hr />
      <FrameCode element={activeElement} />
    </>
  );

  const FormFrameSettings = () => (
    <>
      <RemoveElement />
      <hr />
      <FormCode element={activeElement} />
    </>
  );

  const RemoveElement = () => (
    <div className="dropdown">
      <button className="btn btn-danger dropdown-toggle" type="button" onClick={onRemoveToggle}>
        Remove element <em className="icon ni ni-trash ml-1" />
      </button>
      <div className={`dropdown-menu ${isRemoving ? 'show' : ''}`}>
        <ul className="link-list-opt">
          <li>
            <a href="#r" onClick={onRemoveElement}>
              <span>Remove</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  switch (activeElementType) {
    case 'title':
      return <TextSettings />;
    case 'text':
      return <TextSettings />;
    case 'button':
      return <ButtonSettings />;
    case 'backgroundImage':
      return <ImageSettings />;
    case 'line':
      return <LineSettings />;
    case 'modal':
      return <ModalSettings />;
    case 'codeFrame':
      return <CodeFrameSettings />;
    case 'formFrame':
      return <FormFrameSettings />;
    case 'input':
      return <InputSettings />;
    case 'textarea':
      return <TextareaSettings />;
    default:
      return <></>;
  }
};

export default SettingsElement;
