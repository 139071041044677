import React, { useState } from 'react';
import Pagination from 'rc-pagination';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import './StyledTable.scss';

export const StyledTable = ({
  columns,
  data,
  count,
  sort,
  limit,
  onChangeSort,
  route,
  isChecking = true,
  onRemove,
  onEdit,
  onSend,
  onChangeChecked,
}) => {
  const [checked, setChecked] = useState([]);
  const { page } = useParams();

  const onCheck = id => {
    if (checked.includes(id)) {
      setChecked(prevChecked => prevChecked.filter(el => el !== id));
    } else {
      setChecked(prevChecked => [...prevChecked, id]);
    }
  };

  const onChangeAllChecks = ({ target }) => {
    if (target.checked) {
      setChecked(() => {
        const arrIds = data.map(el => {
          return el.id;
        });

        return arrIds;
      });
    } else {
      setChecked([]);
    }
  };

  useEffect(() => {
    if (onChangeChecked) {
      onChangeChecked(checked);
    }
  }, [checked, onChangeChecked]);

  return (
    <>
      {/* <div className="row justify-between g-2">
        <div className="col-7 col-sm-4 text-left">
          <div id="DataTables_Table_1_filter" className="dataTables_filter">
            <label>
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder="Type in to Search"
                aria-controls="DataTables_Table_1"
              />
            </label>
          </div>
        </div>
        <div className="col-5 col-sm-8 text-right">
          <div className="datatable-filter">
            <div className="d-flex justify-content-end g-2">
              <div className="dataTables_length" id="DataTables_Table_1_length">
                <label>
                  <span className="d-none d-sm-inline-block">Show</span>
                  <div className="form-control-select">
                    {' '}
                    <select
                      name="DataTables_Table_1_length"
                      aria-controls="DataTables_Table_1"
                      className="custom-select custom-select-sm form-control form-control-sm"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>{' '}
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="styled-table">
        {data?.length === 0 ? (
          <p style={{ padding: 20 }}>No data</p>
        ) : (
          <div className="nk-tb-list is-separate mb-3">
            <div className="nk-tb-item nk-tb-head">
              {isChecking && (
                <div
                  className="nk-tb-col nk-tb-col-check"
                  tabindex="0"
                  aria-controls="DataTables_Table_1"
                  rowspan="1"
                  colspan="1"
                  aria-label=": activate to sort column descending"
                  aria-sort="ascending"
                >
                  <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input type="checkbox" className="custom-control-input" id="uid" onChange={onChangeAllChecks} />
                    <label className="custom-control-label" for="uid"></label>
                  </div>
                </div>
              )}

              {columns &&
                Object.keys(columns)?.map(key => (
                  <div
                    className={`nk-tb-col tb-col-mb${
                      key === 'creationDate' && sort !== null && onChangeSort
                        ? ` sorting sorting_${sort ? 'asc' : 'desc'}`
                        : ''
                    } ${key === 'action' ? 'nk-tb-col-tools' : ''} td-${key}`}
                    onClick={() => onChangeSort && onChangeSort(key)}
                    key={key}
                  >
                    <span className={`sub-text ${key === 'action' ? 'pull-right' : ''}`}>{columns[key]}</span>
                  </div>
                ))}
              {/* <div
                className="nk-tb-col nk-tb-col-tools text-right sorting"
                tabindex="0"
                aria-controls="DataTables_Table_1"
                rowspan="1"
                colspan="1"
                aria-label=": activate to sort column ascending"
              ></div> */}
            </div>

            {data &&
              data?.map((tr, key) => (
                <div className="nk-tb-item odd" key={key} data-id={tr.id}>
                  {isChecking && (
                    <div className="nk-tb-col nk-tb-col-check sorting_1">
                      <div className="custom-control custom-control-sm custom-checkbox notext">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={`check-${tr.id}`}
                          checked={checked.includes(tr.id)}
                          onChange={() => onCheck(tr.id)}
                        />
                        <label className="custom-control-label" for={`check-${tr.id}`}></label>
                      </div>
                    </div>
                  )}

                  {Object.keys(tr.data).map(tdKey => (
                    <div className={`nk-tb-col td-${tdKey}`} key={tdKey}>
                      {tr.data[tdKey]}
                    </div>
                  ))}

                  {/* <div className="nk-tb-col nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1">
                      {onRemove && (
                        <li className="nk-tb-action-hidden">
                          <a
                            href="#s"
                            className="btn btn-trigger btn-icon"
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                            data-original-title="Delete"
                          >
                            <i className="icon fas fa-trash-alt"></i>
                          </a>
                        </li>
                      )}

                      {onSend && (
                        <li className="nk-tb-action-hidden">
                          <a
                            href="#s"
                            className="btn btn-trigger btn-icon"
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                            data-original-title="Send Message"
                          >
                            <em className="icon ni ni-mail-fill"></em>
                          </a>
                        </li>
                      )}

                      {onEdit && (
                        <li className="nk-tb-action-hidden">
                          <a
                            href="#s"
                            className="btn btn-trigger btn-icon"
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                            data-original-title="Edit"
                          >
                            <i className="icon fas fa-edit"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div> */}
                </div>
              ))}
          </div>
        )}
      </div>

      {count / limit > 1 && (
        <div className="card">
          <div className="card-inner">
            <div className="dataTables_paginate paging_simple_numbers">
              <Pagination
                className="pagination"
                current={typeof page === 'undefined' ? 1 : page}
                total={count}
                pageSize={limit}
                locale="en_EN"
                style={{
                  'overflow-y': 'hidden',
                  'max-height': '36px',
                  'padding-left': '1px',
                  'border-radius': 0,
                }}
                itemRender={(current, type) => (
                  <>
                    {type === 'prev' && (
                      <Link
                        to={{ pathname: `/${route}/${current}` }}
                        className="page-link"
                        aria-controls="DataTables_Table_1"
                        data-dt-idx={current}
                      >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </Link>
                    )}

                    {type === 'next' && (
                      <Link
                        to={{ pathname: `/${route}/${current}` }}
                        className="page-link"
                        aria-controls="DataTables_Table_1"
                        data-dt-idx={current}
                      >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </Link>
                    )}

                    {type === 'page' && (
                      <Link
                        to={{ pathname: `/${route}/${current}` }}
                        className={`page-link${
                          Number(current) === Number(typeof page === 'undefined' ? 1 : page) ? ' active' : ''
                        }`}
                        aria-controls="DataTables_Table_1"
                        data-dt-idx={current}
                      >
                        {current}
                      </Link>
                    )}

                    {(type === 'jump-prev' || type === 'jump-next') && (
                      <Link
                        to={{ pathname: `/${route}/${current}` }}
                        className="page-link"
                        aria-controls="DataTables_Table_1"
                        data-dt-idx={current}
                      >
                        ...
                      </Link>
                    )}
                  </>
                )}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
