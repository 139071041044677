import React from 'react';
import { Helmet } from 'react-helmet';
import { Dashboard } from 'layouts/Dashboard';
import { Billing } from 'components';

const BillingPage = () => {
  return (
    <>
      <Helmet>
        <title>Billing</title>
      </Helmet>

      <Dashboard>
        <Billing />
      </Dashboard>
    </>
  );
};

export default BillingPage;
