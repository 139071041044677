import React from 'react';
import moment from 'moment';
import { DataPreloader } from 'components/DataPreloader/DataPreloader';

import './HistorySidebar.scss';

export const HistorySidebar = ({ versions, onClose, loading, activeVersion, onChangeVersion }) => {
  return (
    <div className="versions-history-sidebar">
      <i className="fas fa-times close-sidebar" onClick={onClose} />
      <div className="title">Version history</div>

      <DataPreloader loading={loading}>
        {versions?.flow_versions &&
          versions.flow_versions.map(version => (
            <div
              className={`version ${activeVersion === Number(version.version) ? 'active' : ''}`}
              onClick={() => onChangeVersion(version.version)}
            >
              <span className="name">{version.title}</span> -{' '}
              <span className="date">{moment(version.creation_date).format('YYYY/M/D HH:MM')}</span>
              <span className="edit">Edit</span>
            </div>
          ))}
      </DataPreloader>
    </div>
  );
};
