import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useRequest } from 'estafette';
import { useForm } from 'react-hook-form';
import { contacts } from 'libs/http/api/contacts';
import { DataPreloader, Header, Table, Toast, ModalConfirm } from 'components';
import { Status } from 'components/Sms/components/Status/Status';

import './Contacts.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { ContactSidebar } from './ContactSidebar/ContactSidebar';
import { ContactProfileUdItem } from './ContactProfileUdItem';

const tabs = [
  {
    name: 'User detail',
    id: 'contact_detail',
    icon: <em className="icon ni ni-user"></em>,
  },
  {
    name: 'Messages',
    id: 'flows',
    icon: <i className="icon fas fa-stream"></i>,
  },
  {
    name: 'Actions',
    id: 'send_message',
    icon: <i className="icon fas fa-envelope"></i>,
  },
];

// const columnsContactTable = {
//   contact: 'Contact',
//   phone: 'Phone',
//   email: 'Email',
//   creationDate: 'Creation date',
//   flow_name: 'Flow',
// };

const columnsFlowsTable = {
  creationDate: 'Sending date',
  text: 'Text',
  sender: 'Sender',
  status: 'Status',
  cost: 'Cost',
  tag: 'Tag',
};

export const Contact = () => {
  const { client_id, action } = useParams();

  const { register, handleSubmit: handleSubmitMessage } = useForm();

  const { request: requestContact, loading: loadingContact, data: dataContact } = useRequest({});
  const { request: requestDeleteContact, loading: loadingDeleteContact } = useRequest({});
  const { request: requestSendMessage, loading: loadingSendMessage } = useRequest({});
  const { request: requestUpdateContact, loading: loadingUpdateContact } = useRequest({});

  const [dataContactTable, setDataContactTable] = useState(null);
  const [dataFlowsTable, setDataFlowsTable] = useState(null);
  const [activeTab, setActiveTab] = useState(action || 'contact_detail');
  const [isDeleteModalConfirm, setIsDeleteModalConfirm] = useState(false);
  const [plannedAt, setPlannedAt] = useState(null);
  const [isToastSuccess, setIsToastSuccess] = useState(false);
  const [formContact, setFormContact] = useState({});

  const onChangeFormContactField = ({ fieldName, value }) => {
    setFormContact(prevForm => {
      return { ...prevForm, [fieldName]: value };
    });
  };

  const getContact = () => {
    requestContact(contacts.getContact.action({ client_id }));
  };

  const onUpdateContactInfo = async () => {
    await requestUpdateContact(contacts.updateContactInfo.action(client_id, formContact));
    setIsToastSuccess(true);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const onChangeTab = (e, tabId) => {
    e.preventDefault();
    setActiveTab(tabId);
  };

  const onDeleteModalConfirm = () => setIsDeleteModalConfirm(true);

  const onDeleteContact = async () => {
    setIsDeleteModalConfirm(false);
    await requestDeleteContact(contacts.deleteContact.action({ client_id }));
    setIsToastSuccess(true);
    setTimeout(() => {
      window.location.replace('/contacts');
    }, 3000);
  };

  const onCancelDelete = () => {
    setIsDeleteModalConfirm(false);
  };

  const onSubmitMessage = async data => {
    await requestSendMessage(
      contacts.messageToContact.action({
        planned_at: moment(plannedAt).toISOString(),
        phone: data.phone || null,
        sms_text: data.sms_text || null,
        first_name: data.first_name || null,
        last_name: data.last_name || null,
      }),
    );
    setIsToastSuccess(true);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  useEffect(() => {
    console.log('formContact: ', formContact);
  }, [formContact]);

  useEffect(() => {
    getContact();
  }, []);

  useEffect(() => {
    if (dataContact?.contact) {
      setDataContactTable([
        {
          id: dataContact.contact.uid,
          data: {
            name: `${dataContact.contact.first_name} ${dataContact.contact.last_name} ${dataContact.contact.father_name}`,
            phone: dataContact.contact.phone,
            email: dataContact.contact.email,
            creationDate: moment(dataContact.contact.creation_date).utc(0).format('MMMM Do YYYY, h:mm a'),
            flow_name: dataContact.contact.flow_name,
          },
        },
      ]);

      setDataFlowsTable(
        dataContact.contact.flow.map(flow => {
          return {
            id: flow.sms_id,
            data: {
              creationDate: moment(flow.plannedAtDt).utc(0).local().format('MMMM Do YYYY, h:mm a'),
              text: flow.text,
              sender: <span className="badge badge-outline-primary">{flow.sender}</span>,
              status: <Status type={flow.status} message={flow?.error_message} />,
              cost: flow?.cost || '---',
              tag: flow?.tag || '---',
            },
          };
        }),
      );
    }
  }, [dataContact]);

  return (
    <>
      <div className="nk-main">
        <div className="nk-wrap">
          <Header />

          <DataPreloader loading={loadingContact || !dataContactTable}>
            <div className="nk-content">
              <div className="container-fluid">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                      <div className="nk-block-between g-3">
                        <div className="nk-block-head-content" />
                        <div className="nk-block-head-content">
                          <Link
                            to={{ pathname: '/contacts' }}
                            className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                          >
                            <em className="icon ni ni-arrow-left"></em>
                            <span>Back</span>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="row contact-card">
                      <div className="col-md-3">
                        <ContactSidebar
                          onDeleteModalConfirm={onDeleteModalConfirm}
                          dataContact={dataContact?.contact}
                        />
                      </div>

                      <div className="col-md-9">
                        {!loadingContact && dataContactTable && (
                          <>
                            <div className="card">
                              <div className="card-inner">
                                <ul className="nav nav-tabs">
                                  {tabs.map(el => (
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link${activeTab === el.id ? ' active' : ''}`}
                                        data-toggle="tab"
                                        href="#s"
                                        onClick={e => onChangeTab(e, el.id)}
                                      >
                                        {el.icon}
                                        <span>{el.name}</span>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                                <div className="tab-content">
                                  {tabs.map(el => (
                                    <div className={`nav-pane${activeTab === el.id ? ' active' : ''}`}>{}</div>
                                  ))}

                                  {activeTab === 'contact_detail' && (
                                    // <Table
                                    //   columns={columnsContactTable}
                                    //   data={dataContactTable}
                                    //   isChecking={false}
                                    //   sort={false}
                                    // />
                                    <div>
                                      <div>
                                        <div className="nk-block">
                                          <div className="nk-block-head">
                                            <h5 className="title">Personal Information</h5>
                                          </div>

                                          <div className="profile-ud-list">
                                            <ContactProfileUdItem
                                              title="Name"
                                              info={dataContact?.contact?.first_name}
                                              onChangeFormContactField={onChangeFormContactField}
                                              fieldName="first_name"
                                            />
                                            <ContactProfileUdItem
                                              title="Last name"
                                              info={dataContact?.contact?.last_name}
                                              onChangeFormContactField={onChangeFormContactField}
                                              fieldName="last_name"
                                            />
                                            <ContactProfileUdItem
                                              title="Father name"
                                              info={dataContact?.contact?.father_name}
                                              onChangeFormContactField={onChangeFormContactField}
                                              fieldName="father_name"
                                            />
                                            <ContactProfileUdItem
                                              title="Phone"
                                              info={
                                                dataContact?.contact?.phone ? `+${dataContact?.contact?.phone}` : '---'
                                              }
                                              onChangeFormContactField={onChangeFormContactField}
                                              fieldName="phone"
                                            />
                                            <ContactProfileUdItem
                                              title="Email"
                                              info={dataContact?.contact?.email || '---'}
                                              onChangeFormContactField={onChangeFormContactField}
                                              fieldName="email"
                                            />
                                          </div>
                                        </div>

                                        <div className="nk-block">
                                          <div className="nk-block-head nk-block-head-line">
                                            <h6 className="title overline-title text-base">Additional Information</h6>
                                          </div>

                                          <div className="profile-ud-list">
                                            {Object.keys(dataContact?.contact?.extra_fields).map(key => (
                                              <ContactProfileUdItem
                                                title={String(dataContact?.contact?.extra_fields[key].field_title)}
                                                info={
                                                  String(dataContact?.contact?.extra_fields[key].field_value) || '---'
                                                }
                                                onChangeFormContactField={onChangeFormContactField}
                                                fieldName={`extra_fields.${String(
                                                  dataContact?.contact?.extra_fields[key].field_key,
                                                )}`}
                                              />
                                            ))}
                                          </div>
                                        </div>
                                      </div>

                                      {JSON.stringify(formContact) !== '{}' && (
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-manage"
                                          onClick={onUpdateContactInfo}
                                        >
                                          <DataPreloader
                                            loading={loadingUpdateContact}
                                            inline
                                            size="1rem"
                                            styles={{ marginRight: '4px' }}
                                          />
                                          Save
                                        </button>
                                      )}
                                    </div>
                                  )}

                                  {activeTab === 'flows' && (
                                    <Table
                                      columns={columnsFlowsTable}
                                      data={dataFlowsTable}
                                      isChecking={false}
                                      sort={false}
                                    />
                                  )}

                                  {activeTab === 'send_message' && (
                                    <form onSubmit={handleSubmitMessage(onSubmitMessage)}>
                                      {(loadingSendMessage || loadingDeleteContact) && <DataPreloader />}

                                      <div className="form-group mt-4">
                                        <label className="form-label">Date and time</label>
                                        <div className="form-control-input">
                                          <DatePicker
                                            selected={plannedAt}
                                            onChange={date => setPlannedAt(date)}
                                            showTimeSelect
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            timeIntervals={5}
                                            className="form-control"
                                            required
                                          />
                                        </div>
                                      </div>

                                      <div className="form-group mt-4">
                                        <label className="form-label">First name</label>
                                        <div className="form-control-input">
                                          <input
                                            type="text"
                                            required
                                            className="form-control"
                                            name="first_name"
                                            defaultValue={dataContact.contact.first_name}
                                            {...register('first_name')}
                                          />
                                        </div>
                                      </div>

                                      <div className="form-group mt-4">
                                        <label className="form-label">Last name</label>
                                        <div className="form-control-input">
                                          <input
                                            type="text"
                                            required
                                            className="form-control"
                                            name="last_name"
                                            defaultValue={dataContact.contact.last_name}
                                            {...register('last_name')}
                                          />
                                        </div>
                                      </div>

                                      <div className="form-group mt-4">
                                        <label className="form-label">Phone</label>
                                        <div className="form-control-input">
                                          <input
                                            type="number"
                                            required
                                            className="form-control"
                                            name="phone"
                                            defaultValue={dataContact.contact.phone}
                                            {...register('phone')}
                                          />
                                        </div>
                                      </div>

                                      <div className="form-group mt-4">
                                        <label className="form-label">SMS text</label>
                                        <div className="form-control-input">
                                          <textarea
                                            className="form-control"
                                            required
                                            name="sms_text"
                                            {...register('sms_text')}
                                          />
                                        </div>
                                      </div>

                                      <div className="text-right">
                                        <input type="submit" className="btn btn-primary" value="Submit" />
                                      </div>
                                    </form>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DataPreloader>
        </div>
      </div>

      {isDeleteModalConfirm && (
        <ModalConfirm>
          <div className="nk-modal-action mt-5">
            <button className="btn btn-lg btn-mw btn-light mr-1" onClick={onCancelDelete}>
              Cancel
            </button>
            <button className="btn btn-lg btn-mw btn-danger ml-1" onClick={onDeleteContact}>
              Delete
            </button>
          </div>
        </ModalConfirm>
      )}

      {isToastSuccess && <Toast text="Success" />}
    </>
  );
};
