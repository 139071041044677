import React, { useRef } from 'react';

import './PositionSelector.scss';

const PositionSelector = ({ onChangePosition, defaultValue }) => {
  const popupPositionRef = useRef([
    'topLeft',
    'topCenter',
    'topRight',
    'middleLeft',
    'middleCenter',
    'middleRight',
    'bottomLeft',
    'bottomCenter',
    'bottomRight',
  ]);

  return (
    <div className="position-selector">
      <div className="select">
        {popupPositionRef.current.map((itemPosition, key) => (
          <input
            key={key}
            type="radio"
            value={itemPosition}
            onChange={onChangePosition}
            checked={defaultValue === itemPosition}
          />
        ))}
      </div>
    </div>
  );
};

export default PositionSelector;
