import React, { useEffect, useRef, useState } from 'react';
import { useRequest } from 'estafette';
import { form } from 'libs/http/api/form';

const FormCode = ({ element }) => {
  const textareaRef = useRef(null);

  const { request: requestForms, data: dataForms } = useRequest({});

  const [formId, setFormId] = useState(null);

  const getFormScript = id => {
    return `<div id="tf-wrapper"><script type="text/javascript" src="https://api.ezflow.cc/utils/get_popup_script?form_id=${id}"></script></div>`;
  };

  const setInnerHTML = (elm, html) => {
    elm.innerHTML = html || '';
    Array.from(elm.querySelectorAll('script')).forEach(oldScript => {
      const newScript = document.createElement('script');
      Array.from(oldScript.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
      newScript.appendChild(document.createTextNode(oldScript.innerHTML));
      oldScript.parentNode.replaceChild(newScript, oldScript);
    });
  };

  // const onSaveCodeFrame = () => {
  //   element.setAttribute('data-code', encodeURIComponent(textareaRef.current.value));
  //   element.innerHTML = textareaRef.current?.value || '';
  //   setInnerHTML(element, textareaRef.current?.value || '');
  // };

  const onInsertForm = e => {
    // textareaRef.current.value = getFormScript(formId);
    const script = getFormScript(formId);
    element.setAttribute('data-code', encodeURIComponent(script));
    element.innerHTML = script || '';
    setInnerHTML(element, script || '');
  };

  useEffect(() => {
    if (element) {
      textareaRef.current.value = decodeURIComponent(element.getAttribute('data-code') || '');
    }
  }, [element]);

  useEffect(() => {
    requestForms(form.list.action({}));
  }, []);

  useEffect(() => {
    if (dataForms?.forms && dataForms?.forms[0]) {
      setFormId(dataForms.forms[0].uid);
    }
  }, [dataForms]);

  return (
    <div className="form-group mt-4">
      <textarea className="form-control form-control-sm" ref={textareaRef} style={{ display: 'none' }} />

      <div className="form-group mt-4">
        <label className="form-label">Select input type</label>

        <div className="form-control-wrap">
          <div className="form-control-select">
            <select className="form-control" onChange={e => setFormId(e.target.value)}>
              {dataForms?.forms &&
                dataForms.forms.map((option, optionIndex) => (
                  <option key={optionIndex} value={option.uid}>
                    {option.title}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>

      <div className="text-right">
        <button className="btn btn-primary" onClick={onInsertForm}>
          Add form
        </button>
      </div>
    </div>
  );
};

export default FormCode;
