import React, { useRef, useState } from 'react';
import { SettingsSidebar } from './SettingsSidebar';

import { Header, ChatArea, ChatEditor } from 'components/Chat';

import 'assets/styles/Chat.scss';
import './SettingsWebChat.scss';

export const SettingsWebChat = () => {
  const messagesAreaRef = useRef();

  const [messages, setMessages] = useState([
    {
      message_datetime: new Date(),
      message_object: {
        from: { is_bot: true, first_name: 'Bot' },
        text: 'Hi, I am a bot that allows you to test the functionality of the ezFlow service.',
        buttons: ['Tell a joke', 'Do the math'],
      },
    },
    {
      message_datetime: new Date(),
      message_object: {
        from: {},
        text: 'Hi!',
      },
    },
  ]);

  return (
    <div className="settings-web-chat row">
      <div className="col-md-6">
        <SettingsSidebar />
      </div>
      <div className="col-md-6">
        <div className="nk-chat chat-wrapper chat-wrapper-settings">
          <div className="nk-chat-body">
            <Header />

            <ChatArea
              userDialog={messages}
              onChooseButton={() => {}}
              messagesAreaRef={messagesAreaRef}
              isTypingMessage={false}
            />

            <ChatEditor onMessage={() => {}} />

            <div className="copyright text-center">
              Powered by{' '}
              <a href="https://ezflow.cc/" target="_blank" rel="noopener noreferrer">
                ezFlow
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
