import React, { useState, useEffect } from 'react';

const IsRequired = ({ element }) => {
  const [required, setRequired] = useState(element?.getAttribute('required'));

  const onChange = () => setRequired(prevRequired => !prevRequired);

  useEffect(() => {
    if (element) {
      element.setAttribute('required', required);
    }
  }, [required]);

  return (
    <div className="mt-4">
      <div className="custom-control custom-switch mt-1">
        <input
          type="checkbox"
          className="custom-control-input"
          id="formIsRequired"
          onChange={onChange}
          checked={required}
        />
        <label className="custom-control-label" for="formIsRequired">
          Field is required
        </label>
      </div>
    </div>
  );
};

export default IsRequired;
