import React, { useState, useEffect, useRef } from 'react';

const NameInput = ({ element }) => {
  const inputRef = useRef(null);

  const [name, setName] = useState(element?.getAttribute('name') || '');

  const onChange = ({ target }) => {
    const prevVal = target.value
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[-]+/g, '-')
      .replace(/[^\w-]+/g, '');
    setName(prevVal);
  };

  useEffect(() => {
    if (element) {
      element.setAttribute('name', name);
    }
  }, [name]);

  return (
    <div className="form-group mt-4">
      <label className="form-label">Name</label>
      <input type="text" required="" className="form-control" onChange={onChange} ref={inputRef} value={name} />
    </div>
  );
};

export default NameInput;
