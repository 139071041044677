import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useRequest } from 'estafette';
import { Helmet } from 'react-helmet';
import { popup } from 'libs/http/api/popup';
import { Header, EmptyData } from 'components';
import Table from 'components/TemplateBuilder/components/Table/Table';
import { DataPreloader } from 'components/DataPreloader/DataPreloader';
import { Modal } from 'components/Modal/Modal';
import { useGTM } from '../../hooks/useGTM';

import './../../assets/styles/PopUpList.scss';

const PopUpList = () => {
  const textareaCodeRef = useRef(null);
  const { sendEvent } = useGTM();

  const { request: requestPopups, data: dataPopups, loading: loadingPopups } = useRequest({ loading: true });
  const { request: requestDelete, loading: loadingDelete } = useRequest({});
  const { request: requestPatchActive, loading: loadingPatchActive } = useRequest({});
  const { request: requestStatistik, data: dataStatistik } = useRequest({});

  const [activePopup, setActivePopup] = useState(null);
  const [isCodeModal, setIsCodeModal] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const onToggleDropdown = uid => {
    if (uid) {
      setActiveDropdown(uid);
    } else {
      setActiveDropdown(null);
    }
  };

  const onSetActivePopup = uid => {
    if (uid) {
      setActivePopup(uid);
    } else {
      setActivePopup(null);
    }
  };

  const onToggleCodeModal = stat => setIsCodeModal(stat);

  const onDeletePopup = async uid => {
    await requestDelete(popup.delete.action(uid));
    requestPopups(popup.list.action({}));
  };

  const onActivePopup = async (e, uid) => {
    await requestPatchActive(popup.statusUpdate.action(uid, e.target.value === 'true' ? 0 : 1));
    requestPopups(popup.list.action({}));
  };

  useEffect(() => {
    if (isCodeModal) {
      textareaCodeRef.current.value = `<script type="text/javascript" src="https://api.ezflow.cc/utils/get_popup_script?popup_id=${activePopup}"></script>`;
      textareaCodeRef.current.select();
    }
  }, [isCodeModal, activePopup]);

  useEffect(() => {
    requestPopups(popup.list.action({}));
    requestStatistik(popup.getStatistik.action({}));
  }, []);

  return (
    <>
      <Helmet>
        <title>My popups</title>
      </Helmet>

      <div className="nk-main">
        <div className="nk-wrap">
          <Header />

          <DataPreloader loading={loadingPopups || loadingDelete || loadingPatchActive}>
            <div className="nk-content " style={{ marginTop: '70px' }}>
              <div className="container-fluid">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    {/* <PageTitle title={'Popups'}>
              <p>My popups</p>
            </PageTitle> */}
                    {dataPopups?.popups?.length === 0 ? (
                      <EmptyData
                        title="No growth tools yet"
                        description="You don't have any growth tools yet. You can create first one – right now"
                        backgroundImage="https://res.cloudinary.com/fosh/image/upload/v1641740563/empty_01_qd6wh9.png"
                      >
                        <Link
                          onClick={() => sendEvent('create_popup')}
                          to={{ pathname: '/popup/templates' }}
                          className="btn btn-primary mb-3"
                        >
                          Create popup
                        </Link>
                      </EmptyData>
                    ) : (
                      <div>
                        <div className="text-right">
                          <Link
                            onClick={() => sendEvent('create_popup')}
                            to={{ pathname: '/popup/analytics' }}
                            className="btn btn-primary mr-25"
                          >
                            <em className="icon ni ni-growth mr-1" style={{ marginBottom: '2px' }} />
                            View all analytics
                          </Link>

                          <Link to={{ pathname: '/popup/templates' }} className="btn btn-success">
                            <em className="icon ni ni-plus mr-1" />
                            Create popup
                          </Link>
                        </div>

                        <div className="mt-25" />

                        <div className="card mt-4">
                          <div className="card-inner">
                            <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                              <div className="datatable-wrap my-3" style={{ overflow: 'visible' }}>
                                <Table className="datatable-init nk-tb-list nk-tb-ulist dataTable no-footer table table-tranx popup-stat-table popup-list-table">
                                  <Table.Tr className="tb-tnx-item nk-tb-item">
                                    <Table.Th className="tb-tnx-id">Title</Table.Th>

                                    <Table.Th className="tb-tnx-info text-center td-stat stat-center">Views</Table.Th>

                                    <Table.Th className="tb-tnx-info text-center td-stat stat-center">
                                      Conversions
                                    </Table.Th>

                                    <Table.Th className="tb-tnx-info text-center stat-center">Conversion Rate</Table.Th>

                                    <Table.Th className="tb-tnx-info text-center td-stat stat-center">Closes</Table.Th>

                                    <Table.Th className="tb-tnx-info text-center td-stat stat-center-right" />

                                    <Table.Th className="tb-tnx-item stat-right">Status</Table.Th>

                                    <Table.Th className="tb-tnx-action stat-right">Action</Table.Th>
                                  </Table.Tr>

                                  {dataPopups?.popups &&
                                    dataPopups.popups.map(popup => (
                                      <Table.Tr>
                                        <Table.Td className="tb-tnx-id">
                                          <Link
                                            to={{
                                              pathname: popup.config?.info
                                                ? `/template-builder/${popup.uid}`
                                                : `/popup/builder/${popup.uid}`,
                                            }}
                                          >
                                            <span>{popup.title}</span>
                                          </Link>
                                        </Table.Td>

                                        <Table.Td className="text-center td-stat stat-center">
                                          {dataStatistik.popups?.find(item => item.uid === popup.uid)?.data
                                            ?.popup_impression
                                            ? dataStatistik.popups?.find(item => item.uid === popup.uid).data
                                                .popup_impression
                                            : '-'}
                                        </Table.Td>

                                        <Table.Td className="text-center td-stat stat-center">
                                          {dataStatistik.popups?.find(item => item.uid === popup.uid)?.data
                                            ?.popup_conversion
                                            ? dataStatistik.popups?.find(item => item.uid === popup.uid).data
                                                .popup_conversion
                                            : '-'}
                                        </Table.Td>

                                        <Table.Td className="text-center td-stat stat-center">
                                          {dataStatistik.popups?.find(item => item.uid === popup.uid)?.data
                                            ?.popup_conversion
                                            ? `${(
                                                (dataStatistik.popups?.find(item => item.uid === popup.uid).data
                                                  .popup_conversion /
                                                  dataStatistik.popups.find(item => item.uid === popup.uid).data
                                                    .popup_impression) *
                                                100
                                              ).toFixed(1)}%`
                                            : '-'}
                                        </Table.Td>

                                        <Table.Td className="text-center td-stat stat-center">
                                          {dataStatistik.popups?.find(item => item.uid === popup.uid)?.data?.popup_close
                                            ? dataStatistik.popups?.find(item => item.uid === popup.uid).data
                                                .popup_close
                                            : '-'}
                                        </Table.Td>

                                        <Table.Td className="text-center td-stat stat-center stat-center-right" />

                                        <Table.Td className="tb-tnx-amount tb-tnx-item stat-right">
                                          <div className="custom-control custom-switch custom-switch-wlabel mt-1">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              checked={popup.active}
                                              value={popup.active}
                                              onChange={e => onActivePopup(e, popup.uid)}
                                              id={popup.uid}
                                            />

                                            <label className="custom-control-label" for={popup.uid} />
                                          </div>
                                        </Table.Td>

                                        <Table.Td className="tb-tnx-amount tb-tnx-action stat-right">
                                          <div className="dropdown">
                                            <span
                                              className="button text-soft dropdown-toggle btn btn-icon btn-trigger"
                                              onClick={() => onToggleDropdown(popup.uid)}
                                            >
                                              <em className="icon ni ni-more-h"></em>
                                            </span>

                                            {activeDropdown === popup.uid && (
                                              <>
                                                <div
                                                  className="dropdown-menu-close"
                                                  onClick={() => {
                                                    onToggleDropdown(null);
                                                  }}
                                                />
                                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs show">
                                                  <ul className="link-list-plain">
                                                    <li>
                                                      <span
                                                        className="button"
                                                        onClick={() => {
                                                          onToggleCodeModal(true);
                                                          onToggleDropdown(null);
                                                          onSetActivePopup(popup.uid);
                                                        }}
                                                      >
                                                        View code
                                                      </span>
                                                    </li>
                                                    <li>
                                                      <Link to={{ pathname: `/popup/analytics/${popup.uid}` }}>
                                                        View analytics
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      <Link
                                                        className="button"
                                                        to={{
                                                          pathname: popup.config?.info
                                                            ? `/template-builder/${popup.uid}`
                                                            : `/popup/builder/${popup.uid}`,
                                                        }}
                                                      >
                                                        <span>Edit</span>
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      <span className="button" onClick={() => onDeletePopup(popup.uid)}>
                                                        Delete
                                                      </span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </Table.Td>
                                      </Table.Tr>
                                    ))}
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </DataPreloader>

          {isCodeModal && (
            <Modal title="Popup import code" onClose={() => onToggleCodeModal(false)}>
              <>
                <p>Paste this code before the closing tag of the body</p>
                <p>
                  <textarea className="form-control form-control-sm" ref={textareaCodeRef} />
                </p>
              </>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default PopUpList;
