import React from 'react';

import promoImg from 'assets/images/login-ez.png';

import './Login.scss';

export const Login = ({ children }) => (
  <div className="nk-main">
    <div className="nk-wrap nk-wrap-nosidebar">
      <div className="nk-content ">
        <div className="nk-split nk-split-page nk-split-md">
          <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white w-lg-45">
            <div className="absolute-top-right d-lg-none p-3 p-sm-5">
              <a href="#s" className="toggle btn btn-white btn-icon btn-light" data-target="athPromo">
                <em className="icon ni ni-info"></em>
              </a>
            </div>
            <div className="nk-block nk-block-middle nk-auth-body">{children}</div>
            <div className="nk-block nk-auth-footer">
              <div className="nk-block-between">
                <ul className="nav nav-sm">
                  <li className="nav-item">
                    <a className="nav-link" href="#s">
                      Terms &amp; Condition
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#s">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-3">
                <p>© 2021 Ezflow. All Rights Reserved.</p>
              </div>
            </div>
          </div>

          <div
            className="nk-split-content nk-split-stretch bg-lighter promo-bg d-flex toggle-break-lg toggle-slide toggle-slide-right"
            data-content="athPromo"
            data-toggle-screen="lg"
            data-toggle-overlay="true"
          >
            <div className="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
              <img className="promo-img" src={promoImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
