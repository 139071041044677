import React, { useEffect, useRef } from 'react';

const FrameCode = ({ element }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (element) {
      textareaRef.current.value = decodeURIComponent(element.getAttribute('data-code') || '');
    }
  }, [element]);

  const setInnerHTML = (elm, html) => {
    elm.innerHTML = html || '';
    Array.from(elm.querySelectorAll('script')).forEach(oldScript => {
      const newScript = document.createElement('script');
      Array.from(oldScript.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
      newScript.appendChild(document.createTextNode(oldScript.innerHTML));
      oldScript.parentNode.replaceChild(newScript, oldScript);
    });
  };

  const onSaveCodeFrame = () => {
    element.setAttribute('data-code', encodeURIComponent(textareaRef.current.value));
    element.innerHTML = textareaRef.current?.value || '';
    setInnerHTML(element, textareaRef.current?.value || '');
  };

  return (
    <div className="form-group mt-4">
      <label className="form-label">Paste code into textarea</label>
      <textarea className="form-control form-control-sm" ref={textareaRef} />
      <br />
      <button className="btn btn-primary" type="button" onClick={onSaveCodeFrame}>
        Paste code
      </button>
    </div>
  );
};

export default FrameCode;
