import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from 'estafette';
import { useDispatch } from 'react-redux';
import { setUserInfo } from 'features/userSlice';
import { user } from 'libs/http/api/user';
import { DataPreloader, Header } from 'components';
import { BillingPlan } from './BillingPlan';
import { TariffPlans } from './TariffPlans';
import { BillingSuccess } from './BillingSuccess';
import { BillingError } from './BillingError';

import './Billing.scss';

export const Billing = () => {
  const { tab } = useParams();

  const dispatch = useDispatch();

  const { request: requestUserInfo, loading: loadingUserInfo, data: dataUserInfo } = useRequest({ loading: true });

  const getUserInfo = async () => {
    const data = await requestUserInfo(user.getInfo.action());
    dispatch(setUserInfo(data));
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="nk-main">
      <div className="nk-wrap">
        <Header />

        <DataPreloader loading={loadingUserInfo}>
          <div className="nk-content">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body billing-page">
                  {!tab && <BillingPlan dataUserInfo={dataUserInfo} />}

                  {tab === 'tariff-plans' && <TariffPlans dataUserInfo={dataUserInfo} />}

                  {tab === 'success' && <BillingSuccess />}

                  {tab === 'error' && <BillingError />}
                </div>
              </div>
            </div>
          </div>
        </DataPreloader>
      </div>
    </div>
  );
};
