import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import store from 'store';
import { Provider } from 'react-redux';
import PrivateRoute from 'utils/PrivateRoute';
import { AuthProvider } from 'utils/Auth';
import {
  SignIn,
  SignUp,
  FlowBuilder,
  FlowAreaShare,
  FlowList,
  Contacts,
  Contact,
  Chat,
  Sms,
  Settings,
  Billing,
  PopUpList,
  PopUpStatistik,
  FormStatistik,
  FlowAnalytics,
  TemplateBuilder,
  TemplatesList,
  FormBuilder,
  FormList,
  FormTemplatesList,
} from 'pages/Dashboard/';

import 'index.css';
import 'assets/dashlite/css/dashlite.css';
import 'assets/dashlite/css/theme.css';
import 'assets/dashlite/css/skins/theme-blue.css';
import 'assets/styles/main.scss';

function App() {
  useEffect(() => {
    // GTM events for all buttons
    document.addEventListener('click', e => {
      if (!e.target.tagName) return;

      const trackTags = ['A', 'BUTTON'];
      const elTag = e.target.tagName;
      const parentElTag = e.target?.parentNode?.tagName;

      if (trackTags.includes(elTag) || trackTags.includes(parentElTag)) {
        const name = e.target.innerText;
        name && window.dataLayer.push({ event: 'button_click', button_name: name });
      }
    });
  }, []);

  return (
    <>
      <Provider store={store}>
        <AuthProvider>
          <Router>
            <Switch>
              {/* <PrivateRoute exact path="/" component={Dashboard} /> */}

              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/share/:id?" component={FlowAreaShare} />
              <PrivateRoute exact path="/" component={Contacts} />
              <PrivateRoute exact path="/flows" component={FlowList} />
              <PrivateRoute exact path="/flow/analytics/:id?" component={FlowAnalytics} />
              <PrivateRoute exact path="/flow-builder/:id?" component={FlowBuilder} />
              <PrivateRoute exact path="/contacts/:page?" component={Contacts} />
              <PrivateRoute exact path="/contact/:client_id/:action?" component={Contact} />
              <PrivateRoute exact path="/messages/:page?" component={Sms} />
              <PrivateRoute exact path="/settings/:tab?/:subtab?" component={Settings} />
              <PrivateRoute exact path="/billing/:tab?/:subtab?" component={Billing} />

              <PrivateRoute exact path="/popup/list" component={PopUpList} />
              <PrivateRoute exact path="/popup/analytics/:id?" component={PopUpStatistik} />
              <PrivateRoute exact path="/popup/templates" component={TemplatesList} />
              <PrivateRoute exact path="/template-builder/:id?" component={TemplateBuilder} />

              <PrivateRoute exact path="/form/list" component={FormList} />
              <PrivateRoute exact path="/form/analytics/:id?" component={FormStatistik} />
              <PrivateRoute exact path="/form/templates" component={FormTemplatesList} />
              <PrivateRoute exact path="/form-builder/:id?" component={FormBuilder} />

              <Route exact path="/chat/:chatToken?" component={Chat} />

              <Redirect to="/signin" />
            </Switch>
          </Router>
        </AuthProvider>

        <div id="modal-root" />
        <div id="portal-root" />
      </Provider>
    </>
  );
}

export default App;
