import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import MediumEditor from 'medium-editor';
import * as htmlToImage from 'html-to-image';
import { useRequest } from 'estafette';
import { useParams } from 'react-router-dom';
import { form } from 'libs/http/api/form';
import { DataPreloader } from 'components/DataPreloader/DataPreloader';
import { Header, Toast } from 'components';
import FormBuilder from './form-builder';
import Sidebar from './components/Sidebar/Sidebar';
import { initConfig } from './config';

import './FormBuilder.scss';
import './../../../node_modules/medium-editor/src/sass/medium-editor.scss';
import './../../../node_modules/medium-editor/src/sass/themes/default.scss';

import InputImg from './../../assets/images/input.png';
import ButtonImg from './../../assets/images/button.png';

let mediumEditor = new MediumEditor('#tuls-form [data-tuls-type="title"], #tuls-form [data-tuls-type="text"]', {
  toolbar: {
    buttons: ['bold', 'italic', 'underline', 'anchor'],
  },
});

const Builder = () => {
  const { template } = useSelector(state => state);

  const { id: idForm } = useParams();

  const { request: requestUploadImage } = useRequest({});
  const { request: requestCreateTemplate } = useRequest({});
  const { request: requestCreateForm, loading: loadingCreateForm } = useRequest({});
  const {
    request: requestCurrentTemplate,
    loading: loadingCurrentTemplate,
    data: dataCurrentTemplate,
  } = useRequest({});

  const tulsBuilderRef = useRef(null);
  const [config, setConfig] = useState(initConfig);
  const [activeElement, setActiveElement] = useState(null);
  const [activeElementType, setActiveElementType] = useState(null);
  const [dragElement, setDragElement] = useState(null);
  const [isDragElement, setIsDragElement] = useState(false);
  const [dropElement, setDropElement] = useState(null);
  const [isCropTemplate, setIsCropTemplate] = useState(false);
  const [isCropGrid, setIsCropGrid] = useState(false);
  const [activeGrid, setActiveGrid] = useState(null);
  const [formSizes, setFormSizes] = useState({ h: 400, w: 300 });
  const [isLoading, setIsLoading] = useState(false);
  const [isNoContactFields, setIsNoContactFields] = useState(false);

  const insertAfter = (newNode, referenceNode) => {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  };

  const insertBefore = (newNode, referenceNode) => {
    referenceNode.parentNode.insertBefore(newNode, referenceNode);
  };

  const onDragstartHandler = e => {
    setIsDragElement(true);
    mediumEditor.destroy();
    setDragElement(e.target);
    e.dataTransfer.effectAllowed = 'move';
  };

  const onDragstartHandlerComponent = e => {
    const img = new Image();
    img.src = e.target.getAttribute('data-tuls-type') === 'button' ? ButtonImg : InputImg;
    e.dataTransfer.setDragImage(img, 10, 10);

    setIsDragElement(true);
    mediumEditor.destroy();
    setDragElement(e.target);
    e.dataTransfer.effectAllowed = 'move';
  };

  const onDragEnterHandler = e => {
    setDropElement(e.target);

    const img = new Image();
    img.src = e.target.getAttribute('data-tuls-type') === 'button' ? ButtonImg : InputImg;
    e.dataTransfer.setDragImage(img, 10, 10);
  };

  const onMouseEnterGridCol = e => {
    setActiveGrid(e.target);
  };

  const onDropHandler = e => {
    e.stopPropagation();
    e.preventDefault();

    const type = dragElement.getAttribute('data-tuls-type');

    const newNode = document.importNode(dragElement, true);
    newNode.draggable = true;
    newNode.ondragstart = onDragstartHandler;
    newNode.onclick = onFocusElement;

    if (dropElement.closest('.tuls-column-empty')) {
      dropElement.closest('.tuls-column-empty').classList.remove('tuls-column-empty');
    }

    if (type === null) {
      if (dropElement.closest('.tuls-line')) {
        const lineHeight = dropElement.closest('.tuls-line').offsetHeight;
        const lineTop = dropElement.closest('.tuls-line').getBoundingClientRect().top;
        const middleLine = lineTop + lineHeight - lineHeight / 2 + window.scrollY;
        if (e.pageY < middleLine) {
          insertBefore(newNode, dropElement.closest('.tuls-line'));
        } else {
          insertAfter(newNode, dropElement.closest('.tuls-line'));
        }
      } else {
        dropElement.appendChild(newNode);
      }
    } else {
      if (type !== 'backgroundImage' && type !== 'codeFrame' && type !== 'formFrame') {
        if (type !== 'button') {
          newNode.contentEditable = 'true';
        } else {
          newNode.querySelector('span').contentEditable = 'true';
        }
      }
      let column;
      if (dropElement.classList.contains('tuls-column')) {
        column = dropElement;
      } else if (dropElement.closest('.tuls-column')) {
        column = dropElement.closest('.tuls-column');
      }
      if (column) {
        if (dropElement.classList.contains('tuls-component')) {
          const elementHeight = dropElement.offsetHeight;
          const elementTop = dropElement.getBoundingClientRect().top;
          const middleElement = elementTop + elementHeight - elementHeight / 2 + window.scrollY;
          if (e.pageY < middleElement) {
            insertBefore(newNode, dropElement);
          } else {
            insertAfter(newNode, dropElement);
          }
        } else {
          column.appendChild(newNode);
        }
      }
    }

    if (dragElement.closest('#tuls-form')) {
      dragElement.remove();
    }

    setIsDragElement(false);
    mediumEditor.setup();
  };

  const onDragoverHandler = e => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const uploadImages = async () => {
    await Promise.all(
      Array.from(document.body.querySelectorAll('#tuls-form, .tuls-line, .tuls-column, .tuls-form-img')).map(el => {
        console.log(el);
        if (el.style?.backgroundImage && el.style.backgroundImage.includes('blob:')) {
          return new Promise((resolve, reject2) => {
            const dataImageSrc = el.style.backgroundImage.match(/url\("(.*)"\)/)[1];
            const screenImg = document.createElement('img');
            screenImg.src = dataImageSrc;
            document.body.appendChild(screenImg);

            setTimeout(async () => {
              const dataImage = await onCapture(screenImg);
              const fileData = new FormData();
              fileData.append('image', dataImage);
              screenImg.remove();

              const resFileUpload = await requestUploadImage(form.uploadImage.action(fileData));
              el.style.backgroundImage = `url(${resFileUpload.image})`;

              resolve();
            }, 200);
          });
        }
      }),
    );

    return true;
  };

  const onIsNoContactFields = () => setIsNoContactFields(false);

  const onSave = async ({ type = 'template', name, description }) => {
    if (document.querySelectorAll('#tuls-form input[name="email"], #tuls-form input[name="phone"]').length === 0) {
      setIsNoContactFields(true);
      return false;
    }

    setIsLoading(true);
    await uploadImages();

    mediumEditor.destroy();
    const templateBuilder = new FormBuilder(idForm || null, initConfig, document.querySelector('#tuls-form'), true);

    const newConfig = templateBuilder.createConfig();

    let cf = {
      title: name,
      description,
      config: newConfig,
    };
    cf.config.info = {
      ...cf.info,
      title: name,
      description,
      height: formSizes.h,
      width: formSizes.w,
      style: templateBuilder.stringStyleToObject(document.querySelector('#tuls-form').getAttribute('style')),
    };
    console.log('cf', cf);

    let resCreate;
    if (type === 'template') {
      const dataImage = await onCapture(document.querySelector('#tuls-form'));
      const fileData = new FormData();
      fileData.append('image', dataImage);
      const resFileUpload = await requestUploadImage(form.uploadImage.action(fileData));
      cf.config.info.preview = resFileUpload.image;
      cf.thumbnail = resFileUpload.image;
      resCreate = await requestCreateTemplate(form.createTemplate.action(cf));
    } else if (type === 'form') {
      if (idForm) {
        cf.id = idForm;
        cf.active = 1;
        resCreate = await requestCreateForm(form.update.action(cf));
      } else {
        resCreate = await requestCreateForm(form.post.action(cf));
      }
    }

    console.log(resCreate);

    setIsLoading(false);

    window.location.href = '/form/list';
  };

  const rerenderTulsComponents = () => {
    document
      .getElementById('tuls-form')
      .querySelectorAll('.tuls-component')
      .forEach(element => {
        element.ondragstart = onDragstartHandler;
        element.onclick = onFocusElement;
      });
    document
      .getElementById('tuls-form')
      .querySelectorAll('.tuls-line')
      .forEach(element => {
        element.ondragstart = onDragstartHandler;
        element.draggable = 'true';
        element.onclick = onFocusElement;
      });
    mediumEditor.setup();
  };

  const onFocusElement = e => {
    setActiveElement(null);

    if (typeof e.persist === 'function') {
      e.persist();
    }
    e.stopPropagation();

    const target = e.target;
    setTimeout(() => {
      if (!target.classList.contains('tuls-inner')) {
        let reEl = target;
        if (!target.getAttribute('data-tuls-type') && !target.classList.contains('tuls-column')) {
          reEl = target.closest('[data-tuls-type]');
        }
        setActiveElement(reEl);
      } else {
        setActiveElement(document.querySelector('#tuls-form'));
      }
    });
  };

  const onCloseSettings = () => setActiveElement(null);

  const onRemoveElement = e => {
    e.preventDefault();
    setActiveElementType(null);
    setActiveElement(null);
    if (activeElement.classList.contains('tuls-column')) {
      activeElement.closest('.tuls-line').remove();
    } else {
      activeElement.remove();
    }
  };

  const onMouseDownCropTemplate = () => setIsCropTemplate(true);

  const onCapture = async element => {
    const form = element;
    form.classList.add('capture');
    const dataImage = await htmlToImage.toBlob(element).then(function (dataUrl) {
      form.classList.remove('capture');
      return dataUrl;
    });
    return dataImage;
  };

  const loadTemplate = id => {
    requestCurrentTemplate(form.getUserCurrentTemplate.action(id));
  };

  const templateRender = newConfig => {
    const templateBuilder = new FormBuilder(idForm || null, newConfig, document.querySelector('#tuls-form'), true);
    templateBuilder.init({ editable: true });

    rerenderTulsComponents();
  };

  useEffect(() => {
    templateRender(initConfig);
  }, [tulsBuilderRef]);

  useEffect(() => {
    console.log(activeElementType);
  }, [activeElementType]);

  useEffect(() => {
    if (activeElement) {
      if (activeElement?.getAttribute('data-tuls-type')) {
        setActiveElementType(activeElement?.getAttribute('data-tuls-type'));
      } else if (activeElement?.closest('.tuls-component')?.getAttribute('data-tuls-type')) {
        setActiveElementType(activeElement?.closest('.tuls-component')?.getAttribute('data-tuls-type'));
      } else if (activeElement?.closest('.tuls-line')) {
        setActiveElementType('line');
      }
    } else {
      if (activeElement?.getAttribute('data-tuls-type') === 'form') {
        setActiveElementType('form');
      } else {
        setActiveElementType(null);
      }
    }
  }, [activeElement]);

  useEffect(() => {
    document.onmousemove = e => {
      if (isCropTemplate) {
        const difX =
          e.pageX - (tulsBuilderRef.current.getBoundingClientRect().left + tulsBuilderRef.current.offsetWidth);
        tulsBuilderRef.current.style.width = `${tulsBuilderRef.current.offsetWidth + difX}px`;
        const difY =
          e.pageY -
          (tulsBuilderRef.current.getBoundingClientRect().top + tulsBuilderRef.current.offsetHeight + window.scrollY);
        tulsBuilderRef.current.style.height = `${tulsBuilderRef.current.offsetHeight + difY}px`;

        setFormSizes({
          h: tulsBuilderRef.current.offsetHeight,
          w: tulsBuilderRef.current.offsetWidth,
        });
      }
    };
  }, [isCropTemplate, isCropGrid, activeGrid]);

  useEffect(() => {
    document.onmouseup = () => {
      setIsCropTemplate(false);
    };
  }, []);

  useEffect(() => {
    templateRender(config);
  }, [config]);

  useEffect(() => {
    if (dataCurrentTemplate?.form_template?.config || dataCurrentTemplate?.form?.config) {
      setConfig(dataCurrentTemplate?.form_template?.config || dataCurrentTemplate?.form?.config);
      if (dataCurrentTemplate?.form?.config?.info) {
        setFormSizes({
          h: dataCurrentTemplate.form.config.info?.height,
          w: dataCurrentTemplate.form.config.info?.width,
        });
      }
    }
  }, [dataCurrentTemplate]);

  useEffect(() => {
    if (idForm) {
      const getForm = async () => {
        if (idForm) {
          requestCurrentTemplate(form.get.action(idForm));
        }
      };
      getForm();
    }
  }, [idForm]);

  useEffect(() => {
    if (template.data?.uid) {
      setConfig(template.data.config);
    }
  }, [template]);

  return (
    <>
      <div className="nk-main">
        <div className="nk-wrap">
          <Header />
          <Helmet>
            <title>Template Builder</title>
          </Helmet>

          <DataPreloader loading={loadingCurrentTemplate}>
            <div className="nk-content">
              <div className="container-fluid">
                <div className="nk-content-inner">
                  <div className="container builder-container">
                    <DataPreloader loading={loadingCurrentTemplate}>
                      <Sidebar
                        onDragstartHandler={onDragstartHandler}
                        onFocusElement={onFocusElement}
                        onDragstartHandlerComponent={onDragstartHandlerComponent}
                        onSave={onSave}
                        activeElement={activeElement}
                        activeElementType={activeElementType}
                        onCloseSettings={onCloseSettings}
                        onRemoveElement={onRemoveElement}
                        loadTemplate={loadTemplate}
                        formTemplate={dataCurrentTemplate}
                        loading={loadingCreateForm || isLoading}
                      />
                    </DataPreloader>

                    <div className="nk-wrap">
                      <DataPreloader loading={loadingCurrentTemplate}>
                        <div className="nk-content">
                          <div className="container-fluid" style={{ width: '100%' }}>
                            <div className="nk-content-inner" style={{ width: '100%' }}>
                              <div
                                id="tuls-form"
                                ref={tulsBuilderRef}
                                className="tuls-builder tuls-form dropzone"
                                style={{
                                  maxHeight: `400px`,
                                  width: `800px`,
                                }}
                                data-tuls-type="form"
                                onClick={onFocusElement}
                              >
                                <form
                                  className="tuls-inner"
                                  onMouseEnter={onMouseEnterGridCol}
                                  onDrop={onDropHandler}
                                  onDragOver={onDragoverHandler}
                                  onDragEnter={onDragEnterHandler}
                                  onSubmit={e => e.preventDefault()}
                                ></form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </DataPreloader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DataPreloader>
        </div>
      </div>

      {isNoContactFields && (
        <Toast
          title="Error"
          text="You need to create at least one field width name 'email' or 'phone'"
          onClose={onIsNoContactFields}
        />
      )}
    </>
  );
};

export default Builder;
