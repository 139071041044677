import React, { useState } from 'react';

export const ContactProfileUdItem = ({ title, info, fieldName, onChangeFormContactField }) => {
  const [isEditField, setIsEditField] = useState(false);

  const onShowEditField = () => {
    setIsEditField(true);
  };

  const onChangeField = e => onChangeFormContactField({ fieldName, value: e.target.value });

  return (
    <div className="profile-ud-item">
      <div className="profile-ud wider">
        <span className="profile-ud-label">{title}</span>
        <span className="profile-ud-value" onClick={onShowEditField}>
          {isEditField ? (
            <div className="form-control-input">
              <input type="text" className="form-control tuls-component" defaultValue={info} onChange={onChangeField} />
            </div>
          ) : (
            <>{info}</>
          )}
        </span>
      </div>
    </div>
  );
};
