import axios from 'axios';

export const host = 'https://api.ezflow.cc';
let url = `${host}/`;
if (process.env.NODE_ENV !== 'development') {
  url = `${host}/`;
}

const axiosConfig = axios.create({
  baseURL: url,
  responseType: 'json',
  headers: {
    Accepted: 'appication/json',
    'Content-Type': 'application/json',
  },
});

axiosConfig.interceptors.request.use(
  config => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    console.log('axiosConfig.interceptors.request.use', error);
  },
);

axiosConfig.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.data.detail === 'incorrect_username_or_password') {
      return error;
    }
    if (401 === error.response.status) {
      console.log('AUTH ERROR');
      window.location.replace('/signin');
    }
    if (error.response.status !== 200) {
      return { errors: error.response.data };
    }
  },
);

export const axiosUtilsConfig = axios.create({
  baseURL: host,
  responseType: 'json',
  headers: {
    Accepted: 'appication/json',
    'Content-Type': 'application/json',
  },
});

axiosUtilsConfig.interceptors.request.use(
  config => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    console.log('axiosUtilsConfig.interceptors.request.use', error);
  },
);

export const logout = () => {
  localStorage.setItem('access_token', '');
  localStorage.setItem('user_email', '');
  localStorage.setItem('api_token', '');
  window.location.replace('/signin');
};

export default axiosConfig;
