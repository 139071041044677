import React, { useState, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';

export default data => {
  const [label, setLabel] = useState(data.data?.label);

  useEffect(() => {
    if (data.data.type === 'sms' && data.data?.form?.name) {
      setLabel(data.data.form.name);
      console.log('NODE ', data.data?.form)
    }

    if (data.data?.label) {
      setLabel(data.data.label);
    }
  }, [data]);

  return (
    <div className={`flow-button-node ${data.data.className}`} data-type={data.data.type}>
      {data.data?.form?.next_sms_offset && data.data.form.next_sms_offset > 0 && (
        <span className="badge badge-pill badge-outline-primary">{data.data.form.next_sms_offset}</span>
      )}

      {data.data?.form?.offset && Number(data.data.form.offset) > 0 && (
        <span className="badge badge-pill badge-outline-primary">{data.data.form.offset}</span>
      )}

      {data.data.isHandleTop && data.data.type !== 'default-fallback' && (
        <Handle type="target" position="top" isConnectable={false} style={{ marginTop: 30, marginBottom: 5 }} />
      )}

      {data.data.type === 'default-fallback' && (
        <Handle type="target" position="top" isConnectable={false} style={{ marginTop: 5, marginBottom: 5 }} />
      )}

      {/* <div className="inner">{data.data.text}</div> */}

      <div className={`inner`} data-label={data.data?.form?.button_text || label || data.data.text}>
        {(!data.data.type || data.id === 'node-1') && <i className="fab fa-wpforms" />}

        {data.data.type === 'sms' && <i className="fas fa-sms" />}

        {data.data.type === 'function' && <i className="fas fa-code-branch" />}

        {data.data.type === 'callFunction' && <i className="fas fa-random" />}

        {data.data.type === 'variable' && <i className="fas fa-archive" />}

        {data.data.type === 'create-else' && <i className="fas fa-plus" />}

        {data.data.type === 'response' && <i className="fas fa-reply" />}

        {data.data.type === 'responseButton' && <i className="fas fa-caret-square-down" />}

        {data.data.type === 'conversation-end' && <i className="fas fa-comment-slash" />}

        {data.data.type === 'jump-node' && <em className="icon ni ni-curve-left-up" />}

        {data.data.type === 'jump-flow-node' && (
          <i className="fas fa-retweet" style={{ fontSize: '15px', marginTop: '3px' }} />
        )}

        {data.data.type === 'http-request' && <i className="icon fas fa-project-diagram" />}

        {data.data.type === 'default-fallback' && <i className="icon fas fa-comment-dots" />}
      </div>

      <div className="label" data-label={data.data?.form?.button_text || label || data.data.text}>
        {data.data?.form?.button_text || label || data.data.text}
      </div>

      {data.data?.totalSendedMessagesCount > 0 && (
        <span className="label-stat" style={{ backgroundColor: data.id === 'node-1' ? '#37cb95' : '#eea620' }}>
          {data.id === 'node-1' ? (
            '100%'
          ) : (
            <>{((data.data.sendedMessagesCount / data.data.totalSendedMessagesCount) * 100).toFixed(2)}%</>
          )}{' '}
          <strong>{data.data?.sendedMessagesCount || data.data.totalSendedMessagesCount}</strong>
        </span>
      )}

      {data.data.isHandleBottom &&
        data.data.type !== 'conversation-end' &&
        data.data.type !== 'jump-node' &&
        data.data.type !== 'jump-flow-node' && (
          <Handle type="source" position="bottom" isConnectable={false} style={{ marginBottom: -5, marginTop: 5 }} />
        )}

      {data.data.type === 'jump-node' && (
        <Handle
          type="source"
          position="right"
          isConnectable={false}
          style={{ margin: 'auto', left: 0, right: 0, zIndex: -1 }}
        />
      )}
    </div>
  );
};
