import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { logout } from 'utils/api';

import logo from 'assets/images/logo_main.svg';

import './Header.scss';

export const Header = ({ onExportJson, onSaveFlow }) => {
  const [isUserDropdown, setIsUserDropdown] = useState(false);
  const [isMenu, setIsMenu] = useState(false);

  const onUserDropdown = e => {
    e.preventDefault();
    setIsUserDropdown(prevState => !prevState);
  };

  const onLogout = e => {
    e.preventDefault();
    logout();
  };

  const onToggleMenu = () => setIsMenu(prevIsMenu => !prevIsMenu);

  return (
    <div className="nk-header nk-header-fixed is-light">
      <div className="container-fluid">
        <div className="nk-header-wrap">
          {/* <div className="nk-menu-trigger d-xl-none ml-n1">
            <a href="#s" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu">
              <em className="icon ni ni-menu"></em>
            </a>
          </div> */}
          <div className="nk-header-app-name">
            <div className="nk-header-app-logo">
              <Link to={{ pathname: `/` }}>
                <img src={logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="nk-header-menu is-light" style={{ transform: `translateX(${isMenu ? '0' : '-280px'})` }}>
            <div className="nk-header-menu-inner">
              <a
                href="#s"
                className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
                data-target="sidebarMenu"
                onClick={onToggleMenu}
                style={{ transform: `translateX(${isMenu ? '-280px' : '0'})` }}
              >
                <em className="icon ni ni-menu" />
              </a>
              <ul className="nk-menu nk-menu-main">
                <li className="nk-menu-item">
                  <Link to={{ pathname: '/contacts' }} className="nk-menu-link">
                    <span className="nk-menu-text">Contacts</span>
                  </Link>
                </li>
                <li className="nk-menu-item">
                  <Link to={{ pathname: '/messages' }} className="nk-menu-link">
                    <span className="nk-menu-text">SMS</span>
                  </Link>
                </li>
                <li className="nk-menu-item">
                  <Link to={{ pathname: '/flows' }} className="nk-menu-link">
                    <span className="nk-menu-text">Flows</span>
                  </Link>
                </li>
                <li className="nk-menu-item">
                  <Link to={{ pathname: '/popup/list' }} className="nk-menu-link">
                    <span className="nk-menu-text">Popups</span>
                  </Link>
                </li>
                <li className="nk-menu-item">
                  <Link to={{ pathname: '/form/list' }} className="nk-menu-link">
                    <span className="nk-menu-text">Forms</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              {onSaveFlow && (
                <li>
                  <button className="btn btn-success" onClick={onSaveFlow}>
                    Save
                  </button>
                </li>
              )}

              {onExportJson && (
                <li>
                  <button className="btn btn-primary" onClick={onExportJson}>
                    Export JSON
                  </button>
                </li>
              )}

              {/* <li className="dropdown notification-dropdown">
                <a href="#" className="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                  <div className="icon-status icon-status-info">
                    <em className="icon ni ni-bell"></em>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right">
                  <div className="dropdown-head">
                    <span className="sub-title nk-dropdown-title">Notifications</span>
                    <a href="#">Mark All as Read</a>
                  </div>
                  <div className="dropdown-body">
                    <div className="nk-notification">
                      <div className="nk-notification-item dropdown-inner">
                        <div className="nk-notification-icon">
                          <em className="icon icon-circle bg-primary-dim ni ni-share"></em>
                        </div>
                        <div className="nk-notification-content">
                          <div className="nk-notification-text">
                            Iliash shared <span>Dashlite-v2</span> with you.
                          </div>
                          <div className="nk-notification-time">Just now</div>
                        </div>
                      </div>
                      <div className="nk-notification-item dropdown-inner">
                        <div className="nk-notification-icon">
                          <em className="icon icon-circle bg-info-dim ni ni-edit"></em>
                        </div>
                        <div className="nk-notification-content">
                          <div className="nk-notification-text">
                            Iliash <span>invited</span> you to edit <span>DashLite</span> folder
                          </div>
                          <div className="nk-notification-time">2 hrs ago</div>
                        </div>
                      </div>
                      <div className="nk-notification-item dropdown-inner">
                        <div className="nk-notification-icon">
                          <em className="icon icon-circle bg-primary-dim ni ni-share"></em>
                        </div>
                        <div className="nk-notification-content">
                          <div className="nk-notification-text">
                            You have shared <span>project v2</span> with Parvez.
                          </div>
                          <div className="nk-notification-time">7 days ago</div>
                        </div>
                      </div>
                      <div className="nk-notification-item dropdown-inner">
                        <div className="nk-notification-icon">
                          <em className="icon icon-circle bg-success-dim ni ni-spark"></em>
                        </div>
                        <div className="nk-notification-content">
                          <div className="nk-notification-text">
                            Your <span>Subscription</span> renew successfully.
                          </div>
                          <div className="nk-notification-time">2 month ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-foot center">
                    <a href="#">View All</a>
                  </div>
                </div>
              </li> */}
              <li className="dropdown user-dropdown">
                <a href="#s" className="dropdown-toggle mr-n1" data-toggle="dropdown" onClick={onUserDropdown}>
                  <div className="user-toggle">
                    <div className="user-avatar sm">
                      <em className="icon ni ni-user-alt"></em>
                    </div>
                  </div>
                </a>

                {isUserDropdown && (
                  <>
                    <div className="modal modal-dropdown" onClick={onUserDropdown} />
                    <div className="dropdown-menu dropdown-menu-md dropdown-menu-right show">
                      <div className="dropdown-inner">
                        <ul className="link-list">
                          <li>
                            <Link to={{ pathname: `/settings/preferences` }}>
                              <em className="icon ni ni-setting-alt"></em>
                              <span>Settings</span>
                            </Link>
                          </li>
                          <li>
                            <Link to={{ pathname: `/billing` }}>
                              <em className="icon ni ni-activity-alt"></em>
                              <span>Billing</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="dropdown-inner">
                        <ul className="link-list">
                          <li>
                            <a href="#s" onClick={onLogout}>
                              <em className="icon ni ni-signout"></em>
                              <span>Logout</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
