import axiosConfig, { axiosUtilsConfig } from 'utils/api';

export const popup = {
  post: {
    action: (params = {}) => axiosConfig.post('/popups/api/v1/create_popup', params),
    cancel: () => null,
  },
  update: {
    action: (params = {}) =>
      axiosConfig.post(`/popups/api/v1/update_popup?popup_id=${params.id}&active=${params.active}`, params),
    cancel: () => null,
  },
  get: {
    action: id =>
      axiosConfig.get(`/popups/api/v1/get_current_popup`, {
        params: {
          popup_id: id,
        },
      }),
    cancel: () => null,
  },
  delete: {
    action: id =>
      axiosConfig.delete(`/popups/api/v1/delete_popup_by_id`, {
        params: {
          popup_id: id,
        },
      }),
    cancel: () => null,
  },
  statusUpdate: {
    action: (id, active) => axiosConfig.patch(`/popups/api/v1/update_popup_status?popup_id=${id}&active=${active}`),
    cancel: () => null,
  },
  list: {
    action: (params = {}) => axiosConfig.get('/popups/api/v1/get_user_popups', params),
    cancel: () => null,
  },
  uploadImage: {
    action: imageUpload => axiosUtilsConfig.post('/utils/upload_image', imageUpload),
    cancel: () => null,
  },
  getStatistik: {
    action: () => axiosConfig.get('/popups/api/v1/get_current_user_popups_raw_stat'),
    cancel: () => null,
  },
  getFullStatistik: {
    action: (params = {}) => axiosConfig.get(`/popups/api/v2/get_current_user_popups_stat`, { params }),
    cancel: () => null,
  },
  createTemplate: {
    action: (params = {}) => axiosConfig.post(`/popups/api/v1/create_popup_template`, params),
    cancel: () => null,
  },
  getUserTemplates: {
    action: (params = {}) => axiosConfig.get(`/popups/api/v1/get_user_popup_templates`, params),
    cancel: () => null,
  },
  getUserCurrentTemplate: {
    action: id => axiosConfig.get(`/popups/api/v1/get_user_current_popup_template?popup_template_id=${id}`),
    cancel: () => null,
  },
  getPublicTemplates: {
    action: (params = {}) => axiosConfig.get(`/popups/api/v1/get_public_popup_templates`, params),
    cancel: () => null,
  },
  deleteTemplate: {
    action: id =>
      axiosConfig.delete(`/popups/api/v1/delete_popup_template_by_id`, {
        params: {
          popup_template_id: id,
        },
      }),
    cancel: () => null,
  },
};
