import React, { useState, useEffect } from 'react';

import './Avatar.scss';

export const Avatar = ({ name, image, className, style }) => {
  const [abbr, setAbbr] = useState(null);

  useEffect(() => {
    const arrAbbr = name.trim().split(' ');
    setAbbr(() => `${arrAbbr[0][0]}${arrAbbr[1] ? arrAbbr[1][0] : arrAbbr[0][1]}`.toUpperCase());
  }, []);

  return (
    <div className="chat-avatar" style={style}>
      <div className={`user-avatar ${className}`}>{image ? <img src={image} alt="" /> : <span>{abbr}</span>}</div>
    </div>
  );
};
