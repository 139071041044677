import React from 'react';
import { Helmet } from 'react-helmet';
import { SignupForm } from 'components';
import { Login } from 'layouts';

const SignUp = () => {
  return (
    <>
      <Helmet>
        <title>Create your account</title>
      </Helmet>

      <Login>
        <SignupForm />
      </Login>
    </>
  );
};

export default SignUp;
