import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRequest } from 'estafette';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { setTemplate } from 'features/templateSlice';
import { form } from 'libs/http/api/form';
import { DataPreloader } from 'components/DataPreloader/DataPreloader';
import { ModalConfirm } from 'components/ModalConfirm/ModalConfirm';
// import NavHeader from 'components/NavHeader/v2/NavHeader';
import { Header } from 'components';

import './FormTemplatesList.scss';

const FormTemplatesList = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { request: requestUserTemplates, loading: loadingUserTemplates, data: dataUserTemplates } = useRequest({});
  const { request: requestDeleteTemplate, loading: loadingDeleteTemplate } = useRequest({});

  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const [idTemplate, setIdTemplate] = useState(null);

  const onSetTemplate = template => {
    dispatch(setTemplate(template));

    history.push('/form-builder');
  };

  const getTemplates = () => {
    requestUserTemplates(form.getUserTemplates.action());
  };

  const onDeleteTemplate = async () => {
    setIdTemplate(null);
    setIsRemoveModal(false);
    await requestDeleteTemplate(form.deleteTemplate.action(idTemplate));
    getTemplates();
  };

  const onDeleteModal = id => {
    setIdTemplate(id);
    setIsRemoveModal(true);
  };

  const onCancelDelete = () => {
    setIdTemplate(null);
    setIsRemoveModal(false);
  };

  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    if (dataUserTemplates?.forms && dataUserTemplates.forms.length === 0) {
      history.push('/form-builder');
    }
  }, [dataUserTemplates]);

  return (
    <>
      <div className="nk-main">
        <div className="nk-wrap">
          <Header />
          <Helmet>
            <title>Form templates</title>
          </Helmet>

          <DataPreloader loading={loadingUserTemplates || loadingDeleteTemplate || !dataUserTemplates?.forms}>
            <div className="nk-content">
              <div className="container-fluid">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div className="flex">
                      <div>
                        <h3>Overlay Templates</h3>
                      </div>
                      <div className="text-right">
                        <Link to="/form-builder">
                          <button className="btn btn-primary mb-3">Create From Scratch</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="row templates-list">
                    {!loadingUserTemplates &&
                      dataUserTemplates?.form_templates &&
                      dataUserTemplates.form_templates.map(template => (
                        <div className="template col-lg-4" onClick={() => onSetTemplate(template)} key={template.uid}>
                          <span
                            className="remove-template"
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              onDeleteModal(template.uid);
                            }}
                          >
                            <em className="icon ni ni-cross-circle-fill" />
                          </span>

                          <div
                            className="template-preview"
                            style={{ backgroundImage: `url(${template.config.info.preview})` }}
                          />

                          <div className="template-name">
                            {template.title} <i className="fas fa-arrow-right" />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </DataPreloader>
        </div>
      </div>

      {isRemoveModal && (
        <ModalConfirm>
          <div className="nk-modal-action mt-5">
            <DataPreloader loading={loadingDeleteTemplate}>
              <button className="btn btn-lg btn-mw btn-light mr-1" onClick={onCancelDelete}>
                Cancel
              </button>
              <button className="btn btn-lg btn-mw btn-danger ml-1" onClick={onDeleteTemplate}>
                Delete
              </button>
            </DataPreloader>
          </div>
        </ModalConfirm>
      )}
    </>
  );
};

export default FormTemplatesList;
