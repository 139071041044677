export const createBranchNode = ({ activeElement, setElements, setActiveElement, getLayoutedElements, branchName }) => {
  setElements(els => {
    let newNode = {};
    let newNodes = [];
    const newNodeId = `n-${String(Math.random()).replace('.', '')}`;
    const newEdgeId = `e-${String(Math.random()).replace('.', '')}`;
    const newCreateButtonElseId = `n-${String(Math.random()).replace('.', '')}`;

    let prevSource;
    if (activeElement?.data?.type === 'create-else') {
      prevSource = activeElement.id;
    } else {
      prevSource = els.find(el => el?.target === activeElement.id).source;
    }

    const prevTarget = els.find(el => el.source === activeElement.id).target;
    let newEls = els;
    let createButtonElse;

    if (!branchName) {
      newEls = newEls.filter(el => el.source !== activeElement.id);

      newNode = {
        id: newNodeId,
        type: 'buttonnode',
        data: {
          text: branchName || 'Function 1',
          isHandleBottom: true,
          isHandleTop: true,
          className: 'branch-node',
          type: 'branch',
        },
      };

      newNodes = [
        newNode,
        {
          id: `e-p-${newEdgeId}`,
          source: prevSource,
          target: newNodeId,
          type: 'smoothstep',
        },
        {
          id: newEdgeId,
          source: newNodeId,
          target: newCreateButtonElseId,
          type: 'smoothstep',
        },
        {
          id: `s-${newEdgeId}`,
          source: newCreateButtonElseId,
          target: prevTarget,
          type: 'smoothstep',
        },
      ];

      createButtonElse = [
        {
          id: newCreateButtonElseId,
          type: 'buttonnode',
          data: {
            text: '+',
            isHandleTop: true,
            isHandleBottom: true,
            className: 'create-node-else',
            type: 'create-else',
          },
          className: 'create-else',
        },
      ];
    } else {
      newNode = {
        id: newNodeId,
        type: 'buttonnode',
        data: {
          text: branchName || 'Function 1',
          isHandleBottom: true,
          isHandleTop: true,
          className: 'branch-node',
          type: 'branch',
        },
      };

      newNodes = [
        newNode,
        {
          id: `e-p-${newEdgeId}`,
          source: prevSource,
          target: newNodeId,
          type: 'smoothstep',
        },
        {
          id: newEdgeId,
          source: newNodeId,
          target: newCreateButtonElseId,
          type: 'smoothstep',
        },
        {
          id: `s-${newEdgeId}`,
          source: newCreateButtonElseId,
          target:
            newEls.find(elf1 => elf1.source === newEls.find(elf2 => elf2.source === activeElement.id).target)?.target ||
            'create-button',
          type: 'smoothstep',
        },
        {
          id: `s-s-${newEdgeId}`,
          source: newNodeId,
          target: newCreateButtonElseId,
          type: 'smoothstep',
        },
      ];

      createButtonElse = [
        {
          id: newCreateButtonElseId,
          type: 'buttonnode',
          data: {
            text: '+',
            isHandleTop: true,
            isHandleBottom: true,
            className: 'create-node-else',
            type: 'create-else',
          },
          className: 'create-else',
        },
      ];
    }

    let objectElements = [...newEls, ...newNodes, ...createButtonElse];
    objectElements.map(el => {
      if (el?.data?.type === 'create-else' && !objectElements.find(elf => elf?.source === el.id)) {
        const newCreateButtonElseId = `n-${String(Math.random()).replace('.', '')}`;
        objectElements = [
          ...objectElements,
          {
            id: `e-${newCreateButtonElseId}`,
            source: el.id,
            target: 'create-button',
            type: 'smoothstep',
            data: { typesmooth: 'to-create' },
          },
        ];
      }
    });

    objectElements = getLayoutedElements(objectElements, 'TB');

    setActiveElement(newNode);

    return objectElements;
  });
};
