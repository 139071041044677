import React from 'react';

export const Toast = ({ onClose, title = 'Notification', text }) => (
  <div style={{ position: 'fixed', bottom: '15px', right: 0, left: 0, zIndex: 99, width: '348px', margin: 'auto' }}>
    <div className="toast" role="alert" aria-live="assertive" aria-atomic="true" style={{ opacity: 1, margin: 'auto' }}>
      <div className="toast-header">
        <strong className="mr-auto text-primary">{title}</strong>
        {onClose && (
          <button type="button" className="close" data-dismiss="toast" aria-label="Close" onClick={onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
      <div className="toast-body">{text}</div>
    </div>
  </div>
);
