import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRequest } from 'estafette';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { flow } from 'libs/http/api/flow';
import { chat } from 'libs/http/api/chat';
import { Header, DataPreloader, AnalyticsFlowItem, Modal } from 'components';
import Table from 'components/TemplateBuilder/components/Table/Table';
import FlowStatGraph from 'components/FlowStatGraph/FlowStatGraph';
import { ChatArea } from 'components/Chat';

const FlowAnalytics = () => {
  const { id } = useParams();

  const { request: requestFlowAnalytics, loading: loadingFlowAnalytics } = useRequest({ loading: true });
  const { request: requestDialog, data: dataDialog, loading: loadingDialog } = useRequest({ loading: true });

  const [data, setData] = useState(null);
  const [firstLoading, setFirstLoading] = useState(true);
  const [activeUser, setActiveUser] = useState(null);
  const [activeUserName, setActiveUserName] = useState(null);
  const [activeDropdownKey, setActiveDropdownKey] = useState(null);
  const [isUserDialogModal, setIsUserDialogModal] = useState(false);

  const onToggleDropdown = (uid, key, name) => {
    if (uid) {
      setActiveUser(uid);
      setActiveDropdownKey(`${uid}_${key}`);
      setActiveUserName(name);
    } else {
      setActiveUser(null);
      setActiveDropdownKey(null);
      setActiveUserName(null);
    }
  };

  const onShowChat = () => {
    requestDialog(chat.getUserDialog.action(id, activeUser));
    setIsUserDialogModal(true);
  };

  const onCloseUserDialogModal = () => setIsUserDialogModal(false);

  const getChartSeries = async (startDate, endDate) => {
    const newFilter = { start_date: startDate, end_date: endDate };
    const newData = await requestFlowAnalytics(flow.getAnalytics.action(id, newFilter));
    setData(newData);
    setFirstLoading(false);
    return newData?.chart_series || null;
  };

  useEffect(() => {
    if (!loadingFlowAnalytics) {
      setFirstLoading(loadingFlowAnalytics);
    }
  }, [loadingFlowAnalytics]);

  // useEffect(() => {
  //   getChartSeries();
  // }, []);

  return (
    <>
      <Helmet>
        <title>Flow analitics</title>
      </Helmet>

      <div className="nk-main">
        <div className="nk-wrap">
          <Header />

          <DataPreloader loading={firstLoading} />

          <div className="nk-content" style={{ marginTop: '70px', opacity: firstLoading ? 0 : 1 }}>
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  {/* <PageTitle title="analytics" /> */}

                  <div>
                    <div className="text-right">
                      <Link to={{ pathname: '/flows' }} className="btn btn-primary">
                        <em className="icon ni ni-arrow-left"></em>
                        Back to flow list
                      </Link>
                    </div>

                    <div className="mt-3">
                      <div className="row g-gs">
                        <AnalyticsFlowItem title="Users" count={data?.users_count} />

                        <AnalyticsFlowItem title="Messages" count={data?.messages_count} />

                        <AnalyticsFlowItem title="Messages per user" count={data?.messages_per_user} />

                        <AnalyticsFlowItem title="Active users" count={data?.active_users_count} />
                      </div>
                    </div>

                    <FlowStatGraph
                      className="mt-50"
                      getChartSeries={getChartSeries}
                      loadingFilterStatistik={loadingFlowAnalytics}
                      typeStat="popup"
                    />

                    {data?.users_list && data.users_list.length > 0 && (
                      <div className="conversion-stat-graph card mt-3">
                        <div className="card-inner">
                          <div className="datatable-wrap my-3">
                            <Table className="datatable-init nk-tb-list nk-tb-ulist dataTable no-footer">
                              <Table.Tr className="nk-tb-head">
                                <Table.Th className="nk-tb-col">
                                  <span className="sub-text">Name</span>
                                </Table.Th>
                                <Table.Th className="nk-tb-col">
                                  <span className="sub-text">Subscribed</span>
                                </Table.Th>
                                <Table.Th className="nk-tb-col">
                                  <span className="sub-text">Source</span>
                                </Table.Th>
                                <Table.Th className="nk-tb-col"></Table.Th>
                              </Table.Tr>

                              {data.users_list.map((user, key) => (
                                <Table.Tr className="nk-tb-item">
                                  <Table.Td className="nk-tb-col">{user.user_object.first_name}</Table.Td>

                                  <Table.Td className="nk-tb-col">
                                    {moment(user.message_datetime).format('MMMM Do YYYY, h:mm a')}
                                  </Table.Td>

                                  <Table.Td className="nk-tb-col">Telegram</Table.Td>

                                  <Table.Td className="nk-tb-col tb-tnx-action stat-right">
                                    <div className="dropdown">
                                      <span
                                        className="button text-soft dropdown-toggle btn btn-icon btn-trigger"
                                        onClick={() =>
                                          onToggleDropdown(user.user_object.id, key, user.user_object.first_name)
                                        }
                                      >
                                        <em className="icon ni ni-more-h"></em>
                                      </span>

                                      {activeDropdownKey === `${user.user_object.id}_${key}` && (
                                        <>
                                          <div
                                            className="dropdown-menu-close"
                                            onClick={() => {
                                              onToggleDropdown(null);
                                            }}
                                          />
                                          <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs show">
                                            <ul className="link-list-plain">
                                              <li onClick={() => onShowChat(user.user_object.id)}>
                                                <span className="button">Show Dialog</span>
                                              </li>
                                              <li>
                                                <span className="button">Edit</span>
                                              </li>
                                              {/* <li>
                                                  <Link
                                                    className="button"
                                                    to={{
                                                      pathname: popup.config?.info
                                                        ? `/template-builder/${popup.uid}`
                                                        : `/popup/builder/${popup.uid}`,
                                                    }}
                                                  >
                                                    <span>Edit</span>
                                                  </Link>
                                                </li>
                                                <li>
                                                  <span className="button" onClick={() => onDeletePopup(popup.uid)}>
                                                    Delete
                                                  </span>
                                                </li> */}
                                            </ul>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </Table.Td>
                                </Table.Tr>
                              ))}
                            </Table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isUserDialogModal && (
        <Modal title={`Dialog with ${activeUserName}`} onClose={onCloseUserDialogModal} className="chat-demo-modal">
          <DataPreloader loading={loadingDialog}>
            <ChatArea userDialog={dataDialog?.user_dialog} />
          </DataPreloader>
        </Modal>
      )}
    </>
  );
};

export default FlowAnalytics;
