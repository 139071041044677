import axiosConfig from 'utils/api';

export const form = {
  post: {
    action: (params = {}) => axiosConfig.post('/api/v1/create_form', params),
    cancel: () => null,
  },
  update: {
    action: (params = {}) =>
      axiosConfig.post(`/api/v1/update_form?form_id=${params.id}&active=${params.active}`, params),
    cancel: () => null,
  },
  get: {
    action: id =>
      axiosConfig.get(`/api/v1/get_current_form`, {
        params: {
          form_id: id,
        },
      }),
    cancel: () => null,
  },
  delete: {
    action: id =>
      axiosConfig.delete(`/api/v1/delete_form_by_id`, {
        params: {
          form_id: id,
        },
      }),
    cancel: () => null,
  },
  statusUpdate: {
    action: (id, active) => axiosConfig.patch(`/api/v1/update_form_status?form_id=${id}&active=${active}`),
    cancel: () => null,
  },
  list: {
    action: (params = {}) => axiosConfig.get('/api/v1/get_user_forms', params),
    cancel: () => null,
  },
  getUserTemplates: {
    action: (params = {}) => axiosConfig.get('/api/v1/get_user_forms', params),
    cancel: () => null,
  },
  uploadImage: {
    action: imageUpload => axiosConfig.post('/api/v1/upload_popup_image', imageUpload),
    cancel: () => null,
  },
  getStatistik: {
    action: () => axiosConfig.get('/api/v1/get_current_user_forms_raw_stat'),
    cancel: () => null,
  },
  getFullStatistik: {
    action: (params = {}) => axiosConfig.get(`/api/v1/get_current_user_forms_stat`, { params }),
    cancel: () => null,
  },
  postLead: {
    action: params => axiosConfig.post('/api/v1/create_lead', { params }),
    cancel: () => null,
  },
  getLeads: {
    action: (params = {}) => axiosConfig.get(`/api/v1/get_user_leads`, { params }),
    cancel: () => null,
  },
  getLeadOne: {
    action: (params = {}) => axiosConfig.get(`/api/v1/get_lead_by_id`, { params }),
    cancel: () => null,
  },
  deleteLead: {
    action: (params = {}) => axiosConfig.delete(`/api/v1/delete_form_by_id`, { params }),
    cancel: () => null,
  },
};
