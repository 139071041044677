import React from 'react';

import './DataPreloader.scss';

export const DataPreloader = ({ loading, children, inline, size = '2rem', styles = {} }) => {
  return (
    <>
      {loading && (
        <div className={`preloader${inline ? ' inline' : ''}`} style={styles}>
          <div className="spinner-border text-brand" role="status" style={{ width: size, height: size }}>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      {!loading && children && children}
    </>
  );
};
