import React, { useState } from 'react';

import './Dropdown.scss';

export const Dropdown = ({ title, style, className, children }) => {
  const [isList, setIsList] = useState(false);

  const onToggleList = () => setIsList(prev => !prev);

  return (
    <div className={`select-dropdown ${className ? className : ''}`} style={style}>
      {isList && <div className="close-select" onClick={onToggleList} />}

      <div className="select-button" onClick={onToggleList}>
        <span>{title}</span>
        <em className="icon ni ni-chevron-down" />
      </div>

      <div className={`dropdown-menu${isList ? ' show' : ''}`}>{children}</div>
    </div>
  );
};
