import React from 'react';
import { Avatar } from '..';

export const Header = ({ name }) => {
  return (
    <div className="nk-chat-head">
      <ul className="nk-chat-head-info">
        <li className="nk-chat-head-user">
          <div className="user-card">
            <Avatar name="BO" />

            <div className="user-info">
              <div className="lead-text">Chat Bot</div>
              <div className="sub-text">
                <span className="d-none d-sm-inline mr-1">Online</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
