import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import MediumEditor from 'medium-editor';
import * as htmlToImage from 'html-to-image';
import { useRequest } from 'estafette';
import { useParams } from 'react-router-dom';
import { popup } from 'libs/http/api/popup';
import { DataPreloader } from 'components/DataPreloader/DataPreloader';
import { Header } from 'components';
import TemplateBuilder from './template-builder';
import Sidebar from './components/Sidebar/Sidebar';
import { initConfig } from './config';

import './TemplateBuilder.scss';
import './TemplateBuilderInner.scss';
import './../../../node_modules/medium-editor/src/sass/medium-editor.scss';
import './../../../node_modules/medium-editor/src/sass/themes/default.scss';
import { HeaderModalPreview } from 'components/HeaderModalPreview/HeaderModalPreview';

let mediumEditor = new MediumEditor('#tuls-modal [data-tuls-type="title"], #tuls-modal [data-tuls-type="text"]', {
  toolbar: {
    buttons: ['bold', 'italic', 'underline', 'anchor'],
  },
});

const Builder = () => {
  const { template } = useSelector(state => state);

  const { id: idPopup } = useParams();

  const { request: requestUploadImage } = useRequest({});
  const { request: requestCreateTemplate } = useRequest({});
  const { request: requestCreatePopup, loading: loadingCreatePopup } = useRequest({});
  const {
    request: requestCurrentTemplate,
    loading: loadingCurrentTemplate,
    data: dataCurrentTemplate,
  } = useRequest({});

  const tulsBuilderRef = useRef(null);
  const [config, setConfig] = useState(initConfig);
  const [activeElement, setActiveElement] = useState(null);
  const [activeElementType, setActiveElementType] = useState(null);
  const [dragElement, setDragElement] = useState(null);
  const [isDragElement, setIsDragElement] = useState(false);
  const [dropElement, setDropElement] = useState(null);
  const [isCropTemplate, setIsCropTemplate] = useState(false);
  const [isCropGrid, setIsCropGrid] = useState(false);
  const [activeGrid, setActiveGrid] = useState(null);
  const [popupSizes, setPopupSizes] = useState({ h: 400, w: 300 });
  const [isLoading, setIsLoading] = useState(false);
  const [activeView, setActiveView] = useState('edit');

  const insertAfter = (newNode, referenceNode) => {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  };

  const insertBefore = (newNode, referenceNode) => {
    referenceNode.parentNode.insertBefore(newNode, referenceNode);
  };

  const onDragstartHandler = e => {
    setIsDragElement(true);
    mediumEditor.destroy();
    setDragElement(e.target);
    e.dataTransfer.effectAllowed = 'move';
  };

  const onDragstartHandlerComponent = e => {
    setIsDragElement(true);
    mediumEditor.destroy();
    setDragElement(e.target);
    e.dataTransfer.effectAllowed = 'move';
  };

  const onDragEnterHandler = e => {
    setDropElement(e.target);
  };

  const onMouseEnterGridCol = e => {
    setActiveGrid(e.target);
  };

  const onDropHandler = e => {
    e.stopPropagation();
    e.preventDefault();

    const type = dragElement.getAttribute('data-tuls-type');

    const newNode = document.importNode(dragElement, true);
    newNode.draggable = true;
    newNode.ondragstart = onDragstartHandler;
    newNode.onclick = onFocusElement;

    if (dropElement.closest('.tuls-column-empty')) {
      dropElement.closest('.tuls-column-empty').classList.remove('tuls-column-empty');
    }

    if (type === null) {
      if (dropElement.closest('.tuls-line')) {
        const lineHeight = dropElement.closest('.tuls-line').offsetHeight;
        const lineTop = dropElement.closest('.tuls-line').getBoundingClientRect().top;
        const middleLine = lineTop + lineHeight - lineHeight / 2 + window.scrollY;
        if (e.pageY < middleLine) {
          insertBefore(newNode, dropElement.closest('.tuls-line'));
        } else {
          insertAfter(newNode, dropElement.closest('.tuls-line'));
        }
      } else {
        dropElement.appendChild(newNode);
      }
    } else {
      if (type !== 'backgroundImage' && type !== 'codeFrame' && type !== 'formFrame') {
        if (type !== 'button') {
          newNode.contentEditable = 'true';
        } else {
          newNode.querySelector('span').contentEditable = 'true';
        }
      }
      let column;
      if (dropElement.classList.contains('tuls-column')) {
        column = dropElement;
      } else if (dropElement.closest('.tuls-column')) {
        column = dropElement.closest('.tuls-column');
      }
      if (column) {
        if (dropElement.classList.contains('tuls-component')) {
          const elementHeight = dropElement.offsetHeight;
          const elementTop = dropElement.getBoundingClientRect().top;
          const middleElement = elementTop + elementHeight - elementHeight / 2 + window.scrollY;
          if (e.pageY < middleElement) {
            insertBefore(newNode, dropElement);
          } else {
            insertAfter(newNode, dropElement);
          }
        } else {
          column.appendChild(newNode);
        }
      }
    }

    if (dragElement.closest('#tuls-modal')) {
      dragElement.remove();
    }

    setIsDragElement(false);
    mediumEditor.setup();
  };

  const onDragoverHandler = e => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const uploadImages = async () => {
    await Promise.all(
      Array.from(document.body.querySelectorAll('#tuls-modal, .tuls-line, .tuls-column, .tuls-modal-img')).map(el => {
        console.log(el);
        if (el.style?.backgroundImage && el.style.backgroundImage.includes('blob:')) {
          return new Promise((resolve, reject2) => {
            const dataImageSrc = el.style.backgroundImage.match(/url\("(.*)"\)/)[1];
            const screenImg = document.createElement('img');
            screenImg.src = dataImageSrc;
            document.body.appendChild(screenImg);

            el.setAttribute('data-base64', dataImageSrc);

            setTimeout(async () => {
              const dataImage = await onCapture(screenImg);
              const fileData = new FormData();
              fileData.append('image', dataImage);
              screenImg.remove();

              const resFileUpload = await requestUploadImage(popup.uploadImage.action(fileData));
              el.style.backgroundImage = `url(${resFileUpload.image})`;

              resolve();
            }, 300);
          });
        }
      }),
    );

    return true;
  };

  const onSave = async ({ type = 'template', name, description, target, targetValue, targetDevice, modalPosition }) => {
    setIsLoading(true);
    await uploadImages();

    mediumEditor.destroy();
    const templateBuilder = new TemplateBuilder(
      idPopup || null,
      initConfig,
      document.querySelector('#tuls-modal'),
      true,
    );

    const newConfig = templateBuilder.createConfig();

    let cf = {
      title: name,
      description,
      config: newConfig,
    };
    cf.config.info = {
      ...cf.info,
      closeButtonSvg: newConfig.info.closeButtonSvg,
      closeButtonSvg_size: newConfig.info.closeButtonSvg_size,
      closeButtonSvg_position: newConfig.info.closeButtonSvg_position,
      title: name,
      description,
      target,
      targetValue,
      targetDevice,
      modalPosition,
      height: popupSizes.h,
      width: popupSizes.w,
      style: templateBuilder.stringStyleToObject(document.querySelector('#tuls-modal').getAttribute('style')),
    };
    console.log('cf', cf);

    let resCreate;
    if (type === 'template') {
      const dataImage = await onCapture(document.querySelector('#tuls-modal'));
      const fileData = new FormData();
      fileData.append('image', dataImage);
      const resFileUpload = await requestUploadImage(popup.uploadImage.action(fileData));
      cf.config.info.preview = resFileUpload.image;
      cf.thumbnail = resFileUpload.image;
      resCreate = await requestCreateTemplate(popup.createTemplate.action(cf));
    } else if (type === 'popup') {
      if (idPopup) {
        cf.id = idPopup;
        cf.active = 1;
        resCreate = await requestCreatePopup(popup.update.action(cf));
      } else {
        resCreate = await requestCreatePopup(popup.post.action(cf));
      }
    }

    setIsLoading(false);

    window.location.href = '/popup/list';
  };

  const rerenderTulsComponents = () => {
    document
      .getElementById('tuls-modal')
      .querySelectorAll('.tuls-component')
      .forEach(element => {
        element.ondragstart = onDragstartHandler;
        element.onclick = onFocusElement;
      });
    document
      .getElementById('tuls-modal')
      .querySelectorAll('.tuls-line')
      .forEach(element => {
        element.ondragstart = onDragstartHandler;
        element.draggable = 'true';
        element.onclick = onFocusElement;
      });
    mediumEditor.setup();
  };

  const onFocusElement = e => {
    if (typeof e.persist === 'function') {
      e.persist();
    }
    e.stopPropagation();
    if (!e.target.classList.contains('tuls-inner')) {
      let reEl = e.target;
      if (!e.target.getAttribute('data-tuls-type') && !e.target.classList.contains('tuls-column')) {
        reEl = e.target.closest('[data-tuls-type]');
      }
      setActiveElement(reEl);
    } else {
      setActiveElement(document.querySelector('#tuls-modal'));
    }
  };

  const onCloseSettings = () => setActiveElement(null);

  const onRemoveElement = e => {
    e.preventDefault();
    setActiveElementType(null);
    setActiveElement(null);
    if (activeElement.classList.contains('tuls-column')) {
      activeElement.closest('.tuls-line').remove();
    } else {
      activeElement.remove();
    }
  };

  const onMouseDownCropTemplate = () => {
    document.getElementById('tuls-modal').classList.add('tuls-modal-resizing');
    setIsCropTemplate(true);
  };

  const onCapture = async element => {
    const modal = element;
    modal.classList.add('capture');
    const dataImage = await htmlToImage.toBlob(element).then(function (dataUrl) {
      modal.classList.remove('capture');
      return dataUrl;
    });
    return dataImage;
  };

  const loadTemplate = id => {
    requestCurrentTemplate(popup.getUserCurrentTemplate.action(id));
  };

  const templateRender = newConfig => {
    const templateBuilder = new TemplateBuilder(
      idPopup || null,
      newConfig,
      document.querySelector('#tuls-modal'),
      true,
    );
    templateBuilder.init({ editable: true });

    rerenderTulsComponents();
  };

  const onChangeView = newActiveView => setActiveView(newActiveView);

  useEffect(() => {
    templateRender(initConfig);
  }, [tulsBuilderRef]);

  useEffect(() => {
    console.log(activeElementType);
  }, [activeElementType]);

  useEffect(() => {
    if (activeView !== 'edit') {
      document.querySelectorAll('#tuls-modal [contenteditable="true"]').forEach(el => {
        el.setAttribute('data-contenteditable', true);
        el.setAttribute('contenteditable', false);
        el.setAttribute('draggable', false);
      });
    } else {
      document.querySelectorAll('#tuls-modal [data-contenteditable="true"]').forEach(el => {
        el.setAttribute('data-contenteditable', true);
        el.setAttribute('contenteditable', true);
        el.setAttribute('draggable', true);
      });
    }
  }, [activeView]);

  useEffect(() => {
    if (activeElement) {
      if (activeElement?.getAttribute('data-tuls-type')) {
        setActiveElementType(activeElement?.getAttribute('data-tuls-type'));
      } else if (activeElement?.closest('.tuls-component')?.getAttribute('data-tuls-type')) {
        setActiveElementType(activeElement?.closest('.tuls-component')?.getAttribute('data-tuls-type'));
      } else if (activeElement?.closest('.tuls-line')) {
        setActiveElementType('line');
      }
    } else {
      if (activeElement?.getAttribute('data-tuls-type') === 'modal') {
        setActiveElementType('modal');
      } else {
        setActiveElementType(null);
      }
    }
  }, [activeElement]);

  useEffect(() => {
    document.onmousemove = e => {
      if (isCropTemplate) {
        const difX =
          e.pageX - (tulsBuilderRef.current.getBoundingClientRect().left + tulsBuilderRef.current.offsetWidth);
        tulsBuilderRef.current.style.width = `${tulsBuilderRef.current.offsetWidth + difX}px`;
        const difY =
          e.pageY -
          (tulsBuilderRef.current.getBoundingClientRect().top + tulsBuilderRef.current.offsetHeight + window.scrollY);
        tulsBuilderRef.current.style.height = `${tulsBuilderRef.current.offsetHeight + difY}px`;

        setPopupSizes({
          h: tulsBuilderRef.current.offsetHeight,
          w: tulsBuilderRef.current.offsetWidth,
        });
      }
    };
  }, [isCropTemplate, isCropGrid, activeGrid]);

  useEffect(() => {
    document.onmouseup = () => {
      document.getElementById('tuls-modal') &&
        document.getElementById('tuls-modal').classList.remove('tuls-modal-resizing');
      setIsCropTemplate(false);
    };
  }, []);

  useEffect(() => {
    templateRender(config);
  }, [config]);

  useEffect(() => {
    if (dataCurrentTemplate?.popup_template?.config || dataCurrentTemplate?.popup?.config) {
      setConfig(dataCurrentTemplate?.popup_template?.config || dataCurrentTemplate?.popup?.config);
      if (dataCurrentTemplate?.popup?.config?.info) {
        setPopupSizes({
          h: dataCurrentTemplate.popup.config.info?.height,
          w: dataCurrentTemplate.popup.config.info?.width,
        });
      }
    }
  }, [dataCurrentTemplate]);

  useEffect(() => {
    if (idPopup) {
      const getPopup = async () => {
        if (idPopup) {
          requestCurrentTemplate(popup.get.action(idPopup));
        }
      };
      getPopup();
    }
  }, [idPopup]);

  useEffect(() => {
    if (template.data?.uid) {
      setConfig(template.data.config);
    }
  }, [template]);

  return (
    <>
      <div className="nk-main">
        <div className="nk-wrap">
          <Header />
          <Helmet>
            <title>Template Builder</title>
          </Helmet>

          <HeaderModalPreview onChangeView={onChangeView} activeView={activeView} />

          <DataPreloader loading={loadingCurrentTemplate}>
            <div className="nk-content">
              <div className="container-fluid">
                <div className={`nk-content-inner view-${activeView}`}>
                  <div className="container builder-container">
                    <div style={{ display: activeView === 'edit' ? 'block' : 'none' }}>
                      <DataPreloader loading={loadingCurrentTemplate}>
                        <Sidebar
                          onDragstartHandler={onDragstartHandler}
                          onFocusElement={onFocusElement}
                          onDragstartHandlerComponent={onDragstartHandlerComponent}
                          onSave={onSave}
                          activeElement={activeElement}
                          activeElementType={activeElementType}
                          onCloseSettings={onCloseSettings}
                          onRemoveElement={onRemoveElement}
                          loadTemplate={loadTemplate}
                          popupTemplate={dataCurrentTemplate}
                          loading={loadingCreatePopup || isLoading}
                        />
                      </DataPreloader>
                    </div>

                    <div className="nk-wrap">
                      <DataPreloader loading={loadingCurrentTemplate}>
                        <div className="nk-content">
                          <div className="container-fluid" style={{ width: '100%' }}>
                            <div className="nk-content-inner" style={{ width: '100%' }}>
                              <div
                                id="tuls-modal"
                                ref={tulsBuilderRef}
                                className="tuls-builder tuls-modal dropzone"
                                style={{
                                  height: `${popupSizes.h}px`,
                                  width: `${popupSizes.w}px`,
                                  padding: '20px',
                                }}
                                data-tuls-type="modal"
                                onClick={onFocusElement}
                              >
                                <div
                                  className="tuls-inner"
                                  onMouseEnter={onMouseEnterGridCol}
                                  onDrop={onDropHandler}
                                  onDragOver={onDragoverHandler}
                                  onDragEnter={onDragEnterHandler}
                                >
                                  {/* <div className="tuls-grid">
                            {getGridLines().map((line) => (
                              <div className="tuls-grid-line">
                                {getGridColums().map((col) => {
                                  const column = line*4+col;
                                  if (grid.includes(column)) {
                                    let styleCol;
                                    const size = gridSizes.find(item => item[column]);
                                    if (size) {
                                      styleCol = {'width': size[column].w, 'height': size[column].h};
                                    }
                                    return <div className="tuls-grid-col" onMouseEnter={onMouseEnterGridCol} onDrop={onDropHandler} onDragOver={onDragoverHandler} onDragEnter={onDragEnterHandler} data-grid-col={column} style={styleCol} />;
                                  }
                                })} 
                              </div>
                            ))}
                          </div> */}

                                  {/* <div className="tuls-line" style={templateBuilderFunctions.stringStyleToObject("background-color: gray; padding-top: 50px;")}>
                            <div className="tuls-column" style={templateBuilderFunctions.stringStyleToObject("background-color: red;")}>
                              <div className="tuls-title" data-tuls-type="title" style={templateBuilderFunctions.stringStyleToObject("background-color: red;")}>Whats up?</div>
                              <div className="tuls-title" data-tuls-type="title" style={templateBuilderFunctions.stringStyleToObject("background-color: red;")}>Whats up 2s?</div>
                              </div>
                              <div className="tuls-column" style={templateBuilderFunctions.stringStyleToObject("background-color: green;")}>
                                <div className="tuls-text" data-tuls-type="text" style={templateBuilderFunctions.stringStyleToObject("background-color: orange;")}>I am text!
                                </div>
                              </div>
                            </div>
                            <div class="tuls-line" style={templateBuilderFunctions.stringStyleToObject("background-color: blue; padding-top: 50px;")}>
                              <div className="tuls-column" style={templateBuilderFunctions.stringStyleToObject("background-color: red;")}>
                                <div className="tuls-title" data-tuls-type="title" style={templateBuilderFunctions.stringStyleToObject("background-color: red;")}>Whats up?</div>
                                  <div className="tuls-title" data-tuls-type="title" style={templateBuilderFunctions.stringStyleToObject("background-color: red;")}>Whats up 2s?</div>
                                  </div>
                            <div className="tuls-column" style={templateBuilderFunctions.stringStyleToObject("background-color: green;")}>
                              <div className="tuls-text" data-tuls-type="text" style={templateBuilderFunctions.stringStyleToObject("background-color: orange;")}>I am text!</div>
                            </div>
                          </div> */}
                                </div>
                                <em className="icon ni ni-crop crop-template" onMouseDown={onMouseDownCropTemplate} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </DataPreloader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DataPreloader>
        </div>
      </div>
    </>
  );
};

export default Builder;
