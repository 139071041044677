import React, { useCallback } from 'react';

export const useGTM = () => {
  const sendEvent = useCallback((event, payload = {}) => {
    const data = { event, ...payload };
    window.dataLayer.push();
  }, []);

  return { sendEvent };
};
