import React from 'react';
import { useForm } from 'react-hook-form';
import { Accordion } from 'components';
import BackgroundImage from 'components/TemplateBuilder/components/BackgroundImage/BackgroundImage';
import Select from 'components/TemplateBuilder/components/Select/Select';
import RangeSelector from 'components/TemplateBuilder/components/RangeSelector/RangeSelector';
import BlockColorPicker from 'components/TemplateBuilder/components/BlockColorPicker/BlockColorPicker';

export const SettingsSidebar = () => {
  const { register, unregister, handleSubmit, setValue, getValues, watch } = useForm();

  const onSubmit = data => {
    // f daf
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div id="accordion" className="accordion">
          <Accordion title="General settings">
            <>
              <div className="form-group">
                <label className="form-label">Name</label>
                <div className="form-control-wrap">
                  <input type="text" name="name" {...register('name')} className="form-control" required />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label">Description</label>
                <div className="form-control-wrap">
                  <textarea name="description" {...register('description')} className="form-control" required />
                </div>
              </div>

              <BackgroundImage
                element={document.querySelector('.chat-wrapper-settings .user-avatar')}
                styleName={'backgroundImage'}
                item={{ label: 'Avatar' }}
              />

              <BackgroundImage
                element={document.querySelector('.chat-wrapper-settings .user-avatar')}
                styleName={'backgroundImage'}
                item={{ label: 'Chat button' }}
              />

              <Select
                element={document.querySelector('.chat-wrapper-settings .user-avatar')}
                styleName="align"
                item={{
                  label: 'Position',
                  value: 'right',
                  options: ['left', 'right'],
                }}
              />

              <RangeSelector
                element={document.querySelector('.chat-wrapper-settings .user-avatar')}
                styleName="marginRight"
                item={{ label: 'Side margin', value: [25], min: 0, max: 50, unit: 'px' }}
              />

              <RangeSelector
                element={document.querySelector('.chat-wrapper-settings .user-avatar')}
                styleName="marginBottom"
                item={{ label: 'Bottom margin', value: [25], min: 0, max: 50, unit: 'px' }}
              />
            </>
          </Accordion>

          <Accordion title="Colors">
            <>
              <BlockColorPicker
                element={document.querySelector('.chat-wrapper-settings .nk-chat-head')}
                styleName="backgroundColor"
                label="Header background"
                defaultValue="rgb(255,255,255)"
              />

              <BlockColorPicker
                element={document.querySelector('.chat-wrapper-settings .nk-chat-head')}
                styleName="color"
                label="Header text"
                defaultValue="#364a63"
              />

              <BlockColorPicker
                element={document.querySelector('.chat-wrapper-settings .nk-chat-head')}
                styleName="color"
                label="Chat background"
                defaultValue="#f0f3f6"
              />

              <BlockColorPicker
                element={document.querySelector('.chat-wrapper-settings .nk-chat-head')}
                styleName="color"
                label="Bot message background"
                defaultValue="#ffffff"
              />

              <BlockColorPicker
                element={document.querySelector('.chat-wrapper-settings .nk-chat-head')}
                styleName="color"
                label="Bot message text"
                defaultValue="#526484"
              />
            </>
          </Accordion>
        </div>
      </form>
    </>
  );
};
