class TemplateBuilder {
  constructor(id, config, rootElement, local = false) {
    this.id = id;
    this.config = config;
    this.rootElement = rootElement;
    this.local = local;
    this.fonts = [];
    this.popupClosed = false;
  }

  get config() {
    return this._config;
  }

  set config(value) {
    this._config = value;
  }

  get id() {
    return this._id;
  }

  set local(value) {
    this._local = value;
  }

  get local() {
    return this._local;
  }

  set fonts(value) {
    this._fonts = value;
  }

  get fonts() {
    return this._fonts;
  }

  set popupClosed(value) {
    this._popupClosed = value;
  }

  get popupClosed() {
    return this._popupClosed;
  }

  set id(value) {
    this._id = value;
  }

  setCookie(name, value, options = {}) {
    options = {
      path: '/',
      ...options,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (let optionKey in options) {
      updatedCookie += '; ' + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }

    document.cookie = updatedCookie;
  }

  getCookie(name) {
    let matches = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }

  getBrowser() {
    let sBrowser,
      sUsrAg = navigator.userAgent;
    if (sUsrAg.indexOf('Firefox') > -1) {
      sBrowser = 'Mozilla Firefox';
    } else if (sUsrAg.indexOf('Opera') > -1) {
      sBrowser = 'Opera';
    } else if (sUsrAg.indexOf('Trident') > -1) {
      sBrowser = 'Microsoft Internet Explorer';
    } else if (sUsrAg.indexOf('Edge') > -1) {
      sBrowser = 'Microsoft Edge';
    } else if (sUsrAg.indexOf('Chrome') > -1) {
      sBrowser = 'Google Chrome';
    } else if (sUsrAg.indexOf('Safari') > -1) {
      sBrowser = 'Apple Safari';
    } else {
      sBrowser = 'unknown';
    }
    return sBrowser;
  }

  postStatistick({ eventType }) {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const data = {
      language: navigator.language,
      vendor: navigator.vendor,
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      device: isMobile ? 'mobile' : 'desktop',
      width: window.innerWidth,
      height: window.innerHeight,
      type: eventType,
      browser: this.getBrowser(),
    };
    fetch(`https://api.ezflow.cc/trk/clc?popup_id=${this.id}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(data),
    });
  }

  onCloseModal() {
    this.setCookie('tulsPopupClosed', 1, { 'max-age': 3600 });
    this.rootElement.style.display = 'none';
    this.popupClosed = true;
  }

  async initStyles() {
    let styles = await fetch('https://app.ezflow.cc/assets/styles/tuls-modal.css');
    styles = await styles.text();
    const stylesTag = document.createElement('style');
    stylesTag.rel = 'stylesheet';
    stylesTag.id = 'tuls-modal-styles';
    stylesTag.innerHTML = styles;
    document.body.appendChild(stylesTag);
  }

  stringStyleToObject(input) {
    let result = {};
    if (input) {
      let attributes = input.split(';');
      for (let i = 0; i < attributes.length; i++) {
        const entry = attributes[i].split(':');
        const prop = entry.splice(0, 1)[0].trim();
        if (prop) {
          result[prop] = entry.join(':').trim();
        }
      }
    }
    return result;
  }

  setInnerHTML(elm, html) {
    elm.innerHTML = html;
    Array.from(elm.querySelectorAll('script')).forEach(oldScript => {
      const newScript = document.createElement('script');
      Array.from(oldScript.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
      newScript.appendChild(document.createTextNode(oldScript.innerHTML));
      oldScript.parentNode.replaceChild(newScript, oldScript);
    });
  }

  childrenToJson(columnElement) {
    const children = [];
    columnElement.childNodes.forEach(childrenElement => {
      const childrenJson = {
        style: this.stringStyleToObject(childrenElement.getAttribute('style')),
        type: childrenElement.getAttribute('data-tuls-type'),
        html:
          childrenElement?.getAttribute('data-tuls-type') !== 'button'
            ? childrenElement.innerHTML
            : childrenElement.innerText,
      };
      if (
        childrenElement?.getAttribute('data-tuls-type') === 'codeFrame' ||
        childrenElement?.getAttribute('data-tuls-type') === 'formFrame'
      ) {
        childrenJson.html = '';
        childrenJson.code = childrenElement.getAttribute('data-code');
      }
      if (childrenElement?.getAttribute('data-tuls-action')) {
        childrenJson.action = childrenElement.getAttribute('data-tuls-action');
      }
      if (childrenElement?.getAttribute('data-tuls-link')) {
        childrenJson.link = childrenElement.getAttribute('data-tuls-link');
      }
      children.push(childrenJson);
    });
    return children;
  }

  columnsToJson(lineElement) {
    const columns = [];
    lineElement.querySelectorAll('.tuls-column').forEach(columnElement => {
      const columnJson = {
        width: columnElement.getAttribute('data-width'),
        style: this.stringStyleToObject(columnElement.getAttribute('style')),
        children: this.childrenToJson(columnElement),
      };
      columns.push(columnJson);
    });
    return columns;
  }

  createConfig() {
    const info = { name: 'Template name', description: 'Description' };
    info.style = this.stringStyleToObject(this.rootElement.getAttribute('style'));
    const lines = [];
    const linesElements = this.rootElement.querySelectorAll('.tuls-line');
    linesElements.forEach(lineElement => {
      const lineJson = {
        style: this.stringStyleToObject(lineElement.getAttribute('style')),
        columns: this.columnsToJson(lineElement),
      };
      lines.push({ line: lineJson });
    });
    const closeButton = this.rootElement.querySelector('.tuls-close');
    info.closeButtonSvg = closeButton.innerHTML;
    info.closeButtonSvg_size = parseFloat(closeButton.style?.width) || 20;
    info.closeButtonSvg_position = closeButton.getAttribute('data-tuls-position');
    console.log(JSON.stringify({ info, lines }));
    return { info, lines };
  }

  addStyles(element, style) {
    if (style) {
      Object.keys(style).map(prop => {
        element.style[prop] = style[prop];
      });
    }
  }

  renderTitle(column, element) {
    const crElement = document.createElement('div');
    crElement.classList = 'tuls-component tuls-title';
    crElement.innerHTML = element.html;
    this.addStyles(crElement, element.style);
    if (this?.editable) {
      crElement.contentEditable = String(this.editable);
    }
    crElement.draggable = String(this.editable);
    crElement.dataset.tulsType = 'title';
    column.appendChild(crElement);
  }

  renderText(column, element) {
    const crElement = document.createElement('div');
    crElement.classList = 'tuls-component tuls-text';
    crElement.innerHTML = element.html;
    this.addStyles(crElement, element.style);
    if (this?.editable) {
      crElement.contentEditable = String(this.editable);
    }
    crElement.draggable = String(this.editable);
    crElement.dataset.tulsType = 'text';
    column.appendChild(crElement);
  }

  renderButton(column, element) {
    const crElement = document.createElement('button');
    const crElementSpan = document.createElement('span');
    crElementSpan.innerHTML = element.html;
    crElement.classList = 'tuls-component tuls-button';
    crElement.appendChild(crElementSpan);
    this.addStyles(crElement, element.style);
    this.addStyles(crElement, { whiteSpace: 'nowrap' });
    if (this?.editable && this.local) {
      crElementSpan.contentEditable = String(this.editable);
      // crElement.querySelectorAll("span").setAttribute("contenteditable", false);
      crElement.querySelectorAll('span').forEach(el => {
        el.setAttribute('contenteditable', true);
      });
    } else {
      crElement.querySelectorAll('span').forEach(el => {
        el.setAttribute('contenteditable', false);
      });
    }
    crElement.dataset.tulsType = 'button';
    if (element.link) {
      crElement.dataset.tulsLink = element.link;
    }
    if (element.action) {
      crElement.dataset.tulsAction = element.action;

      crElement.addEventListener('click', () => {
        if (!this.local) {
          if (element.action === 'close') {
            this.postStatistick({ eventType: 'popup_close' });
          } else if (element.action === 'link' || element.action === 'phone' || element.action === 'button') {
            this.postStatistick({ eventType: 'popup_conversion' });
            if (element.link) {
              window.location.href = element.link;
            }
          }

          this.onCloseModal();
        }
      });
    }
    column.appendChild(crElement);
  }

  renderBackgroundImage(column, element) {
    const crElement = document.createElement('div');
    crElement.classList = 'tuls-component tuls-modal-img';
    crElement.innerHTML = element.html;
    this.addStyles(crElement, element.style);
    crElement.draggable = String(this.editable);
    crElement.dataset.tulsType = 'backgroundImage';
    column.appendChild(crElement);
  }

  renderCodeFrame(column, element) {
    const crElement = document.createElement('div');
    crElement.classList = 'tuls-component tuls-code-frame';
    const code = decodeURIComponent(element.code);
    crElement.innerHTML = code;
    crElement.setAttribute('data-code', element.code);
    this.setInnerHTML(crElement, code);
    this.addStyles(crElement, element.style);
    crElement.contentEditable = false;
    crElement.draggable = String(this.editable);
    crElement.dataset.tulsType = 'codeFrame';
    column.appendChild(crElement);
  }

  renderFormFrame(column, element) {
    const crElement = document.createElement('div');
    crElement.classList = 'tuls-component tuls-form-frame';
    const code = decodeURIComponent(element.code);
    crElement.innerHTML = code;
    crElement.setAttribute('data-code', element.code);
    this.setInnerHTML(crElement, code);
    this.addStyles(crElement, element.style);
    crElement.contentEditable = false;
    crElement.draggable = String(this.editable);
    crElement.dataset.tulsType = 'formFrame';
    column.appendChild(crElement);
  }

  renderChildren(elColumn, childrenElement) {
    if (childrenElement?.style['font-family']) {
      this.fonts = [...this.fonts, childrenElement?.style['font-family']];
    }

    switch (childrenElement.type) {
      case 'title':
        this.renderTitle(elColumn, childrenElement);
        break;
      case 'text':
        this.renderText(elColumn, childrenElement);
        break;
      case 'button':
        this.renderButton(elColumn, childrenElement);
        break;
      case 'backgroundImage':
        this.renderBackgroundImage(elColumn, childrenElement);
        break;
      case 'codeFrame':
        this.renderCodeFrame(elColumn, childrenElement);
        break;
      case 'formFrame':
        this.renderFormFrame(elColumn, childrenElement);
        break;
      default:
        break;
    }
  }

  renderColumn(line, column) {
    const elColumn = document.createElement('div');
    elColumn.classList = 'tuls-column';
    if (this.editable) {
      elColumn.classList.add('tuls-column-empty');
    }
    elColumn.dataset.width = column.width;
    this.addStyles(elColumn, column.style);
    line.appendChild(elColumn);

    column.children.map(children => {
      this.renderChildren(elColumn, children);
      if (children) {
        elColumn.classList.remove('tuls-column-empty');
      }
    });
  }

  renderLine(line) {
    const elLine = document.createElement('div');
    elLine.classList = 'tuls-line';
    this.addStyles(elLine, line.line.style);
    this.rootElement.querySelector('.tuls-inner').appendChild(elLine);

    line.line.columns.map(column => {
      this.renderColumn(elLine, column);
    });
  }

  checkDevice({ editable }) {
    let popupTargetDevice = this.config.info.targetDevice;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (popupTargetDevice === 'Desktop' && !isMobile) {
      this.rootElement.style.display = 'block';
      this.render({ editable });
    } else if (popupTargetDevice === 'Mobile' && isMobile) {
      this.rootElement.style.display = 'block';
      this.render({ editable });
    } else if (popupTargetDevice === 'All') {
      this.rootElement.style.display = 'block';
      this.render({ editable });
    } else {
      this.rootElement.style.display = 'none';
    }
  }

  init({ editable }) {
    this.rootElement.style.display = 'none';

    if (!this.local) {
      if (this.getCookie('tulsPopupClosed')) {
        return;
      }
      const popupTarget = this.config.info.target;

      if (popupTarget === 'x-seconds') {
        let popupTargetSeconds = Number(this.config.info.targetValue);
        setTimeout(() => {
          this.rootElement.style.display = 'block';
          this.render({ editable });
          this.checkDevice({ editable });
        }, popupTargetSeconds * 1000);
      } else if (popupTarget === 'x-percents') {
        let popupTargetPercents = Number(this.config.info.targetValue);
        window.addEventListener('scroll', () => {
          if (!this.popupClosed) {
            const scroll = ((window.scrollY + window.innerHeight) * 100) / document.body.offsetHeight;
            if (scroll >= popupTargetPercents) {
              if (this.rootElement.style.display !== 'block') {
                this.rootElement.style.display = 'block';
                this.render({ editable });
                this.checkDevice({ editable });
              }
            }
          }
        });
      } else if (popupTarget === 'page-path') {
        let popupTargetPath = Number(this.config.info.targetValue);
        if (popupTargetPath === document.location.href) {
          this.rootElement.style.display = 'block';
          this.render({ editable });
          this.checkDevice({ editable });
        }
      } else if (popupTarget === 'leave-page') {
        window.addEventListener('mouseout', () => {
          this.rootElement.style.display = 'block';
          this.render({ editable });
          this.checkDevice({ editable });
        });
      }
    } else {
      this.rootElement.style.display = 'block';
      this.render({ editable });
    }
  }

  getFonts() {
    const newFonts = new Set(this.fonts);
    const googleFontsLink = 'https://fonts.googleapis.com/css?family=';
    newFonts.forEach(font => {
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = `${googleFontsLink}${font}`;
      document.head.appendChild(style);
    });
  }

  render({ editable }) {
    this.initStyles();

    const closeButton = document.createElement('span');
    closeButton.classList = 'tuls-close';
    closeButton.setAttribute('data-tuls-type', 'buttonClosePopup');

    if (this.config.info?.closeButtonSvg && this.config.info.closeButtonSvg !== '') {
      closeButton.style.backgroundImage = 'none';
      closeButton.innerHTML = this.config.info.closeButtonSvg;
      closeButton.setAttribute('data-tuls-position', this.config.info.closeButtonSvg_position || 'top-right');
      closeButton.style.height = `${this.config.info.closeButtonSvg_size}px`;
      closeButton.style.width = `${this.config.info.closeButtonSvg_size}px`;
      // closeButton.querySelector('svg path').style.fill = this.config?.closeButtonSvg_color || '#000';
    }

    // fetch(this.config.closeButtonSvg || 'https://app.ezflow.cc/assets/images/icons/close.svg')
    //   .then(function (response) {
    //     closeButton.style.opacity = '1';
    //     switch (response.status) {
    //       case 200:
    //         return response.text();
    //       case 404:
    //         throw response;
    //       default:
    //         throw response;
    //     }
    //   })
    //   .then(function (code) {
    //     console.log(code);
    //     if (code) {
    //       closeButton.style.backgroundImage = 'none';
    //       closeButton.innerHTML = code;
    //       closeButton.querySelector('svg path').style.fill = '#000';
    //     }
    //   })
    //   .catch(function (response) {
    //     console.log(response);
    //   });

    closeButton.addEventListener('click', () => {
      if (!this.local) {
        this.postStatistick({ eventType: 'popup_close' });
        this.onCloseModal();
      }
    });
    this.rootElement.querySelector('.tuls-inner').innerHTML = '';
    this.rootElement.querySelector('.tuls-inner').appendChild(closeButton);
    if (editable) {
      this.editable = true;
    }
    this.rootElement.classList.add(`tuls-modal--${this.config.info.modalPosition}`);
    this.addStyles(this.rootElement, this.config.info.style);
    // this.rootElement.style.height = `${this.config.info.height}px`;
    // this.rootElement.style.width = `${this.config.info.width}px`;
    this.rootElement.dataset.tulsPosition = this.config.info.position;
    this.config.lines.map(line => {
      this.renderLine(line);
    });

    this.getFonts();
  }
}

// module.exports = {
//   TemplateBuilder,
// };

export default TemplateBuilder;
