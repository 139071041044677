import React, { useState } from 'react';

import './Accordion.scss';

export const Accordion = ({ title, prevIsShow, children }) => {
  const [isShow, setIsShow] = useState(prevIsShow || false);

  const toggleCollapse = () => setIsShow(_isShow => !_isShow);

  return (
    <div className="accordion-item">
      <a href="#s" className={`accordion-head ${!isShow ? 'collapsed' : ''}`} onClick={toggleCollapse}>
        <h6 className="title">{title}</h6>
        <span className="accordion-icon" />
      </a>
      <div className={`accordion-body ${!isShow ? 'collapse' : ''}`}>
        <div className="accordion-inner">{children}</div>
      </div>
    </div>
  );
};
