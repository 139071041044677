export const initConfig = {
  info: {
    id: 0,
    name: 'Template name',
    description: 'Description',
    position: 'middleCenter',
    style: { height: '400px', width: '300px' },
    target: 'x-seconds',
    targetSeconds: 0,
    targetPercents: 0,
    targetPagePath: '',
    targetDevice: 'All',
    preview: 'https://app.ezflow.cc/assets/images/placeholders/forest.jpg',
  },
  lines: [
    {
      line: {
        style: {},
        columns: [{ width: '4', style: {}, children: [] }],
      },
    },
  ],
};
