import React, { useState, useEffect } from 'react';
import { useRequest } from 'estafette';
import { popup } from 'libs/http/api/popup';
import Line from '../Line/Line';
import SettingsElement from '../SettingsElement/SettingsElement';
import PositionSelector from '../PositionSelector/PositionSelector';
import TemplateRadio from '../TemplateRadio/TemplateRadio';
import { DataPreloader } from 'components/DataPreloader/DataPreloader';

const Sidebar = ({
  onDragstartHandler,
  onFocusElement,
  onDragstartHandlerComponent,
  onSave,
  activeElement,
  activeElementType,
  onCloseSettings,
  onRemoveElement,
  loadTemplate,
  popupTemplate,
  loading,
}) => {
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const [idTemplate, setIdTemplate] = useState(null);
  const [step, setStep] = useState(0);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [modalPosition, setModalPosition] = useState(
    popupTemplate?.popup?.config?.info?.modalPosition || 'middleCenter',
  );
  const [activeTemplate, setActiveTemplate] = useState(popupTemplate?.uid);
  const [targetValue, setTargetValue] = useState('x-seconds');
  const [targetDevice, setTargetDevice] = useState('All');
  const [isSave, setIsSave] = useState(false);
  const [targetValueOptions] = useState([
    {
      value: 'x-seconds',
      label: 'Seconds',
      inputType: 'number',
      placeholder: 0,
    },
    {
      value: 'x-percents',
      label: 'Percents',
      inputType: 'number',
      placeholder: 0,
    },
    {
      value: 'page-path',
      label: 'Link page',
      inputType: 'text',
      placeholder: 'http://...',
    },
  ]);
  const [targetOptions] = useState([
    {
      value: 'x-seconds',
      name: 'In x seconds after loading',
    },
    {
      value: 'x-percents',
      name: 'After x-percent scrolling of the page',
    },
    {
      value: 'page-path',
      name: 'Special page path',
    },
    {
      value: 'leave-page',
      name: 'When trying to leave the page',
    },
  ]);
  const [target, setTarget] = useState('x-seconds');

  const onChangeStep = type => {
    window.scrollTo({ top: 0 });

    if (type === 'minus' && step > 0) {
      setStep(prevStep => prevStep - 1);
    } else if (type === 'plus' && step < 3) {
      setStep(prevStep => prevStep + 1);
    }
  };

  const onChangePositionModal = ({ target }) => {
    document.getElementById('tuls-modal').classList.remove('tuls-modal--topLeft');
    document.getElementById('tuls-modal').classList.remove('tuls-modal--topCenter');
    document.getElementById('tuls-modal').classList.remove('tuls-modal--topRight');
    document.getElementById('tuls-modal').classList.remove('tuls-modal--middleLeft');
    document.getElementById('tuls-modal').classList.remove('tuls-modal--middleCenter');
    document.getElementById('tuls-modal').classList.remove('tuls-modal--middleRight');
    document.getElementById('tuls-modal').classList.remove('tuls-modal--bottomLeft');
    document.getElementById('tuls-modal').classList.remove('tuls-modal--bottomCenter');
    document.getElementById('tuls-modal').classList.remove('tuls-modal--bottomRight');
    document.getElementById('tuls-modal').classList.add(`tuls-modal--${target.value}`);
    setModalPosition(target.value);
  };

  const onChangeTemplate = ({ target }) => {
    setActiveTemplate(target.value);
    loadTemplate(target.value);
  };

  const onChangeTarget = ({ target }) => {
    setTargetValue(targetValueOptions?.find(item => item.value === target.value)?.placeholder);
    setTarget(target.value);
  };

  const onChangeTargetValue = ({ target }) => setTargetValue(target.value);

  const onChangeTargetDevice = ({ target }) => setTargetDevice(target.value);

  const onChangeName = ({ target }) => setName(target.value);

  const onChangeDescription = ({ target }) => setDescription(target.value);

  const onSaveToggle = () => setIsSave(prevIsSave => !prevIsSave);

  const onSaveMid = type => {
    onSave({ type, name, description, target, targetValue, targetDevice, modalPosition });
  };

  useEffect(() => {
    if (popupTemplate?.popup) {
      setName(popupTemplate.popup.config.info?.title);
      setDescription(popupTemplate.popup.config.info?.description);
      setModalPosition(popupTemplate.popup.config.info?.modalPosition);
      setTargetValue(popupTemplate.popup.config.info?.targetValue);
      setTargetDevice(popupTemplate.popup.config.info?.targetDevice);
      setTarget(popupTemplate.popup.config.info?.target);
    }
  }, [popupTemplate]);

  return (
    <>
      <div className="nk-sidebar side-builder">
        <div className="nk-sidebar-body">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu">
              <div className="simplebar-wrapper">
                <div id="accordion" className="accordion">
                  <div className="accordion-body">
                    <div className="accordion-inner">
                      <div className="btn-group side-steps">
                        <button type="button" className={`btn${step === 0 ? ' active' : ''}`} disabled>
                          Settings
                        </button>
                        <button type="button" className={`btn${step === 1 ? ' active' : ''}`} disabled>
                          Layout
                        </button>
                        <button type="button" className={`btn${step === 2 ? ' active' : ''}`} disabled>
                          Targeting
                        </button>
                      </div>
                    </div>
                  </div>

                  {!activeElementType ? (
                    <>
                      {step === 0 && (
                        <div className="accordion-body">
                          <div className="accordion-inner">
                            <div className="form-group mt-4">
                              <label className="form-label">Name</label>
                              <input
                                type="text"
                                required
                                className="form-control"
                                onChange={onChangeName}
                                value={name}
                              />
                            </div>

                            <div className="form-group mt-4">
                              <label className="form-label">Description</label>
                              <textarea
                                required
                                className="form-control"
                                onChange={onChangeDescription}
                                value={description}
                              />
                            </div>

                            <div className="form-group mt-4">
                              <label className="form-label">Modal position</label>
                              <PositionSelector onChangePosition={onChangePositionModal} defaultValue={modalPosition} />
                            </div>
                          </div>
                        </div>
                      )}

                      {step === 1 && (
                        <>
                          <div className="accordion-item">
                            <div className="accordion-head">
                              <h6 className="title">Rows</h6>
                            </div>
                            <div className="accordion-body">
                              <div className="accordion-inner">
                                <Line columns={[4]} onDragstartHandler={onDragstartHandler} onClick={onFocusElement} />
                                <Line
                                  columns={[1, 3]}
                                  onDragstartHandler={onDragstartHandler}
                                  onClick={onFocusElement}
                                />
                                <Line
                                  columns={[2, 2]}
                                  onDragstartHandler={onDragstartHandler}
                                  onClick={onFocusElement}
                                />
                                <Line
                                  columns={[3, 1]}
                                  onDragstartHandler={onDragstartHandler}
                                  onClick={onFocusElement}
                                />
                                <Line
                                  columns={[1, 1, 2]}
                                  onDragstartHandler={onDragstartHandler}
                                  onClick={onFocusElement}
                                />
                                <Line
                                  columns={[1, 2, 1]}
                                  onDragstartHandler={onDragstartHandler}
                                  onClick={onFocusElement}
                                />
                                <Line
                                  columns={[2, 1, 1]}
                                  onDragstartHandler={onDragstartHandler}
                                  onClick={onFocusElement}
                                />
                                <Line
                                  columns={[1, 1, 1, 1]}
                                  onDragstartHandler={onDragstartHandler}
                                  onClick={onFocusElement}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <div className="accordion-head">
                              <h6 className="title">Elements</h6>
                            </div>
                            <div className="accordion-body">
                              <div className="accordion-inner">
                                <div
                                  draggable="true"
                                  onDragStart={onDragstartHandlerComponent}
                                  data-tuls-type="title"
                                  className="tuls-component tuls-head"
                                  style={{
                                    'font-size': '26px',
                                    'font-weight': '600',
                                    'font-family': 'Arial',
                                    color: 'rgb(0, 0, 0)',
                                  }}
                                >
                                  Heading text
                                </div>

                                <div
                                  draggable="true"
                                  onDragStart={onDragstartHandlerComponent}
                                  data-tuls-type="text"
                                  className="tuls-component tuls-description"
                                  style={{
                                    'font-size': '16px',
                                    'font-weight': '400',
                                    'font-family': 'Arial',
                                    color: 'rgb(0, 0, 0)',
                                  }}
                                >
                                  Description text
                                </div>

                                <button
                                  draggable="true"
                                  onDragStart={onDragstartHandlerComponent}
                                  data-tuls-type="button"
                                  className="tuls-component tuls-modal-button"
                                  style={{
                                    'font-size': '18px',
                                    'font-family': 'Arial',
                                    'font-weight': '500',
                                    color: 'rgb(255, 255, 255)',
                                    'background-color': 'rgb(133, 79, 255)',
                                    'border-radius': '5px',
                                    padding: '5px 40px',
                                    width: '100%',
                                  }}
                                >
                                  <span>Button</span>
                                </button>

                                <div
                                  draggable="true"
                                  onDragStart={onDragstartHandlerComponent}
                                  data-tuls-type="backgroundImage"
                                  className="tuls-component tuls-modal-img"
                                  style={{
                                    'background-image':
                                      'url("https://res.cloudinary.com/fosh/image/upload/v1641890796/popups/qvlfvmcshsrqzbjth3it.png")',
                                    height: '100px',
                                    left: '0%',
                                    top: '0px',
                                    'background-repeat': 'no-repeat',
                                    'background-color': 'transparent',
                                  }}
                                />

                                <div
                                  draggable="true"
                                  onDragStart={onDragstartHandlerComponent}
                                  data-tuls-type="codeFrame"
                                  className="tuls-component tuls-code-frame"
                                />

                                <div
                                  draggable="true"
                                  onDragStart={onDragstartHandlerComponent}
                                  data-tuls-type="formFrame"
                                  className="tuls-component tuls-form-frame"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {step === 2 && (
                        <div className="accordion-body">
                          <div className="accordion-inner">
                            <div className="form-group mt-4">
                              <label className="form-label">Select target</label>
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <select value={target} onChange={onChangeTarget} className="form-control">
                                    {targetOptions.map((option, key) => (
                                      <option key={key} value={option.value}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {target && (
                              <>
                                {targetValueOptions?.find(item => item.value === target) && (
                                  <div className="form-group mt-4">
                                    <label className="form-label">
                                      {targetValueOptions.find(item => item.value === target).label}
                                    </label>
                                    <input
                                      type={targetValueOptions.find(item => item.value === target).inputType}
                                      required
                                      className="form-control"
                                      onChange={onChangeTargetValue}
                                      placeholder={targetValueOptions.find(item => item.value === target).placeholder}
                                      value={targetValue}
                                    />
                                  </div>
                                )}
                              </>
                            )}

                            <div className="form-group mt-4">
                              <label className="form-label">Select device</label>
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <select value={targetDevice} onChange={onChangeTargetDevice} className="form-control">
                                    <option value="All">All</option>
                                    <option value="Mobile">Mobile</option>
                                    <option value="Desktop">Desktop</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="flex navigation">
                        <DataPreloader loading={loading}>
                          <div>
                            {step !== 0 && (
                              <button className="btn btn-wider btn-primary" onClick={() => onChangeStep('minus')}>
                                <em className="icon ni ni-arrow-left" />
                                <span>Prev</span>
                              </button>
                            )}
                          </div>

                          <div className="text-right">
                            {step < 2 ? (
                              <button className="btn btn-wider btn-primary" onClick={() => onChangeStep('plus')}>
                                <span>Next</span>
                                <em className="icon ni ni-arrow-right" />
                              </button>
                            ) : (
                              <div className="dropdown">
                                <button
                                  className="btn btn-save dropdown-toggle"
                                  type="button"
                                  dataToggle="dropdown"
                                  onClick={onSaveToggle}
                                >
                                  Save as <em className="icon ni ni-chevron-down ml-1" />
                                </button>
                                <div
                                  className={`dropdown-menu ${isSave ? 'show' : ''}`}
                                  style={{ 'min-width': '109px' }}
                                >
                                  <ul className="link-list-opt">
                                    <li>
                                      <a href="#r" onClick={() => onSaveMid('popup')}>
                                        <span>popup</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#r" onClick={() => onSaveMid('template')}>
                                        <span>template</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              // <button
                              //     className="btn btn-wider btn-primary"
                              //     onClick={onSaveTemplateMid}
                              //     // disabled={loadingSave || loadingUploadImage}
                              // >
                              //     <span>Save</span>
                              //     <em className="icon ni ni-check" />
                              // </button>
                            )}
                          </div>
                        </DataPreloader>
                      </div>
                    </>
                  ) : (
                    <div className="accordion-item">
                      <div className="accordion-head row d-flex">
                        <h6 className="title col-md-8">Settings</h6>
                        <div className="text-right col-md-4">
                          <em className="icon ni ni-cross close-settings" onClick={onCloseSettings} />
                        </div>
                      </div>
                      <div className="accordion-body">
                        <div className="accordion-inner">
                          <SettingsElement
                            activeElement={activeElement}
                            activeElementType={activeElementType}
                            onRemoveElement={onRemoveElement}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
