import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import { useRequest } from 'estafette';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
// import { configWebChat } from './configs/config.webchat';
import { setUserInfo } from 'features/userSlice';
import { user } from 'libs/http/api/user';
import { extraFields } from 'libs/http/api/extraFields';
import { DataPreloader, Header } from 'components';
import { ConnectToken } from './ConnectToken';
import { ChannelInfo } from './ChannelInfo';
import { SettingsWebChat } from './SettingsWebChat';
import { useGTM } from '../../hooks/useGTM';

import './Settings.scss';
import { Loader } from 'react-feather';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { ToolsDropdown } from 'components/FlowArea/components/ToolsDropdown/ToolsDropdown';
import { ModalConfirm } from 'components/ModalConfirm/ModalConfirm';

const tabs = [
  {
    name: 'Main',
    list: [
      {
        name: 'Preferences',
        id: 'preferences',
        icon: <em className="icon ni ni-user"></em>,
      },
      {
        name: 'Custom Data Fields',
        id: 'custom-data-fields',
        icon: <i className="fab fa-elementor"></i>,
      },
    ],
  },
  {
    name: 'Channels',
    list: [
      {
        name: 'Web',
        id: 'web',
        icon: <i className="fas fa-comment-dots" />,
      },
      {
        name: 'Facebook',
        id: 'facebook',
        icon: <i className="fab fa-facebook" />,
      },
      {
        name: 'SMS',
        id: 'sms',
        icon: <i className="fas fa-comment-alt" />,
      },
      {
        name: 'Telegram',
        id: 'telegram',
        icon: <i className="fab fa-telegram" />,
      },
      {
        name: 'Instagram',
        id: 'instagram',
        icon: <i className="fab fa-instagram-square" />,
        disable: true,
      },
      {
        name: 'Email',
        id: 'email',
        icon: <i className="fas fa-envelope-square" />,
        disable: true,
      },
      {
        name: 'WhatsApp',
        id: 'whatsapp',
        icon: <i className="fab fa-whatsapp-square" />,
        disable: true,
      },
    ],
  },
];

export const Settings = () => {
  const dispatch = useDispatch();

  const { register, handleSubmit: handleSubmitUserInfo, watch, getValues, setValue } = useForm();
  const { sendEvent } = useGTM();

  const { tab, subtab } = useParams();

  const { request: requestUserInfo, loading: loadingUserInfo, data: dataUserInfo } = useRequest({ loading: true });
  const { request: requestUpdateUserInfo, loading: loadingUpdateUserInfo } = useRequest({ loading: false });
  const { request: requestRemoveExtraFields, loading: loadingRemoveExtraFields } = useRequest({ loading: false });

  const {
    request: requestGetExtraFields,
    loading: loadingGetExtraFields,
    data: dataExtraFields,
  } = useRequest({ loading: false });
  const { request: requestAddExtraFields, loading: loadingAddExtraFields } = useRequest({ loading: false });

  const [activeTab, setActiveTab] = useState('preferences');
  const [apiToken, setApiToken] = useState(null);
  const [facebookPages, setFacebookPages] = useState(null);
  const [facebookPagesLoading, setFacebookPagesLoading] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [removeExtraFieldId, setRemoveExtraFieldId] = useState(null);

  const onNewCustomField = () => {
    const newVar = String(Math.random()).replace('.', '');
    setCustomFields(prevCards => [...prevCards, { id: newVar }]);
  };

  const getUserInfo = async () => {
    const data = await requestUserInfo(user.getInfo.action());
    dispatch(setUserInfo(data));
    setApiToken(data.user.api_token.token_value);
  };

  const generateApiToken = () => setApiToken(uuidv4());

  const updateUserInfo = async data => {
    await requestUpdateUserInfo(user.updateUserData.action(data));
    getUserInfo();
  };

  const getExtraFields = async () => {
    requestGetExtraFields(extraFields.getFields.action());
  };

  const onSubmitUserInfo = async data => {
    let newData = JSON.parse(JSON.stringify(dataUserInfo.user));
    if (apiToken !== dataUserInfo.user.api_token.token_value) {
      newData.api_token.token_value = apiToken;
      newData.api_token.token_creation_date = moment().toISOString();
    }
    newData = { ...newData, ...data };
    updateUserInfo(newData);
  };

  const onRemoveChannel = async channel => {
    const newUserData = JSON.parse(JSON.stringify(dataUserInfo.user));
    newUserData.bots[channel] = null;
    updateUserInfo(newUserData);
  };

  const onAddChannel = async (channel, token) => {
    const newUserData = JSON.parse(JSON.stringify(dataUserInfo.user));
    if (!newUserData?.bots) {
      newUserData.bots = {};
    }
    newUserData.bots[channel] = {
      token,
      active: true,
    };
    updateUserInfo(newUserData);
  };

  const onConnectFacebook = async e => {
    e.preventDefault();

    const res = await fetch('https://bot.api.ezflow.cc/api/facebook/authorization/url', {
      headers: { authorization: dataUserInfo.user.id },
    });
    const { url } = await res.json();
    window.open(url, '_blank', 'Connect to Facebook', 'left=100,top=100,width=320,height=320,popup=yes');
  };

  const getFacebookPages = async () => {
    setFacebookPagesLoading(true);

    try {
      const res = await fetch('https://bot.api.ezflow.cc/api/facebook/pages', {
        headers: { authorization: dataUserInfo.user.id },
      });
      const pages = await res.json();
      setFacebookPages(pages);
    } catch (err) {
      console.log(err);
    }

    setFacebookPagesLoading(false);
  };

  const onSaveExtraField = id => {
    if (getValues(`field_key--${id}`) !== '' && getValues(`field_title--${id}`) !== '') {
      const params = {
        id,
        field_key: getValues(`field_key--${id}`),
        field_title: getValues(`field_title--${id}`),
        field_type: getValues(`field_type--${id}`),
      };

      if (!dataExtraFields?.user_extra_fields?.find(el => el.id === id)) {
        requestAddExtraFields(extraFields.addFields.action([params]));
      } else {
        requestAddExtraFields(extraFields.patchField.action(params));
      }
    } else {
      alert('Fill every fields, please');
    }
  };

  const onRemoveExtraField = async () => {
    setIsModalConfirm(false);
    await requestRemoveExtraFields(extraFields.removeField.action(removeExtraFieldId));
    getExtraFields();
  };

  const onRemoveExtraFieldModal = id => {
    setRemoveExtraFieldId(id);
    setIsModalConfirm(true);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    setActiveTab(tab);

    if (tab === 'custom-data-fields') {
      getExtraFields();
    }
  }, [tab]);

  useEffect(() => {
    if (dataUserInfo?.user) {
      getFacebookPages();
    }
  }, [dataUserInfo]);

  useEffect(() => {
    if (dataExtraFields?.user_extra_fields && dataExtraFields?.user_extra_fields.length > 0) {
      setCustomFields(dataExtraFields.user_extra_fields);
    }
  }, [dataExtraFields]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === 'change' && name.includes('field_key--')) {
        setValue(name, getValues(name).replace(/[^A-Za-z,\-,_]/gi, ''));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div className="nk-main">
      <div className="nk-wrap">
        <Header />

        <DataPreloader loading={loadingUserInfo || facebookPagesLoading}>
          <div className="nk-content">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body sittings-page">
                  <div className="side-menu">
                    {tabs.map(section => (
                      <ul key={section.name}>
                        <li>
                          <h5 className="section-name">{section.name}</h5>

                          <ul>
                            {section.list.map(el => (
                              <li className="nav-item" key={el.id}>
                                <Link
                                  className={`nav-link${activeTab === el.id ? ' active' : ''}${
                                    el.disable ? ' disable' : ''
                                  }`}
                                  data-toggle="tab"
                                  to={{ pathname: `/settings/${el.id}` }}
                                  onClick={e => (el.disable ? e.preventDefault() : true)}
                                >
                                  <span className="icon">{el.icon}</span>
                                  <span>{el.name}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    ))}
                  </div>

                  {!loadingUserInfo && (
                    <>
                      {activeTab === 'preferences' && (
                        <div className="right-section card">
                          <div className="card-inner">
                            <form onSubmit={handleSubmitUserInfo(onSubmitUserInfo)}>
                              <div className="form-group mt-4">
                                <label className="form-label">Email</label>
                                <div className="form-control-input">
                                  <input
                                    type="text"
                                    required
                                    className="form-control"
                                    name="email"
                                    defaultValue={dataUserInfo.user.email}
                                    disabled
                                    // {...register('email')}
                                  />
                                </div>
                              </div>

                              <div className="form-group mt-4">
                                <label className="form-label">Name</label>
                                <div className="form-control-input">
                                  <input
                                    type="text"
                                    required
                                    className="form-control"
                                    name="name"
                                    defaultValue={dataUserInfo.user.name}
                                    {...register('name')}
                                  />
                                </div>
                              </div>

                              <div className="form-group mt-4">
                                <label className="form-label">Phone</label>
                                <div className="form-control-wrap">
                                  <div className="form-icon form-icon-right">
                                    {dataUserInfo.user.verified_phone ? (
                                      <i className="icon fas fa-check" title="Verified"></i>
                                    ) : (
                                      <i className="icon fas fa-times" title="Not verified"></i>
                                    )}
                                  </div>

                                  <input
                                    type="number"
                                    required
                                    className="form-control"
                                    name="phone"
                                    defaultValue={dataUserInfo.user.phone}
                                    {...register('phone')}
                                  />
                                </div>
                              </div>

                              <div className="form-group mt-4">
                                <label className="form-label">API token</label>
                                <div className="form-control-wrap">
                                  <div
                                    className="form-icon form-icon-right generate-token-icon"
                                    onClick={generateApiToken}
                                  >
                                    <i className="fas fa-sync"></i>
                                  </div>

                                  <input
                                    type="text"
                                    required
                                    className="form-control"
                                    name="token_value"
                                    defaultValue={apiToken}
                                    disabled
                                    // {...register('token_value')}
                                  />
                                </div>
                              </div>

                              <div className="text-right">
                                <DataPreloader loading={loadingUpdateUserInfo}>
                                  <input type="submit" className="btn btn-primary" value="Submit" />
                                </DataPreloader>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}

                      {activeTab === 'custom-data-fields' && (
                        <div className="right-section card">
                          <div
                            className="card-inner"
                            style={{
                              display: 'grid',
                              gridTemplateColumns: 'repeat(4, 1fr)',
                              gridRowGap: '16px',
                              gridColumnGap: '16px',
                            }}
                          >
                            <DataPreloader loading={loadingGetExtraFields}>
                              {customFields &&
                                customFields.length > 0 &&
                                customFields.map(item => {
                                  return (
                                    <div
                                      class="panel panel-default"
                                      style={{ padding: '12px', background: '#f5f6fa', borderRadius: '3px' }}
                                      key={item.id}
                                    >
                                      <div class="panel-body">
                                        <div className="form-group">
                                          <label className="form-label">Key</label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              name={`field_key--${item.id}`}
                                              {...register(`field_key--${item.id}`)}
                                              defaultValue={item.field_key}
                                              className="form-control"
                                              required
                                            />
                                          </div>
                                        </div>

                                        <div className="form-group">
                                          <label className="form-label">Title</label>
                                          <div className="form-control-wrap">
                                            <input
                                              type="text"
                                              name={`field_title--${item.id}`}
                                              {...register(`field_title--${item.id}`)}
                                              defaultValue={item.field_title}
                                              className="form-control"
                                              required
                                            />
                                          </div>
                                        </div>

                                        <div className="form-group">
                                          <label className="form-label">Type</label>
                                          <div className="form-control-wrap">
                                            <Select
                                              name={`field_type--${item.id}`}
                                              {...register(`field_type--${item.id}`)}
                                              // value={{
                                              //   value: getValues('checker_function'),
                                              //   label: dataFunction.functions.find(el => el.uid === getValues('function'))?.display_name,
                                              // }}
                                              defaultValue={{
                                                label: item.field_type,
                                                value: item.field_type,
                                              }}
                                              options={[
                                                {
                                                  label: 'int',
                                                  value: 'int',
                                                },
                                                {
                                                  label: 'string',
                                                  value: 'string',
                                                },
                                                {
                                                  label: 'float',
                                                  value: 'float',
                                                },
                                                {
                                                  label: 'bool',
                                                  value: 'bool',
                                                },
                                              ]}
                                              onChange={selected => setValue(`field_type--${item.id}`, selected.value)}
                                              isSearchable={false}
                                              required
                                            />
                                          </div>
                                        </div>

                                        <input
                                          type="hidden"
                                          name={`field_id--${item.id}`}
                                          {...register(`field_id--${item.id}`)}
                                          defaultValue={item.id}
                                          className="form-control"
                                          required
                                        />
                                      </div>

                                      <div style={{ display: 'flex', justifyContent: 'end' }}>
                                        <div>
                                          <button
                                            className="btn btn-secundary btn-manage btn-small"
                                            onClick={() => onRemoveExtraFieldModal(item.id)}
                                            disabled={loadingAddExtraFields || loadingRemoveExtraFields}
                                          >
                                            Remove
                                          </button>
                                        </div>
                                        <div>
                                          <button
                                            className="btn btn-outline-gray btn-small"
                                            onClick={() => onSaveExtraField(item.id)}
                                            disabled={loadingAddExtraFields || loadingRemoveExtraFields}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}

                              <button
                                type="button"
                                className="btn btn-primary add-node-button"
                                onClick={onNewCustomField}
                                style={{ height: '36px' }}
                              >
                                <em className="icon ni ni-plus" /> Add custom field
                              </button>
                            </DataPreloader>
                          </div>
                        </div>
                      )}

                      {activeTab === 'telegram' && (
                        <div className="right-section">
                          <h4 className="title nk-block-title mb-5">
                            <i className="fab fa-telegram icon" /> Telegram
                          </h4>

                          <div className="card">
                            <div className="card-inner">
                              {dataUserInfo.user.bots?.telegram?.token ? (
                                <ChannelInfo
                                  data={dataUserInfo.user.bots.telegram}
                                  onRemoveChannel={onRemoveChannel}
                                  channel="telegram"
                                  loading={loadingUpdateUserInfo}
                                />
                              ) : (
                                <>
                                  {subtab !== 'connect-token' ? (
                                    <div className="social-connect">
                                      <div className="social-image social-image--telegram mb-4" />
                                      <h4 className="mt-4 mb-4">Establish customer communication on Telegram</h4>
                                      <div className="description mt-4 mb-4">
                                        Build powerful omnichannel conversation flows. Create a new campaign and reach a
                                        multi-million Telegram audience. Start productive conversations with your
                                        customers right now
                                      </div>

                                      <Link
                                        className="btn btn-primary btn-lg mt-4"
                                        onClick={() => sendEvent('connect_telegram')}
                                        to={{ pathname: `/settings/telegram/connect-token` }}
                                      >
                                        Connect
                                      </Link>
                                    </div>
                                  ) : (
                                    <ConnectToken
                                      channel="telegram"
                                      onAddChannel={onAddChannel}
                                      loading={loadingUpdateUserInfo}
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {activeTab === 'facebook' && (
                        <div className="right-section">
                          <h4 className="title nk-block-title mb-5">
                            <i className="fab fa-facebook icon" /> Facebook
                          </h4>

                          <div className="card">
                            <div className="card-inner">
                              {subtab === 'error' ? (
                                <div className="mb-4 text-center">
                                  <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger" />
                                  <h4 className="nk-modal-title">Error</h4>
                                  <div className="nk-modal-action">
                                    <Link
                                      to={{ pathname: `/settings/facebook` }}
                                      className="btn btn-lg btn-mw btn-primary"
                                    >
                                      Back to Facebook connect page
                                    </Link>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {facebookPages && facebookPages.length > 0 ? (
                                    <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                                      <div className="datatable-wrap my-3">
                                        <table className="table info">
                                          <tbody>
                                            {facebookPages.map(el => (
                                              <tr>
                                                <td>
                                                  <strong>{el?.name}:</strong>
                                                </td>
                                                <td>{el?.token}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {subtab !== 'connect-token' ? (
                                        <div className="social-connect">
                                          <div className="social-image social-image--facebook mb-4" />
                                          <h4 className="mt-4 mb-4">Establish customer communication on Facebook</h4>
                                          <div className="description mt-4 mb-4">
                                            Build powerful omnichannel conversation flows. Create a new campaign and
                                            reach a multi-million Facebook audience. Start productive conversations with
                                            your customers right now
                                          </div>

                                          <a
                                            href="#s"
                                            className="btn btn-primary btn-lg mt-4"
                                            onClick={onConnectFacebook}
                                          >
                                            Connect
                                          </a>
                                        </div>
                                      ) : (
                                        <ConnectToken
                                          channel="facebook"
                                          onAddChannel={onAddChannel}
                                          loading={loadingUpdateUserInfo}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {activeTab === 'sms' && (
                        <div className="right-section">
                          <h4 className="title nk-block-title mb-5">
                            <i className="fas fa-comment-alt icon" /> Sms
                          </h4>

                          <div className="card">
                            <div className="card-inner">
                              {dataUserInfo.user.bots?.sms?.token ? (
                                <ChannelInfo
                                  data={dataUserInfo.user.bots.sms}
                                  onRemoveChannel={onRemoveChannel}
                                  channel="sms"
                                  loading={loadingUpdateUserInfo}
                                />
                              ) : (
                                <>
                                  {subtab !== 'connect-token' ? (
                                    <div className="social-connect">
                                      <div className="social-image social-image--sms mb-4" />
                                      <h4 className="mt-4 mb-4">Establish customer communication on SMS</h4>
                                      <div className="description mt-4 mb-4">
                                        Build powerful omnichannel conversation flows. Create a new campaign and reach
                                        your audience via SMS. Start productive conversations with your customers right
                                        now
                                      </div>

                                      <Link
                                        className="btn btn-primary btn-lg mt-4"
                                        onClick={() => sendEvent('connect_sms')}
                                        to={{ pathname: `/settings/sms/connect-token` }}
                                      >
                                        Connect
                                      </Link>
                                    </div>
                                  ) : (
                                    <ConnectToken
                                      channel="sms"
                                      onAddChannel={onAddChannel}
                                      loading={loadingUpdateUserInfo}
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {activeTab === 'web' && (
                        <div className="right-section">
                          <h4 className="title nk-block-title mb-5">
                            <i className="fas fa-comment-dots icon" /> Web
                          </h4>

                          <div className="card">
                            <div className="card-inner">
                              <SettingsWebChat />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DataPreloader>
      </div>

      {isModalConfirm && (
        <ModalConfirm>
          <div className="nk-modal-action mt-5">
            <button className="btn btn-lg btn-mw btn-light mr-1" onClick={() => setIsModalConfirm(false)}>
              Cancel
            </button>
            <button className="btn btn-lg btn-mw btn-danger ml-1" onClick={onRemoveExtraField}>
              Delete
            </button>
          </div>
        </ModalConfirm>
      )}
    </div>
  );
};
