import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRequest } from 'estafette';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { user } from 'libs/http/api/user';
import { DataPreloader, Modal } from 'components';

import logo from './../../assets/images/logo_main.svg';
import './SignupForm.scss';

export const SignupForm = () => {
  const { request: requestSignupUser, loading: loadingSignupUser } = useRequest({});

  const { register, handleSubmit: handleSubmitMessage } = useForm();

  const [isSuccessModal, setIsSuccessModal] = useState(false);

  const onSubmitForm = async data => {
    await requestSignupUser(
      user.signupUser.action({
        name: data.name,
        email: data.email,
        phone: data.phone.replace(/[^\d]/g, ''),
        password: data.password,
      }),
    );

    setIsSuccessModal(true);
    setTimeout(() => {
      window.location.href = '/signin';
    }, 5000);
  };

  return (
    <>
      <div className="brand-logo pb-5">
        <a href="/" className="logo-link">
          <img src={logo} alt="" />
        </a>
      </div>
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title">Register</h5>
          <div className="nk-block-des">
            <p>Create New Ezflow Account</p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmitMessage(onSubmitForm)}>
        <div className="form-group">
          <label className="form-label" htmlFor="name">
            Name
          </label>
          <div className="form-control-wrap">
            <input
              type="text"
              className="form-control form-control-lg"
              id="name"
              placeholder="Your first and last name"
              name="name"
              autoComplete="false"
              {...register('name')}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <div className="form-control-wrap">
            <input
              type="email"
              className="form-control form-control-lg"
              id="email"
              placeholder="Your email address"
              name="email"
              autoComplete="false"
              {...register('email')}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="phone">
            Phone
          </label>
          <div className="form-control-wrap">
            <InputMask
              className="form-control form-control-lg"
              id="phone"
              placeholder="Your personal phone number"
              name="phone"
              autoComplete="false"
              minLength="11"
              mask="+7\ 999 9999 999"
              maskChar=" "
              {...register('phone')}
              required
            />
            {/* <input
              type="number"
              className="form-control form-control-lg"
              id="phone"
              placeholder="Your personal phone number"
              name="phone"
              autoComplete="false"
              minLength="11"
              {...register('phone')}
              required
            /> */}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="password">
            Password
          </label>
          <div className="form-control-wrap">
            <input
              type="password"
              className="form-control form-control-lg"
              id="password"
              placeholder="Your password"
              name="password"
              autoComplete="false"
              {...register('password')}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <div className="custom-control custom-control-xs custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="agreeCheckbox"
              {...register('agreeCheckbox')}
              required
            />
            <label className="custom-control-label" htmlFor="agreeCheckbox">
              I agree with ezFlow{' '}
              <a tabIndex="-1" href="#s">
                Privacy Policy
              </a>{' '}
              &amp;{' '}
              <a tabIndex="-1" href="#s">
                {' '}
                Terms.
              </a>
            </label>
          </div>
        </div>
        <div className="form-group">
          <DataPreloader loading={loadingSignupUser}>
            <button type="submit" className="btn btn-lg btn-primary btn-block">
              Register
            </button>
          </DataPreloader>
        </div>
      </form>

      <div className="form-note-s2 pt-4">
        {' '}
        Already have an account ?{' '}
        <Link to={{ pathname: '/signin' }} className="fs-13">
          <strong>Sign in instead</strong>
        </Link>
      </div>

      {isSuccessModal && (
        <Modal title="Success">
          <div className="modal-body modal-body-lg text-center">
            <div className="nk-modal">
              <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
              <h4 className="nk-modal-title">Congratulations!</h4>
              <div className="nk-modal-text">
                <div className="caption-text">You've successfully registered</div>
                <span className="sub-text-sm">In 5 seconds you'll be redirected on login page</span>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
