import React, { useState, useEffect } from 'react';
import { BlockPicker } from 'react-color';

import './CloseButtonColorPicker.scss';

function componentToHex(c) {
  const hex = Number(c).toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

function rgbToHex(r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function colorFormat(color) {
  let newColor = null;
  if (color && color.includes('rgb')) {
    newColor = rgbToHex(...color.match(/rgb\((.*)\)/)[1].split(','));
  }
  return newColor || color || '#000000';
}

const CloseButtonColorPicker = ({ element, defaultValue, label, styleName, onChangeColor }) => {
  const [value, setValue] = useState(colorFormat(defaultValue));
  const [isPalette, setIsPalette] = useState(false);

  const onHandleColor = obj => {
    if (onChangeColor) {
      onChangeColor(obj);
    }

    const closeButton = document.querySelector('#tuls-modal .tuls-close svg path');

    closeButton.style[styleName] = obj.hex;
    document.querySelectorAll('.close-buttons div').forEach(el => {
      el.querySelector('svg path').style.fill = obj.hex;
    });
    setValue(obj.hex);
    setIsPalette(false);
  };

  const togglePalette = () => setIsPalette(prev => !prev);

  useEffect(() => {
    if (element && element.style) {
      if (typeof styleName === 'string' && element.style[styleName]) {
        setValue([colorFormat(element.style[styleName])]);
      } else if (typeof styleName === 'object') {
        styleName.forEach(name => {
          setValue([colorFormat(element.style[name])]);
        });
      }
    }
  }, [element]);

  return (
    <div className="form-group mt-4">
      <div className="mt-25">
        <label className="form-label">{label}</label>

        <div className="selected-color" onClick={togglePalette}>
          <div className="color" style={{ backgroundColor: value }} />
          <span className="color-name">{value}</span>
        </div>
        {isPalette && (
          <div className="block-picker-wrapper">
            <BlockPicker
              color={value}
              onChange={onHandleColor}
              colors={[
                '#ffffff',
                '#000000',
                '#D9E3F0',
                '#F47373',
                '#697689',
                '#37D67A',
                '#2CCCE4',
                '#555555',
                '#dce775',
                '#ff8a65',
                '#ba68c8',
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CloseButtonColorPicker;
