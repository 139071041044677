import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import {
  DataPreloader,
  Header,
  Modal,
  StyledTable as Table,
  EmptyData,
  Toast,
  ModalConfirm,
  Avatar,
  Dropdown,
} from 'components';
import { useRequest } from 'estafette';
import { contacts } from 'libs/http/api/contacts';
import { ToolsDropdown } from 'components/FlowArea/components/ToolsDropdown/ToolsDropdown';
import { capitalizeTheFirstLetters } from 'helpers/capitalizeTheFirstLetters';
import { useGTM } from '../../hooks/useGTM';

import './Contacts.scss';

const filterFields = ['phone', 'email', 'first_name', 'last_name', 'flow_name'];

const columnsTable = {
  contact: 'Contact',
  creation_date: 'Creation date',
  source: 'Source',
  flow_name: 'Flow name',
  action: 'Action',
};

export const Contacts = () => {
  const { page } = useParams();

  const { register, handleSubmit } = useForm();
  const { sendEvent } = useGTM();

  const {
    request: requestContactsList,
    loading: loadingContactsList,
    data: dataContactsListRequest,
  } = useRequest({ loading: true });
  const { request: requestNewContact, loading: loadingNewContact } = useRequest({});
  const { request: requestDeleteContacts, loading: loadingDeleteContacts } = useRequest({});
  const {
    request: requestCountSMS,
    loading: loadingCountSMS,
    data: dataCountSMS,
  } = useRequest({ loadingCountSMS: false });

  const [dataTable, setDataTable] = useState(null);
  const [isNewContactModal, setIsNewContactModal] = useState(false);
  const [sort, setSort] = useState(false);
  const [errorsNewContact, setErrorsNewContact] = useState(null);
  const [isModalConfirmSelected, setIsModalConfirmSelected] = useState(false);
  const [isToastSuccess, setIsToastSuccess] = useState(false);
  const [checked, setChecked] = useState([]);
  const [closeDropdown, setCloseDropdown] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [isModalConfirmDeleting, setIsModalConfirmDeleting] = useState(null);
  const [isFilterDropdown, setIsFilterDropdown] = useState(false);
  const [filterField, setFilterField] = useState('phone');
  const [filterText, setFilterText] = useState(null);
  const [dataContactsList, setDataContactsList] = useState(null);
  const [firstdataContactsList, setFirstdataContactsList] = useState(null);
  const [checkedStatuses, setCheckedStatuses] = useState([0, 1]);

  const onChangeCheckedStatuses = (_, key) => {
    setCheckedStatuses(prevChecked => {
      if (prevChecked.includes(key)) {
        return prevChecked.filter(el => el !== key);
      }
      return [...prevChecked, key];
    });
  };

  const limit = useRef(50);

  const onShowFilterDropdown = () => setIsFilterDropdown(prevIsFilter => !prevIsFilter);

  const onSelectFilterField = (e, field) => {
    e.preventDefault();
    setIsFilterDropdown(false);
    setFilterField(field);
  };

  const onChangeFilterText = ({ target }) => setFilterText(target.value);

  const onFilter = e => {
    e.preventDefault();

    setDataContactsList({ ...dataContactsListRequest, contacts: [] });

    getContactsList();
  };

  const onSubmitContact = async data => {
    requestNewContact(
      contacts.create
        .action({
          client_id: data?.client_id || null,
          first_name: data?.first_name || null,
          last_name: data?.last_name || null,
          email: data?.email || null,
          phone: data?.phone || null,
          father_name: data?.father_name || null,
          apply_flow: data?.apply_flow ? true : false,
        })
        .then(res => {
          if (res?.errors) {
            setErrorsNewContact(res.errors.detail);
          } else {
            sendEvent('create_contact');
            setIsNewContactModal(false);
            setErrorsNewContact(null);
            getContactsList();
          }
        }),
    );
  };

  const onDeleteContactModal = uid => {
    setDeletingId(uid);
    setIsModalConfirmDeleting(true);
  };

  const onCancelDeleteContact = () => setIsModalConfirmDeleting(false);

  const onDeleteContact = async () => {
    setIsModalConfirmDeleting(false);
    await requestDeleteContacts(contacts.deleteContact.action({ client_id: deletingId }));
    setIsToastSuccess(true);
    getContactsList();
  };

  const onShowNewContactModal = () => setIsNewContactModal(true);

  const onCloseNewContactModal = () => setIsNewContactModal(false);

  const onChangeSort = key => {
    if (key === 'creationDate') {
      setSort(prevSort => !prevSort);
    }
  };

  const getContactsList = () => {
    if (filterText) {
      requestContactsList(contacts.filterList.action({ [filterField]: filterText }));
    } else {
      const payload = {
        offset: page ? page * limit.current - limit.current : 0,
        limit: limit.current,
        sort: sort ? 1 : -1,
      };
      if (checkedStatuses.length < 2) {
        payload.flow_done = checkedStatuses.includes(1);
      }
      requestContactsList(contacts.list.action(payload));
    }
  };

  const onModalConfirmSelected = () => setIsModalConfirmSelected(true);

  const onDeleteContacts = async () => {
    setIsModalConfirmSelected(false);
    await requestDeleteContacts(contacts.deleteContactsList.action({ phones_list: checked.join(',') }));
    setIsToastSuccess(true);
    getContactsList();
    setChecked([]);
  };

  const onCancelDeleteContacts = () => {
    setIsModalConfirmSelected(false);
  };

  const onChangeChecked = newChecked => setChecked(newChecked);

  const onCloseSuccess = () => setIsToastSuccess(false);

  useEffect(() => {
    getContactsList();
  }, [page, sort, checkedStatuses]);

  useEffect(() => {
    setDataContactsList({ contacts: dataContactsListRequest?.results, ...dataContactsListRequest });

    if (!firstdataContactsList && dataContactsListRequest?.contacts) {
      setFirstdataContactsList(dataContactsListRequest);
    }
    console.log(444, dataContactsListRequest);
    if (dataContactsListRequest?.phones_list || dataContactsListRequest?.results) {
      requestCountSMS(
        contacts.getContactsSMSCount.action({
          phones_list: dataContactsListRequest?.phones_list || dataContactsListRequest?.results,
        }),
      );
    }
  }, [dataContactsListRequest]);

  useEffect(() => {
    if (dataContactsList?.contacts) {
      setDataTable(
        dataContactsList.contacts.map(el => {
          return {
            id: el.uid,
            data: {
              name: (
                <Link to={{ pathname: `/contact/${el.client_id}` }}>
                  <div className="user-card">
                    <Avatar
                      name={`${el.first_name} ${el.last_name}`}
                      className="bg-primary"
                      style={{ marginBottom: 0 }}
                    />
                    <div className="user-info ml-2">
                      <span className="tb-lead">
                        {el?.last_name ?? ''} {el?.first_name ?? ''} {el?.father_name ?? ''}
                      </span>
                      <span>{(el?.phone && `+${el?.phone}`) || el?.email}</span>
                    </div>
                  </div>
                </Link>
              ),
              creation_date: moment(el?.creation_date).utc(0).local().format('MMMM Do YYYY, h:mm a'),
              source: el.source,
              flow_name: (
                <>
                  {el.flow_name}{' '}
                  {!loadingCountSMS && (
                    <>
                      <br />
                      <DataPreloader loading={!loadingCountSMS} inline size="0.7rem" styles={{ fontSize: '14px' }} />
                    </>
                  )}
                  {dataCountSMS && dataCountSMS?.sms_count && dataCountSMS?.sms_count[el.phone] && (
                    <>
                      (<span style={{ color: '#1ee0ac' }}>{dataCountSMS?.sms_count[el.phone][1]}</span> /{' '}
                      <span style={{ color: '#b7c2d0' }}>{dataCountSMS?.sms_count[el.phone][0]}</span>)
                    </>
                  )}
                  <br />
                  {!el?.flow_name ? (
                    <span className="lead-text text-danger">N/A</span>
                  ) : (
                    <>
                      {dataCountSMS && dataCountSMS?.sms_count && dataCountSMS?.sms_count[el.phone] && (
                        <>
                          {dataCountSMS?.sms_count[el.phone][0] === 0 ? (
                            <span className="lead-text text-success">Done</span>
                          ) : (
                            <span className="lead-text text-warning">Processing</span>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ),
              action: (
                <ToolsDropdown
                  altButton={
                    <span className="btn btn-icon">
                      <em className="icon ni ni-more-h" data-toggle="dropdown" />
                    </span>
                  }
                  closeDropdown={closeDropdown}
                  className="pull-right"
                >
                  <>
                    <li>
                      <Link to={{ pathname: `/contact/${el.client_id}` }}>
                        <span>Details</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={{ pathname: `/contact/${el.client_id}/send_message` }}>
                        <span>Send Message</span>
                      </Link>
                    </li>
                    <li>
                      <a href="#s" onClick={() => onDeleteContactModal(el.uid)}>
                        <span>Delete</span>
                      </a>
                    </li>
                  </>
                </ToolsDropdown>
              ),
            },
          };
        }),
      );
    }
  }, [dataContactsList, dataCountSMS]);

  useEffect(() => {
    setTimeout(() => {
      onCloseSuccess();
    }, 3000);
  }, [isToastSuccess]);

  return (
    <>
      <div className="nk-main contacts-page">
        <div className="nk-wrap">
          <Header />

          <DataPreloader loading={loadingContactsList || loadingNewContact || loadingDeleteContacts}>
            <div className="nk-content">
              <div className="container-fluid">
                {firstdataContactsList && firstdataContactsList?.contacts?.length > 0 && (
                  <div className="nk-block-between">
                    <div className="nk-block-head nk-block-head-sm">
                      <ul className="nk-block-tools g-3">
                        <li className="nk-block-tools-opt">
                          <a href="#s" className="btn btn-icon btn-primary d-md-none">
                            <em className="icon ni ni-plus"></em>
                          </a>
                          <a
                            href="#s"
                            className="btn btn-primary d-none d-md-inline-flex"
                            onClick={onShowNewContactModal}
                          >
                            <em className="icon ni ni-plus"></em>
                            <span>Add</span>
                          </a>
                        </li>

                        {checked.length > 0 && (
                          <li className="nk-block-tools-opt">
                            <button className="btn btn-danger" onClick={onModalConfirmSelected}>
                              Delete Selected
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>

                    <div className="nk-block-head nk-block-head-sm pull-right">
                      <div className="nk-block-head-content">
                        <div className="toggle-wrap nk-block-tools-toggle">
                          {/* <a
                            href="#s"
                            className="btn btn-icon btn-trigger toggle-expand mr-n1"
                            data-target="more-options"
                          >
                            <em className="icon ni ni-more-v"></em>
                          </a> */}
                          <div className="toggle-expand-content" data-content="more-options">
                            <ul className="nk-block-tools g-3">
                              <li>
                                {/* <div className="form-control-wrap">
                                  <div className="form-icon form-icon-right">
                                    <em className="icon ni ni-search"></em>
                                  </div>
                                  <input type="text" className="form-control" placeholder="Search by name" />
                                </div> */}

                                <div className="form-control-wrap">
                                  <div className="input-group sort-dropdown">
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={e => onChangeFilterText(e)}
                                      defaultValue={filterText}
                                      placeholder="Search"
                                    />

                                    <div className="input-group-append">
                                      <div
                                        className={`dropdown-menu dropdown-menu-right ${
                                          isFilterDropdown ? 'show' : ''
                                        }`}
                                      >
                                        <ul className="link-list-opt no-bdr">
                                          {filterFields.map((el, key) => (
                                            <li key={key}>
                                              <a href="#s" onClick={e => onSelectFilterField(e, el)}>
                                                {capitalizeTheFirstLetters(el.replace('_', ' '))}
                                              </a>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                      <button
                                        className="btn btn-outline-primary btn-dim dropdown-toggle"
                                        data-toggle="dropdown"
                                        onClick={onShowFilterDropdown}
                                      >
                                        <span>By {capitalizeTheFirstLetters(filterField.replace('_', ' '))}</span>
                                        <em className="icon mx-n1 ni ni-chevron-down"></em>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <Dropdown title="Filter by status" className="small-dropdown sort-contacts">
                                  <ul className="link-tidy">
                                    {['N/A, Proccessing', 'Done'].map((el, key) => (
                                      <li key={key}>
                                        <div className="custom-control custom-control-sm custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={`cdp${key}-blx`}
                                            checked={checkedStatuses.includes(key)}
                                            onChange={e => onChangeCheckedStatuses(e, key)}
                                          />
                                          <label className="custom-control-label" for={`cdp${key}-blx`}>
                                            {el}
                                          </label>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Dropdown>
                              </li>

                              <li className="nk-block-tools-opt">
                                <a href="#s" className="btn btn-icon btn-primary d-md-none" onClick={onFilter}>
                                  <em className="icon ni ni-search"></em>
                                </a>
                                <a href="#s" className="btn btn-primary d-none d-md-inline-flex" onClick={onFilter}>
                                  <em className="icon ni ni-search"></em>
                                  <span>Search</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {(!loadingContactsList || !loadingNewContact) && dataTable && dataTable.length > 0 ? (
                  <Table
                    columns={columnsTable}
                    data={dataTable}
                    count={dataContactsList.contacts_count}
                    page={page ? page * limit.current : 0}
                    sort={sort}
                    onChangeSort={onChangeSort}
                    onChangeChecked={onChangeChecked}
                    limit={limit.current}
                    route="contacts"
                  />
                ) : (
                  <>
                    {filterText === null ? (
                      <EmptyData
                        title="No contacts yet"
                        description="You don't have any contacts yet. You can create first one or upload it via API"
                        backgroundImage="https://res.cloudinary.com/fosh/image/upload/v1641741178/empty_04_ayk8hz.png"
                      >
                        <button className="btn btn-primary mb-3" onClick={onShowNewContactModal}>
                          Create New Contact
                        </button>
                      </EmptyData>
                    ) : (
                      <EmptyData
                        title="No contacts"
                        description="You don't have any contacts for this query"
                        backgroundImage="https://res.cloudinary.com/fosh/image/upload/v1641741178/empty_04_ayk8hz.png"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </DataPreloader>
        </div>
      </div>

      {isNewContactModal && (
        <Modal title="Create contact" onClose={onCloseNewContactModal}>
          <form onSubmit={handleSubmit(onSubmitContact)}>
            <div className="form-group mt-4">
              <label className="form-label">First name</label>
              <div className="form-control-input">
                <input type="text" required className="form-control" name="first_name" {...register('first_name')} />
              </div>
            </div>

            <div className="form-group mt-4">
              <label className="form-label">Last name</label>
              <div className="form-control-input">
                <input type="text" required className="form-control" name="last_name" {...register('last_name')} />
              </div>
            </div>

            <div className="form-group mt-4">
              <label className="form-label">Father name</label>
              <div className="form-control-input">
                <input type="text" className="form-control" name="father_name" {...register('father_name')} />
              </div>
            </div>

            <div className="form-group mt-4">
              <label className="form-label">Phone</label>
              <div className="form-control-input">
                <input type="number" required className="form-control" name="phone" {...register('phone')} />
              </div>
            </div>

            <div className="form-group mt-4">
              <label className="form-label">Email</label>
              <div className="form-control-input">
                <input type="text" className="form-control" name="email" {...register('email')} />
              </div>
            </div>

            <div className="form-group mt-4">
              <label className="form-label">Client id</label>
              <div className="form-control-input">
                <input type="number" required className="form-control" name="client_id" {...register('client_id')} />
              </div>
            </div>

            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="applaytoflowcheck"
                {...register('apply_flow')}
              />
              <label className="custom-control-label" for="applaytoflowcheck">
                Apply to flow
              </label>
            </div>

            {errorsNewContact === 'problem_with_payment_plan' && (
              <div className="alert alert-danger alert-icon mt-3">
                <em className="icon ni ni-cross-circle"></em> you have exceeded the limits of a payment plan
              </div>
            )}

            <div className="text-right">
              <input type="submit" className="btn btn-primary" value="Create" />
            </div>
          </form>
        </Modal>
      )}

      {isModalConfirmSelected && (
        <ModalConfirm>
          <div className="nk-modal-action mt-5">
            <button className="btn btn-lg btn-mw btn-light mr-1" onClick={onCancelDeleteContacts}>
              Cancel
            </button>
            <button className="btn btn-lg btn-mw btn-danger ml-1" onClick={onDeleteContacts}>
              Delete
            </button>
          </div>
        </ModalConfirm>
      )}

      {isModalConfirmDeleting && (
        <ModalConfirm>
          <div className="nk-modal-action mt-5">
            <button className="btn btn-lg btn-mw btn-light mr-1" onClick={onCancelDeleteContact}>
              Cancel
            </button>
            <button className="btn btn-lg btn-mw btn-danger ml-1" onClick={onDeleteContact}>
              Delete
            </button>
          </div>
        </ModalConfirm>
      )}

      {isToastSuccess && <Toast text="Success" onClose={onCloseSuccess} />}
    </>
  );
};
