import React from 'react';

import './Status.scss';

export const Status = ({ type, message }) => {
  switch (type) {
    case 0:
      return <span class="badge badge-gray badge-scheduled">scheduled</span>;
    case 1:
      return <span class="badge badge-success badge-created">created</span>;
    case 2:
      return <span class="badge badge-danger badge-error">{message}</span>;
    case 3:
      return <span class="badge badge-info badge-sent">sent</span>;
    case 5:
      return (
        <span class="badge badge-error" style={{ color: '#fff' }}>
          cancelled
        </span>
      );
    default:
      return <span>{type}</span>;
  }
};
