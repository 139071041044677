import React, { useEffect, useState } from 'react';

import './ToolsDropdown.scss';

export const ToolsDropdown = ({ className, altButton, closeDropdown, children }) => {
  const [isShow, setIsShow] = useState();

  const onShowDropdown = e => {
    e.preventDefault();
    setIsShow(prev => !prev);
  };

  useEffect(() => {
    setIsShow(false);
  }, [closeDropdown]);

  return (
    <div className={`dropdown dropleft tools-dropdown tools-dropdown ${className ? className : ''}`}>
      <div className="dropdown-dots" onClick={onShowDropdown}>
        {!altButton ? (
          <a className="btn btn-primary dropdown-toggle" href="#s" type="button" data-toggle="dropdown">
            Action
          </a>
        ) : (
          altButton
        )}
      </div>

      <div className={`dropdown-menu ${isShow ? 'show' : ''}`}>
        <div className="close-wrapper" onClick={onShowDropdown} />

        <ul className="link-list-opt" style={{ zIndex: 3, position: 'relative' }}>
          {children}
        </ul>
      </div>
    </div>
  );
};
