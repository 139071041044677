import React from 'react';
import { Helmet } from 'react-helmet';
import { SigninForm } from 'components';
import { Login } from 'layouts';

const SignIn = () => {
  return (
    <>
      <Helmet>
        <title>Login to your account</title>
      </Helmet>

      <Login>
        <SigninForm />
      </Login>
    </>
  );
};

export default SignIn;
