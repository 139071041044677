import React from 'react';

import './AnalyticsFlowItem.scss';

export const AnalyticsFlowItem = ({ title, count }) => (
  <div className="col-md-3 stat-flow-item">
    <div className="card card-bordered card-full">
      <div className="card-inner">
        <div className="card-title-group align-start mb-0">
          <div className="card-title">
            <h6>{title}</h6>
          </div>
        </div>
        <div className="card-amount">
          <span className="amount">{count}</span>
        </div>
      </div>
    </div>
  </div>
);
