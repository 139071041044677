import React from 'react';

import './UsedLimit.scss';

export const UsedLimit = ({ title, hint, used, limit, percentPlan }) => (
  <div className="used-limit">
    <h6 className="title">{title}</h6>

    <div className="progress progress-lg">
      <div
        className="progress-bar"
        data-progress={`${used}`}
        style={{
          width: `${percentPlan}%`,
        }}
      >
        {used}
      </div>
    </div>

    <div>
      {limit} Included ({used} Used)
    </div>
  </div>
);
