import React, { useState } from 'react';
import SelectFont from '../SelectFont/SelectFont';
import RangeSelector from '../RangeSelector/RangeSelector';
import BlockColorPicker from '../BlockColorPicker/BlockColorPicker';
import Select from '../Select/Select';
import FrameCode from '../FrameCode/FrameCode';
import FormCode from '../FormCode/FormCode';
import BackgroundImage from '../BackgroundImage/BackgroundImage';
import BoxShadow from '../BoxShadow/BoxShadow';
import Input from '../Input/Input';
import FullWidth from '../FullWidth/FullWidth';
import CloseButtonColorPicker from '../CloseButtonColorPicker/CloseButtonColorPicker';
// import Width100 from 'components/FormBuilder/components/Width100/Width100';

import './SettingsElement.scss';

const SettingsElement = ({ activeElement, activeElementType, onRemoveElement }) => {
  const [isRemoving, setIsRemoving] = useState(false);

  const onRemoveToggle = () => setIsRemoving(prev => !prev);

  const onAddCloseIcon = e => {
    document.querySelector('#tuls-modal .tuls-close').innerHTML = e.currentTarget.innerHTML;
  };

  const TextSettings = () => (
    <>
      <RemoveElement />
      <hr />
      {/* <Width100 element={activeElement} /> */}
      <SelectFont element={activeElement} styleName="fontFamily" />
      <RangeSelector
        element={activeElement}
        styleName="width"
        item={{ label: 'Width', value: [100], min: 0, max: 100, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="fontSize"
        item={{ label: 'Font size', value: [18], min: 12, max: 48, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="fontWeight"
        item={{ label: 'Font weight', value: [300], min: 300, max: 800, step: 100, unit: '' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName={['paddingLeft', 'paddingRight']}
        item={{ label: 'Horizontal padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName={['paddingTop', 'paddingBottom']}
        item={{ label: 'Vertical padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="marginLeft"
        item={{ label: 'Margin left', value: [0], min: 0, max: 200, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="marginTop"
        item={{ label: 'Margin top', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="lineHeight"
        item={{ label: 'Line height', value: [24], min: 0, max: 80, unit: 'px' }}
      />
      <Select
        element={activeElement}
        styleName="textAlign"
        item={{ label: 'Text align', value: 'left', options: ['left', 'center', 'right'] }}
      />
      <BlockColorPicker element={activeElement} styleName="color" label="Color" />
    </>
  );

  const ImageSettings = () => (
    <>
      <RemoveElement />
      <hr />
      <BackgroundImage element={activeElement} styleName="backgroundImage" item={{ label: 'Background image' }} />
      <Select
        element={activeElement}
        styleName="backgroundRepeat"
        item={{
          label: 'Repeat mode',
          value: 'repeat',
          options: ['repeat', 'no-repeat', 'repeat-x', 'repeat-y', 'space', 'round'],
        }}
      />
      <Select
        element={activeElement}
        styleName="backgroundSize"
        item={{ label: 'Background size', value: 'auto', options: ['auto', 'contain', 'cover'] }}
      />
      <Select
        element={activeElement}
        styleName="backgroundPosition"
        item={{ label: 'Background position', value: 'left', options: ['left', 'right', 'top', 'bottom', 'center'] }}
      />
      <BlockColorPicker element={activeElement} styleName="backgroundColor" label="Background color" />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName={['paddingLeft', 'paddingRight']}
        item={{ label: 'Horizontal padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName={['paddingTop', 'paddingBottom']}
        item={{ label: 'Vertical padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="left"
        item={{ label: 'Margin left', value: [0], min: 0, max: 200, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="top"
        item={{ label: 'Margin top', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="height"
        item={{ label: 'Height', value: [0], min: 0, max: 1000, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="width"
        item={{ label: 'Width', value: [0], min: 0, max: 1000, unit: 'px' }}
      />
    </>
  );

  const ButtonSettings = () => (
    <>
      <RemoveElement />
      <hr />
      {/* <Width100 element={activeElement} /> */}
      <SelectFont element={activeElement} styleName="fontFamily" />
      <RangeSelector
        element={activeElement}
        styleName="width"
        item={{ label: 'Width', value: [100], min: 0, max: 100, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="fontSize"
        item={{ label: 'Font size', value: [18], min: 12, max: 48, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="fontWeight"
        item={{ label: 'Font weight', value: [300], min: 300, max: 800, step: 100, unit: '' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="lineHeight"
        item={{ label: 'Line height', value: [24], min: 0, max: 80, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName={['paddingLeft', 'paddingRight']}
        item={{ label: 'Horizontal padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName={['paddingTop', 'paddingBottom']}
        item={{ label: 'Vertical padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="marginLeft"
        item={{ label: 'Margin left', value: [0], min: 0, max: 200, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName="marginTop"
        item={{ label: 'Margin top', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <BlockColorPicker element={activeElement} styleName="backgroundColor" label="Background color" />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="borderRadius"
        item={{ label: 'Border radius', value: [0], min: 0, max: 100, unit: 'px' }}
      />
      <hr />
      <Select
        element={activeElement}
        attributeName="data-tuls-action"
        item={{ label: 'Choose action', value: 'button', options: ['button', 'link', 'phone', 'close'] }}
      />
      <Input
        element={activeElement}
        attributeName="data-tuls-link"
        item={{
          label: 'Button link',
          value: activeElement?.getAttribute('data-tuls-link') || '',
          placeholder: 'https://...',
        }}
      />
    </>
  );

  const ButtonCloseSettings = () => {
    const closeButton = document.querySelector('#tuls-modal .tuls-close');
    if (!closeButton.querySelector('svg')) {
      document.querySelector('#tuls-modal .tuls-close').innerHTML = `<svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="times-circle"
      class="svg-inline--fa fa-times-circle fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="#000"
        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
      ></path>
    </svg>`;
      closeButton.style.backgroundImage = 'none';
    }

    return (
      <>
        {/* <Width100 element={activeElement} /> */}
        <RangeSelector
          element={activeElement}
          styleName={['width', 'height']}
          item={{ label: 'Size', value: [20], min: 10, max: 50, unit: 'px' }}
        />
        <hr />
        <Select
          element={activeElement}
          attributeName="data-tuls-position"
          item={{
            label: 'Position',
            value: 'top-right',
            options: ['top-right', 'bottom-right', 'bottom-left', 'top-left'],
          }}
        />
        <hr />
        <CloseButtonColorPicker element={activeElement?.querySelector('svg path')} styleName="fill" label="Color" />
        <hr />

        <label class="form-label">Variant</label>
        <div className="close-buttons">
          <div onClick={onAddCloseIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
            </svg>
          </div>

          <div onClick={onAddCloseIcon}>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="times-circle"
              class="svg-inline--fa fa-times-circle fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#000"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
              ></path>
            </svg>
          </div>

          <div onClick={onAddCloseIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M448 32C483.3 32 512 60.65 512 96V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H448zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z" />
            </svg>
          </div>

          <div onClick={onAddCloseIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M384 32C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H384zM143 208.1L190.1 255.1L143 303C133.7 312.4 133.7 327.6 143 336.1C152.4 346.3 167.6 346.3 176.1 336.1L223.1 289.9L271 336.1C280.4 346.3 295.6 346.3 304.1 336.1C314.3 327.6 314.3 312.4 304.1 303L257.9 255.1L304.1 208.1C314.3 199.6 314.3 184.4 304.1 175C295.6 165.7 280.4 165.7 271 175L223.1 222.1L176.1 175C167.6 165.7 152.4 165.7 143 175C133.7 184.4 133.7 199.6 143 208.1V208.1z" />
            </svg>
          </div>

          <div onClick={onAddCloseIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="M376.6 427.5c11.31 13.58 9.484 33.75-4.094 45.06c-5.984 4.984-13.25 7.422-20.47 7.422c-9.172 0-18.27-3.922-24.59-11.52L192 305.1l-135.4 162.5c-6.328 7.594-15.42 11.52-24.59 11.52c-7.219 0-14.48-2.438-20.47-7.422c-13.58-11.31-15.41-31.48-4.094-45.06l142.9-171.5L7.422 84.5C-3.891 70.92-2.063 50.75 11.52 39.44c13.56-11.34 33.73-9.516 45.06 4.094L192 206l135.4-162.5c11.3-13.58 31.48-15.42 45.06-4.094c13.58 11.31 15.41 31.48 4.094 45.06l-142.9 171.5L376.6 427.5z" />
            </svg>
          </div>
        </div>
      </>
    );
  };

  const LineSettings = () => (
    <>
      <RemoveElement />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="width"
        item={{ label: 'Width', value: [100], min: 0, max: 100, unit: '%' }}
      />
      <RangeSelector
        element={activeElement}
        styleName={['paddingLeft', 'paddingRight']}
        item={{ label: 'Horizontal padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName={['paddingTop', 'paddingBottom']}
        item={{ label: 'Vertical padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <BackgroundImage element={activeElement} styleName="backgroundImage" item={{ label: 'Background image' }} />
      <Select
        element={activeElement}
        styleName="backgroundRepeat"
        item={{
          label: 'Repeat mode',
          value: 'repeat',
          options: ['repeat', 'no-repeat', 'repeat-x', 'repeat-y', 'space', 'round'],
        }}
      />
      <Select
        element={activeElement}
        styleName="backgroundSize"
        item={{ label: 'Background size', value: 'auto', options: ['auto', 'contain', 'cover'] }}
      />
      <Select
        element={activeElement}
        styleName="backgroundPosition"
        item={{ label: 'Background position', value: 'left', options: ['left', 'right', 'top', 'bottom', 'center'] }}
      />
      <hr />
      <BlockColorPicker element={activeElement} styleName="backgroundColor" label="Background color" />
    </>
  );

  const ModalSettings = () => (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>
          <RangeSelector
            element={activeElement}
            styleName={['width']}
            item={{ label: 'Width', value: [0], min: 0, max: 800, unit: 'px' }}
          />
        </div>
        <div className="form-group" style={{ width: '40%', paddingLeft: '10px', paddingTop: '65px' }}>
          <FullWidth element={activeElement} />
        </div>
      </div>
      <RangeSelector
        element={activeElement}
        styleName={['height']}
        item={{ label: 'Height', value: [0], min: 0, max: 800, unit: 'px' }}
      />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName={['paddingLeft', 'paddingRight']}
        item={{ label: 'Horizontal padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <RangeSelector
        element={activeElement}
        styleName={['paddingTop', 'paddingBottom']}
        item={{ label: 'Vertical padding', value: [0], min: 0, max: 200, unit: 'px' }}
      />
      <hr />
      <BackgroundImage element={activeElement} styleName="backgroundImage" item={{ label: 'Background image' }} />
      <Select
        element={activeElement}
        styleName="backgroundRepeat"
        item={{
          label: 'Repeat mode',
          value: 'repeat',
          options: ['repeat', 'no-repeat', 'repeat-x', 'repeat-y', 'space', 'round'],
        }}
      />
      <Select
        element={activeElement}
        styleName="backgroundSize"
        item={{ label: 'Background size', value: 'auto', options: ['auto', 'contain', 'cover'] }}
      />
      <Select
        element={activeElement}
        styleName="backgroundPosition"
        item={{ label: 'Background position', value: 'left', options: ['left', 'right', 'top', 'bottom', 'center'] }}
      />
      <BlockColorPicker element={activeElement} styleName="backgroundColor" label="Background color" />
      <hr />
      <RangeSelector
        element={activeElement}
        styleName="borderWidth"
        item={{ label: 'Border width', value: [0], min: 0, max: 5, unit: 'px' }}
      />
      <BlockColorPicker element={activeElement} styleName="borderColor" label="Border color" />
      <hr />
      <BoxShadow element={activeElement} />
    </>
  );

  const CodeFrameSettings = () => (
    <>
      <RemoveElement />
      <hr />
      <FrameCode element={activeElement} />
    </>
  );

  const FormFrameSettings = () => (
    <>
      <RemoveElement />
      <hr />
      <FormCode element={activeElement} />
    </>
  );

  const RemoveElement = () => (
    <div className="dropdown">
      <button className="btn btn-danger dropdown-toggle" type="button" onClick={onRemoveToggle}>
        Remove element <em className="icon ni ni-trash ml-1" />
      </button>
      <div className={`dropdown-menu ${isRemoving ? 'show' : ''}`}>
        <ul className="link-list-opt">
          <li>
            <a href="#r" onClick={onRemoveElement}>
              <span>Remove</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  switch (activeElementType) {
    case 'title':
      return <TextSettings />;
    case 'text':
      return <TextSettings />;
    case 'button':
      return <ButtonSettings />;
    case 'backgroundImage':
      return <ImageSettings />;
    case 'line':
      return <LineSettings />;
    case 'modal':
      return <ModalSettings />;
    case 'codeFrame':
      return <CodeFrameSettings />;
    case 'formFrame':
      return <FormFrameSettings />;
    case 'buttonClosePopup':
      return <ButtonCloseSettings />;
    default:
      return <>{activeElementType} active-element</>;
  }
};

export default SettingsElement;
