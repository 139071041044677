import React from 'react';

import './FlowPreviewHeader.scss';

export const FlowPreviewHeader = () => {
  return (
    <div className="flow-preview-header">
      <a href="https://ezflow.cc">ezFlow</a> Dialog Prewiew
    </div>
  );
};
