import axiosConfig from 'utils/api';

const apiToken = localStorage.getItem('api_token');

export const contacts = {
  list: {
    action: ({ limit, offset, sort, flow_done }) =>
      axiosConfig.get(
        `/api/contacts/v1/get_contacts?limit=${limit}&offset=${offset}&sort=${sort}${
          flow_done ? `&flow_done=${flow_done}` : ''
        }`,
      ),
    cancel: () => null,
  },
  filterList: {
    action: (params = {}) => axiosConfig.post(`/api/contacts/v1/find_contacts`, params),
    cancel: () => null,
  },
  create: {
    action: (params = {}) => {
      const newParams = { ...params };
      delete newParams['apply_flow'];
      return axiosConfig.post(`/api/contacts/v1/create_contact?api_token=${apiToken}&apply_flow=${params.apply_flow}`, {
        ...newParams,
      });
    },
    cancel: () => null,
  },
  getContact: {
    action: ({ client_id }) => axiosConfig.get(`/api/contacts/v1/get_contact?client_id=${client_id}`),
    cancel: () => null,
  },
  deleteContact: {
    action: ({ client_id }) => axiosConfig.delete(`/api/contacts/v2/delete_contact?client_id=${client_id}`),
    cancel: () => null,
  },
  deleteContactsList: {
    action: ({ phones_list }) => axiosConfig.delete(`/api/contacts/v1/delete_contacts?phones_list=${phones_list}`),
    cancel: () => null,
  },
  messageToContact: {
    action: (params = {}) => axiosConfig.post('/api/contacts/v1/send_message_to_contact', params),
    cancel: () => null,
  },
  getContactsSMSCount: {
    action: (params = {}) => {
      return axiosConfig.post(`/api/contacts/v1/get_contacts_sms_count`, params);
    },
    cancel: () => null,
  },
  updateContactInfo: {
    action: (id_contact, params = {}) =>
      axiosConfig.patch(`/api/contacts/v1/update_contact_by_client_id_private/${id_contact}`, params),
    cancel: () => null,
  },
};
