import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRequest } from 'estafette';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import ReactJson from 'react-json-view';
import { setUserInfo } from 'features/userSlice';
import { flow } from 'libs/http/api/flow';
import { user } from 'libs/http/api/user';
import { DataPreloader, Modal, Dropdown } from 'components';
import ImgUpload from 'components/ui/ImgUpload';

import './FlowSidebar.scss';
import { textToHTML } from './textToHTML';

export const FlowSidebar = ({
  elementDataForm,
  type,
  onNewBranch,
  onNewResponseBranch,
  onChangeFunction,
  onCloseSidebar,
  onIsSavedForm,
  onSaveForm,
  onRemoveElement,
  onCopyNode,
  dataFunction,
  activeElement,
  elements,
  dataFlow,
}) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => state);

  const { register, unregister, handleSubmit, setValue, getValues, watch } = useForm();
  const { register: registerVariables, handleSubmit: handleSubmitVariables } = useForm();
  const {
    register: registerRequestVariable,
    setValue: setValueRequestVariable,
    handleSubmit: handleSubmitRequestVariable,
  } = useForm();

  const { request: requestUpload, loading: loadingUpload } = useRequest({});
  const { request: requestFlowList } = useRequest({});

  const [isVariablesModal, setIsVariablesModal] = useState(false);
  const [isRequestModal, setIsRequestModal] = useState(false);
  const [responseType, setResponseType] = useState(null);
  const [userResponseType, setUserResponseType] = useState('text');
  const [childrenNodesForms, setChildrenNodesForms] = useState([]);
  const [responseCards, setResponseCards] = useState([{ id: String(Math.random()).replace('.', '') }]);
  const [variables, setVariables] = useState([]);
  const [flowConditions, setFlowConditions] = useState([]);
  const [checkedBots, setCheckedBots] = useState([0, 1, 2, 3]);
  const [bots, setBots] = useState([]);
  const [jumpAllNodes, setJumpAllNodes] = useState([]);
  const [jumpAllFlows, setJumpAllFlows] = useState([]);
  const [jumpNode, setJumpNode] = useState(null);
  const [jumpFlowNode, setJumpFlowNode] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [testErrors, setTestErrors] = useState([]);
  const [loadingTest, setLoadingTest] = useState(false);
  const [isShowtyping, setIsShowtyping] = useState(false);
  const [responseTest, setResponseTest] = useState(null);
  const [isRequestVariableModal, setIsRequestVariableModal] = useState(false);
  const [requestVariablePath, setRequestVariablePath] = useState(null);
  const [dragElement, setDragElement] = useState(null);
  const [isDragElement, setIsDragElement] = useState(false);
  const [dropElement, setDropElement] = useState(null);
  const [functions, setFunctions] = useState([]);

  const onChangeCheckedBots = (_, key) => {
    setCheckedBots(prevChecked => {
      if (prevChecked.includes(key)) {
        return prevChecked.filter(el => el !== key);
      }
      return [...prevChecked, key];
    });
  };

  const onSubmit = data => {
    // const children = {};
    // Object.keys(data).map(key => {
    //   const varArr = key.split('--');
    //   if (varArr.length > 1) {
    //     if (!children[varArr[1]]) {
    //       children[varArr[1]] = {};
    //     }
    //     children[varArr[1]][varArr[0]] = data[key];
    //   }
    // });

    const variables = {};
    Object.keys(data).map(key => {
      const varArr = key.split('_variable--');
      if (varArr.length > 1) {
        if (!variables[varArr[1]]) {
          variables[varArr[1]] = {};
        }
        variables[varArr[1]][varArr[0]] = data[key];
      }
    });

    const headers = {};
    Object.keys(data).map(key => {
      const headersArr = key.split('_header--');
      if (headersArr.length > 1) {
        if (!headers[headersArr[1]]) {
          headers[headersArr[1]] = {};
        }
        headers[headersArr[1]][headersArr[0]] = data[key];
      }
    });

    onIsSavedForm(true);

    if (responseType === 'card' || responseType === 'gallery' || responseType === 'incoming-lead') {
      if (responseType === 'incoming-lead') {
        const objectBots = bots.map(el => {
          return { bot_name: el, bot_token: userInfo.data.user.bots[el]?.token };
        });

        const conditions = {};
        Object.keys(data).map(key => {
          const conditionsArr = key.split('_condition--');
          if (conditionsArr.length > 1) {
            if (!conditions[conditionsArr[1]]) {
              conditions[conditionsArr[1]] = {};
            }
            conditions[conditionsArr[1]][conditionsArr[0]] = data[key];
          }
        });

        console.log('conditions', conditions);

        data = { ...data, variables, bots: objectBots, conditions };
      } else {
        data = { ...data, children: variables };
      }
    }

    console.log('children', variables);
    console.log('headers', headers);

    if (responseType === 'user-response') {
      data = { ...JSON.parse(JSON.stringify(data)), type: data['user_response_type'] };
      // delete data['user_response_type'];

      if (data.type === 'email' || data.type === 'phone') {
        data.validation = data.type;
      } else {
        data.validation = null;
      }

      if (!data.error_message) {
        data.error_message = null;
      }

      if (data.type === 'first_name' || data.type === 'last_name' || data.type === 'email' || data.type === 'phone') {
        data.response_field = data.type;
      }

      if (!data.response_field) {
        data.response_field = data.type;
      }
    }

    if (type === 'jump-node') {
      data.jump_node = jumpNode.value;
    }

    if (type === 'jump-flow-node') {
      data.jump_flow = jumpFlowNode.value;
      data.jump_flow_name = jumpFlowNode.label;
    }

    if (type === 'http-request') {
      data = { ...data, headers: headers };
    }

    console.log('type', type);
    console.log('vars', data);

    if (responseType === 'incoming-lead') {
      onSaveForm(data, null, responseType);
    } else {
      onSaveForm(data, variables, responseType);
    }
  };

  const onSubmitVariables = data => {
    console.log(data);
    // onIsSavedForm(true);
    // onSaveForm(data);
  };

  const onSubmitRequestVariable = data => {
    let newVariables = Object.keys(elements[0].data.form.variables).map(key => {
      setValue(`key_variable--${key}`, elements[0].data.form.variables[key].key);
      setValue(`value_variable--${key}`, elements[0].data.form.variables[key].value);
      return { ...elements[0].data.form.variables[key], id: key };
    });

    const id = String(Math.random()).replace('.', '');
    setValue(`key_variable--${id}`, data.variable_name);
    setValue(`value_variable--${id}`, data.variable_path);
    newVariables = [...newVariables, { key: data.variable_name, value: data.variable_path, id: id }];

    elements[0].data.form.variables = newVariables;
    setVariables(newVariables);

    setIsRequestVariableModal(false);
    setIsRequestModal(false);
  };

  const onChangeFunctionInner = (e, name) => {
    onChangeFunction(e);
    setValue(name, e.value);
  };

  // const onVariablesModal = () => setIsVariablesModal(true);

  const onCloseVasiablesModal = () => setIsVariablesModal(false);

  const onNewResponseBranchPrev = (e, type) => {
    onNewResponseBranch(e, type);
  };

  const getFunctions = () => {
    const newFunctions = [];
    elements.map(el => {
      if (el?.data?.type === 'callFunction') {
        newFunctions.push({ key: el.data.text, value: `functions_result['${el.data.functionName}']` });
      }
    });
    setFunctions(newFunctions);
  };

  const Variables = () => (
    <>
      <label className="form-label mt-2">Drag these elements to the textarea</label>
      <div className="functions-list">
        {/* <span draggable onDragStart={onDragstartHandlerVariables} contentEditable="false">
          {'{{first_name_111}}'}
        </span> */}

        {/* <span draggable onDragStart={onDragstartHandlerComponent}>
          {'{{first_name}}'}
        </span>
        <span draggable onDragStart={onDragstartHandlerComponent}>
          {'{{last_name}}'}
        </span>
        <span draggable onDragStart={onDragstartHandlerComponent}>
          {'{{phone_name}}'}
        </span>
        <span draggable onDragStart={onDragstartHandlerComponent}>
          {'{{url_name}}'}
        </span>
        <span draggable onDragStart={onDragstartHandlerComponent}>
          {'{{function_name}}'}
        </span> */}

        {Object.values(elements[0].data.form.variables).map(item => (
          <span
            draggable
            onDragStart={onDragstartHandlerVariables}
            key={item.key}
            data-variable={item.value}
            contentEditable="false"
          >
            {`{{${item.key}}}`}
          </span>
        ))}

        {functions.map(func => (
          <span
            draggable
            onDragStart={onDragstartHandlerVariables}
            key={func.key}
            data-variable={func.value}
            contentEditable="false"
          >
            {`{{${func.key}}}`}
          </span>
        ))}
      </div>
    </>
  );

  const onNewCardResponse = () => {
    const newCard = String(Math.random()).replace('.', '');
    setResponseCards(prevCards => [...prevCards, { id: newCard }]);
  };

  const onNewVariable = () => {
    const newVar = String(Math.random()).replace('.', '');
    setVariables(prevCards => [...prevCards, { id: newVar }]);
  };

  const onNewCondition = () => {
    const newCondition = String(Math.random()).replace('.', '');
    setFlowConditions(prevConditions => [...prevConditions, { id: newCondition }]);
  };

  const onChangeVideo = async e => {
    const fileData = new FormData();
    fileData.append('video', e.target.files[0]);
    const resFileUpload = await requestUpload(flow.uploadVideo.action(fileData));
    setValue('video', resFileUpload.file, { shouldDirty: true });
  };

  const onChangeJumpNode = val => setJumpNode(val);

  const onChangeJumpFlowNode = val => setJumpFlowNode(val);

  const onAddHeader = () => {
    const newHeader = String(Math.random()).replace('.', '');
    setHeaders(prevHeaders => [...prevHeaders, { id: newHeader }]);
  };

  const onRemoveHeader = id => {
    unregister(`key_header--${id}`);
    unregister(`value_header--${id}`);
    setHeaders(prevHeaders => prevHeaders.filter(header => header.id !== id));
  };

  const onRequestModal = () => {
    setTestErrors([]);
    setIsRequestModal(true);

    const errors = [];

    const url = getValues('request_url');
    const requestType = getValues('request_type');

    let newHeaders = [];
    try {
      if (headers.length > 0) {
        newHeaders = headers.map(el => {
          return {
            [getValues(`key_header--${el.id}`)]: getValues(`value_header--${el.id}`),
          };
        });
        newHeaders = Object.assign(...newHeaders);
      }
    } catch (err) {
      errors.push(`[Headers] ${err.name}: ${err.message}. More info in console`);
      console.error(err);
    }

    let params;
    try {
      params = eval(`(${JSON.parse(JSON.stringify(getValues('request_body') || '{}'))})`);
    } catch (err) {
      errors.push(`[Request Body field] ${err.name}: ${err.message}. More info in console`);
      console.error(err);
    }

    setTestErrors(errors);

    const axiosConfig = axios.create({
      baseURL: url,
      responseType: 'json',
      headers: {
        Accepted: 'appication/json',
        'Content-Type': 'application/json',
        ...newHeaders,
      },
    });

    setLoadingTest(true);
    axiosConfig[requestType]('', params)
      .then(response => {
        const responseData = JSON.stringify(response.data);
        // responseTestRef.current.value = responseData;
        setResponseTest(responseData);
      })
      .catch(error => {
        // responseTestRef.current.value = JSON.stringify(error);
        setTestErrors([...errors, error.message]);
      })
      .then(function () {
        setLoadingTest(false);
      });
  };

  const onCloseRequestModal = () => setIsRequestModal(false);

  const onCloseRequestVariableModal = () => setIsRequestVariableModal(false);

  const onConvertToVariable = e => {
    setIsRequestVariableModal(true);
    let path = e.namespace.join('.');
    path = path.replace('root.', `functions_result['${getValues('name')}'].`);

    // path = path.replaceAll(/(\.\d)/g, `[$1]`);
    // path = path.replaceAll('[.', '[');

    setRequestVariablePath(path);
    setValueRequestVariable('variable_path', path);
  };

  const onDragstartHandlerVariables = e => {
    setIsDragElement(true);
    e.dataTransfer.effectAllowed = 'move';
    const target = e.target;
    setTimeout(() => {
      setDragElement(target);
    });
  };

  const onDragEnterHandlerVariables = e => {
    setDropElement(e.target);
  };

  const onDragoverHandlerVariables = e => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const onDropHandlerVariables = e => {
    e.stopPropagation();
    e.preventDefault();

    // Drop element in this case might be an inner div
    const findParentDropElement = el => {
      return el.classList.contains('form-control') ? el : el.parentElement;
    };

    if (dropElement.tagName === 'SPAN') return;
    const parentDropElement = findParentDropElement(dropElement);
    dropElement.append(dragElement);
    console.log('dropElement', parentDropElement);
    onInputTextareaVariables(parentDropElement);
  };

  const onInputTextareaVariables = target => {
    const key = target.getAttribute('data-register');

    const strVars = txt => {
      return txt.replaceAll(/<span[^>]*>([\s\S]+)<\/span>/gi, '$1');
    };

    let html = target.innerHTML;
    let text = '';
    let firstDivIdx = target.innerHTML.indexOf('<div>');
    if (firstDivIdx > 0) {
      text += strVars(html.substring(0, firstDivIdx) + '\n');
    }
    if (target.querySelectorAll('div').length > 0) {
      target.querySelectorAll('div').forEach(el => {
        text += strVars(el.innerHTML) + '\n';
      });
    } else {
      text += strVars(target.innerHTML) + '\n';
    }

    console.log('text', text);
    setValue(key, textareaVars(text));
  };

  const variableToKey = variable => {
    let textVariable = variable;
    // if (variable) {
    //   const arrVars = [...variable.matchAll(/{{(.*?)}}/g)];
    //   if (arrVars.length > 0) {
    //     arrVars.map(el => {
    //       if (
    //         Object.values({ ...elements[0].data.form.variables, ...functions }).find(fEl => fEl.value === el[1])?.key
    //       ) {
    //         // onDragStart={onDragstartHandlerVariables}
    //         textVariable = textVariable.replace(
    //           el[0],
    //           `<span
    //           draggable
    //           key="${el[1]}"
    //           data-variable="${el[1]}"
    //           contentEditable="false"
    //         >
    //           {{${
    //             Object.values({ ...elements[0].data.form.variables, ...functions }).find(fEl => fEl.value === el[1]).key
    //           }}}
    //         </span>`,
    //         );
    //       }
    //     });
    //   }
    // }
    return textVariable;
  };

  const textareaVars = text => {
    let newText = text;
    if (newText) {
      newText = text.replaceAll('<br>', '\n');
    }
    return newText;
  };

  const htmlTextareaVars = text => {
    let newText = text;
    if (newText) {
      newText = text.replaceAll('\n', '<br>');

      // newText = newText.replaceAll(/{{(.*?)}}/g, (v1, v2) => {
      //   return `<span draggable key="${v2}" data-variable="${v2}" contentEditable="false">{{${v2}}}</span>`;
      // });
    }
    return newText;
  };

  const breakLines = text => (text ? text.replaceAll('\n', '</br>') : text);

  useEffect(() => {
    getFunctions();
  }, []);

  useEffect(() => {
    if (elementDataForm?.form) {
      Object.keys(elementDataForm.form).map(key => {
        setValue(key, elementDataForm.form[key]);
      });
      onIsSavedForm(true);
    } else {
      onIsSavedForm(false);
    }
  }, []);

  useEffect(() => {
    setResponseType(getValues('response_type'));
    setUserResponseType(getValues('user_response_type') || 'text');

    const subscription = watch(({ type }) => {
      if (type === 'change') {
        onIsSavedForm(false);
      }
      setResponseType(getValues('response_type'));
      setUserResponseType(getValues('user_response_type') || 'text');
      setIsShowtyping(getValues('sending_delay') > 0 || false);

      // if (getValues('response_type') === 'buttons' && childrenNodesForms.length === 0) {
      //   onNewResponseBranchPrev(null, 'button');
      // }
    });
    return () => subscription.unsubscribe();
  }, [watch, onIsSavedForm]);

  useEffect(() => {
    if (activeElement?.data?.type === 'response') {
      const children = elements.filter(el2 => el2.source === activeElement.id);
      let childrenNodes = children.map(el => elements.find(el2 => el2.id === el.target));
      childrenNodes = childrenNodes.filter(el => el?.data?.type === 'responseButton');
      if (childrenNodes.length) {
        setChildrenNodesForms(childrenNodes);
      }

      if (activeElement?.data?.type === 'response' && activeElement?.data?.form?.children) {
        const newChildren = Object.keys(activeElement.data.form.children).map(key => {
          return { ...activeElement.data.form.children[key], id: key };
        });
        setResponseCards(newChildren);
      }
    }

    if (activeElement?.data?.type === 'http-request' && activeElement?.data?.form?.headers) {
      const newHeaders = Object.keys(activeElement.data.form.headers).map(key => {
        return { ...activeElement.data.form.headers[key], id: key };
      });

      if (newHeaders) {
        setHeaders(newHeaders);
      } else {
        setHeaders({ id: String(Math.random()).replace('.', ''), key: '', value: '' });
      }
    }

    if (
      (activeElement?.data?.type === 'incoming-lead' || activeElement?.id === 'node-1') &&
      activeElement?.data?.form?.variables
    ) {
      const newVariables = Object.keys(activeElement.data.form.variables).map(key => {
        setValue(`key_variable--${key}`, activeElement.data.form.variables[key].key);
        setValue(`value_variable--${key}`, activeElement.data.form.variables[key].value);
        return { ...activeElement.data.form.variables[key], id: key };
      });
      setVariables(newVariables);
    }

    if (activeElement?.data?.type === 'jump-flow-node' && activeElement?.data?.form?.jump_flow) {
      setJumpFlowNode({
        value: activeElement.data.form.jump_flow,
        label: activeElement.data.form.jump_flow_name,
      });
    }

    // if (
    //   (activeElement?.data?.type === 'incoming-lead' || activeElement?.id === 'node-1') &&
    //   activeElement?.data?.form?.flowConditions
    // ) {
    //   const newFlowConditions = Object.keys(activeElement.data.form.flowConditions).map(key => {
    //     setValue(`key_condition--${key}`, activeElement.data.form.flowConditions[key].key);
    //     setValue(`value_condition--${key}`, activeElement.data.form.flowConditions[key].value);
    //     return { ...activeElement.data.form.flowConditions[key], id: key };
    //   });
    //   console.log(555, newFlowConditions);
    //   setFlowConditions(newFlowConditions);
    // }

    if (dataFlow.flow?.flow_conditions) {
      const newConditions = Object.keys(dataFlow.flow.flow_conditions).map(key => {
        return { ...dataFlow.flow?.flow_conditions[key], id: key };
      });

      if (newConditions) {
        setFlowConditions(newConditions);
      } else {
        setFlowConditions({ id: String(Math.random()).replace('.', ''), key: '', value: '' });
      }
    }

    if (activeElement?.data?.form?.conditions) {
      const newConditions = Object.keys(activeElement?.data?.form?.conditions).map(key => {
        return { ...activeElement?.data?.form?.conditions[key], id: key };
      });

      if (newConditions) {
        setFlowConditions(newConditions);
      } else {
        setFlowConditions({ id: String(Math.random()).replace('.', ''), key: '', value: '' });
      }
    }

    setJumpAllNodes(() => {
      const exceptionsTypes = ['incoming-lead', 'create-else', 'create', 'responseButton', 'jump-node'];
      const newJumpNodes = elements
        .filter(el => el?.data && el?.data?.type && !exceptionsTypes.includes(el?.data?.type))
        .map(el => {
          return { value: el.id, label: `${el?.data?.type} - ${el.data?.form?.name || el?.data?.text || ''}` };
        });
      setJumpNode(newJumpNodes[0]);
      return newJumpNodes;
    });
  }, [elements]);

  useEffect(() => {
    if (activeElement?.data?.form?.jump_node) {
      setJumpNode({
        value: activeElement.data.form.jump_node,
        label: jumpAllNodes.find(el => el.value === activeElement.data.form.jump_node)?.label || '111',
      });
    }
  }, [jumpAllNodes, activeElement]);

  useEffect(() => {
    const getData = async () => {
      if (userInfo?.data && Object.keys(userInfo.data).length === 0) {
        const data = await user.getInfo.action();
        dispatch(setUserInfo(data.data));
      } else {
        const { bots } = userInfo.data.user;
        bots && setBots(Object.keys(bots).filter(key => bots[key]));
      }
    };
    getData();
  }, [userInfo]);

  useEffect(() => {
    if (responseType === 'card' || responseType === 'gallery') {
      responseCards.map(item => {
        setValue(`img--${item.id}`, item?.img || null, { shouldDirty: true });
      });
    }
  }, [responseType]);

  useEffect(() => {
    if (document.querySelectorAll('.copy-to-clipboard-container').length > 0) {
      document.querySelectorAll('.copy-to-clipboard-container').forEach(el => {
        el.setAttribute('title', 'Convert to variable');
      });
    }
  }, [responseTest]);

  useEffect(() => {
    const getFlowsList = async () => {
      const flows = await requestFlowList(flow.list.action());
      setJumpAllFlows(() =>
        flows.flows.map(el => {
          return {
            value: el.uid,
            label: el.title,
          };
        }),
      );
    };
    getFlowsList();
  }, []);

  const getFunctionsSelect = (dataFunction, type = 'branch') => {
    if (dataFunction?.functions) {
      const funcs = dataFunction.functions
        .map(func => {
          if (func.type === type) return { value: func.uid, label: func.display_name };
        })
        .filter(func => {
          return func?.value || false;
        });
      return funcs;
    }
    return [];
  };

  if (!type) {
    return null;
  }

  return (
    <>
      <div className="flowarea-sidebar text-left">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="lead-settings-body">
            {type === 'sms' && (
              <>
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <div className="form-control-wrap">
                    <input type="text" name="name" {...register('name')} className="form-control" required />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Description</label>
                  <div className="form-control-wrap">
                    <textarea name="description" {...register('description')} className="form-control" />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">SMS text</label>
                  <div className="form-control-wrap">
                    <textarea
                      name="sms_text"
                      {...register(`sms_text`)}
                      value={textareaVars(variableToKey(getValues('sms_text')))}
                      className="form-control"
                      style={{ display: 'none' }}
                    />
                    <div
                      className="form-control functions-list"
                      contentEditable
                      style={{ height: '122px', overflow: 'auto' }}
                      // onMouseEnter={onMouseEnterGridCol}
                      onDrop={onDropHandlerVariables}
                      onDragOver={onDragoverHandlerVariables}
                      onDragEnter={onDragEnterHandlerVariables}
                      data-register="sms_text"
                      required
                      onInput={e => onInputTextareaVariables(e.target)}
                      dangerouslySetInnerHTML={{ __html: htmlTextareaVars(variableToKey(getValues('sms_text'))) }}
                    ></div>
                    {/* <textarea
                      name="sms_text"
                      {...register('sms_text')}
                      className="form-control"
                      onDrop={e => onDropHandler(e, 'sms_text')}
                      onDragOver={onDragoverHandler}
                      required
                    /> */}
                  </div>

                  <Variables />
                  {/* <a href="#s" onClick={onVariablesModal}>
                    Edit variables
                  </a> */}
                </div>

                <div className="form-group">
                  <label className="form-label">Sending delay (minutes)</label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      name="next_sms_offset"
                      defaultValue="0"
                      {...register('next_sms_offset')}
                      className="form-control"
                      required
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Sender</label>
                  <div className="form-control-wrap">
                    <input type="text" name="sender" {...register('sender')} className="form-control" required />
                  </div>
                </div>

                <div className="sub-account-settings__wrapper">
                  <span className="preview-title-lg overline-title">
                    Setting up subaccounts (required to track queue statistics)
                  </span>

                  <div className="form-group">
                    <label className="form-label">Subaccount #1</label>
                    <div className="form-control-wrap">
                      <input type="text" name="sub1" {...register('sub1')} className="form-control" />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label">Subaccount #2</label>
                    <div className="form-control-wrap">
                      <input type="text" name="sub2" {...register('sub2')} className="form-control" />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label">Subaccount #3</label>
                    <div className="form-control-wrap">
                      <input type="text" name="sub3" {...register('sub3')} className="form-control" />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label">Subaccount #4</label>
                    <div className="form-control-wrap">
                      <input type="text" name="sub4" {...register('sub4')} className="form-control" />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label">IsActive</label>
                    <div className="form-control-wrap">
                      <input type="text" name="sub3" {...register('isActive')} className="form-control" />
                    </div>
                  </div>
                  
                </div>
              </>
            )}

            {type === 'function' && (
              <>
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <div className="form-control-wrap">
                    <input type="text" name="name" {...register('name')} className="form-control" required />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Description</label>
                  <div className="form-control-wrap">
                    <textarea name="description" {...register('description')} className="form-control" />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Change a function</label>
                  <div className="form-control-wrap">
                    {/* <select className="form-select form-control" {...register('function')} onChange={onChangeFunction}>
                      <option value="0">Choose a function</option>
                      {dataFunction?.functions &&
                        dataFunction.functions.map(func => {
                          if (func.type === 'branch') {
                            return (
                              <option value={func.uid} key={func.uid}>
                                {func.display_name}
                              </option>
                            );
                          }
                        })}
                    </select> */}

                    <div style={{ marginLeft: '2px' }}>
                      <Select
                        name="function"
                        {...register('function')}
                        // defaultValue={{
                        //   value: getValues('function'),
                        //   label: getFunctionsSelect(dataFunction).find(el => el.value === getValues('function'))?.label,
                        // }}
                        value={{
                          value: getValues('checker_function'),
                          label: dataFunction.functions.find(el => el.uid === getValues('function'))?.display_name,
                        }}
                        onChange={e => {
                          onChangeFunctionInner(e, 'function');
                        }}
                        options={getFunctionsSelect(dataFunction)}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {type === 'callFunction' && (
              <>
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <div className="form-control-wrap">
                    <input type="text" name="name" {...register('name')} className="form-control" required />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Description</label>
                  <div className="form-control-wrap">
                    <textarea name="description" {...register('description')} className="form-control" />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Change a function</label>
                  <div className="form-control-wrap">
                    {/* <NodeSelect
                      className="form-select form-control"
                      name="checker_function"
                      {...register('checker_function')}
                      defaultValue={getValues('checker_function')}
                      onChange={onChangeFunction}
                      options={[
                        { value: '0', label: 'Choose a function' },
                        dataFunction?.functions &&
                          dataFunction.functions.map(func => {
                            if (func.type === 'regular') {
                              return { value: func.uid, label: func.display_name };
                            }
                          }),
                      ]}
                    /> */}
                    <div style={{ marginLeft: '2px' }}>
                      <Select
                        name="checker_function"
                        {...register('checker_function')}
                        // value={{
                        //   value: getValues('checker_function'),
                        //   label: getFunctionsSelect(dataFunction, 'regular').find(
                        //     el => el.value === getValues('checker_function'),
                        //   )?.label,
                        // }}
                        value={{
                          value: getValues('checker_function'),
                          label: dataFunction.functions.find(el => el.uid === getValues('checker_function'))
                            ?.display_name,
                        }}
                        onChange={e => {
                          onChangeFunctionInner(e, 'checker_function');
                        }}
                        options={getFunctionsSelect(dataFunction, 'regular')}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {type === 'default-fallback' && (
              <>
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <div className="form-control-wrap">
                    <input type="text" name="name" {...register('name')} className="form-control" required />
                  </div>
                </div>
              </>
            )}

            {type === 'response' && (
              <>
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <div className="form-control-wrap">
                    <input type="text" name="name" {...register('name')} className="form-control" required />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Description</label>
                  <div className="form-control-wrap">
                    <textarea name="description" {...register('description')} className="form-control" />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Sending delay (seconds)</label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      name="sending_delay"
                      defaultValue="0"
                      {...register('sending_delay')}
                      className="form-control"
                      required
                    />
                  </div>
                </div>

                {isShowtyping && (
                  <div className="form-group">
                    <label className="form-label">Show typing</label>
                    <div className="form-control-wrap">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="showTypingCheck"
                          name="show_typing"
                          {...register('show_typing')}
                        />
                        <label class="custom-control-label" for="showTypingCheck">
                          Show typing
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                <div className="form-group">
                  <label className="form-label">Select response type</label>
                  <div className="form-control-wrap">
                    {/* <NodeSelect
                      defaultValue={responseType}
                      {...register('response_type')}
                      options={[
                        { value: '0', label: 'Choose type' },
                        { value: 'text', label: 'Text' },
                        { value: 'buttons', label: 'Buttons' },
                        { value: 'card', label: 'Card' },
                        { value: 'video', label: 'Video' },
                        { value: 'gallery', label: 'Gallery' },
                        { value: 'user-response', label: 'User Response' },
                      ]}
                    /> */}
                    <div style={{ marginLeft: '2px' }}>
                      <Select
                        defaultValue={responseType}
                        {...register('response_type')}
                        options={[
                          { value: '0', label: 'Choose type' },
                          { value: 'text', label: 'Text' },
                          { value: 'buttons', label: 'Buttons' },
                          { value: 'card', label: 'Card' },
                          { value: 'video', label: 'Video' },
                          { value: 'gallery', label: 'Gallery' },
                          { value: 'user-response', label: 'User Response' },
                        ]}
                        onChange={e => setValue('response_type', e.value)}
                        // onChange={e => {
                        //   onChangeFunctionInner(e, 'response_type');
                        // }}
                      />
                    </div>
                  </div>
                </div>

                {responseType === 'text' && (
                  <>
                    <div className="form-group">
                      <label className="form-label">Response text</label>
                      <div className="form-control-wrap">
                        <textarea
                          name="response_text"
                          {...register(`response_text`)}
                          value={getValues('response_text')}
                          className="form-control"
                          style={{ display: 'none' }}
                        />
                        <div
                          className="form-control functions-list"
                          contentEditable
                          style={{ height: '122px', overflow: 'auto' }}
                          // onMouseEnter={onMouseEnterGridCol}
                          onDrop={onDropHandlerVariables}
                          onDragOver={onDragoverHandlerVariables}
                          onDragEnter={onDragEnterHandlerVariables}
                          data-register="response_text"
                          required
                          onInput={e => onInputTextareaVariables(e.target)}
                          dangerouslySetInnerHTML={{ __html: textToHTML(getValues('response_text')) }}
                        ></div>
                        {/* <textarea
                          name="response_text"
                          {...register('response_text')}
                          className="form-control"
                          onDrop={e => onDropHandler(e, 'response_text')}
                          onDragOver={onDragoverHandler}
                          required
                        /> */}
                      </div>
                    </div>

                    <Variables />
                  </>
                )}

                {responseType === 'buttons' && (
                  <>
                    <div className="form-group">
                      <label className="form-label">Response text</label>
                      <div className="form-control-wrap">
                        <textarea
                          name="response_text"
                          {...register(`response_text`)}
                          value={getValues('response_text')}
                          className="form-control"
                          style={{ display: 'none' }}
                        />
                        <div
                          className="form-control functions-list"
                          contentEditable
                          style={{ height: '122px', overflow: 'auto' }}
                          // onMouseEnter={onMouseEnterGridCol}
                          onDrop={onDropHandlerVariables}
                          onDragOver={onDragoverHandlerVariables}
                          onDragEnter={onDragEnterHandlerVariables}
                          data-register="response_text"
                          required
                          onInput={e => onInputTextareaVariables(e.target)}
                          dangerouslySetInnerHTML={{ __html: textToHTML(getValues('response_text')) }}
                        ></div>
                        {/* <textarea
                          name="response_text"
                          {...register('response_text')}
                          className="form-control"
                          onDrop={e => onDropHandler(e, 'response_text')}
                          onDragOver={onDragoverHandler}
                          required
                        /> */}
                      </div>

                      <Variables />
                    </div>

                    {childrenNodesForms.map(el => {
                      return (
                        <div className="response-button" key={el.id}>
                          <div className="form-group">
                            <label className="form-label">Button text</label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                name={`button_text--${el.id}`}
                                defaultValue={el?.data?.form?.button_text}
                                {...register(`button_text--${el.id}`)}
                                className="form-control"
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="form-label">Button type</label>
                            <div className="form-control-wrap">
                              {/* <NodeSelect
                                options={[{ value: 'send_message', label: 'Send Message' }]}
                                name={`button_type--${el.id}`}
                                defaultValue={el?.data?.form?.button_type}
                                {...register(`button_type--${el.id}`)}
                                required
                              /> */}
                              <div style={{ marginLeft: '2px' }}>
                                <Select
                                  options={[{ value: 'send_message', label: 'Send Message' }]}
                                  name={`button_type--${el.id}`}
                                  defaultValue={el?.data?.form?.button_type}
                                  {...register(`button_type--${el.id}`)}
                                  required
                                  onChange={e => setValue('response_type', e.value)}

                                  // defaultValue={{
                                  //   value: getValues('response_type'),
                                  //   label: getFunctionsSelect(dataFunction).find(el => el.value === getValues('response_type'))?.label,
                                  // }}
                                  // onChange={e => {
                                  //   onChangeFunctionInner(e, 'response_type');
                                  // }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="form-label">Button response</label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                name={`button_response--${el.id}`}
                                defaultValue={el?.data?.form?.button_response}
                                {...register(`button_response--${el.id}`)}
                                className="form-control"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <button
                      type="button"
                      className="btn btn-primary add-node-button"
                      onClick={e => onNewResponseBranchPrev(e, 'button')}
                    >
                      <em className="icon ni ni-plus" /> Add Button
                    </button>
                  </>
                )}

                {(responseType === 'card' || responseType === 'gallery') && (
                  <>
                    {responseCards &&
                      responseCards.length &&
                      responseCards.map(item => {
                        return (
                          <div className="card-item" key={item.id}>
                            <div className="form-group">
                              <label className="form-label">Response text</label>
                              <div className="form-control-wrap">
                                <textarea
                                  name={`text--${item.id}`}
                                  {...register(`text--${item.id}`)}
                                  value={item.text}
                                  className="form-control"
                                  style={{ display: 'none' }}
                                />
                                <div
                                  className="form-control functions-list"
                                  contentEditable
                                  style={{ height: '122px', overflow: 'auto' }}
                                  // onMouseEnter={onMouseEnterGridCol}
                                  onDrop={onDropHandlerVariables}
                                  onDragOver={onDragoverHandlerVariables}
                                  onDragEnter={onDragEnterHandlerVariables}
                                  data-register={`text--${item.id}`}
                                  required
                                  // dangerouslySetInnerHTML={{ __html: variableToKey(item.text) }}
                                  onInput={e => onInputTextareaVariables(e.target)}
                                  dangerouslySetInnerHTML={{ __html: htmlTextareaVars(variableToKey(item.text)) }}
                                />
                              </div>

                              <Variables />
                            </div>

                            {/* {setValue(`img--${item.id}`, item?.img || null, { shouldDirty: true })} */}

                            <ImgUpload
                              onChangeImg={async e => {
                                const fileData = new FormData();
                                fileData.append('image', e.target.files[0]);
                                const resFileUpload = await requestUpload(flow.uploadImage.action(fileData));

                                setValue(`img--${item.id}`, resFileUpload.file, { shouldDirty: true });
                              }}
                              item={{ name: `img--${item.id}`, label: 'Img', value: item?.img || null }}
                              itemName="img"
                            />
                          </div>
                        );
                      })}

                    {responseType === 'gallery' && (
                      <button type="button" className="btn btn-primary add-node-button" onClick={onNewCardResponse}>
                        <em className="icon ni ni-plus" /> Add response
                      </button>
                    )}
                  </>
                )}

                {responseType === 'video' && (
                  <div className="form-group">
                    <label className="form-label">Video file</label>
                    <div className="form-control-wrap">
                      <div class="custom-file">
                        <input
                          type="file"
                          accept="video/*"
                          class="custom-file-input"
                          id="uploadVideo"
                          onChange={onChangeVideo}
                        />
                        <label class="custom-file-label" htmlFor="uploadVideo">
                          Choose file
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                {responseType === 'user-response' && (
                  <>
                    <div className="form-group">
                      <label className="form-label">Response text</label>
                      <div className="form-control-wrap">
                        <textarea
                          name="response_text"
                          {...register(`response_text`)}
                          value={getValues('response_text')}
                          className="form-control"
                          style={{ display: 'none' }}
                        />
                        <div
                          className="form-control functions-list"
                          contentEditable
                          style={{ height: '122px', overflow: 'auto' }}
                          // onMouseEnter={onMouseEnterGridCol}
                          onDrop={onDropHandlerVariables}
                          onDragOver={onDragoverHandlerVariables}
                          onDragEnter={onDragEnterHandlerVariables}
                          data-register="response_text"
                          required
                          dangerouslySetInnerHTML={{ __html: textToHTML(getValues('response_text')) }}
                          onInput={e => onInputTextareaVariables(e.target)}
                        />
                        {/* <textarea
                          name="response_text"
                          {...register('response_text')}
                          className="form-control"
                          onDrop={e => onDropHandler(e, 'response_text')}
                          onDragOver={onDragoverHandler}
                          required
                        /> */}
                      </div>
                    </div>

                    <Variables />

                    <div className="form-group mt-5">
                      <label className="form-label">Response type</label>
                      <div className="form-control-wrap">
                        {/* <NodeSelect
                          options={[
                            { value: 'text', label: 'text' },
                            { value: 'number', label: 'number' },
                            { value: 'first_name', label: 'first name' },
                            { value: 'last_name', label: 'last name' },
                            { value: 'email', label: 'email' },
                            { value: 'phone', label: 'phone' },
                            { value: 'url', label: 'url' },
                          ]}
                          className="form-select form-control"
                          name="user_response_type"
                          defaultValue={userResponseType}
                          {...register('user_response_type')}
                          required
                        /> */}
                        <div style={{ marginLeft: '2px' }}>
                          <Select
                            options={[
                              { value: 'text', label: 'text' },
                              { value: 'number', label: 'number' },
                              { value: 'first_name', label: 'first name' },
                              { value: 'last_name', label: 'last name' },
                              { value: 'email', label: 'email' },
                              { value: 'phone', label: 'phone' },
                              { value: 'url', label: 'url' },
                            ]}
                            className="form-select form-control"
                            name="user_response_type"
                            defaultValue={userResponseType}
                            {...register('user_response_type')}
                            required
                            onChange={e => setValue('response_type', e.value)}
                          />
                        </div>
                      </div>
                    </div>

                    {(userResponseType === 'text' || userResponseType === 'number' || userResponseType === 'url') && (
                      <div className="form-group">
                        <label className="form-label">Save response to field</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            name="response_field"
                            // defaultValue={el?.data?.form?.button_text}
                            {...register('response_field')}
                            className="form-control"
                            pattern="[^' ']+"
                            title="Cyrillic only without spaces"
                            required
                          />
                        </div>
                      </div>
                    )}

                    {(userResponseType === 'first_name' ||
                      userResponseType === 'last_name' ||
                      userResponseType === 'email' ||
                      userResponseType === 'phone') && (
                      <div className="form-group">
                        <label className="form-label">Validation error message</label>
                        <div className="form-control-wrap">
                          <textarea
                            name="error_message"
                            {...register('error_message')}
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {type === 'responseButton' && (
              <>
                <div className="form-group">
                  <label className="form-label">Button text</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      name="button_text"
                      {...register('button_text')}
                      className="form-control"
                      required
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Button type</label>
                  <div className="form-control-wrap">
                    {/* <NodeSelect
                      options={[{ value: 'send_message', label: 'Send Message' }]}
                      className="form-select form-control"
                      name="button_type"
                      {...register('button_type')}
                      defaultValue={getValues('button_type')}
                      required
                    /> */}
                    <div style={{ marginLeft: '2px' }}>
                      <Select
                        options={[{ value: 'send_message', label: 'Send Message' }]}
                        className="form-select form-control"
                        name="button_type"
                        {...register('button_type')}
                        defaultValue={getValues('button_type')}
                        required
                        onChange={e => setValue('response_type', e.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Button response</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      name="button_response"
                      {...register('button_response')}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </>
            )}

            {type === 'branch' && (
              <>
                <div className="form-group">
                  <label className="form-label">Create branch</label>
                  <div className="form-control-wrap">
                    {/* <NodeSelect
                      options={[
                        { value: '0', label: 'New branch' },
                        { value: 'Function 1', label: 'Function 1' },
                        { value: 'Function 2', label: 'Function 2' },
                        { value: 'Function 3', label: 'Function 3' },
                      ]}
                      className="form-select form-control"
                      onChange={onNewBranch}
                    /> */}
                    <div style={{ marginLeft: '2px' }}>
                      <Select
                        defaultValue={{ value: '0', label: 'New branch' }}
                        options={[
                          { value: '0', label: 'New branch' },
                          { value: 'Function 1', label: 'Function 1' },
                          { value: 'Function 2', label: 'Function 2' },
                          { value: 'Function 3', label: 'Function 3' },
                        ]}
                        className="form-select form-control"
                        onChange={e => onNewBranch(e.value)}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {type === 'variable' && (
              <>
                <div className="form-group mt-4">
                  <label className="form-label">Name</label>
                  <div className="form-control-input">
                    <input type="text" required className="form-control" name="name" {...register('name')} />
                  </div>
                </div>

                <div className="form-group mt-4">
                  <label className="form-label">Variable name</label>
                  <div className="form-control-input">
                    <input
                      type="text"
                      required
                      className="form-control"
                      name="variable_name"
                      {...register('variable_name')}
                    />
                  </div>
                </div>

                <div className="form-group mt-4">
                  <label className="form-label">Variable value</label>
                  <div className="form-control-input">
                    <input
                      type="text"
                      required
                      className="form-control"
                      name="variable_value"
                      {...register('variable_value')}
                    />
                  </div>
                </div>
              </>
            )}

            {type === 'conversation-end' && (
              <>
                <div className="form-group mt-4">
                  <label className="form-label">Name</label>
                  <div className="form-control-input">
                    <input type="text" required className="form-control" name="name" {...register('name')} />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Description</label>
                  <div className="form-control-wrap">
                    <textarea name="description" {...register('description')} className="form-control" />
                  </div>
                </div>
              </>
            )}

            {type === 'incoming-lead' && (
              <>
                <div className="form-group">
                  <label className="form-label">Default response</label>
                  <div className="form-control-wrap">
                    <textarea name="default_response" {...register('default_response')} className="form-control" />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Bots</label>
                  <div className="form-control-wrap">
                    <Dropdown title="Select Bots" style={{ width: '100%' }}>
                      <ul class="link-tidy">
                        {bots.map((el, key) => (
                          <li key={key}>
                            <div class="custom-control custom-control-sm custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id={`cdp${key}-blx`}
                                checked={checkedBots.includes(key)}
                                onChange={e => onChangeCheckedBots(e, key)}
                              />
                              <label class="custom-control-label" for={`cdp${key}-blx`}>
                                {el}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Dropdown>
                  </div>
                </div>

                <div className="form-label" style={{ marginTop: '10px' }}>
                  Variables
                </div>

                {variables &&
                  variables.length > 0 &&
                  variables.map(item => {
                    return (
                      <div className="card-item" key={item.id}>
                        <div className="form-group">
                          <label className="form-label">Key</label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              name={`key_variable--${item.id}`}
                              {...register(`key_variable--${item.id}`)}
                              defaultValue={item.key}
                              className="form-control"
                              required
                              disabled={item.key === 'owner_id'}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="form-label">Value</label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              name={`value_variable--${item.id}`}
                              {...register(`value_variable--${item.id}`)}
                              defaultValue={item.value}
                              className="form-control"
                              required
                              disabled={item.key === 'owner_id'}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <button type="button" className="btn btn-primary add-node-button" onClick={onNewVariable}>
                  <em className="icon ni ni-plus" /> Add variable
                </button>

                <input type="hidden" name="response_type" {...register('response_type')} value="incoming-lead" />

                <div className="form-label" style={{ marginTop: '20px' }}>
                  Flow conditions
                </div>

                {flowConditions &&
                  flowConditions.length > 0 &&
                  flowConditions.map(item => {
                    return (
                      <div className="card-item" key={item.id}>
                        <div className="form-group">
                          <label className="form-label">Key</label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              name={`key_condition--${item.id}`}
                              {...register(`key_condition--${item.id}`)}
                              defaultValue={item?.key || item?.field_key}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="form-label">Value</label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              name={`value_condition--${item.id}`}
                              {...register(`value_condition--${item.id}`)}
                              // defaultValue={item?.value || item?.field_value}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <button type="button" className="btn btn-primary add-node-button" onClick={onNewCondition}>
                  <em className="icon ni ni-plus" /> Add flow condition
                </button>
              </>
            )}

            {type === 'jump-node' && (
              <>
                <div className="form-group mt-4">
                  <label className="form-label">Name</label>
                  <div className="form-control-input">
                    <input type="text" required className="form-control" name="name" {...register('name')} />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Description</label>
                  <div className="form-control-wrap">
                    <textarea name="description" {...register('description')} className="form-control" />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Select Node</label>
                  <div className="form-control-wrap">
                    <div style={{ marginLeft: '2px' }}>
                      <Select options={jumpAllNodes} onChange={onChangeJumpNode} value={jumpNode} />
                    </div>
                    {/* {...register('target_jump')} */}
                  </div>
                </div>
              </>
            )}

            {type === 'jump-flow-node' && (
              <>
                <div className="form-group mt-4">
                  <label className="form-label">Name</label>
                  <div className="form-control-input">
                    <input type="text" required className="form-control" name="name" {...register('name')} />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Description</label>
                  <div className="form-control-wrap">
                    <textarea name="description" {...register('description')} className="form-control" />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Sending delay (minutes)</label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      name="offset"
                      defaultValue="0"
                      {...register('offset')}
                      className="form-control"
                      required
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Select Flow</label>
                  <div className="form-control-wrap">
                    <div style={{ marginLeft: '2px' }}>
                      <Select options={jumpAllFlows} onChange={onChangeJumpFlowNode} value={jumpFlowNode} />
                    </div>
                    {/* {...register('target_jump')} */}
                  </div>
                </div>
              </>
            )}

            {type === 'http-request' && (
              <>
                <div className="form-group mt-4">
                  <label className="form-label">Name</label>
                  <div className="form-control-input">
                    <input type="text" required className="form-control" name="name" {...register('name')} />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Description</label>
                  <div className="form-control-wrap">
                    <textarea name="description" {...register('description')} className="form-control" />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Request Type</label>
                  <div className="form-control-wrap">
                    {/* <NodeSelect
                      options={[
                        { value: 'get', label: 'GET' },
                        { value: 'post', label: 'POST' },
                        { value: 'put', label: 'PUT' },
                        { value: 'delete', label: 'DELETE' },
                      ]}
                      {...register('request_type')}
                      defaultValue={getValues('request_type')}
                    /> */}
                    <div style={{ marginLeft: '2px' }}>
                      <Select
                        name="checker_function"
                        {...register('checker_function')}
                        defaultValue={getValues('checker_function')}
                        onChange={onChangeFunction}
                        options={getFunctionsSelect(dataFunction)}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group mt-4">
                  <label className="form-label">Request URL</label>
                  <div className="form-control-input">
                    <input
                      type="text"
                      required
                      className="form-control"
                      name="request_url"
                      {...register('request_url')}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Headers</label>
                  <div className="form-control-wrap headers">
                    {headers &&
                      headers.length > 0 &&
                      headers.map(header => {
                        return (
                          <div className="flex" key={header.id}>
                            <div className="input-key">
                              <input
                                type="text"
                                required
                                className="form-control"
                                name={`key_header--${header.id}`}
                                defaultValue={header.key}
                                {...register(`key_header--${header.id}`)}
                              />
                            </div>

                            <div className="input-value">
                              <input
                                type="text"
                                required
                                className="form-control"
                                name={`value_header--${header.id}`}
                                defaultValue={header.value}
                                {...register(`value_header--${header.id}`)}
                              />
                            </div>

                            <div className="remove-header">
                              <i className="icon fas fa-times-circle" onClick={() => onRemoveHeader(header.id)} />
                            </div>
                          </div>
                        );
                      })}

                    <button className="btn btn-outline-primary btn-sm mt-3" type="button" onClick={onAddHeader}>
                      <i className="fas fa-plus mr-1" /> Add header
                    </button>
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Request Body</label>
                  <div className="form-control-wrap">
                    <textarea
                      name="request_body"
                      {...register(`request_body`)}
                      value={variableToKey(getValues('request_body'))}
                      className="form-control"
                      style={{ display: 'none' }}
                    />
                    <div
                      className="form-control functions-list"
                      contentEditable
                      style={{ height: '122px', overflow: 'auto' }}
                      // onMouseEnter={onMouseEnterGridCol}
                      onDrop={onDropHandlerVariables}
                      onDragOver={onDragoverHandlerVariables}
                      onDragEnter={onDragEnterHandlerVariables}
                      data-register="request_body"
                      required
                      onInput={e => onInputTextareaVariables(e.target)}
                      dangerouslySetInnerHTML={{ __html: htmlTextareaVars(variableToKey(getValues('request_body'))) }}
                    />
                    {/* <textarea
                      name="reques_body"
                      {...register('request_body')}
                      className="form-control"
                      onDrop={e => onDropHandler(e, 'request_body')}
                      onDragOver={onDragoverHandler}
                      required
                    /> */}
                  </div>
                </div>

                <Variables />

                <button className="btn btn-outline-primary btn-sm mt-3 mb-3" type="button" onClick={onRequestModal}>
                  Test the Request
                </button>
              </>
            )}
          </div>

          <div className="row tools">
            <div className="col-md-8">
              <button className="btn btn-outline-primary mr-2" disabled={loadingUpload}>
                <DataPreloader loading={loadingUpload} inline>
                  Save
                </DataPreloader>
              </button>
              <button className="btn btn-outline-secondary" onClick={onCloseSidebar}>
                Close
              </button>
            </div>

            {type !== 'incoming-lead' && (
              <div className="col-md-4 text-right">
                <span className="mr-2 copy-element" onClick={onCopyNode}>
                  <em className="fas fa-copy" />
                </span>

                <span className="remove-element" onClick={onRemoveElement}>
                  <em className="fas fa-trash-alt" />
                </span>
              </div>
            )}
          </div>
        </form>
      </div>

      {isVariablesModal && (
        <Modal title="JSON" onClose={onCloseVasiablesModal}>
          <>
            <form onSubmit={handleSubmitVariables(onSubmitVariables)}>
              <div className="form-group mt-4">
                <label className="form-label">Name</label>
                <div className="form-control-input">
                  <input type="text" required className="form-control" name="name" {...registerVariables('name')} />
                </div>
              </div>

              <div className="form-group mt-4">
                <label className="form-label">variable_name</label>
                <div className="form-control-input">
                  <input
                    type="text"
                    required
                    className="form-control"
                    name="variable_name"
                    {...registerVariables('variable_name')}
                  />
                </div>
              </div>

              <div className="form-group mt-4">
                <label className="form-label">variable_value</label>
                <div className="form-control-input">
                  <input
                    type="text"
                    required
                    className="form-control"
                    name="variable_value"
                    {...registerVariables('variable_value')}
                  />
                </div>
              </div>

              <div className="text-right">
                <input type="submit" className="btn btn-primary" value="Create" />
              </div>
            </form>
          </>
        </Modal>
      )}

      {isRequestModal && (
        <Modal
          title="Test your configuration"
          onClose={onCloseRequestModal}
          style={{ width: '800px', maxWidth: '800px' }}
        >
          <>
            <DataPreloader loading={loadingTest}>
              <>
                {testErrors.map(error => (
                  <div className="alert alert-danger alert-icon">
                    <em className="icon ni ni-cross-circle" /> <strong>{error}</strong>
                  </div>
                ))}

                {testErrors.length === 0 && (
                  <div className="alert alert-success alert-icon">
                    <em className="icon ni ni-check-circle" /> <strong>Test successful.</strong>
                  </div>
                )}
              </>
            </DataPreloader>

            <div className="form-group">
              <div className="form-control-input">
                {/* <textarea className="form-control" style={{ minHeight: '200px' }} ref={responseTestRef} /> */}
                {responseTest && (
                  <ReactJson src={JSON.parse(responseTest)} enableClipboard={e => onConvertToVariable(e)} />
                )}
              </div>
            </div>

            <div className="text-right">
              <input
                type="button"
                className="btn btn-outline-light mr-3"
                value="Cancel"
                onClick={onCloseRequestModal}
              />
              <input
                type="button"
                className="btn btn-primary"
                value="Run test again"
                onClick={onRequestModal}
                disabled={loadingTest}
              />
            </div>
          </>
        </Modal>
      )}

      {isRequestVariableModal && (
        <Modal title="Convert to variable" onClose={onCloseRequestVariableModal}>
          <form onSubmit={handleSubmitRequestVariable(onSubmitRequestVariable)}>
            <div className="form-group mt-4">
              <label className="form-label">Variable name</label>
              <div className="form-control-input">
                <input
                  type="text"
                  required
                  className="form-control"
                  name="variable_name"
                  {...registerRequestVariable('variable_name')}
                />
              </div>
            </div>

            <div className="form-group mt-4">
              <label className="form-label">Variable path</label>
              <div className="form-control-input">
                <input
                  type="text"
                  disabled
                  required
                  className="form-control"
                  name="variable_path"
                  value={requestVariablePath}
                  {...registerRequestVariable('variable_path')}
                />
              </div>
            </div>

            <div className="text-right">
              <input
                type="button"
                className="btn btn-outline-light mr-3"
                value="Cancel"
                onClick={onCloseRequestVariableModal}
              />
              <input type="submit" className="btn btn-primary" value="Save" />
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};
