import React from 'react';
import { Helmet } from 'react-helmet';
import { Dashboard } from 'layouts/Dashboard';
import { FlowArea } from 'components';

const FlowBuilder = () => {
  return (
    <>
      <Helmet>
        <title>Build the Flow</title>
      </Helmet>

      <Dashboard>
        <FlowArea />
      </Dashboard>
    </>
  );
};

export default FlowBuilder;
