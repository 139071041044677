class FormBuilder {
  constructor(id, config, rootElement, local = false) {
    this.id = id;
    this.config = config;
    this.rootElement = rootElement;
    this.local = local;
    this.fonts = [];
    this.formClosed = false;
  }

  get config() {
    return this._config;
  }

  set config(value) {
    this._config = value;
  }

  get id() {
    return this._id;
  }

  set local(value) {
    this._local = value;
  }

  get local() {
    return this._local;
  }

  set fonts(value) {
    this._fonts = value;
  }

  get fonts() {
    return this._fonts;
  }

  set formClosed(value) {
    this._formClosed = value;
  }

  get formClosed() {
    return this._formClosed;
  }

  set id(value) {
    this._id = value;
  }

  setCookie(name, value, options = {}) {
    options = {
      path: '/',
      ...options,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (let optionKey in options) {
      updatedCookie += '; ' + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }

    document.cookie = updatedCookie;
  }

  getCookie(name) {
    let matches = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }

  getBrowser() {
    let sBrowser,
      sUsrAg = navigator.userAgent;
    if (sUsrAg.indexOf('Firefox') > -1) {
      sBrowser = 'Mozilla Firefox';
    } else if (sUsrAg.indexOf('Opera') > -1) {
      sBrowser = 'Opera';
    } else if (sUsrAg.indexOf('Trident') > -1) {
      sBrowser = 'Microsoft Internet Explorer';
    } else if (sUsrAg.indexOf('Edge') > -1) {
      sBrowser = 'Microsoft Edge';
    } else if (sUsrAg.indexOf('Chrome') > -1) {
      sBrowser = 'Google Chrome';
    } else if (sUsrAg.indexOf('Safari') > -1) {
      sBrowser = 'Apple Safari';
    } else {
      sBrowser = 'unknown';
    }
    return sBrowser;
  }

  getStatistick({ eventType }) {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const data = {
      language: navigator.language,
      vendor: navigator.vendor,
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      device: isMobile ? 'mobile' : 'desktop',
      width: window.innerWidth,
      height: window.innerHeight,
      type: eventType,
      browser: this.getBrowser(),
    };
    return JSON.stringify(data);
  }

  postStatistick({ eventType }) {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const data = {
      language: navigator.language,
      vendor: navigator.vendor,
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      device: isMobile ? 'mobile' : 'desktop',
      width: window.innerWidth,
      height: window.innerHeight,
      type: eventType,
      browser: this.getBrowser(),
    };
    fetch(`https://api.ezflow.cc/trk/clc?form_id=${this.id}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(data),
    });
  }

  onCloseForm() {
    this.setCookie('tulsformClosed', 1, { 'max-age': 3600 });
    this.rootElement.style.display = 'none';
    this.formClosed = true;
  }

  async initStyles() {
    let styles = await fetch('https://app.ezflow.cc/assets/styles/tuls-form.css');
    styles = await styles.text();
    const stylesTag = document.createElement('style');
    stylesTag.rel = 'stylesheet';
    stylesTag.id = 'tuls-form-styles';
    stylesTag.innerHTML = styles;
    document.body.appendChild(stylesTag);
  }

  stringStyleToObject(input) {
    let result = {};
    if (input) {
      let attributes = input.split(';');
      for (let i = 0; i < attributes.length; i++) {
        const entry = attributes[i].split(':');
        const prop = entry.splice(0, 1)[0].trim();
        if (prop) {
          result[prop] = entry.join(':').trim();
        }
      }
    }
    return result;
  }

  setInnerHTML(elm, html) {
    elm.innerHTML = html;
    Array.from(elm.querySelectorAll('script')).forEach(oldScript => {
      const newScript = document.createElement('script');
      Array.from(oldScript.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
      newScript.appendChild(document.createTextNode(oldScript.innerHTML));
      oldScript.parentNode.replaceChild(newScript, oldScript);
    });
  }

  childrenToJson(columnElement) {
    const children = [];
    columnElement.childNodes.forEach(childrenElement => {
      const childrenJson = {
        style: this.stringStyleToObject(childrenElement.getAttribute('style')),
        type: childrenElement.getAttribute('data-tuls-type'),
        html:
          childrenElement?.getAttribute('data-tuls-type') !== 'button'
            ? childrenElement.innerHTML
            : childrenElement.innerText,
        inputType: childrenElement?.getAttribute('type'),
        value: childrenElement.value,
        placeholder: childrenElement.getAttribute('placeholder'),
        required: childrenElement.getAttribute('required'),
        name: childrenElement.getAttribute('name'),
      };

      if (
        childrenElement?.getAttribute('data-tuls-type') === 'codeFrame' ||
        childrenElement?.getAttribute('data-tuls-type') === 'formFrame'
      ) {
        childrenJson.html = '';
        childrenJson.code = childrenElement.getAttribute('data-code');
      }

      if (childrenElement?.getAttribute('data-tuls-action')) {
        childrenJson.action = childrenElement.getAttribute('data-tuls-action');
      }

      if (childrenElement?.getAttribute('data-tuls-link')) {
        childrenJson.link = childrenElement.getAttribute('data-tuls-link');
      }
      children.push(childrenJson);
    });
    return children;
  }

  columnsToJson(lineElement) {
    const columns = [];
    lineElement.querySelectorAll('.tuls-column').forEach(columnElement => {
      const columnJson = {
        width: columnElement.getAttribute('data-width'),
        style: this.stringStyleToObject(columnElement.getAttribute('style')),
        children: this.childrenToJson(columnElement),
      };
      columns.push(columnJson);
    });
    return columns;
  }

  createConfig() {
    const info = { name: 'Template name', description: 'Description' };
    info.style = this.stringStyleToObject(this.rootElement.getAttribute('style'));
    const lines = [];
    const linesElements = this.rootElement.querySelectorAll('.tuls-line');
    linesElements.forEach(lineElement => {
      const lineJson = {
        style: this.stringStyleToObject(lineElement.getAttribute('style')),
        columns: this.columnsToJson(lineElement),
      };
      lines.push({ line: lineJson });
    });
    console.log(JSON.stringify({ info, lines }));
    return { info, lines };
  }

  addStyles(element, style) {
    if (style) {
      Object.keys(style).map(prop => {
        element.style[prop] = style[prop];
      });
    }
  }

  postForm(e) {
    e.preventDefault();
    const form_data = {};
    const form = document.querySelector('#tuls-form').querySelector('form').querySelectorAll('input, textarea');
    form.forEach(el => {
      const name = el.name
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[-]+/g, '-')
        .replace(/[^\w-]+/g, '');
      const value = el.value;
      if (name) {
        form_data[name] = value;
      }
    });

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const lead_browser_data = {
      language: navigator.language,
      vendor: navigator.vendor,
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      device: isMobile ? 'mobile' : 'desktop',
      width: window.innerWidth,
      height: window.innerHeight,
      browser: this.getBrowser(),
    };

    fetch(`https://api.ezflow.cc/api/v1/form/create_contact?form_id=${this.id}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify({
        form_data,
        lead_browser_data,
        type: 'form_conversion',
      }),
    }).then(() => {
      document.querySelector('#tuls-form > .tuls-inner').innerHTML =
        '<p class="tf-thanks">Thanks for submitting the form</p>';
      setTimeout(() => {
        if (typeof TemplateBuilder === 'function') {
          // eslint-disable-next-line no-undef
          const pr = new TemplateBuilder();
          pr.onCloseModal();
          document.body.querySelector('#tuls-modal').style.display = 'none';
        }
      }, 2000);
    });

    // this.postStatistick({ eventType: "form_conversion" });
  }

  renderButton(column, element) {
    const crElement = document.createElement('button');
    const crElementSpan = document.createElement('span');
    crElementSpan.innerHTML = element.html;
    crElement.classList = 'tuls-component tuls-button';
    crElement.appendChild(crElementSpan);
    this.addStyles(crElement, element.style);
    if (this?.editable && !this.local) {
      crElementSpan.contentEditable = String(this.editable);
      crElement.querySelectorAll('span').setAttribute('contenteditable', false);
    }
    crElement.dataset.tulsType = 'button';

    column.appendChild(crElement);
  }

  renderInput(column, element) {
    const crElement = document.createElement('input');
    crElement.classList = 'tuls-component tuls-input';
    crElement.setAttribute('type', element.inputType);
    crElement.setAttribute('required', element.required);
    crElement.setAttribute('placeholder', element.placeholder);
    crElement.setAttribute('name', element.name);
    crElement.value = element.value;
    this.addStyles(crElement, element.style);
    if (this?.editable) {
      crElement.contentEditable = String(this.editable);
    }
    crElement.draggable = String(this.editable);
    crElement.dataset.tulsType = 'input';
    column.appendChild(crElement);
  }

  renderTextarea(column, element) {
    const crElement = document.createElement('textarea');
    crElement.classList = 'tuls-component tuls-textarea';
    crElement.setAttribute('type', element.inputType);
    crElement.setAttribute('required', element.required);
    crElement.setAttribute('placeholder', element.placeholder);
    crElement.setAttribute('name', element.name);
    crElement.value = element.value;
    this.addStyles(crElement, element.style);
    if (this?.editable) {
      crElement.contentEditable = String(this.editable);
    }
    crElement.draggable = String(this.editable);
    crElement.dataset.tulsType = 'textarea';
    column.appendChild(crElement);
  }

  renderTitle(column, element) {
    const crElement = document.createElement('div');
    crElement.classList = 'tuls-component tuls-title';
    crElement.innerHTML = element.html;
    this.addStyles(crElement, element.style);
    if (this?.editable) {
      crElement.contentEditable = String(this.editable);
    }
    crElement.draggable = String(this.editable);
    crElement.dataset.tulsType = 'title';

    crElement.addEventListener('click', e => {
      e.preventDefault();

      if (!this.local && crElement?.nextSibling) {
        crElement.nextSibling.focus();
      }
    });

    column.appendChild(crElement);
  }

  renderChildren(elColumn, childrenElement) {
    if (childrenElement?.style['font-family']) {
      this.fonts = [...this.fonts, childrenElement?.style['font-family']];
    }

    switch (childrenElement.type) {
      case 'button':
        this.renderButton(elColumn, childrenElement);
        break;
      case 'input':
        this.renderInput(elColumn, childrenElement);
        break;
      case 'textarea':
        this.renderTextarea(elColumn, childrenElement);
        break;
      case 'title':
        this.renderTitle(elColumn, childrenElement);
        break;
      default:
        break;
    }
  }

  renderColumn(line, column) {
    const elColumn = document.createElement('div');
    elColumn.classList = 'tuls-column';
    if (this.editable) {
      elColumn.classList.add('tuls-column-empty');
    }
    elColumn.dataset.width = column.width;
    this.addStyles(elColumn, column.style);
    line.appendChild(elColumn);

    column.children.map(children => {
      this.renderChildren(elColumn, children);
      if (children) {
        elColumn.classList.remove('tuls-column-empty');
      }
    });
  }

  renderLine(line) {
    const elLine = document.createElement('div');
    elLine.classList = 'tuls-line';
    this.addStyles(elLine, line.line.style);
    this.rootElement.querySelector('.tuls-inner').appendChild(elLine);

    line.line.columns.map(column => {
      this.renderColumn(elLine, column);
    });
  }

  checkDevice({ editable }) {
    let formTargetDevice = this.config.info.targetDevice;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (formTargetDevice === 'Desktop' && !isMobile) {
      this.rootElement.style.display = 'block';
      this.render({ editable });
    } else if (formTargetDevice === 'Mobile' && isMobile) {
      this.rootElement.style.display = 'block';
      this.render({ editable });
    } else if (formTargetDevice === 'All') {
      this.rootElement.style.display = 'block';
      this.render({ editable });
    } else {
      this.rootElement.style.display = 'none';
    }
  }

  init({ editable }) {
    this.rootElement.style.display = 'none';

    if (!this.local) {
      if (this.getCookie('tulsFormClosed')) {
        return;
      }

      this.rootElement.style.display = 'block';
      this.render({ editable });
    } else {
      this.rootElement.style.display = 'block';
      this.render({ editable });
    }
  }

  getFonts() {
    const newFonts = new Set(this.fonts);
    const googleFontsLink = 'https://fonts.googleapis.com/css?family=';
    newFonts.forEach(font => {
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = `${googleFontsLink}${font}`;
      document.head.appendChild(style);
    });
  }

  render({ editable }) {
    this.initStyles();

    this.rootElement.querySelector('.tuls-inner').innerHTML = '';

    this.rootElement.querySelector('.tuls-inner').addEventListener('submit', e => {
      e.preventDefault();

      if (!this.local) {
        this.postForm(e);
      }
    });

    if (editable) {
      this.editable = true;
    }
    this.addStyles(this.rootElement, this.config.info.style);
    if (!this.local) {
      this.rootElement.style.width = 'auto';
    }
    this.rootElement.style.overflow = 'visible';
    this.config.lines.map(line => {
      this.renderLine(line);
    });

    this.getFonts();
  }
}

// module.exports = {
//   TemplateBuilder,
// };

export default FormBuilder;
