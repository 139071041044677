import React, { useState, useEffect } from 'react';

const FullWidth = ({ element }) => {
  const [isChecked, setIsChecked] = useState(false);

  const onChange = e => setIsChecked(prev => !prev);

  useEffect(() => {
    setIsChecked(element?.style?.width === '100%' ? true : false);
  }, []);

  useEffect(() => {
    if (isChecked) {
      element.style.width = '100%';
    } else {
      // element.style.width = 'auto';
    }
  }, [isChecked]);

  return (
    <div className="form-control-wrap">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="wull-width-checkbox" onChangeCapture={onChange} />
        <label class="custom-control-label" for="wull-width-checkbox">
          100%
        </label>
      </div>
    </div>
  );
};

export default FullWidth;
