import React, { useState, useEffect } from 'react';

const Select = ({ element, item, styleName, attributeName }) => {
  const [value, setValue] = useState(item.value);

  const onSelect = (e) => {
    setValue(e.target.value);
    if (element) {
      if (styleName) {
        element.style[styleName] = e.target.value;
      } else if (attributeName) {
        element.setAttribute(attributeName, e.target.value);
      }
    }
  };

  useEffect(() => {
    if (styleName && element) {
      if (element && element.style) {
        if (element.style[styleName]) {
          setValue([element.style[styleName]]);
        } else {
          setValue([item.value]);
        }
      }
    } else if (attributeName) {
      if (element) {
        if (element?.getAttribute(attributeName)) {
          setValue(element.getAttribute(attributeName));
        } else {
          element.setAttribute(attributeName, item.value);
        }
      }
    }
  }, [element]);

  return (
    <div className="form-group mt-4">
      <label className="form-label">
        {item.label}
      </label>

      <div className="form-control-wrap ">
        <div className="form-control-select">
          <select value={value} onChange={onSelect} className="form-control">
            {item.options.map((option, optionIndex) => {
              if (typeof option === 'string' || typeof option === 'number') {
                return (
                  <option key={optionIndex} value={option}>
                    {option}
                  </option>
                );
              } else if (typeof option === 'object') {
                return (
                  <option key={optionIndex} value={option.value}>
                    {option.name}
                  </option>
                );
              }
            })}
          </select>
        </div>
      </div>
    </div>
  );
}

export default Select;
