import React from 'react';
import ReactDOM from 'react-dom';
import { usePortal } from 'hooks/usePortal';

import './Modal.scss';

export const Modal = ({ title, onClose, className, style, children }) => {
  const target = usePortal('modal-root');

  return ReactDOM.createPortal(
    <div className={`modal show ${className}`} aria-modal="true" role="dialog">
      <div className="modal-dialog modal-dialog-popup" role="document" style={style}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            {onClose && (
              <span className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                <em className="icon ni ni-cross"></em>
              </span>
            )}
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>,
    target,
  );
};
