import React from 'react';
import moment from 'moment';
import { Avatar } from 'components';

import './ContactSidebar.scss';

export const ContactSidebar = ({ onDeleteModalConfirm, dataContact }) => {
  return (
    <div className="card-aside user-aside content-active">
      <div className="card-inner-group">
        <div className="simplebar-content">
          <div className="card-inner">
            <div className="user-card user-card-s2">
              <Avatar
                name={`${dataContact?.first_name} ${dataContact?.last_name}`}
                className="lg bg-purple"
                image={dataContact?.contact_avatar}
              />

              <div className="user-info">
                <h5>
                  {dataContact?.last_name} {dataContact?.first_name} {dataContact?.father_name}
                </h5>
                <span className="sub-text">
                  {dataContact?.email || dataContact?.phone ? `+${dataContact?.phone}` : ''}
                </span>
              </div>
            </div>
          </div>
          <div className="card-inner card-inner-sm">
            <ul className="btn-toolbar justify-center gx-1">
              <li>
                <a href="#s" className="btn btn-trigger btn-icon">
                  <i className="icon fas fa-user-edit"></i>
                </a>
              </li>
              <li>
                <a href="#s" className="btn btn-trigger btn-icon text-danger" onClick={onDeleteModalConfirm}>
                  <em className="icon ni ni-na"></em>
                </a>
              </li>
            </ul>
          </div>

          <div className="card-inner">
            <h6 className="overline-title-alt mb-2">Additional</h6>
            <div className="row g-3">
              <div className="col-6">
                <span className="sub-text">Source:</span>
                <span>{dataContact?.source}</span>
              </div>
              <div className="col-6">
                <span className="sub-text">Creation date:</span>
                <span>{moment(dataContact?.creation_date).utc(0).local().format('MMMM Do YYYY, h:mm a')}</span>
              </div>
              <div className="col-6">
                <span className="sub-text">User ID:</span>
                <span>{dataContact?.user_id}</span>
              </div>
              <div className="col-12" />
              <div className="col-6">
                <span className="sub-text">Flow name:</span>
                <span>{dataContact?.flow_name}</span>
              </div>
              <div className="col-6">
                <span className="sub-text">Flow done:</span>
                <span className={`lead-text text-${!dataContact?.flow_done ? 'danger' : 'success'}`}>
                  {!dataContact?.flow_done ? 'no' : 'yes'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="simplebar-placeholder"></div>

        <div className="simplebar-track simplebar-horizontal">
          <div className="simplebar-scrollbar"></div>
        </div>
        <div className="simplebar-track simplebar-vertical">
          <div className="simplebar-scrollbar"></div>
        </div>
      </div>
    </div>
  );
};
