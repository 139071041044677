import React from 'react';
import { Helmet } from 'react-helmet';
import { Dashboard } from 'layouts/Dashboard';
import { Sms } from 'components';

const SmsPage = () => {
  return (
    <>
      <Helmet>
        <title>Sms</title>
      </Helmet>

      <Dashboard>
        <Sms />
      </Dashboard>
    </>
  );
};

export default SmsPage;
