import { createSlice } from '@reduxjs/toolkit';

export const templateSlice = createSlice({
  name: 'template',
  initialState: {
    data: {},
  },
  reducers: {
    setTemplate: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setTemplate } = templateSlice.actions;

export default templateSlice.reducer;
