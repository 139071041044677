import React, { useState, useEffect } from 'react';

export const Avatar = ({ name }) => {
  const [abbr, setAbbr] = useState(null);

  useEffect(() => {
    const arrAbbr = name.split(' ');
    setAbbr(() => `${arrAbbr[0][0]}${arrAbbr[1] ? arrAbbr[1][0] : arrAbbr[0][1]}`.toUpperCase());
  }, []);

  return (
    <div className="chat-avatar">
      <div className="user-avatar bg-purple">
        <span>{abbr}</span>
      </div>
    </div>
  );
};
